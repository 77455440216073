export default () => {
    return {
        overrides: {
            MuiTouchRipple: {
                rippleVisible: {
                    animationDuration: '0ms',
                },
                ripplePulsate: {
                    animationDuration: '0ms',
                },
                childLeaving: {
                    animationDuration: '0ms',
                },
                childPulsate: {
                    animationDuration: '0ms',
                },
            },
        }
    }
}
