export default {
    AF: {
        code: 'AF',
        name: {
            en: 'Afghanistan',
            de: 'Afghanistan',
            fr: 'Afghanistan',
            it: 'Afghanistan',
        },
        currency: 'AFN',
    },
    AO: {
        code: 'AO',
        name: {
            en: 'Angola',
            de: 'Angola',
            fr: 'Angola',
            it: 'Angola',
        },
        currency: 'AOA',
    },
    AL: {
        code: 'AL',
        name: {
            en: 'Albania',
            de: 'Albanien',
            fr: 'Albanie',
            it: 'Albania',
        },
        currency: 'ALL',
    },
    AD: {
        code: 'AD',
        name: {
            en: 'Andorra',
            de: 'Andorra',
            fr: 'Andorre',
            it: 'Andorra',
        },
        currency: 'EUR',
    },
    AE: {
        code: 'AE',
        name: {
            en: 'United Arab Emirates',
            de: 'Vereinigte Arabische Emirate',
            fr: 'Émirats arabes unis',
            it: 'Emirati Arabi Uniti',
        },
        currency: 'AED',
    },
    AR: {
        code: 'AR',
        name: {
            en: 'Argentina',
            de: 'Argentinien',
            fr: 'Argentine',
            it: 'Argentina',
        },
        currency: 'ARS',
    },
    AM: {
        code: 'AM',
        name: {
            en: 'Armenia',
            de: 'Armenien',
            fr: 'Arménie',
            it: 'Armenia',
        },
        currency: 'AMD',
    },
    AG: {
        code: 'AG',
        name: {
            en: 'Antigua and Barbuda',
            de: 'Antigua und Barbuda',
            fr: 'Antigua-et-Barbuda',
            it: 'Antigua e Barbuda',
        },
        currency: 'XCD',
    },
    AU: {
        code: 'AU',
        name: {
            en: 'Australia',
            de: 'Australien',
            fr: 'Australie',
            it: 'Australia',
        },
        currency: 'AUD',
    },
    AT: {
        code: 'AT',
        name: {
            en: 'Austria',
            de: 'Österreich',
            fr: 'Autriche',
            it: 'Austria',
        },
        delimiters: {
            thousands: ' ',
            decimal: '.',
        },
        currency: 'EUR',
        sepa: true,
        validateZip: zip => {
            if (!zip || typeof zip !== 'string' || zip.length !== 4) {
                return false
            }

            const number = Number.parseInt(zip, 10)
            return !Number.isNaN(number) && number >= 1000 && number <= 9999
        },
        sampleZip: '1010',
        sampleCity: 'Wien',
        phoneCode: 43,
    },
    AZ: {
        code: 'AZ',
        name: {
            en: 'Azerbaijan',
            de: 'Aserbaidschan',
            fr: 'Azerbaïdjan',
            it: 'Azerbaijan',
        },
        currency: 'AZN',
    },
    BI: {
        code: 'BI',
        name: {
            en: 'Burundi',
            de: 'Burundi',
            fr: 'Burundi',
            it: 'Burundi',
        },
        currency: 'BIF',
    },
    BE: {
        code: 'BE',
        name: {
            en: 'Belgium',
            de: 'Belgien',
            fr: 'Belgique',
            it: 'Belgio',
        },
        currency: 'EUR',
    },
    BJ: {
        code: 'BJ',
        name: {
            en: 'Benin',
            de: 'Benin',
            fr: 'Bénin',
            it: 'Benin',
        },
        currency: 'XOF',
    },
    BF: {
        code: 'BF',
        name: {
            en: 'Burkina Faso',
            de: 'Burkina Faso',
            fr: 'Burkina Faso',
            it: 'Burkina Faso',
        },
        currency: 'XOF',
    },
    BD: {
        code: 'BD',
        name: {
            en: 'Bangladesh',
            de: 'Bangladesch',
            fr: 'Bangladesh',
            it: 'Bangladesh',
        },
        currency: 'BDT',
    },
    BG: {
        code: 'BG',
        name: {
            en: 'Bulgaria',
            de: 'Bulgarien',
            fr: 'Bulgarie',
            it: 'Bulgaria',
        },
        currency: 'BGN',
        phoneCode: 359,
    },
    BH: {
        code: 'BH',
        name: {
            en: 'Bahrain',
            de: 'Bahrain',
            fr: 'Bahreïn',
            it: 'Bahrein',
        },
        currency: 'BHD',
    },
    BS: {
        code: 'BS',
        name: {
            en: 'Bahamas',
            de: 'Bahamas',
            fr: 'Bahamas',
            it: 'Bahamas',
        },
        currency: 'BSD',
    },
    BA: {
        code: 'BA',
        name: {
            en: 'Bosnia and Herzegovina',
            de: 'Bosnien und Herzegowina',
            fr: 'Bosnie-Herzégovine',
            it: 'Bosnia ed Erzegovina',
        },
        currency: 'BAM',
    },
    BY: {
        code: 'BY',
        name: {
            en: 'Belarus',
            de: 'Weißrussland',
            fr: 'Biélorussie',
            it: 'Bielorussia',
        },
        currency: 'BYN',
    },
    BZ: {
        code: 'BZ',
        name: {
            en: 'Belize',
            de: 'Belize',
            fr: 'Belize',
            it: 'Belize',
        },
        currency: 'BZD',
    },
    BO: {
        code: 'BO',
        name: {
            en: 'Bolivia',
            de: 'Bolivien',
            fr: 'Bolivie',
            it: 'Bolivia',
        },
        currency: 'BOB',
    },
    BR: {
        code: 'BR',
        name: {
            en: 'Brazil',
            de: 'Brasilien',
            fr: 'Brésil',
            it: 'Brasile',
        },
        currency: 'BRL',
    },
    BB: {
        code: 'BB',
        name: {
            en: 'Barbados',
            de: 'Barbados',
            fr: 'Barbade',
            it: 'Barbados',
        },
        currency: 'BBD',
    },
    BN: {
        code: 'BN',
        name: {
            en: 'Brunei',
            de: 'Brunei',
            fr: 'Brunei',
            it: 'Brunei',
        },
        currency: 'BND',
    },
    BT: {
        code: 'BT',
        name: {
            en: 'Bhutan',
            de: 'Bhutan',
            fr: 'Bhoutan',
            it: 'Bhutan',
        },
        currency: 'BTN',
    },
    BW: {
        code: 'BW',
        name: {
            en: 'Botswana',
            de: 'Botswana',
            fr: 'Botswana',
            it: 'Botswana',
        },
        currency: 'BWP',
    },
    CF: {
        code: 'CF',
        name: {
            en: 'Central African Republic',
            de: 'Zentralafrikanische Republik',
            fr: 'République centrafricaine',
            it: 'Repubblica Centrafricana',
        },
        currency: 'XAF',
    },
    CA: {
        code: 'CA',
        name: {
            en: 'Canada',
            de: 'Kanada',
            fr: 'Canada',
            it: 'Canada',
        },
        currency: 'CAD',
    },
    CH: {
        code: 'CH',
        name: {
            en: 'Switzerland',
            de: 'Schweiz',
            fr: 'Suisse',
            it: 'Svizzera',
        },
        delimiters: {
            thousands: '’',
            decimal: '.',
        },
        currency: 'CHF',
        swissQRBill: true,
        validateZip: zip => {
            if (!zip || typeof zip !== 'string' || zip.length !== 4) {
                return false
            }

            const number = Number.parseInt(zip, 10)
            return !Number.isNaN(number) && number >= 1000 && number <= 9999
        },
        sampleZip: '8000',
        sampleCity: 'Zürich',
        phoneCode: 41,
    },
    CL: {
        code: 'CL',
        name: {
            en: 'Chile',
            de: 'Chile',
            fr: 'Chili',
            it: 'Cile',
        },
        currency: 'CLP',
    },
    CN: {
        code: 'CN',
        name: {
            en: 'China',
            de: 'China',
            fr: 'Chine',
            it: 'Cina',
        },
        currency: 'CNY',
    },
    CI: {
        code: 'CI',
        name: {
            en: 'Ivory Coast',
            de: 'Elfenbeinküste',
            fr: "Côte d'Ivoire",
            it: "Costa d'Avorio",
        },
        currency: 'XOF',
    },
    CM: {
        code: 'CM',
        name: {
            en: 'Cameroon',
            de: 'Kamerun',
            fr: 'Cameroun',
            it: 'Camerun',
        },
        currency: 'XAF',
    },
    CD: {
        code: 'CD',
        name: {
            en: 'DR Congo',
            de: 'Kongo (Dem. Rep.)',
            fr: 'Congo (Rép. dém.)',
            it: 'Congo (Rep. Dem.)',
        },
        currency: 'CDF',
    },
    CG: {
        code: 'CG',
        name: {
            en: 'Republic of the Congo',
            de: 'Kongo',
            fr: 'Congo',
            it: 'Congo',
        },
        currency: 'XAF',
    },
    CO: {
        code: 'CO',
        name: {
            en: 'Colombia',
            de: 'Kolumbien',
            fr: 'Colombie',
            it: 'Colombia',
        },
        currency: 'COP',
    },
    KM: {
        code: 'KM',
        name: {
            en: 'Comoros',
            de: 'Komoren',
            fr: 'Comores',
            it: 'Comore',
        },
        currency: 'KMF',
    },
    CV: {
        code: 'CV',
        name: {
            en: 'Cape Verde',
            de: 'Kap Verde',
            fr: 'Îles du Cap-Vert',
            it: 'Capo Verde',
        },
        currency: 'CVE',
    },
    CR: {
        code: 'CR',
        name: {
            en: 'Costa Rica',
            de: 'Costa Rica',
            fr: 'Costa Rica',
            it: 'Costa Rica',
        },
        currency: 'CRC',
    },
    CU: {
        code: 'CU',
        name: {
            en: 'Cuba',
            de: 'Kuba',
            fr: 'Cuba',
            it: 'Cuba',
        },
        currency: 'CUC',
    },
    CY: {
        code: 'CY',
        name: {
            en: 'Cyprus',
            de: 'Zypern',
            fr: 'Chypre',
            it: 'Cipro',
        },
        currency: 'EUR',
    },
    CZ: {
        code: 'CZ',
        name: {
            en: 'Czechia',
            de: 'Tschechien',
            fr: 'Tchéquie',
            it: 'Cechia',
        },
        currency: 'CZK',
    },
    DE: {
        code: 'DE',
        name: {
            en: 'Germany',
            de: 'Deutschland',
            fr: 'Allemagne',
            it: 'Germania',
        },
        delimiters: {
            thousands: '.',
            decimal: ',',
        },
        currency: 'EUR',
        sepa: true,
        validateZip: zip => {
            if (!zip || typeof zip !== 'string' || zip.length !== 5) {
                return false
            }

            const number = Number.parseInt(zip, 10)
            return !Number.isNaN(number) && number >= 1000 && number <= 99999
        },
        sampleZip: '10179',
        sampleCity: 'Berlin',
        phoneCode: 49,
    },
    DJ: {
        code: 'DJ',
        name: {
            en: 'Djibouti',
            de: 'Dschibuti',
            fr: 'Djibouti',
            it: 'Gibuti',
        },
        currency: 'DJF',
    },
    DM: {
        code: 'DM',
        name: {
            en: 'Dominica',
            de: 'Dominica',
            fr: 'Dominique',
            it: 'Dominica',
        },
        currency: 'XCD',
    },
    DK: {
        code: 'DK',
        name: {
            en: 'Denmark',
            de: 'Dänemark',
            fr: 'Danemark',
            it: 'Danimarca',
        },
        currency: 'DKK',
    },
    DO: {
        code: 'DO',
        name: {
            en: 'Dominican Republic',
            de: 'Dominikanische Republik',
            fr: 'République dominicaine',
            it: 'Repubblica Dominicana',
        },
        currency: 'DOP',
    },
    DZ: {
        code: 'DZ',
        name: {
            en: 'Algeria',
            de: 'Algerien',
            fr: 'Algérie',
            it: 'Algeria',
        },
        currency: 'DZD',
    },
    EC: {
        code: 'EC',
        name: {
            en: 'Ecuador',
            de: 'Ecuador',
            fr: 'Équateur',
            it: 'Ecuador',
        },
        currency: 'USD',
    },
    EG: {
        code: 'EG',
        name: {
            en: 'Egypt',
            de: 'Ägypten',
            fr: 'Égypte',
            it: 'Egitto',
        },
        currency: 'EGP',
    },
    ER: {
        code: 'ER',
        name: {
            en: 'Eritrea',
            de: 'Eritrea',
            fr: 'Érythrée',
            it: 'Eritrea',
        },
        currency: 'ERN',
    },
    ES: {
        code: 'ES',
        name: {
            en: 'Spain',
            de: 'Spanien',
            fr: 'Espagne',
            it: 'Spagna',
        },
        currency: 'EUR',
        phoneCode: 34,
    },
    EE: {
        code: 'EE',
        name: {
            en: 'Estonia',
            de: 'Estland',
            fr: 'Estonie',
            it: 'Estonia',
        },
        currency: 'EUR',
    },
    ET: {
        code: 'ET',
        name: {
            en: 'Ethiopia',
            de: 'Äthiopien',
            fr: 'Éthiopie',
            it: 'Etiopia',
        },
        currency: 'ETB',
    },
    FI: {
        code: 'FI',
        name: {
            en: 'Finland',
            de: 'Finnland',
            fr: 'Finlande',
            it: 'Finlandia',
        },
        currency: 'EUR',
    },
    FJ: {
        code: 'FJ',
        name: {
            en: 'Fiji',
            de: 'Fidschi',
            fr: 'Fidji',
            it: 'Figi',
        },
        currency: 'FJD',
    },
    FR: {
        code: 'FR',
        name: {
            en: 'France',
            de: 'Frankreich',
            fr: 'France',
            it: 'Francia',
        },
        currency: 'EUR',
        phoneCode: 33,
    },
    GA: {
        code: 'GA',
        name: {
            en: 'Gabon',
            de: 'Gabun',
            fr: 'Gabon',
            it: 'Gabon',
        },
        currency: 'XAF',
    },
    GB: {
        code: 'GB',
        name: {
            en: 'United Kingdom',
            de: 'Vereinigtes Königreich',
            fr: 'Royaume-Uni',
            it: 'Regno Unito',
        },
        currency: 'GBP',
        phoneCode: 44,
    },
    GE: {
        code: 'GE',
        name: {
            en: 'Georgia',
            de: 'Georgien',
            fr: 'Géorgie',
            it: 'Georgia',
        },
        currency: 'GEL',
    },
    GH: {
        code: 'GH',
        name: {
            en: 'Ghana',
            de: 'Ghana',
            fr: 'Ghana',
            it: 'Ghana',
        },
        currency: 'GHS',
    },
    GN: {
        code: 'GN',
        name: {
            en: 'Guinea',
            de: 'Guinea',
            fr: 'Guinée',
            it: 'Guinea',
        },
        currency: 'GNF',
    },
    GM: {
        code: 'GM',
        name: {
            en: 'Gambia',
            de: 'Gambia',
            fr: 'Gambie',
            it: 'Gambia',
        },
        currency: 'GMD',
    },
    GW: {
        code: 'GW',
        name: {
            en: 'Guinea-Bissau',
            de: 'Guinea-Bissau',
            fr: 'Guinée-Bissau',
            it: 'Guinea-Bissau',
        },
        currency: 'XOF',
    },
    GQ: {
        code: 'GQ',
        name: {
            en: 'Equatorial Guinea',
            de: 'Äquatorialguinea',
            fr: 'Guinée équatoriale',
            it: 'Guinea Equatoriale',
        },
        currency: 'XAF',
    },
    GR: {
        code: 'GR',
        name: {
            en: 'Greece',
            de: 'Griechenland',
            fr: 'Grèce',
            it: 'Grecia',
        },
        currency: 'EUR',
    },
    GD: {
        code: 'GD',
        name: {
            en: 'Grenada',
            de: 'Grenada',
            fr: 'Grenade',
            it: 'Grenada',
        },
        currency: 'XCD',
    },
    GT: {
        code: 'GT',
        name: {
            en: 'Guatemala',
            de: 'Guatemala',
            fr: 'Guatemala',
            it: 'Guatemala',
        },
        currency: 'GTQ',
    },
    GY: {
        code: 'GY',
        name: {
            en: 'Guyana',
            de: 'Guyana',
            fr: 'Guyana',
            it: 'Guyana',
        },
        currency: 'GYD',
    },
    HN: {
        code: 'HN',
        name: {
            en: 'Honduras',
            de: 'Honduras',
            fr: 'Honduras',
            it: 'Honduras',
        },
        currency: 'HNL',
    },
    HR: {
        code: 'HR',
        name: {
            en: 'Croatia',
            de: 'Kroatien',
            fr: 'Croatie',
            it: 'Croazia',
        },
        currency: 'HRK',
    },
    HT: {
        code: 'HT',
        name: {
            en: 'Haiti',
            de: 'Haiti',
            fr: 'Haïti',
            it: 'Haiti',
        },
        currency: 'HTG',
    },
    HU: {
        code: 'HU',
        name: {
            en: 'Hungary',
            de: 'Ungarn',
            fr: 'Hongrie',
            it: 'Ungheria',
        },
        currency: 'HUF',
    },
    ID: {
        code: 'ID',
        name: {
            en: 'Indonesia',
            de: 'Indonesien',
            fr: 'Indonésie',
            it: 'Indonesia',
        },
        currency: 'IDR',
    },
    IN: {
        code: 'IN',
        name: {
            en: 'India',
            de: 'Indien',
            fr: 'Inde',
            it: 'India',
        },
        currency: 'INR',
    },
    IE: {
        code: 'IE',
        name: {
            en: 'Ireland',
            de: 'Irland',
            fr: 'Irlande',
            it: 'Irlanda',
        },
        currency: 'EUR',
    },
    IR: {
        code: 'IR',
        name: {
            en: 'Iran',
            de: 'Iran',
            fr: 'Iran',
            it: 'Iran',
        },
        currency: 'IRR',
    },
    IQ: {
        code: 'IQ',
        name: {
            en: 'Iraq',
            de: 'Irak',
            fr: 'Irak',
            it: 'Iraq',
        },
        currency: 'IQD',
    },
    IS: {
        code: 'IS',
        name: {
            en: 'Iceland',
            de: 'Island',
            fr: 'Islande',
            it: 'Islanda',
        },
        currency: 'ISK',
    },
    IL: {
        code: 'IL',
        name: {
            en: 'Israel',
            de: 'Israel',
            fr: 'Israël',
            it: 'Israele',
        },
        currency: 'ILS',
    },
    IT: {
        code: 'IT',
        name: {
            en: 'Italy',
            de: 'Italien',
            fr: 'Italie',
            it: 'Italia',
        },
        currency: 'EUR',
        phoneCode: 39,
    },
    JM: {
        code: 'JM',
        name: {
            en: 'Jamaica',
            de: 'Jamaika',
            fr: 'Jamaïque',
            it: 'Giamaica',
        },
        currency: 'JMD',
    },
    JO: {
        code: 'JO',
        name: {
            en: 'Jordan',
            de: 'Jordanien',
            fr: 'Jordanie',
            it: 'Giordania',
        },
        currency: 'JOD',
    },
    JP: {
        code: 'JP',
        name: {
            en: 'Japan',
            de: 'Japan',
            fr: 'Japon',
            it: 'Giappone',
        },
        currency: 'JPY',
    },
    KZ: {
        code: 'KZ',
        name: {
            en: 'Kazakhstan',
            de: 'Kasachstan',
            fr: 'Kazakhstan',
            it: 'Kazakistan',
        },
        currency: 'KZT',
    },
    KE: {
        code: 'KE',
        name: {
            en: 'Kenya',
            de: 'Kenia',
            fr: 'Kenya',
            it: 'Kenya',
        },
        currency: 'KES',
    },
    KG: {
        code: 'KG',
        name: {
            en: 'Kyrgyzstan',
            de: 'Kirgisistan',
            fr: 'Kirghizistan',
            it: 'Kirghizistan',
        },
        currency: 'KGS',
    },
    KH: {
        code: 'KH',
        name: {
            en: 'Cambodia',
            de: 'Kambodscha',
            fr: 'Cambodge',
            it: 'Cambogia',
        },
        currency: 'KHR',
    },
    KI: {
        code: 'KI',
        name: {
            en: 'Kiribati',
            de: 'Kiribati',
            fr: 'Kiribati',
            it: 'Kiribati',
        },
        currency: 'AUD',
    },
    KN: {
        code: 'KN',
        name: {
            en: 'Saint Kitts and Nevis',
            de: 'St. Kitts und Nevis',
            fr: 'Saint-Christophe-et-Niévès',
            it: 'Saint Kitts e Nevis',
        },
        currency: 'XCD',
    },
    KR: {
        code: 'KR',
        name: {
            en: 'South Korea',
            de: 'Südkorea',
            fr: 'Corée du Sud',
            it: 'Corea del Sud',
        },
        currency: 'KRW',
    },
    KW: {
        code: 'KW',
        name: {
            en: 'Kuwait',
            de: 'Kuwait',
            fr: 'Koweït',
            it: 'Kuwait',
        },
        currency: 'KWD',
    },
    LA: {
        code: 'LA',
        name: {
            en: 'Laos',
            de: 'Laos',
            fr: 'Laos',
            it: 'Laos',
        },
        currency: 'LAK',
    },
    LB: {
        code: 'LB',
        name: {
            en: 'Lebanon',
            de: 'Libanon',
            fr: 'Liban',
            it: 'Libano',
        },
        currency: 'LBP',
    },
    LR: {
        code: 'LR',
        name: {
            en: 'Liberia',
            de: 'Liberia',
            fr: 'Liberia',
            it: 'Liberia',
        },
        currency: 'LRD',
    },
    LY: {
        code: 'LY',
        name: {
            en: 'Libya',
            de: 'Libyen',
            fr: 'Libye',
            it: 'Libia',
        },
        currency: 'LYD',
    },
    LC: {
        code: 'LC',
        name: {
            en: 'Saint Lucia',
            de: 'St. Lucia',
            fr: 'Sainte-Lucie',
            it: 'Santa Lucia',
        },
        currency: 'XCD',
    },
    LI: {
        code: 'LI',
        name: {
            en: 'Liechtenstein',
            de: 'Liechtenstein',
            fr: 'Liechtenstein',
            it: 'Liechtenstein',
        },
        delimiters: {
            thousands: '’',
            decimal: '.',
        },
        currency: 'CHF',
        swissQRBill: true,
        validateZip: zip => {
            if (!zip || typeof zip !== 'string' || zip.length !== 4) {
                return false
            }

            const number = Number.parseInt(zip, 10)
            return !Number.isNaN(number) && number >= 1000 && number <= 9999
        },
        phoneCode: 423,
    },
    LK: {
        code: 'LK',
        name: {
            en: 'Sri Lanka',
            de: 'Sri Lanka',
            fr: 'Sri Lanka',
            it: 'Sri Lanka',
        },
        currency: 'LKR',
    },
    LS: {
        code: 'LS',
        name: {
            en: 'Lesotho',
            de: 'Lesotho',
            fr: 'Lesotho',
            it: 'Lesotho',
        },
        currency: 'LSL',
    },
    LT: {
        code: 'LT',
        name: {
            en: 'Lithuania',
            de: 'Litauen',
            fr: 'Lituanie',
            it: 'Lituania',
        },
        currency: 'EUR',
    },
    LU: {
        code: 'LU',
        name: {
            en: 'Luxembourg',
            de: 'Luxemburg',
            fr: 'Luxembourg',
            it: 'Lussemburgo',
        },
        currency: 'EUR',
    },
    LV: {
        code: 'LV',
        name: {
            en: 'Latvia',
            de: 'Lettland',
            fr: 'Lettonie',
            it: 'Lettonia',
        },
        currency: 'EUR',
    },
    MA: {
        code: 'MA',
        name: {
            en: 'Morocco',
            de: 'Marokko',
            fr: 'Maroc',
            it: 'Marocco',
        },
        currency: 'MAD',
    },
    MC: {
        code: 'MC',
        name: {
            en: 'Monaco',
            de: 'Monaco',
            fr: 'Monaco',
            it: 'Principato di Monaco',
        },
        currency: 'EUR',
    },
    MD: {
        code: 'MD',
        name: {
            en: 'Moldova',
            de: 'Moldawien',
            fr: 'Moldavie',
            it: 'Moldavia',
        },
        currency: 'MDL',
    },
    MG: {
        code: 'MG',
        name: {
            en: 'Madagascar',
            de: 'Madagaskar',
            fr: 'Madagascar',
            it: 'Madagascar',
        },
        currency: 'MGA',
    },
    MV: {
        code: 'MV',
        name: {
            en: 'Maldives',
            de: 'Malediven',
            fr: 'Maldives',
            it: 'Maldive',
        },
        currency: 'MVR',
    },
    MX: {
        code: 'MX',
        name: {
            en: 'Mexico',
            de: 'Mexiko',
            fr: 'Mexique',
            it: 'Messico',
        },
        currency: 'MXN',
    },
    MH: {
        code: 'MH',
        name: {
            en: 'Marshall Islands',
            de: 'Marshallinseln',
            fr: 'Îles Marshall',
            it: 'Isole Marshall',
        },
        currency: 'USD',
    },
    MK: {
        code: 'MK',
        name: {
            en: 'North Macedonia',
            de: 'Nordmazedonien',
            fr: 'Macédoine du Nord',
            it: 'Macedonia del Nord',
        },
        currency: 'MKD',
    },
    ML: {
        code: 'ML',
        name: {
            en: 'Mali',
            de: 'Mali',
            fr: 'Mali',
            it: 'Mali',
        },
        currency: 'XOF',
    },
    MT: {
        code: 'MT',
        name: {
            en: 'Malta',
            de: 'Malta',
            fr: 'Malte',
            it: 'Malta',
        },
        currency: 'EUR',
    },
    MM: {
        code: 'MM',
        name: {
            en: 'Myanmar',
            de: 'Myanmar',
            fr: 'Birmanie',
            it: 'Birmania',
        },
        currency: 'MMK',
    },
    ME: {
        code: 'ME',
        name: {
            en: 'Montenegro',
            de: 'Montenegro',
            fr: 'Monténégro',
            it: 'Montenegro',
        },
        currency: 'EUR',
    },
    MN: {
        code: 'MN',
        name: {
            en: 'Mongolia',
            de: 'Mongolei',
            fr: 'Mongolie',
            it: 'Mongolia',
        },
        currency: 'MNT',
    },
    MZ: {
        code: 'MZ',
        name: {
            en: 'Mozambique',
            de: 'Mosambik',
            fr: 'Mozambique',
            it: 'Mozambico',
        },
        currency: 'MZN',
    },
    MR: {
        code: 'MR',
        name: {
            en: 'Mauritania',
            de: 'Mauretanien',
            fr: 'Mauritanie',
            it: 'Mauritania',
        },
        currency: 'MRU',
    },
    MU: {
        code: 'MU',
        name: {
            en: 'Mauritius',
            de: 'Mauritius',
            fr: 'Île Maurice',
            it: 'Mauritius',
        },
        currency: 'MUR',
    },
    MW: {
        code: 'MW',
        name: {
            en: 'Malawi',
            de: 'Malawi',
            fr: 'Malawi',
            it: 'Malawi',
        },
        currency: 'MWK',
    },
    MY: {
        code: 'MY',
        name: {
            en: 'Malaysia',
            de: 'Malaysia',
            fr: 'Malaisie',
            it: 'Malesia',
        },
        currency: 'MYR',
    },
    NA: {
        code: 'NA',
        name: {
            en: 'Namibia',
            de: 'Namibia',
            fr: 'Namibie',
            it: 'Namibia',
        },
        currency: 'NAD',
    },
    NE: {
        code: 'NE',
        name: {
            en: 'Niger',
            de: 'Niger',
            fr: 'Niger',
            it: 'Niger',
        },
        currency: 'XOF',
    },
    NG: {
        code: 'NG',
        name: {
            en: 'Nigeria',
            de: 'Nigeria',
            fr: 'Nigéria',
            it: 'Nigeria',
        },
        currency: 'NGN',
    },
    NI: {
        code: 'NI',
        name: {
            en: 'Nicaragua',
            de: 'Nicaragua',
            fr: 'Nicaragua',
            it: 'Nicaragua',
        },
        currency: 'NIO',
    },
    NL: {
        code: 'NL',
        name: {
            en: 'Netherlands',
            de: 'Niederlande',
            fr: 'Pays-Bas',
            it: 'Paesi Bassi',
        },
        currency: 'EUR',
        phoneCode: 31,
    },
    NO: {
        code: 'NO',
        name: {
            en: 'Norway',
            de: 'Norwegen',
            fr: 'Norvège',
            it: 'Norvegia',
        },
        currency: 'NOK',
    },
    NP: {
        code: 'NP',
        name: {
            en: 'Nepal',
            de: 'Nepal',
            fr: 'Népal',
            it: 'Nepal',
        },
        currency: 'NPR',
    },
    NR: {
        code: 'NR',
        name: {
            en: 'Nauru',
            de: 'Nauru',
            fr: 'Nauru',
            it: 'Nauru',
        },
        currency: 'AUD',
    },
    NZ: {
        code: 'NZ',
        name: {
            en: 'New Zealand',
            de: 'Neuseeland',
            fr: 'Nouvelle-Zélande',
            it: 'Nuova Zelanda',
        },
        currency: 'NZD',
    },
    OM: {
        code: 'OM',
        name: {
            en: 'Oman',
            de: 'Oman',
            fr: 'Oman',
            it: 'oman',
        },
        currency: 'OMR',
    },
    PK: {
        code: 'PK',
        name: {
            en: 'Pakistan',
            de: 'Pakistan',
            fr: 'Pakistan',
            it: 'Pakistan',
        },
        currency: 'PKR',
    },
    PA: {
        code: 'PA',
        name: {
            en: 'Panama',
            de: 'Panama',
            fr: 'Panama',
            it: 'Panama',
        },
        currency: 'PAB',
    },
    PE: {
        code: 'PE',
        name: {
            en: 'Peru',
            de: 'Peru',
            fr: 'Pérou',
            it: 'Perù',
        },
        currency: 'PEN',
    },
    PH: {
        code: 'PH',
        name: {
            en: 'Philippines',
            de: 'Philippinen',
            fr: 'Philippines',
            it: 'Filippine',
        },
        currency: 'PHP',
    },
    PW: {
        code: 'PW',
        name: {
            en: 'Palau',
            de: 'Palau',
            fr: 'Palaos (Palau)',
            it: 'Palau',
        },
        currency: 'USD',
    },
    PG: {
        code: 'PG',
        name: {
            en: 'Papua New Guinea',
            de: 'Papua-Neuguinea',
            fr: 'Papouasie-Nouvelle-Guinée',
            it: 'Papua Nuova Guinea',
        },
        currency: 'PGK',
    },
    PL: {
        code: 'PL',
        name: {
            en: 'Poland',
            de: 'Polen',
            fr: 'Pologne',
            it: 'Polonia',
        },
        currency: 'PLN',
        phoneCode: 48,
    },
    KP: {
        code: 'KP',
        name: {
            en: 'North Korea',
            de: 'Nordkorea',
            fr: 'Corée du Nord',
            it: 'Corea del Nord',
        },
        currency: 'KPW',
    },
    PT: {
        code: 'PT',
        name: {
            en: 'Portugal',
            de: 'Portugal',
            fr: 'Portugal',
            it: 'Portogallo',
        },
        currency: 'EUR',
    },
    PY: {
        code: 'PY',
        name: {
            en: 'Paraguay',
            de: 'Paraguay',
            fr: 'Paraguay',
            it: 'Paraguay',
        },
        currency: 'PYG',
    },
    QA: {
        code: 'QA',
        name: {
            en: 'Qatar',
            de: 'Katar',
            fr: 'Qatar',
            it: 'Qatar',
        },
        currency: 'QAR',
    },
    RO: {
        code: 'RO',
        name: {
            en: 'Romania',
            de: 'Rumänien',
            fr: 'Roumanie',
            it: 'Romania',
        },
        currency: 'RON',
    },
    RU: {
        code: 'RU',
        name: {
            en: 'Russia',
            de: 'Russland',
            fr: 'Russie',
            it: 'Russia',
        },
        currency: 'RUB',
    },
    RW: {
        code: 'RW',
        name: {
            en: 'Rwanda',
            de: 'Ruanda',
            fr: 'Rwanda',
            it: 'Ruanda',
        },
        currency: 'RWF',
    },
    SA: {
        code: 'SA',
        name: {
            en: 'Saudi Arabia',
            de: 'Saudi-Arabien',
            fr: 'Arabie Saoudite',
            it: 'Arabia Saudita',
        },
        currency: 'SAR',
    },
    SD: {
        code: 'SD',
        name: {
            en: 'Sudan',
            de: 'Sudan',
            fr: 'Soudan',
            it: 'Sudan',
        },
        currency: 'SDG',
    },
    SN: {
        code: 'SN',
        name: {
            en: 'Senegal',
            de: 'Senegal',
            fr: 'Sénégal',
            it: 'Senegal',
        },
        currency: 'XOF',
    },
    SG: {
        code: 'SG',
        name: {
            en: 'Singapore',
            de: 'Singapur',
            fr: 'Singapour',
            it: 'Singapore',
        },
        currency: 'SGD',
    },
    SB: {
        code: 'SB',
        name: {
            en: 'Solomon Islands',
            de: 'Salomonen',
            fr: 'Îles Salomon',
            it: 'Isole Salomone',
        },
        currency: 'SBD',
    },
    SL: {
        code: 'SL',
        name: {
            en: 'Sierra Leone',
            de: 'Sierra Leone',
            fr: 'Sierra Leone',
            it: 'Sierra Leone',
        },
        currency: 'SLL',
    },
    SV: {
        code: 'SV',
        name: {
            en: 'El Salvador',
            de: 'El Salvador',
            fr: 'Salvador',
            it: 'El Salvador',
        },
        currency: 'USD',
    },
    SM: {
        code: 'SM',
        name: {
            en: 'San Marino',
            de: 'San Marino',
            fr: 'Saint-Marin',
            it: 'San Marino',
        },
        currency: 'EUR',
    },
    SO: {
        code: 'SO',
        name: {
            en: 'Somalia',
            de: 'Somalia',
            fr: 'Somalie',
            it: 'Somalia',
        },
        currency: 'SOS',
    },
    RS: {
        code: 'RS',
        name: {
            en: 'Serbia',
            de: 'Serbien',
            fr: 'Serbie',
            it: 'Serbia',
        },
        currency: 'RSD',
    },
    SS: {
        code: 'SS',
        name: {
            en: 'South Sudan',
            de: 'Südsudan',
            fr: 'Soudan du Sud',
            it: 'Sudan del sud',
        },
        currency: 'SSP',
    },
    ST: {
        code: 'ST',
        name: {
            en: 'São Tomé and Príncipe',
            de: 'São Tomé und Príncipe',
            fr: 'São Tomé et Príncipe',
            it: 'São Tomé e Príncipe',
        },
        currency: 'STN',
    },
    SR: {
        code: 'SR',
        name: {
            en: 'Suriname',
            de: 'Suriname',
            fr: 'Surinam',
            it: 'Suriname',
        },
        currency: 'SRD',
    },
    SK: {
        code: 'SK',
        name: {
            en: 'Slovakia',
            de: 'Slowakei',
            fr: 'Slovaquie',
            it: 'Slovacchia',
        },
        currency: 'EUR',
    },
    SI: {
        code: 'SI',
        name: {
            en: 'Slovenia',
            de: 'Slowenien',
            fr: 'Slovénie',
            it: 'Slovenia',
        },
        currency: 'EUR',
    },
    SE: {
        code: 'SE',
        name: {
            en: 'Sweden',
            de: 'Schweden',
            fr: 'Suède',
            it: 'Svezia',
        },
        currency: 'SEK',
    },
    SZ: {
        code: 'SZ',
        name: {
            en: 'Eswatini',
            de: 'Swasiland',
            fr: 'Swaziland',
            it: 'Swaziland',
        },
        currency: 'SZL',
    },
    SC: {
        code: 'SC',
        name: {
            en: 'Seychelles',
            de: 'Seychellen',
            fr: 'Seychelles',
            it: 'Seychelles',
        },
        currency: 'SCR',
    },
    SY: {
        code: 'SY',
        name: {
            en: 'Syria',
            de: 'Syrien',
            fr: 'Syrie',
            it: 'Siria',
        },
        currency: 'SYP',
    },
    TD: {
        code: 'TD',
        name: {
            en: 'Chad',
            de: 'Tschad',
            fr: 'Tchad',
            it: 'Ciad',
        },
        currency: 'XAF',
    },
    TG: {
        code: 'TG',
        name: {
            en: 'Togo',
            de: 'Togo',
            fr: 'Togo',
            it: 'Togo',
        },
        currency: 'XOF',
    },
    TH: {
        code: 'TH',
        name: {
            en: 'Thailand',
            de: 'Thailand',
            fr: 'Thaïlande',
            it: 'Tailandia',
        },
        currency: 'THB',
    },
    TJ: {
        code: 'TJ',
        name: {
            en: 'Tajikistan',
            de: 'Tadschikistan',
            fr: 'Tadjikistan',
            it: 'Tagikistan',
        },
        currency: 'TJS',
    },
    TM: {
        code: 'TM',
        name: {
            en: 'Turkmenistan',
            de: 'Turkmenistan',
            fr: 'Turkménistan',
            it: 'Turkmenistan',
        },
        currency: 'TMT',
    },
    TL: {
        code: 'TL',
        name: {
            en: 'Timor-Leste',
            de: 'Osttimor',
            fr: 'Timor oriental',
            it: 'Timor Est',
        },
        currency: 'USD',
    },
    TO: {
        code: 'TO',
        name: {
            en: 'Tonga',
            de: 'Tonga',
            fr: 'Tonga',
            it: 'Tonga',
        },
        currency: 'TOP',
    },
    TT: {
        code: 'TT',
        name: {
            en: 'Trinidad and Tobago',
            de: 'Trinidad und Tobago',
            fr: 'Trinité-et-Tobago',
            it: 'Trinidad e Tobago',
        },
        currency: 'TTD',
    },
    TN: {
        code: 'TN',
        name: {
            en: 'Tunisia',
            de: 'Tunesien',
            fr: 'Tunisie',
            it: 'Tunisia',
        },
        currency: 'TND',
    },
    TR: {
        code: 'TR',
        name: {
            en: 'Turkey',
            de: 'Türkei',
            fr: 'Turquie',
            it: 'Turchia',
        },
        currency: 'TRY',
    },
    TV: {
        code: 'TV',
        name: {
            en: 'Tuvalu',
            de: 'Tuvalu',
            fr: 'Tuvalu',
            it: 'Tuvalu',
        },
        currency: 'AUD',
    },
    TZ: {
        code: 'TZ',
        name: {
            en: 'Tanzania',
            de: 'Tansania',
            fr: 'Tanzanie',
            it: 'Tanzania',
        },
        currency: 'TZS',
    },
    UG: {
        code: 'UG',
        name: {
            en: 'Uganda',
            de: 'Uganda',
            fr: 'Ouganda',
            it: 'Uganda',
        },
        currency: 'UGX',
    },
    UA: {
        code: 'UA',
        name: {
            en: 'Ukraine',
            de: 'Ukraine',
            fr: 'Ukraine',
            it: 'Ucraina',
        },
        currency: 'UAH',
    },
    UY: {
        code: 'UY',
        name: {
            en: 'Uruguay',
            de: 'Uruguay',
            fr: 'Uruguay',
            it: 'Uruguay',
        },
        currency: 'UYU',
    },
    US: {
        code: 'US',
        name: {
            en: 'United States',
            de: 'Vereinigte Staaten',
            fr: 'États-Unis',
            it: "Stati Uniti d'America",
        },
        currency: 'USD',
        phoneCode: 1,
    },
    UZ: {
        code: 'UZ',
        name: {
            en: 'Uzbekistan',
            de: 'Usbekistan',
            fr: 'Ouzbékistan',
            it: 'Uzbekistan',
        },
        currency: 'UZS',
    },
    VA: {
        code: 'VA',
        name: {
            en: 'Vatican City',
            de: 'Vatikanstadt',
            fr: 'Cité du Vatican',
            it: 'Città del Vaticano',
        },
        currency: 'EUR',
    },
    VC: {
        code: 'VC',
        name: {
            en: 'Saint Vincent and the Grenadines',
            de: 'St. Vincent und die Grenadinen',
            fr: 'Saint-Vincent-et-les-Grenadines',
            it: 'Saint Vincent e Grenadine',
        },
        currency: 'XCD',
    },
    VE: {
        code: 'VE',
        name: {
            en: 'Venezuela',
            de: 'Venezuela',
            fr: 'Venezuela',
            it: 'Venezuela',
        },
        currency: 'VES',
    },
    VN: {
        code: 'VN',
        name: {
            en: 'Vietnam',
            de: 'Vietnam',
            fr: 'Viêt Nam',
            it: 'Vietnam',
        },
        currency: 'VND',
    },
    VU: {
        code: 'VU',
        name: {
            en: 'Vanuatu',
            de: 'Vanuatu',
            fr: 'Vanuatu',
            it: 'Vanuatu',
        },
        currency: 'VUV',
    },
    WS: {
        code: 'WS',
        name: {
            en: 'Samoa',
            de: 'Samoa',
            fr: 'Samoa',
            it: 'Samoa',
        },
        currency: 'WST',
    },
    YE: {
        code: 'YE',
        name: {
            en: 'Yemen',
            de: 'Jemen',
            fr: 'Yémen',
            it: 'Yemen',
        },
        currency: 'YER',
    },
    ZA: {
        code: 'ZA',
        name: {
            en: 'South Africa',
            de: 'Südafrika',
            fr: 'Afrique du Sud',
            it: 'Sud Africa',
        },
        currency: 'ZAR',
    },
    ZM: {
        code: 'ZM',
        name: {
            en: 'Zambia',
            de: 'Sambia',
            fr: 'Zambie',
            it: 'Zambia',
        },
        currency: 'ZMW',
    },
    ZW: {
        code: 'ZW',
        name: {
            en: 'Zimbabwe',
            de: 'Simbabwe',
            fr: 'Zimbabwe',
            it: 'Zimbabwe',
        },
        currency: 'BWP',
    },
}
