import mediaBase from '../media.base'

import loadImage from './loadImage'

export default async (source, { maxWidth, maxHeight, jpeg, compress } = {}) => {
    if (!maxWidth || !maxHeight) {
        throw new Error('maxWidth and maxHeight required')
    }

    const image = await loadImage(source)

    const { width, height } = image

    const { width: resizeWidth, height: resizeHeight } = mediaBase.calculateResizeWidth({
        width,
        height,
        maxWidth,
        maxHeight,
    })

    // we do not implement high dpi canvas here for the following reason:
    // when we export the canvas to image, the resulting image will be scaled up
    // thus will be recognized as double size as it should be, making it extremely blurry.
    // we cannot set the dpi of the image anyway, so at least this way we do not
    // increase the image size unkowlingly and when scaled down it still looks like high dpi
    // eslint-disable-next-line no-undef
    const canvas = document.createElement('canvas')
    canvas.width = resizeWidth
    canvas.height = resizeHeight

    const context = canvas.getContext('2d')

    context.drawImage(image, 0, 0, resizeWidth, resizeHeight)

    const imageType = jpeg ? 'image/jpeg' : 'image/png'

    const blob = await new Promise(resolve => canvas.toBlob(resolve, imageType, compress ? 0.75 : 1.0))

    return {
        blob,
        mimeType: imageType,
        width: resizeWidth,
        height: resizeHeight,
        originalWidth: width,
        originalHeight: height,
    }
}
