import React from 'react'
import { observer } from 'mobx-react-lite'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Runtime from '@newspaces/lucky-base/Runtime'

import Avatar from '@newspaces/lucky-components/Avatar'
import Link from '@newspaces/lucky-components/Link'

import i18n from '../i18n'

import AppContext from '../AppContext'

import AppFrame from './AppFrame'

const useStyles = makeStyles(theme => ({
    root: {
        paddingBottom: theme.spacing(2),
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    title: {
        fontSize: 16,
        fontWeight: 500,
        marginTop: theme.spacing(2),
    },
    listHeaderItem: {},
    listItem: {
        minWidth: 400,
        [theme.breakpoints.down('sm')]: {
            minWidth: 'auto',
        },

        '& .MuiListItemText-root': {
            margin: 0,
        },
    },
    listItemContainer: {},
    appIcon: {
        width: 32,
        height: 32,
        marginRight: theme.spacing(2),
    },
}))

export default observer(({ renderAppFrame }) => {
    const classes = useStyles()

    const { zone } = Runtime
    const { organisation, appsUserHasAccessTo } = AppContext

    const Content = (
        <div className={classes.root}>
            {organisation && (
                <div className={classes.header}>
                    <Avatar variant="none" src={organisation.avatar.url} name={organisation.name} />
                    <div className={classes.title}>{organisation.name}</div>
                </div>
            )}
            {!organisation && (
                <div className={classes.header}>
                    <Avatar src={zone.Icon} name={zone.name} />
                    <div className={classes.title}>{zone.name}</div>
                </div>
            )}
            <List disablePadding>
                <ListSubheader className={classes.listHeaderItem} disableSticky>
                    {i18n().app.availableApps}
                </ListSubheader>
                {appsUserHasAccessTo.map(({ key, name, description, Icon, getTheme }) => {
                    const nameString = name()
                    const descriptionString = description && description()
                    const themePrimaryColor = getTheme && getTheme(organisation).primaryColor
                    const primaryColor = themePrimaryColor || zone?.primaryColor

                    return (
                        <ListItem
                            key={key}
                            alignItems={descriptionString ? 'flex-start' : 'center'}
                            button
                            component={Link}
                            href={`${AppContext.getRootUrl(key, organisation?.slugOrId)}`}
                            classes={{
                                container: classes.listItemContainer,
                                root: classNames(classes.listItem, { centered: !descriptionString }),
                            }}
                        >
                            <Icon className={classes.appIcon} color={primaryColor || 'neutral'} />
                            <ListItemText primary={nameString} secondary={descriptionString} />
                        </ListItem>
                    )
                })}
            </List>
        </div>
    )

    if (renderAppFrame) {
        return <AppFrame>{Content}</AppFrame>
    }

    return Content
})
