import nanoid from 'nanoid'

import Runtime from '../Runtime'

import utils from '../utils'

import Model from './Model'

import decorateModel from './decorateModel'

export default class Resource extends Model {
    // -- required to test for resource and avoiding circular deps in Model
    static IS_RESOURCE = true

    static Fields = {
        // -- only used in triggers for cleaning up --
        isResourceModel: {
            type: Boolean,
            default: true,
        },
        type: {
            type: String,
        },
        meta: {
            type: Object,
            default: () => {},
        },
        filename: {
            type: String,
        },
        filesize: {
            type: Number,
        },
        path: {
            type: String,
        },
        previewPath: {
            type: String,
        },
        // -- internal --
        uploadData: {
            type: Object,
            default: () => ({}),
            serialize: false,
            deserialize: false,
        },
        deleteData: {
            type: Object,
            default: () => ({}),
            serialize: false,
            deserialize: false,
        },
    }

    static getType(mimeType) {
        if (mimeType?.indexOf('image/') === 0) {
            return 'image'
        }
        if (mimeType?.indexOf('video/') === 0) {
            return 'video'
        }

        return 'file'
    }

    settings = null

    constructor(settings) {
        super(Resource.Fields)
        this.settings = { ...settings }

        if (this.settings.preview === true) {
            this.settings.preview = {}
        }

        if (this.settings.preview && !settings.preview.maxWidth && !settings.preview.maxHeight) {
            this.settings.preview.maxWidth = 512
            this.settings.preview.maxHeight = 512
        }
    }

    markSaved() {
        super.markSaved()

        this.uploadData = null
        this.deleteData = null
    }

    get downloadUrl() {
        if (this.uploadData) {
            return this.url
        }

        return this.path ? Runtime.getStorageUrl(this.path, 'get', true) : null
    }

    get url() {
        if (this.uploadData) {
            return this.uploadData.data || null
        }

        return this.path ? Runtime.getStorageUrl(this.path) : null
    }

    get previewUrl() {
        if (this.uploadData) {
            return this.uploadData.previewData || null
        }

        return this.previewPath ? Runtime.getStorageUrl(this.previewPath) : null
    }

    fromResource(resource) {
        this.type = resource.type
        this.meta = { ...resource.meta }
        this.filename = resource.filename
        this.filesize = resource.filesize
        this.path = resource.path
        this.previewPath = resource.previewPath

        this.uploadData = resource.uploadData
    }

    async fromBlob(blobOrFile, { forceBinaryFile } = {}) {
        if (process.env.RUNTIME_TARGET === 'browser') {
            this.clear() // mark any old content for deletion

            if (!(blobOrFile instanceof File) && !(blobOrFile instanceof Blob)) {
                throw new Error('Expected File or Blob')
            }
            if (!blobOrFile.name) {
                throw new Error('Missing name for blob')
            }

            const uploadData = {
                data: blobOrFile,
            }

            const type = (!forceBinaryFile && blobOrFile.type) || 'application/octet-stream'
            let meta = {}

            const dataType = Resource.getType(type)
            const dataTypeSettings = this.settings && this.settings[dataType]
            const doPreview = this.settings && this.settings.preview

            if (dataType === 'image') {
                try {
                    if (doPreview) {
                        const {
                            blob,
                            mimeType,
                            originalWidth: width,
                            originalHeight: height,
                        } = await utils.media.resizeImage(blobOrFile, {
                            maxWidth: this.settings.preview.maxWidth,
                            maxHeight: this.settings.preview.maxHeight,
                            jpeg: type === 'image/jpeg',
                            compress: true,
                        })

                        uploadData.previewData = blob
                        uploadData.previewType = mimeType

                        meta = { ...meta, width, height }
                    } else {
                        const { width, height } = await utils.media.getImageSize(blobOrFile)

                        meta = { ...meta, width, height }
                    }

                    if (dataTypeSettings) {
                        // resize image if necessary
                        const { width, height, blob } = await utils.media.resizeImage(blobOrFile, {
                            ...dataTypeSettings,
                            jpeg: type === 'image/jpeg',
                            compress: false,
                        })

                        meta = { ...meta, width, height }
                        uploadData.data = blob
                    }
                } catch (e) {
                    if (e.message === 'Invalid image') {
                        return false
                    }

                    throw e
                }
            }

            if (dataType === 'video') {
                try {
                    if (doPreview) {
                        const result = await utils.media.getVideoPreview(blobOrFile, {
                            thumbnails: this.settings.preview.thumbnails,
                            startAt: this.settings.preview.startAt,
                            maxWidth: this.settings.preview.maxWidth,
                            jpeg: true,
                            compress: true,
                        })

                        if (result) {
                            const {
                                blob,
                                duration,
                                mimeType,
                                originalWidth: width,
                                originalHeight: height,
                            } = result

                            meta = { ...meta, width, height, duration }

                            uploadData.previewData = blob
                            uploadData.previewType = mimeType
                        }
                    } else {
                        const { width, height, duration } = await utils.media.getVideoSize(blobOrFile)

                        meta = {
                            ...meta,
                            width,
                            height,
                            duration,
                        }
                    }
                } catch (e) {
                    if (e.message === 'Invalid video') {
                        return false
                    }

                    throw e
                }
            }

            this.type = type
            this.meta = meta
            this.filename = blobOrFile.name
            this.filesize = blobOrFile.size || null
            this.uploadData = uploadData

            return true
        }

        throw new Error('Resource.fromBlob is supported only in browser environment.')
    }

    // eslint-disable-next-line class-methods-use-this
    async fromBinary(data, type, name) {
        if (process.env.RUNTIME_TARGET === 'backend') {
            this.clear() // mark any old content for deletion

            if (!Buffer.isBuffer(data)) {
                throw new Error('Expected Buffer')
            }

            if (!type) {
                throw new Error('Missing type')
            }

            if (!name) {
                throw new Error('Missing name')
            }

            const uploadData = {
                data,
            }

            const dataType = Resource.getType(type)

            if (dataType === 'image') {
                // TODO do resize and preview here
            }

            this.type = type
            this.meta = {}
            this.filename = name
            this.filesize = data.size || null
            this.uploadData = uploadData

            return true
        }

        throw new Error('Resource.fromBinary is supported only in backend environment.')
    }

    async fromBase64(base64, type, name, options) {
        if (process.env.RUNTIME_TARGET === 'backend') {
            const binary = Runtime.BSON.Binary.fromBase64(base64)
            // send regular base64 data as preview url
            return this.fromBinary(binary, type, name, base64, options)
        }

        if (process.env.RUNTIME_TARGET === 'browser') {
            // TODO !!!
            return false
        }

        return false
    }

    get isStorable() {
        return !!this.uploadData
    }

    get isDeleteable() {
        return !!this.deleteData
    }

    async store(path) {
        if (!path) {
            throw new Error('Missing path for resource.')
        }

        const normalizedPath = path.charAt(0) === '/' ? path.substr(1) : path

        if (normalizedPath.charAt(normalizedPath.length - 1) === '/') {
            throw new Error('Unexpected ending slash in resource path.')
        }

        if (this.path && this.path.indexOf(normalizedPath) < 0) {
            throw new Error(`Path missmatch from "${this.path}" to "${normalizedPath}"`)
        }

        if (!this.uploadData) {
            // Nothing to upload so return early
            return true
        }

        // Save upload data here as it may be cleared out on next call

        // If we have valid data stored on this resource delete it first
        await this.delete(false)

        // Now upload new data
        const { data, previewData, previewType } = this.uploadData
        const previewFileExtension = previewType === 'image/jpeg' ? '.jpg' : '.png'

        let fileExtension = ''
        if (this.filename) {
            const ext = /^.+\.([^.]+)$/.exec(this.filename)
            fileExtension = ext == null ? '' : `.${ext[1]}`
        }

        // we use a random name so we don't have cache issues
        const fileId = nanoid()
        this.path = `${normalizedPath}/${fileId}${fileExtension}`
        this.previewPath = previewData ? `${normalizedPath}/${fileId}-preview${previewFileExtension}` : null

        const [r1, r2] = await Promise.all([
            Runtime.uploadFile(this.path, this.type, data, { filename: this.filename }),
            previewData && previewType
                ? Runtime.uploadFile(this.previewPath, previewType, previewData, { filename: this.filename })
                : Promise.resolve(true),
        ])

        this.uploadData = null

        return r1 && r2
    }

    clear(clearAllData = true) {
        if (this.path || this.previewPath) {
            this.deleteData = { path: this.path, previewPath: this.previewPath }
        }

        if (clearAllData) {
            this.type = null
            this.meta = {}
            this.path = null
            this.filename = null
            this.filesize = null
            this.previewPath = null
            this.uploadData = null
        }

        return true
    }

    async delete(clearAllData = true) {
        if (!this.deleteData) {
            this.clear(clearAllData)
        }

        if (!this.deleteData) {
            // NOOP
            return true
        }

        const result = await this._deleteFromStorage()

        this.deleteData = null

        return result
    }

    async _deleteFromStorage() {
        const { path, previewPath } = this.deleteData

        if (path) {
            const [r1, r2] = await Promise.all([
                Runtime.deleteFile(path),
                previewPath ? Runtime.deleteFile(previewPath) : Promise.resolve(true),
            ])

            if (!r1 || !r2) {
                return false
            }
        }
        return true
    }
}

if (Runtime.mobx) {
    decorateModel(Resource)
}
