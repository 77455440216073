/* eslint-disable import/prefer-default-export */
import React, { useMemo } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core/styles'
import { fade } from '@newspaces/lucky-components/utils'


const useColors = baseColor => {
    const theme = useTheme()

    const color = theme.palette[baseColor]?.main || baseColor || theme.palette.primary.main

    return useMemo(() => ({
        color1: fade(color, 0.54, 'black'),
        color2: fade(color, 0.24, 'white'),
        color3: fade(color, 0.08, 'white'),
    }), [color])
}
    

export const BillingSettingsIcon = React.forwardRef(({ color, ...props }, ref) => {
    const colors = useColors(color)
    const svg = `<g>
  <path d="M23.5,7.994a7.5,7.5,0,0,1-7.5,7.5,4.533,4.533,0,0,1-.52-.03,7.465,7.465,0,0,1-6.95-6.94,4.708,4.708,0,0,1-.03-.53,7.5,7.5,0,0,1,15,0Z" style="fill:${colors.color2}"/>
  <path d="M16,.494a7.5,7.5,0,0,0-7.5,7.5,4.721,4.721,0,0,0,.03.53,7.448,7.448,0,0,0,2.132,4.732l10.6-10.6A7.474,7.474,0,0,0,16,.494Z" style="fill:${colors.color3}"/>
  <path d="M15.483,15.464a7.506,7.506,0,1,1-6.95-6.94A7.465,7.465,0,0,0,15.483,15.464Z" style="fill:${colors.color2}"/>
  <path d="M8.533,8.524A7.5,7.5,0,0,0,2.656,21.265l8.009-8.009A7.448,7.448,0,0,1,8.533,8.524Z" style="fill:${colors.color3}"/>
  <path d="M23.5,7.994a7.5,7.5,0,0,1-7.5,7.5,4.533,4.533,0,0,1-.52-.03,7.465,7.465,0,0,1-6.95-6.94,4.708,4.708,0,0,1-.03-.53,7.5,7.5,0,0,1,15,0Z" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <path d="M15.483,15.464a7.506,7.506,0,1,1-6.95-6.94A7.465,7.465,0,0,0,15.483,15.464Z" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <path d="M7.507,13.494v-1" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <path d="M9,13.494H6.974a1.342,1.342,0,0,0-.5,2.587l2.064.826a1.342,1.342,0,0,1-.5,2.587H6" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <path d="M7.507,20.494v-1" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <path d="M16.007,4.994v-1" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <path d="M17.5,4.994H15.474a1.342,1.342,0,0,0-.5,2.587l2.064.826a1.342,1.342,0,0,1-.5,2.587H14.5" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <path d="M16.007,11.994v-1" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 24 24" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const ChangeAppIcon = React.forwardRef(({ color, ...props }, ref) => {
    const colors = useColors(color)
    const svg = `<g>
  <path d="M20.5,4.5v10a2,2,0,0,1-2,2H2.5a2,2,0,0,1-2-2V4.5Z" style="fill:${colors.color2}"/>
  <path d="M4.5,16.5l12-12H.5v10a2,2,0,0,0,2,2Z" style="fill:${colors.color3}"/>
  <path d="M20.5,4.5H.5v-2a2,2,0,0,1,2-2h16a2,2,0,0,1,2,2Z" style="fill:#ffffff"/>
  <line x1="0.518" y1="4.504" x2="20.518" y2="4.504" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <line x1="3.518" y1="2.254" x2="3.518" y2="2.254" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <path d="M3.518,2.254a.25.25,0,1,0,.25.25.249.249,0,0,0-.25-.25" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <line x1="5.518" y1="2.254" x2="5.518" y2="2.254" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <path d="M5.518,2.254a.25.25,0,1,0,.25.25.249.249,0,0,0-.25-.25" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <line x1="7.518" y1="2.254" x2="7.518" y2="2.254" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <path d="M7.518,2.254a.25.25,0,1,0,.25.25.249.249,0,0,0-.25-.25" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <path d="M9.518,16.5h-7a2,2,0,0,1-2-2V2.5a2,2,0,0,1,2-2h16a2,2,0,0,1,2,2V10" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <circle cx="17.518" cy="17.505" r="4.5" style="fill:${colors.color3}"/>
  <polyline points="15.018 18.505 11.518 18.505 11.518 22.005" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <path d="M22.935,19.738a5.571,5.571,0,0,1-10.813-1.233" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <polyline points="20.018 16.505 23.518 16.505 23.518 13.005" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <path d="M12.1,15.271a5.572,5.572,0,0,1,10.814,1.234" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 24 24" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const ChangeOrganisationIcon = React.forwardRef(({ color, ...props }, ref) => {
    const colors = useColors(color)
    const svg = `<g>
  <path d="M17.43,14.794l5.27,2.292a.5.5,0,0,1,0,.917L12.455,22.458a.5.5,0,0,1-.4,0L1.81,18a.5.5,0,0,1,0-.917l5.27-2.292" style="fill:${colors.color2}"/>
  <path d="M17.43,9.294l5.27,2.292a.5.5,0,0,1,0,.917L12.455,16.958a.5.5,0,0,1-.4,0L1.81,12.5a.5.5,0,0,1,0-.917L7.08,9.294" style="fill:${colors.color2}"/>
  <path d="M12.455,11.453a.5.5,0,0,1-.4,0L1.81,7a.5.5,0,0,1,0-.917L12.055,1.626a.5.5,0,0,1,.4,0L22.7,6.081A.5.5,0,0,1,22.7,7Z" style="fill:${colors.color2}"/>
  <path d="M12.454,11.462a.5.5,0,0,1-.4,0L1.809,7.007a.5.5,0,0,1,0-.916L12.054,1.636a.5.5,0,0,1,.4,0L22.7,6.091a.5.5,0,0,1,0,.916Z" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <path d="M20.879,10.794l1.82.792a.5.5,0,0,1,0,.916L12.454,16.957a.5.5,0,0,1-.4,0L1.809,12.5a.5.5,0,0,1,0-.916l1.82-.792" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <path d="M20.879,16.294l1.82.792a.5.5,0,0,1,0,.916L12.454,22.457a.5.5,0,0,1-.4,0L1.809,18a.5.5,0,0,1,0-.916l1.82-.792" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 24 24" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const ClockIcon = React.forwardRef(({ color, ...props }, ref) => {
    const svg = `<g>
  <path fill="currentColor" fill-opacity="0.3" d="M46 24C46 36.1503 36.1503 46 24 46C11.8497 46 2 36.1503 2 24C2 11.8497 11.8497 2 24 2C36.1503 2 46 11.8497 46 24Z"/>
  <path fill="currentColor" fill-rule="evenodd" d="M24.2067 8C24.4711 8 24.7349 8.0234 24.988 8.06089C26.1564 8.234 26.85 9.35065 26.85 10.5318V20.6353C26.85 20.8709 26.9331 21.0988 27.0847 21.2791L32.9184 28.2142C33.5904 29.013 33.8225 30.124 33.2706 31.01C33.0596 31.3488 32.8027 31.6973 32.5 32C32.2415 32.2585 31.9571 32.4812 31.6756 32.6699C30.7226 33.3086 29.4993 32.9717 28.7102 32.1389L21.8615 24.9118C21.3102 24.3301 21.0146 23.552 21.0406 22.751L21.4381 10.4989C21.4759 9.33468 22.1833 8.25669 23.3337 8.07389C23.6168 8.02889 23.913 8 24.2067 8Z" clip-rule="evenodd"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 48 48" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const DeveloperIcon = React.forwardRef(({ color, ...props }, ref) => {
    const colors = useColors(color)
    const svg = `<g>
  <rect x="0.5" y="2" width="23" height="20" rx="1" style="stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round;fill:#ffffff"/>
  <rect x="2.5" y="4" width="19" height="16" rx="0.5" style="fill:${colors.color2}"/>
  <path d="M3,20H4L20,4H3a.5.5,0,0,0-.5.5v15A.5.5,0,0,0,3,20Z" style="fill:${colors.color3}"/>
  <rect x="2.5" y="4" width="19" height="16" rx="0.5" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <polyline points="7.5 8 10 10.5 7.5 13" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <line x1="13" y1="11" x2="17" y2="11" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 24 24" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const DragDropIcon = React.forwardRef(({ color, ...props }, ref) => {
    const svg = `<g>
  <path fill="currentColor" d="M45.8898 21.4622C45.7645 20.205 44.7659 19.4074 43.5046 19.3346C40.9832 19.189 35.4963 19 24 19C12.5037 19 7.01681 19.189 4.49544 19.3346C3.23409 19.4074 2.23552 20.205 2.1102 21.4622C2.04605 22.1058 2 22.9378 2 24C2 25.0622 2.04605 25.8942 2.1102 26.5378C2.23552 27.795 3.23409 28.5926 4.49544 28.6654C7.01681 28.811 12.5037 29 24 29C35.4963 29 40.9832 28.811 43.5046 28.6654C44.7659 28.5926 45.7645 27.795 45.8898 26.5378C45.9539 25.8942 46 25.0622 46 24C46 22.9378 45.9539 22.1058 45.8898 21.4622Z"/>
  <path fill="currentColor" d="M45.8898 6.46224C45.7645 5.20501 44.7659 4.4074 43.5046 4.33457C40.9832 4.18897 35.4963 4 24 4C12.5037 4 7.01681 4.18897 4.49544 4.33457C3.23409 4.4074 2.23552 5.20501 2.1102 6.46224C2.04605 7.10585 2 7.93779 2 9C2 10.0622 2.04605 10.8942 2.1102 11.5378C2.23552 12.795 3.23409 13.5926 4.49544 13.6654C7.01681 13.811 12.5037 14 24 14C35.4963 14 40.9832 13.811 43.5046 13.6654C44.7659 13.5926 45.7645 12.795 45.8898 11.5378C45.9539 10.8942 46 10.0622 46 9C46 7.93779 45.9539 7.10585 45.8898 6.46224Z"/>
  <path fill="currentColor" d="M45.8898 36.4622C45.7645 35.205 44.7659 34.4074 43.5046 34.3346C40.9832 34.189 35.4963 34 24 34C12.5037 34 7.01681 34.189 4.49544 34.3346C3.23409 34.4074 2.23552 35.205 2.1102 36.4622C2.04605 37.1058 2 37.9378 2 39C2 40.0622 2.04605 40.8942 2.1102 41.5378C2.23552 42.795 3.23409 43.5926 4.49544 43.6654C7.01681 43.811 12.5037 44 24 44C35.4963 44 40.9832 43.811 43.5046 43.6654C44.7659 43.5926 45.7645 42.795 45.8898 41.5378C45.9539 40.8942 46 40.0622 46 39C46 37.9378 45.9539 37.1058 45.8898 36.4622Z"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 48 48" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const FavoriteIcon = React.forwardRef(({ color, ...props }, ref) => {
    const svg = `<g>
  <path fill="currentColor" d="M21.1087 3.78818C22.3531 1.40394 25.6469 1.40394 26.8913 3.78818L32.2597 14.0735L43.3167 16.237C45.8696 16.7365 46.8836 19.969 45.1044 21.9354L37.3645 30.4894L38.8113 42.1397C39.1447 44.8246 36.4837 46.8269 34.1322 45.6606L24 40.635L13.8678 45.6606C11.5163 46.8269 8.85532 44.8246 9.18874 42.1397L10.6355 30.4894L2.8956 21.9354C1.11636 19.969 2.13045 16.7365 4.68329 16.237L15.7403 14.0735L21.1087 3.78818Z"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 48 48" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const LogoutIcon = React.forwardRef(({ color, ...props }, ref) => {
    const svg = `<g>
  <path fill="currentColor" fill-opacity="0.3" d="M19 7C9.61116 7 2 14.6112 2 24C2 33.3888 9.61116 41 19 41C28.3888 41 36 33.3888 36 24C36 14.6112 28.3888 7 19 7Z"/>
  <path fill="currentColor" fill-rule="evenodd" d="M40.0468 31.9877C38.8057 33.073 37.2375 32.3911 37.1481 30.7449C37.1073 29.9937 37.0706 29.0828 37.044 27.9833C26.8145 27.9 18.8944 27.5151 15.4216 27.3174C14.2476 27.2506 13.2271 26.4853 13.0822 25.3183C13.0331 24.9226 13 24.4784 13 23.9995C13 23.5206 13.0331 23.0764 13.0822 22.6807C13.2271 21.5137 14.2476 20.7484 15.4216 20.6816C18.8944 20.4839 26.8145 20.0991 37.044 20.0157C37.0706 18.9171 37.1073 18.0068 37.1481 17.2559C37.2374 15.6094 38.8062 14.9274 40.0475 16.0129C40.7816 16.6548 41.6829 17.4839 42.7647 18.5453C44.5091 20.2569 45.5497 21.5658 46.1626 22.4718C46.802 23.417 46.8019 24.5827 46.1625 25.5279C45.5496 26.4338 44.509 27.7427 42.7647 29.4544C41.6826 30.5164 40.781 31.3457 40.0468 31.9877Z" clip-rule="evenodd"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 48 48" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const MenuDownIcon = React.forwardRef(({ color, ...props }, ref) => {
    const colors = useColors(color)
    const svg = `<g>
  <path d="M23.5,6.5a1,1,0,0,1-1,1H7.5a1,1,0,0,1-1-1v-1a1,1,0,0,1,1-1h15a1,1,0,0,1,1,1Z" style="fill:${colors.color2}"/>
  <path d="M23.5,6V5.5a1,1,0,0,0-1-1H7.5a1,1,0,0,0-1,1V6Z" style="fill:${colors.color3}"/>
  <path d="M20.5,12.5a1,1,0,0,1-1,1H4.5a1,1,0,0,1-1-1v-1a1,1,0,0,1,1-1h15a1,1,0,0,1,1,1Z" style="fill:${colors.color2}"/>
  <path d="M20.5,12v-.5a1,1,0,0,0-1-1H4.5a1,1,0,0,0-1,1V12Z" style="fill:${colors.color3}"/>
  <path d="M17.5,18.5a1,1,0,0,1-1,1H1.5a1,1,0,0,1-1-1v-1a1,1,0,0,1,1-1h15a1,1,0,0,1,1,1Z" style="fill:${colors.color2}"/>
  <path d="M17.5,18v-.5a1,1,0,0,0-1-1H1.5a1,1,0,0,0-1,1V18Z" style="fill:${colors.color3}"/>
  <path d="M23.5,6.5a1,1,0,0,1-1,1H7.5a1,1,0,0,1-1-1v-1a1,1,0,0,1,1-1h15a1,1,0,0,1,1,1Z" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <path d="M20.5,12.5a1,1,0,0,1-1,1H4.5a1,1,0,0,1-1-1v-1a1,1,0,0,1,1-1h15a1,1,0,0,1,1,1Z" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <path d="M17.5,18.5a1,1,0,0,1-1,1H1.5a1,1,0,0,1-1-1v-1a1,1,0,0,1,1-1h15a1,1,0,0,1,1,1Z" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 24 24" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const MenuUpIcon = React.forwardRef(({ color, ...props }, ref) => {
    const colors = useColors(color)
    const svg = `<g>
  <circle cx="12" cy="12" r="12" style="fill:${colors.color2}"/>
  <line x1="0.5" y1="0.499" x2="23.5" y2="23.499" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <line x1="23.5" y1="0.499" x2="0.5" y2="23.499" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 24 24" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const TaskListClockIcon = React.forwardRef(({ color, ...props }, ref) => {
    const colors = useColors(color)
    const svg = `<g>
  <g transform="scale(2)">
    <path d="M17.5,3.5a1,1,0,0,0-1-1H12.052a3.329,3.329,0,0,0-6.1,0H1.5a1,1,0,0,0-1,1v17a1,1,0,0,0,1,1h15a1,1,0,0,0,1-1Z" style="fill:${colors.color2}"/>
    <path d="M14.5,5.5H3.5v13h11Z" style="fill:${colors.color3}"/>
    <polygon points="3.5 16.539 14.5 5.539 14.5 5.498 3.5 5.498 3.5 16.539" style="fill:#ffffff"/>
    <path d="M14.5,9.5v-4H3.5v13h5" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
    <path d="M6.5,8.5h5" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
    <path d="M6.5,11.5h5" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
    <path d="M6.5,14.5h3" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
    <path d="M17.5,8.5v-5a1,1,0,0,0-1-1H12.052a3.329,3.329,0,0,0-6.1,0H1.5a1,1,0,0,0-1,1v17a1,1,0,0,0,1,1h8" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
    <path d="M17.5,23.487a6,6,0,1,0-6-6A6,6,0,0,0,17.5,23.487Z" style="fill:${colors.color3}"/>
    <path d="M17.5,11.486A6,6,0,0,0,13.557,22l8.458-8.458A5.98,5.98,0,0,0,17.5,11.486Z" style="fill:#ffffff"/>
    <path d="M17.5,23.487a6,6,0,1,0-6-6A6,6,0,0,0,17.5,23.487Z" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
    <path d="M19.5,17.482h-2v-3" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  </g>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 48 48" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const UserAccountIcon = React.forwardRef(({ color, ...props }, ref) => {
    const colors = useColors(color)
    const svg = `<g>
  <circle cx="12" cy="12" r="11.5" style="fill:${colors.color2}"/>
  <path d="M12,4.444a11.494,11.494,0,0,1,11.319,9.529A11.5,11.5,0,1,0,.5,12a11.563,11.563,0,0,0,.181,1.973A11.494,11.494,0,0,1,12,4.444Z" style="fill:${colors.color3}"/>
  <circle cx="12" cy="12" r="11.5" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <circle cx="12" cy="8.75" r="4.25" style="fill:${colors.color3}"/>
  <path d="M18.28,18.43a8.958,8.958,0,0,1-12.56,0,6.983,6.983,0,0,1,12.56,0Z" style="fill:${colors.color3}"/>
  <path d="M12,16.718a6.958,6.958,0,0,1,5.338,2.507,8.741,8.741,0,0,0,.942-.8,6.983,6.983,0,0,0-12.56,0,8.741,8.741,0,0,0,.942.8A6.958,6.958,0,0,1,12,16.718Z" style="fill:#ffffff"/>
  <path d="M18.317,18.5a6.988,6.988,0,0,0-12.634,0" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <path d="M12,6.718a4.239,4.239,0,0,1,4.085,3.141A4.188,4.188,0,0,0,16.25,8.75a4.25,4.25,0,0,0-8.5,0,4.188,4.188,0,0,0,.165,1.109A4.239,4.239,0,0,1,12,6.718Z" style="fill:${colors.color3}"/>
  <circle cx="12" cy="8.75" r="4.25" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 24 24" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const UserSettingsIcon = React.forwardRef(({ color, ...props }, ref) => {
    const colors = useColors(color)
    const svg = `<g>
  <rect x="0.503" y="0.5" width="23" height="23" rx="1" style="fill:${colors.color3}"/>
  <path d="M23.5,3.5v-2a1,1,0,0,0-1-1H1.5a1,1,0,0,0-1,1v2Z" style="fill:#ffffff"/>
  <rect x="0.503" y="0.5" width="23" height="23" rx="1" style="stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round;fill:none"/>
  <path d="M3.5,6.5a3,3,0,0,0,3,3h11a3,3,0,0,0,0-6H6.5A3,3,0,0,0,3.5,6.5Z" style="stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round;fill:#ffffff"/>
  <path d="M3.5,17.5a3,3,0,0,0,3,3h11a3,3,0,0,0,0-6H6.5A3,3,0,0,0,3.5,17.5Z" style="stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round;fill:#ffffff"/>
  <circle cx="6.503" cy="6.5" r="3" style="fill:${colors.color2}"/>
  <circle cx="17.503" cy="17.5" r="3" style="fill:${colors.color2}"/>
  <path d="M6.5,9.5a3,3,0,0,1,0-6Z" style="fill:${colors.color3}"/>
  <circle cx="6.503" cy="6.5" r="3" style="stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round;fill:none"/>
  <path d="M17.5,20.5a3,3,0,0,1,0-6Z" style="fill:${colors.color3}"/>
  <circle cx="17.503" cy="17.5" r="3" style="stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round;fill:none"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 24 24" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const UsersManagementIcon = React.forwardRef(({ color, ...props }, ref) => {
    const colors = useColors(color)
    const svg = `<g>
  <path d="M20.16,11.19A6.9,6.9,0,0,0,18.51,11a7.423,7.423,0,0,0-4.16,1.26,8.233,8.233,0,0,0-1.2-2.05A6.077,6.077,0,0,1,16,9.5,5.916,5.916,0,0,1,20.16,11.19Z" style="fill:${colors.color2}"/>
  <path d="M16,10.805a6.015,6.015,0,0,1,1.729.253A7.434,7.434,0,0,1,18.51,11a6.9,6.9,0,0,1,1.65.19A5.916,5.916,0,0,0,16,9.5a6.077,6.077,0,0,0-2.85.71,8.6,8.6,0,0,1,.681,1A6.055,6.055,0,0,1,16,10.805Z" style="fill:${colors.color3}"/>
  <path d="M.5,15.5a6,6,0,0,1,12,0Z" style="fill:${colors.color3}"/>
  <path d="M6.5,11.271A6,6,0,0,1,12.234,15.5H12.5a6,6,0,0,0-12,0H.766A6,6,0,0,1,6.5,11.271Z" style="fill:#ffffff"/>
  <circle cx="6.5" cy="4.25" r="3.75" style="fill:${colors.color3}"/>
  <path d="M6.5,2.488a3.74,3.74,0,0,1,3.6,2.756,3.71,3.71,0,0,0,.15-.994,3.75,3.75,0,0,0-7.5,0,3.71,3.71,0,0,0,.15.994A3.74,3.74,0,0,1,6.5,2.488Z" style="fill:#ffffff"/>
  <circle cx="16" cy="4.25" r="3.75" style="fill:${colors.color2}"/>
  <path d="M16,2.333a3.742,3.742,0,0,1,3.624,2.834,3.75,3.75,0,0,0,.126-.917,3.75,3.75,0,0,0-7.5,0,3.75,3.75,0,0,0,.126.917A3.742,3.742,0,0,1,16,2.333Z" style="fill:${colors.color3}"/>
  <path d="M.5,15.5a6,6,0,0,1,12,0Z" style="stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round;fill:none"/>
  <circle cx="6.5" cy="4.25" r="3.75" style="stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round;fill:none"/>
  <circle cx="16" cy="4.25" r="3.75" style="stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round;fill:none"/>
  <path d="M19.47,10.605A6,6,0,0,0,13,10.3" style="stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round;fill:none"/>
  <circle cx="17.015" cy="20" r="1" style="stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round;fill:none"/>
  <path d="M23.515,13.5h-2l-3.24,3.24a3.287,3.287,0,0,0-1.26-.24,3.5,3.5,0,1,0,3.5,3.5,3.287,3.287,0,0,0-.24-1.26l3.24-3.24Zm-6.5,7.5a1,1,0,1,1,1-1A1,1,0,0,1,17.015,21Z" style="fill:${colors.color2};stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 24 24" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})
