import DateTime from '@newspaces/lucky-base/DateTime'
import Runtime from '@newspaces/lucky-base/Runtime'

export default async userData => {
    if (!userData || Runtime.hasGuestSession) {
        return null
    }

    const now = new DateTime()

    const { lastSeenAt } = userData
    if (!lastSeenAt || now.isAfter(lastSeenAt, 'day')) {
        userData.lastSeenAt = now
        await userData.update({
            lastSeenAt: now.toDate(),
        })

        return now
    }

    return lastSeenAt
}
