import queryString from 'query-string'

export default (query, addDivider = false) => {
    const queryStringified = queryString.stringify(query)
    if (!queryStringified) {
        return ''
    }

    return addDivider ? `?${queryStringified}` : queryStringified
}
