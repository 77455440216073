import React from 'react'

import { observable } from 'mobx'
import { observer } from 'mobx-react-lite'
import Alert from '@material-ui/lab/Alert'

import Runtime from '@newspaces/lucky-base/Runtime'
import baseUtils from '@newspaces/lucky-base/utils'

import AppContext from '../../AppContext'
import i18n from '../../i18n'

export default (organisation) => {

    const prompState = observable({
        error: null,
    })

    const leaveTheOrganisation = async (evt, settings) => {
        try {
            prompState.error = null
            await Runtime.apiMethod(
                `/organisations/${organisation.id}/leave`,
            )

            // wait 2s so the trigger runs and remove organisation from userData
            await baseUtils.async.waitTimeout(2000)
            await AppContext.reloadUserData()
            window.location = '/organisations'
        } catch (e) {
            prompState.error = i18n().manageOrganisations.leaveOrganisationPrompt.errors[e.message] || i18n().misc.errors.unknown
            settings.preventClose = true
        }
    }

    AppContext.pushDialog({
        title: i18n().manageOrganisations.leaveOrganisationPrompt.title({
            organisationName: organisation.name,
        }),
        text: i18n().manageOrganisations.leaveOrganisationPrompt.text({
            organisationName: organisation.name,
        }),
        deleteAction: 1,
        actions: [
            'close',
            {
                label: i18n().manageOrganisations.leaveOrganisationPrompt.action,
                execute: leaveTheOrganisation,
            },
        ],
        Component: observer(() => {
            return <>
                {prompState.error && <Alert severity="error">{prompState.error}</Alert>}
            </>
        }),
    })
}
