const TEMPORARY_IDENTIFIER = ':orderedListSeparator-'

const ignoreOrderedLists = content => {
    content = content || ''

    const lines = content.split(/\r?\n/)

    const newLines = lines.map(line => {
        const groups = line.match(/^(?:\d+\.) .*/g) || []
        if (groups.length) {
            const group = groups[0]
            const indexOfFirstSpace = group.indexOf(' ')
            // extracts "1."", "2.", etc
            const lineNumberAndDot = group.substring(0, indexOfFirstSpace)
            // replace "1." to "1:orderedListSeparator:."
            const replaced = lineNumberAndDot.replace(/./, (match) => `${TEMPORARY_IDENTIFIER}${match}`)
            const orderedListText = group.substring(indexOfFirstSpace, group.length)

            return `${replaced}${orderedListText}`
        }
        return line
    })

    return newLines.join('\n')
}

const unignoreOrderedLists = content => {
    return content.replace(new RegExp(TEMPORARY_IDENTIFIER, 'g'), '')
}

export { ignoreOrderedLists, unignoreOrderedLists }