import Runtime from '@newspaces/lucky-base/Runtime'

export default async (context, { refreshUserData = true, waitForRefresh = false, timeout = 8000 } = {}) => {
    let resolved = false

    if (!Runtime.hasSession || !context.userData) {
        return true
    }

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async resolve => {
        // If timeout is reached, then consider it resolved
        setTimeout(() => {
            if (!resolved) {
                resolve(true)
            }
        }, timeout)

        const waitForCacheUpdate = async () => {
            resolved = true
            // We refresh user data because certain things (ie favorite org)
            // are retrieved from there
            if (refreshUserData) {
                await Runtime.refreshUserData(waitForRefresh)
            }
            resolve(true)
        }

        // we need to query by userId here because is the same query we do on Runtime.browser
        // and since cache relies on executing the exact same query, that is needed
        const loaded = await context.userData.load({ userId: Runtime.userId }, {
            cache: true,
            onCacheUpdated: waitForCacheUpdate,
            onCacheAlreadyUpdated: () => resolve(true)
        })

        // if was not loaded, we return false, otherwise we wait the cache to be
        // updated to return true
        if (!loaded) {
            resolve(false)
        }
    })
}