import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation } from 'react-router-dom'

import Button from '@newspaces/lucky-components/Button'

import AppContext from '../AppContext'

import i18n from '../i18n'

import AuthContainer from './AuthContainer'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    introMessage: {
        marginBottom: theme.spacing(3),
        fontSize: 16,
    },
    button: {
        textTransform: 'none',
        '&:not(:last-child)': {
            marginBottom: theme.spacing(1.5),
        },
    },
}))

export default observer(() => {
    const classes = useStyles()
    const location = useLocation()

    const loginUrl = useMemo(() => {
        return `/auth/login${location.search || ''}`
    }, [location])

    const signUpUrl = useMemo(() => {
        return `/auth/signup${location.search || ''}`
    }, [location])

    return (
        <AuthContainer mode="chooseAction">
            <div className={classes.root}>
                {AppContext.invitationToken && (
                    <div className={classes.introMessage}>{i18n().auth.chooseAction.acceptInvitation}</div>
                )}
                <Button
                    className={classes.button}
                    size="large"
                    variant="contained"
                    color="primary"
                    to={loginUrl}
                >
                    {i18n().auth.chooseAction.loginAction}
                </Button>
                <Button
                    className={classes.button}
                    size="large"
                    variant="contained"
                    color="info"
                    to={signUpUrl}
                >
                    {i18n().auth.chooseAction.signUpAction}
                </Button>
            </div>
        </AuthContainer>
    )
})
