/* eslint-disable no-console */
import Runtime from '../../Runtime'

import DateTime from '../../DateTime'

import string from '../../utils/string'

let CacheStorage = null

if (typeof window !== 'undefined') {
    CacheStorage = {
        getData(key) {
            return window.localStorage.getItem(key) || null
        },
        setData(key, value) {
            window.localStorage.setItem(key, value)
        },
        removeData(key) {
            window.localStorage.removeItem(key)
        },
    }
}

const getCacheKey = (ModelClass, query) => {
    const type = ModelClass.name

    // RegExp by default is always serialized as empty object by JSON.stringify
    // this replacer fixes the issue
    const replacer = (key, value) => (value instanceof RegExp ? value.toString() : value)
    const queryHash = string.hash(JSON.stringify(query, replacer))

    return `${type}:${queryHash}`
}

const getCachedDocuments = (storage, cacheKey) => {
    try {
        const data = storage.getData(cacheKey)

        if (data === null) {
            return {}
        }

        return Runtime.BSON.EJSON.parse(data)
    } catch (e) {
        console.error(e)
        return {}
    }
}

const setCachedDocuments = (storage, cacheKey, documents, timestampNow) => {
    try {
        storage.setData(
            cacheKey,
            Runtime.BSON.EJSON.stringify({
                timestamp: timestampNow || new DateTime().toDate(),
                documents,
            })
        )
        return true
    } catch (e) {
        console.error(e)
        return false
    }
}

const removeCachedData = (ModelClass, query, storage) => {
    storage = storage || CacheStorage

    if (storage) {
        const cacheKey = getCacheKey(ModelClass, query)
        storage.removeData(cacheKey)

        return true
    }

    return false
}

export default async (
    ModelClass,
    query,
    cache,
    execute,
    onCacheUpdated,
    onCacheAlreadyUpdated,
    storage,
    timestampNow,
) => {
    storage = storage || CacheStorage

    if (!cache || !storage) {
        // If we have a storage and an existing data for the query then clear it from cache
        if (storage) {
            const cacheKey = getCacheKey(ModelClass, query)
            storage.removeData(cacheKey)
        }

        return execute(query)
    }

    // Handle caching here. We always use the query as unique hash for the cache key.
    // First we try to gather from cache. If found, we immediately return and in parallel
    // will send an query to the backend asking for any updated data. If we receive any
    // then we merge both old and new data together (using model ids) and call our updated
    // cache callback so the consumer can refresh its data.
    const cacheKey = getCacheKey(ModelClass, query)
    const { documents: cachedDocuments, timestamp: cacheTimestamp } = getCachedDocuments(storage, cacheKey)

    const invalidateCache = (documents, mode) => {
        const doCacheDocuments = typeof cache === 'function' ? cache(documents) : true

        if (!doCacheDocuments) {
            // If no longer caching then clear the data
            storage.removeData(cacheKey)
            return
        }

        if (mode === 'first_time' || mode === 'updated') {
            setCachedDocuments(storage, cacheKey, documents, timestampNow)
        }

        if (mode === 'updated') {
            // Call our cache update function
            onCacheUpdated(documents)
        }
    }

    // If no cached documents found do business as usual, query our documents
    // and store them into the cache the first time
    if (!cachedDocuments?.length) {
        const documents = await execute(query)
        invalidateCache(documents, 'first_time')

        return documents
    }

    // We'll query again but only project the id and createdAt / updatedAt fields. In theory we could
    // only query for updatedAt > timestamp but the problem is that we do not know which
    // documents have actually been removed so we need to re-query all of them. Then we
    // can compare timestamps and only reload the ones that are newever.
    execute(query, { _id: 1, createdAt: 1, updatedAt: 1 }).then(latestDocuments => {
        latestDocuments = latestDocuments || []
        let requireCacheUpdate = false

        // Collect all documents requring an update
        const latestDocumentIds = {}
        const documentIdsToUpdate = []
        latestDocuments.forEach(({ _id, createdAt, updatedAt }) => {
            latestDocumentIds[_id.toString()] = true
            if ((createdAt && createdAt > cacheTimestamp) || (updatedAt && updatedAt > cacheTimestamp)) {
                documentIdsToUpdate.push(_id)
            }
        })

        // Remove all documents that no longer exist
        const newDocuments = cachedDocuments.filter(({ _id }) => {
            if (!latestDocumentIds[_id.toString()]) {
                // -- a document was removed so an update is required
                requireCacheUpdate = true
                return false
            }
            return true
        })

        if (documentIdsToUpdate.length > 0) {
            execute({
                // we need to let the original query here then the permissions
                // will work correctly on lucky core 2
                ...query,
                _id: { $in: documentIdsToUpdate },
            }).then(updatedOrNewDocuments => {
                updatedOrNewDocuments = updatedOrNewDocuments || []
                // -- update existing documents and add new ones
                updatedOrNewDocuments.forEach(updatedOrNewDocument => {
                    const existingIndex = newDocuments.findIndex(
                        ({ _id }) => _id.toString() === updatedOrNewDocument._id.toString()
                    )

                    if (existingIndex >= 0) {
                        newDocuments[existingIndex] = updatedOrNewDocument
                    } else {
                        newDocuments.push(updatedOrNewDocument)
                    }
                })

                invalidateCache(newDocuments, 'updated')
            })
        } else {
            invalidateCache(newDocuments, requireCacheUpdate ? 'updated' : '')
            if (onCacheAlreadyUpdated) {
                onCacheAlreadyUpdated(newDocuments)
            }
        }
    })

    return cachedDocuments
}

export { removeCachedData }
