import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'

import AppContext from '../AppContext'

export default observer(({ modal }) => {
    const { Component, onClose, ...componentProperties } = modal

    const handleClose = useCallback(
        arg => {
            if (onClose) {
                if (onClose(arg) === false) {
                    return
                }
            }

            AppContext.clearModal(modal)
        },
        [modal, onClose]
    )

    return <Component {...componentProperties} onClose={handleClose} />
})
