import getLocale from '../locales'

import DateTime from '../../DateTime'

const RelativeTimeDelta = {
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1,
}

export default (
    locale,
    localeObject,
    dateValue,
    { format, relativeDays = 7, relativeDate, relativeZero, relativeTimeExclude = [], utcFormat = null } = {}
) => {
    const { dateFormat = 'DD.MM.YYYY', timeAMPM = false } = getLocale(locale)

    if (!(dateValue instanceof DateTime)) {
        throw new Error('Expected DateTime, DateTime.Date or DateTime.Time instance for dateValue.')
    }

    if (relativeDate && !(relativeDate instanceof DateTime)) {
        throw new Error('Expected DateTime, DateTime.Date or DateTime.Time instance for relativeDate.')
    }

    // Set whether to always read utc or not
    if (utcFormat === null) {
        if (dateValue instanceof DateTime.Date) {
            utcFormat = true
        } else if (dateValue instanceof DateTime.Time) {
            utcFormat = true
        } else {
            utcFormat = false
        }
    }

    // Pick reasonable default-format if not provided
    if (!format) {
        if (dateValue instanceof DateTime.Date) {
            format = 'L'
        } else if (dateValue instanceof DateTime.Time) {
            format = 'LT'
        } else if (dateValue instanceof DateTime) {
            format = 'L LT'
        }
    }

    const localDate = dateValue.toDate()

    return format
        .split(/\b(L|LT|R|relative|RT)\b/gi)
        .map(f => {
            if (f === 'relative' /* deprecated */ || f === 'R') {
                const { relativeTime } = localeObject
                const daysDiff = dateValue.diff(relativeDate || new DateTime(), 'days')

                if (Math.abs(daysDiff) <= relativeDays) {
                    switch (daysDiff) {
                        case -1:
                            return relativeTime.yesterday
                        case 0:
                            return relativeTime.today
                        case 1:
                            return relativeTime.tomorrow
                        default:
                            return daysDiff < 0
                                ? relativeTime.daysAgo({ days: Math.abs(daysDiff) })
                                : relativeTime.daysIn({ days: daysDiff })
                    }
                }

                // Otherwise use simple date format and continue
                f = 'L'
            }

            // Relative time
            if (f === 'RT') {
                let diffSecs =
                    Math.abs(
                        dateValue.toDate().getTime() - (relativeDate || new DateTime()).toDate().getTime()
                    ) / 1000

                return Object.keys(RelativeTimeDelta)
                    .map(key => {
                        const value = Math.floor(diffSecs / RelativeTimeDelta[key])
                        diffSecs -= value * RelativeTimeDelta[key]

                        if (!relativeTimeExclude.includes(key) && (value > 0 || relativeZero)) {
                            // TODO: fix fallback language to work here
                            return `${value} ${localeObject[key]({}, value !== 1)}`
                        }

                        return null
                    })
                    .filter(x => x !== null)
                    .join(', ')
            }

            if (f === 'L') {
                const year = (utcFormat ? localDate.getUTCFullYear() : localDate.getFullYear()).toString()
                const month = ((utcFormat ? localDate.getUTCMonth() : localDate.getMonth()) + 1).toString()
                const date = (utcFormat ? localDate.getUTCDate() : localDate.getDate()).toString()

                return dateFormat
                    .replace('DD', date.padStart(2, '0'))
                    .replace('MM', month.padStart(2, '0'))
                    .replace('YYYY', year)
            }

            if (f === 'LT') {
                const hours = (utcFormat ? localDate.getUTCHours() : localDate.getHours()).toString()
                const minutes = (utcFormat ? localDate.getUTCMinutes() : localDate.getMinutes()).toString()

                if (timeAMPM) {
                    const hoursString = hours % 12 || 12
                    return `${hoursString}:${minutes.padStart(2, '0')}${
                        hours < 12 || hours === 24 ? 'AM' : 'PM'
                    }`
                }
                return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`
            }

            return f
        })
        .join('')
}
