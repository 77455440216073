import Runtime from '@newspaces/lucky-base/Runtime'

export default (context, route) => {
    const { currentSubdomainType, organisationIdOnUrl } = Runtime
    route = route || ''
    if (route.startsWith('/')) {
        route = route.substring(1)
    }

    let appRouteParam = '/:appKey'
    // when currentSubdomainType is app we dont have app on route
    if (currentSubdomainType === 'app') {
        appRouteParam = ''
    } else if (context.app) {
        appRouteParam = `/${context.app.key}`
    }

    // Global root app
    if (context.app && context.app.isGlobal && context.app.isRoot) {
        return `/${route}`
    }

    // Global apps
    if (context.app && context.app.isGlobal) {
        return `${appRouteParam}/${route}`
    }

    const organisationRouteParam = organisationIdOnUrl ? '/:organisationId' : ''

    // Regular root app
    if (context.app && context.app.isRoot) {
        return `${organisationRouteParam}/${route}`
    }

    // Regular apps
    return `${organisationRouteParam}${appRouteParam}/${route}`
}
