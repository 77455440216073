export default (value, isRequired, validationSettings) => {
    if (isRequired && !value) {
        return 'required'
    }

    if (value !== null && value !== undefined && typeof value !== 'object' && !validationSettings.any) {
        return 'invalidType'
    }

    return null
}
