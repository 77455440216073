export default async (models, removalGroup) => {
    const status = {
        removed: 0,
        failed: 0,
        processed: 0,
    }

    await Promise.all(
        models.map(async model => {
            try {
                const updateResult = await model.update({ removalGroup })
                if (!updateResult) {
                    throw new Error('Unable to update model')
                }
                const removeResult = await model.remove()
                if (!removeResult) {
                    throw new Error('Unable to remove model')
                }
                status.removed += 1
            } catch (e) {
                status.failed += 1
            } finally {
                status.processed += 1
            }
            return true
        })
    )

    return status
}
