import React, { useCallback } from 'react'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import classNames from 'classnames'

import Label from '../Label'

export default ({ renderOption, multiple, filterSelectedOptions }) =>
    useCallback(
        (option, { selected, inputValue }) => {
            if (renderOption) {
                const result = renderOption(option, { selected, inputValue })
                if (result !== null) {
                    return result
                }
            }

            let content = null

            if (option.variant) {
                content = (
                    <div>
                        <Label variant={option.variant} color={option.color} label={option.label} />
                    </div>
                )
            } else {
                content = <span>{option.label}</span>
            }

            let checkbox = null

            if (multiple && !filterSelectedOptions) {
                const CheckboxComponent = selected ? CheckBoxIcon : CheckBoxOutlineBlankIcon
                checkbox = <CheckboxComponent className={classNames('checkbox', { checked: selected })} />
            }

            return (
                <>
                    {checkbox}
                    {content}
                </>
            )
        },
        [renderOption, multiple, filterSelectedOptions]
    )
