import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'

import i18n from '../i18n'

import TextInput from '../TextInput'

import useOptions from './useOptions'
import useValue from './useValue'
import useFilterOptions from './useFilterOptions'
import useOptionSettings from './useOptionSettings'
import useRenderInput from './useRenderInput'
import useRenderOption from './useRenderOption'
import useChange from './useChange'
import useAutoSizing from './useAutoSizing'

const useAutoCompleteStyles = makeStyles(theme => ({
    root: ({ fullHeight, fullWidth, variant, hasClearIcon }) => ({
        height: fullHeight && '100%',
        width: fullWidth && '100%',
        '& .MuiAutocomplete-endAdornment': {
            visibility: variant === 'hovered' ? 'hidden' : 'visible',
            lineHeight: 1,

            '& .MuiButtonBase-root': {
                padding: 0,
                width: 28,
                height: 28,
                borderRadius: theme.shape.borderRadius,

                '&:active': {
                    background: 'rgba(0, 0, 0, 0.12)',
                },

                '& .MuiTouchRipple-root': {
                    display: 'none',
                },
            },
        },
        '&:hover .MuiAutocomplete-endAdornment, &.Mui-focused .MuiAutocomplete-endAdornment': {
            visibility: 'visible',
        },
        '& .MuiAutocomplete-clearIndicator': {
            display: hasClearIcon ? 'inline-flex' : 'none',
        },
    }),
    tag: ({ variant }) => ({
        backgroundColor: 'rgba(0, 0, 0, 0.075)',
        padding: 0,
        margin: variant === 'contained' ? '4px 2px 0 2px' : '2px',
        height: 22,
        fontSize: 13,
        fontWeight: 500,
        borderRadius: theme.shape.smallBorderRadius,
    }),
    inputRoot: ({ variant, iconsCount, inputRootStyle }) => {
        const iconPadding = !iconsCount ? 0 : iconsCount === 1 ? 30 : 58

        switch (variant) {
            case 'contained':
                return { padding: `25px ${iconPadding}px 5px 7px !important`, ...inputRootStyle }
            case 'inlined':
                return { padding: `0px ${iconPadding}px 0px 0px !important`, ...inputRootStyle }
            default:
                return { padding: `5px ${iconPadding}px 5px 7px !important`, ...inputRootStyle }
        }
    },
    input: ({ variant }) => ({
        padding: '0 !important',
        margin: variant === 'inlined' ? 0 : '1px 3px',
        height: 24,
    }),
    endAdornment: ({ variant }) => ({
        height: 28,
        lineHeight: '28px',
        marginTop: variant === 'contained' ? 10 : 0,
    }),
    paper: {
        borderRadius: theme.shape.borderRadius,
        ...theme.overrides.MuiPopover.paper,
    },
    groupLabel: {
        color: theme.palette.text.secondary,
        lineHeight: '1',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1),

        '&:empty': {
            display: 'none',
        },
    },
    option: ({ hasCheckboxes }) => {
        const base = {
            minHeight: 0,
            lineHeight: '36px',
            padding: `0px ${theme.spacing(1)}px !important`,
            marginLeft: theme.spacing(1) - 2,
            marginRight: theme.spacing(1) - 2,
            borderRadius: theme.shape.borderRadius,
            border: '2px solid white',
            overflow: 'hidden',
        }

        if (hasCheckboxes) {
            return {
                ...base,

                '&:not(:hover):not([data-focus="true"])[aria-selected="true"]': {
                    backgroundColor: 'unset !important',
                },

                '& > .checkbox': {
                    color: theme.palette.divider,
                    marginRight: theme.spacing(1),
                    marginLeft: -2,
                    '&.checked': {
                        color: theme.palette.primary.main,
                    },
                },
            }
        }

        return base
    },
}))

const useAutoCompletePopupStyles = makeStyles(theme => ({
    // -- For style "popup"
    root: {
        '& .input-container': {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(1),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),

            '& input': {
                margin: '0 !important',
            },

            '& .MuiAutocomplete-endAdornment': {
                display: 'none',
            },
        },
    },
    popperDisablePortal: {
        position: 'relative',
        width: '100% !important',
    },
    paper: {
        boxShadow: 'none',
        margin: 0,
        borderRadius: 0,
    },
}))

const getBoolean = (value, defaultValue) => (typeof value === 'boolean' ? value : defaultValue)

export default React.forwardRef(
    (
        {
            fullHeight,
            fullWidth,
            style,
            autoSize = true,
            autoComplete = {},
            caseSensitive = false,
            multiple,
            clearable = true,
            editable = false,
            disabled,
            options: sourceOptions,
            renderOption,
            variant,
            value: sourceValue,
            placeholder,
            info,
            error,
            inputRef,
            onChange,
            inputRootStyle,
            ...inputPoperties
        },
        ref
    ) => {
        // Editable is just a shortcut to autoComplete.freeSolo
        const freeSolo = getBoolean(autoComplete.freeSolo, editable)

        // Always clear input if not free Solo
        const clearOnBlur = getBoolean(autoComplete.clearOnBlur, !freeSolo)

        // Force popup icon by default
        const forcePopupIcon = variant === 'popup' ? false : getBoolean(autoComplete.forcePopupIcon, true)

        // Disabe clearable if user does not want to clear the Select
        const disableClearable = getBoolean(autoComplete.disableClearable, clearable === false)

        // We always have a clear icon if clearing is allowed and we are in multiple mode
        const hasClearIcon = !disableClearable && multiple && variant !== 'popup'

        // In multi-selection mode we remove the selected values from the dropdown list
        const filterSelectedOptions = getBoolean(autoComplete.filterSelectedOptions, multiple)

        const hasCheckboxes = multiple && !filterSelectedOptions

        const controlClasses = TextInput.useControlStyles({ fullHeight })
        const inputClasses = TextInput.useInputStyles({ variant, fullHeight })
        const autoCompleteClasses = useAutoCompleteStyles({
            variant,
            fullHeight,
            fullWidth,
            iconsCount: (forcePopupIcon ? 1 : 0) + (hasClearIcon ? 1 : 0),
            hasCheckboxes,
            hasClearIcon,
            inputRootStyle,
        })
        const autoCompletePopupClasses = useAutoCompletePopupStyles()

        const {
            filterOptionsBy,
            inputValue: sourceInputValue,
            onInputChange,
            ...otherAutoComplete
        } = autoComplete

        const autoSizeInputRef = useRef(null)

        //
        // Options related
        //

        const { optionsLoading, options, loadOptions } = useOptions({ sourceOptions, autoSizeInputRef })

        const { getOptionLabel, getOptionDisabled, groupBy } = useOptionSettings()

        const filterOptions = useFilterOptions({ filterOptionsBy })

        const handleRenderOption = useRenderOption({
            renderOption,
            multiple,
            filterSelectedOptions,
        })

        //
        // Value related
        //

        const { value, inputValue, handleInputChange } = useValue({
            sourceValue,
            sourceInputValue,
            onInputChange,
            options,
            optionsLoading,
            loadOptions,
            freeSolo,
        })

        const { handleChange, handleBlurChange } = useChange({
            caseSensitive,
            multiple,
            freeSolo,
            value,
            inputValue,
            onChange,
        })

        //
        // Input related
        //

        const { inputWidth } = useAutoSizing({
            autoSize,
            multiple,
            options,
            fullWidth,
            inputClasses,
            autoSizeInputRef,
        })

        const handleRenderInput = useRenderInput({
            handleBlurChange,
            variant,
            controlClasses,
            inputClasses,
            fullWidth,
            inputWidth,
            error,
            info,
            placeholder: optionsLoading === 'initial' ? i18n().select.initialLoading : placeholder,
            ref,
            inputRef,
            ...inputPoperties,
        })

        const autoHighlight = getBoolean(
            autoComplete.autoHighlight,
            !!inputValue || (variant !== 'popup' && multiple)
        )

        //
        // Render
        //

        let classes = autoCompleteClasses
        let autoCompletePopup = null

        if (variant === 'popup') {
            autoCompletePopup = {
                open: true,
                disablePortal: true,
                disableCloseOnSelect: multiple,
            }

            classes = { ...classes, ...autoCompletePopupClasses }
        }

        return (
            <Autocomplete
                {...otherAutoComplete}
                {...autoCompletePopup}
                classes={classes}
                size="small"
                style={style}
                value={value}
                onChange={handleChange}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                autoHighlight={autoHighlight}
                multiple={multiple}
                forcePopupIcon={forcePopupIcon}
                disableClearable={disableClearable}
                filterSelectedOptions={filterSelectedOptions}
                clearOnBlur={clearOnBlur}
                freeSolo={freeSolo}
                options={options}
                loading={optionsLoading === 'input'}
                disabled={optionsLoading === 'initial' || disabled}
                renderOption={handleRenderOption}
                renderInput={handleRenderInput}
                groupBy={groupBy}
                getOptionLabel={getOptionLabel}
                getOptionDisabled={getOptionDisabled}
                filterOptions={filterOptions}
            />
        )
    }
)
