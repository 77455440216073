import Model from './Model'
import Query from './Query'
import decorateModel from './decorateModel'

const registered = {
    organisation: [],
    user: [],
    system: [],
    systemApi: [],
}

function registerModelClass(modelClass) {
    let target = null

    const parentClass = Object.getPrototypeOf(modelClass)

    // If our parent class is not the Model we take the fields and classname from the parent
    const modelClassToReadFrom = parentClass === Model ? modelClass : parentClass

    if (modelClassToReadFrom.Fields.organisation || modelClassToReadFrom.name === 'Organisation') {
        target = registered.organisation
    } else if (modelClassToReadFrom.Fields.userId) {
        target = registered.user
    } else {
        target = registered.system
    }

    // As we do not support having the same model registered twice we check for the
    // parent model and if its already registered we remove it and register it descendant
    const parentIndex = target.findIndex(m => m === parentClass)

    if (parentIndex >= 0) {
        target[parentIndex] = modelClass
    } else {
        target.push(modelClass)
    }
}

function declareModel(modelClass) {
    if (!Model.isRootModel(modelClass)) {
        throw new Error(`Invalid Model ${modelClass.name} Can only declare root models.`)
    }

    registerModelClass(modelClass)

    // Enhance with some static functions bound to the model class
    modelClass.createQuery = (...queryArgs) => new Query(modelClass, ...queryArgs)

    // Decorate model now
    decorateModel(modelClass)

    return modelClass
}

declareModel.registered = registered

export default declareModel
