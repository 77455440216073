import * as watchersBrowser from './watchers.browser'
import * as watchersBackend from './watchers.backend'

// eslint-disable-next-line import/no-mutable-exports
let watchersExport

if (process.env.RUNTIME_TARGET === 'browser') {
    watchersExport = watchersBrowser
}

if (process.env.RUNTIME_TARGET === 'backend') {
    watchersExport = watchersBackend
}

export function canWatch() {
    return watchersExport.canWatch()
}

export function aquireWatcher(...args) {
    return watchersExport.aquireWatcher(...args)
}

export function releaseWatcher(...args) {
    return watchersExport.releaseWatcher(...args)
}
