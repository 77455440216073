import parseDateTime from './parseDateTime'

export default (instance, value, { forceUTC = false } = {}) => {
    if (value && value.type && ['date', 'time', 'datetime'].includes(value.type)) {
        switch (instance._type) {
            case 'date':
                if (value.type === 'time') {
                    throw new Error('Can not construct DateTime.Date from DateTime.Time')
                }

                instance._year = value._year
                instance._month = value._month
                instance._date = value._date
                break
            case 'time':
                if (value.type === 'date') {
                    throw new Error('Can not construct DateTime.Time from DateTime.Date')
                }

                instance._hours = value._hours
                instance._minutes = value._minutes
                instance._seconds = value._seconds
                instance._milliseconds = value._milliseconds
                break
            case 'datetime':
                instance._year = value._year
                instance._month = value._month
                instance._date = value._date
                instance._hours = value._hours
                instance._minutes = value._minutes
                instance._seconds = value._seconds
                instance._milliseconds = value._milliseconds
                break
            default:
                throw new Error(`Unknown value type - ${instance._type}`)
        }

        return true
    }

    if (value === undefined) {
        value = new Date()
    }

    if (typeof value === 'string') {
        const dateTimeParts = parseDateTime(value, instance._type, 0)

        if (!dateTimeParts) {
            throw new Error(`Invalid ${instance._type} string + "${value}"`)
        }

        if (!instance.fromObject(dateTimeParts)) {
            throw new Error('Unable to instantiate with fromObject')
        }

        return true
    }

    if (typeof value === 'number') {
        value = new Date(value)
    }

    if (!(value instanceof Date)) {
        if (typeof value === 'object') {
            if (instance.fromObject(value)) {
                return true
            }

            throw new Error('Invalid object parameters supplied')
        }

        throw new Error('Expected valid value instance')
    }

    value = new Date(value)

    if (Number.isNaN(value.getTime())) {
        throw new Error('Invalid time for date provided.')
    }

    const local = !forceUTC && ['date', 'time'].includes(instance._type)

    const dateTimeParts = {
        year: local ? value.getFullYear() : value.getUTCFullYear(),
        month: local ? value.getMonth() : value.getUTCMonth(),
        date: local ? value.getDate() : value.getUTCDate(),
        hours: local ? value.getHours() : value.getUTCHours(),
        minutes: local ? value.getMinutes() : value.getUTCMinutes(),
        seconds: local ? value.getSeconds() : value.getUTCSeconds(),
        milliseconds: local ? value.getMilliseconds() : value.getUTCMilliseconds(),
    }

    if (!instance.fromObject(dateTimeParts)) {
        throw new Error('Unable to instantiate with fromObject')
    }

    return true
}
