import countries from './countries'
import languages from './languages'

import i18n from '../../i18n'

const getCountryName = countryCode => {
    const theCountry = countries[countryCode]
    if (!theCountry) {
        throw new Error(`Unknown country-code ${countryCode}`)
    }
    return theCountry.name[i18n.getDefaultLanguage()] || theCountry.name.en
}

const getCountryCode = countryName => {
    for (const countryCode in countries) {
        if (Object.prototype.hasOwnProperty.call(countries, countryCode)) {
            const theName = getCountryName(countryCode)
            if (theName.toLowerCase() === countryName.toLowerCase()) {
                return countryCode
            }
        }
    }

    return null
}

const getCountry = (countryCode, throwIfNotFound = false) => {
    const country = countries[countryCode]

    if (!country) {
        if (throwIfNotFound) {
            throw new Error(`No country found for country-code ${countryCode}`)
        }

        return null
    }

    return country
}

const generatePhoneCodes = () => {
    const codes = {}
    Object.keys(countries).forEach(countryCode => {
        const phoneCode = countries[countryCode]?.phoneCode
        if (phoneCode) {
            codes[countryCode] = phoneCode
        }
    })
    return codes
}

const getLanguageName = code => {
    return languages[code] || {}
}

export default {
    ...countries,
    codes: Object.keys(countries),
    currencies: Object.keys(countries).map(countryCode => countries[countryCode].currency),
    phoneCodes: generatePhoneCodes(),
    getCountryName,
    getCountryCode,
    getCountry,
    getLanguageName,
}
