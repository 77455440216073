import React from 'react'
import { observer } from 'mobx-react-lite'
import { makeStyles, fade } from '@material-ui/core/styles'
import classNames from 'classnames'

const createIndeterminateGradient = primary => {
    const color1 = fade(primary, 0.5)
    const color2 = primary

    return `linear-gradient(90deg, ${color1} 0%, ${color2} 30%, ${color2} 70%, ${color1} 100%)`
}

const useStyles = makeStyles(theme => ({
    root: {
        height: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(4),
    },
    progress: {
        position: 'relative',
        height: 30,
        margin: theme.spacing(3, 0, 4, 0),
    },
    progressbar: {
        display: 'block',
        position: 'absolute',
        zIndex: 9,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        margin: 'auto',
        width: '100%',
        height: '100%',

        '& > .before, & > .after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            zIndex: 99,
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: 15,
            backgroundPosition: '0 0',
        },

        '&.indeterminate': {
            '& > .before, & > .after': {
                backgroundImage: createIndeterminateGradient(theme.palette.primary.main),
                backgroundRepeat: 'repeat-x',
                backgroundSize: '100% 100%',
                animation: '$movebar 2s linear infinite',
            },
        },

        '&.determinate': {
            '& > .before, & > .after': {
                backgroundColor: fade(theme.palette.primary.main, 0.12),
                backgroundImage: `linear-gradient(0deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 100%)`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: `0% 100%`,
                transition: 'background-size 200ms linear',
            },
        },

        '& > .before': {
            boxShadow: '0 6px 10px 0 rgba(0,0,0,0.1)',
        },

        '& > .after': {
            zIndex: 9,
            top: 6,
            filter: 'blur(6px)',
            opacity: 0.3,
        },
    },
    stylization: {
        position: 'absolute',
        zIndex: 999,
        height: 4,
        width: '90%',
        left: '5%',
        top: 6,
        opacity: 0.35,
        filter: 'blur(1px)',

        '&::before, &::after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            height: 4,
            top: 0,
            borderRadius: 2,
        },

        '&::before': {
            background: '#fff',
            left: 0,
            right: 10,
        },

        '&::after': {
            background: '#fff',
            width: 6,
            right: 0,
        },
    },
    '@keyframes movebar': {
        from: {
            backgroundPosition: '0 0',
        },
        to: {
            backgroundPosition: '450px 0',
        },
    },
}))

export default observer(({ progress }) => {
    const type = typeof progress === 'number' ? 'determinate' : 'indeterminate'

    const classes = useStyles()

    const backgroundSize = type === 'determinate' ? `${progress || 0}% 100%` : '100% 100%'

    return (
        <div className={classes.progress}>
            <div className={classNames(classes.progressbar, type)}>
                <div className="before" style={{ backgroundSize }} />
                <div className={classes.stylization} />
                <div className="after" style={{ backgroundSize }} />
            </div>
        </div>
    )

    /*
    return (
        <LinearProgress
            variant={typeof progress === 'number' ? 'determinate' : 'indeterminate'}
            value={typeof progress === 'number' ? progress : null}
            className={classes.root}
        />
    )
    */
})
