import { useCallback } from 'react'

export default ({ caseSensitive, multiple, freeSolo, value, inputValue, onChange }) => {
    const mapOption = option => {
        if (typeof option === 'string') {
            return option
        }

        return option?.value
    }

    const handleChange = useCallback(
        (evt, newOption) => {
            let newValue = Array.isArray(newOption) ? newOption.map(mapOption) : mapOption(newOption)

            // If not case-sensitive make sure to avoid duplicates
            if (multiple && !caseSensitive && newValue) {
                newValue = newValue.filter(
                    (x, index) => newValue.findIndex(y => y.toLowerCase() === x.toLowerCase()) === index
                )
            }

            if (onChange) {
                onChange(newValue || (multiple ? [] : ''))
            }
        },
        [caseSensitive, multiple, onChange]
    )

    const handleBlurChange = useCallback(() => {
        if (freeSolo && !multiple && (!value || value?.label !== inputValue)) {
            handleChange(null, inputValue || '')
        }
    }, [freeSolo, multiple, handleChange, value, inputValue])

    return { handleChange, handleBlurChange }
}
