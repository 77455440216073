import Model from '@newspaces/lucky-base/Model'
import Runtime from '@newspaces/lucky-base/Runtime'

const maybeLoadOrganisation = async (context, defaultOrganisationSlugOrId) => {
    // if we have no currentUser we wont be able to query for the organisation
    if (!Runtime.client.currentUser) {
        return { mode: 'authentication' }
    }

    const zone = context.zone
    let organisationSlugOrId = context.organisationSlugOrId

    let organisationWasAutoChosen = false
    if (!organisationSlugOrId) {
        // If no organisation was provided and we have no authenticated user then
        // route into authentication mode, first. This will then allow to go into
        // choosing an organisation if the user has multiple ones.
        if (!Runtime.hasSession) {
            return { mode: 'authentication' }
        }

        if (!defaultOrganisationSlugOrId) {
            // Let user choose or create an organisation
            return { mode: 'chooseOrganisation' }
        }

        organisationSlugOrId = defaultOrganisationSlugOrId
        organisationWasAutoChosen = true
    }

    // Try to load the organisation now by either id or its slug and
    let organisation = new Runtime.Organisation()

    const loadOrganisation = query =>
        organisation.load(query, {
            // do caching if the user is a member of the organisation
            cache: document => {
                const userOrganisations = Runtime.user?.customData?.organisations
                if (userOrganisations?.length) {
                    return !!userOrganisations.find(({ id }) => id.$oid === document._id.toString())
                }
                return false
            },
        })

    const loadOrgFromUniqueSlug = () =>
        loadOrganisation({
            uniqueSlug: Model.stringSearchTerm(organisationSlugOrId, 'exact'),
        })

    if (Model.isId(organisationSlugOrId)) {
        if (!(await loadOrganisation(Model.id(organisationSlugOrId)))) {
            // sometimes unique slugs can give isId() false positives, so try to
            // load from unique slug aswell before setting it to null
            if (!(await loadOrgFromUniqueSlug())) {
                organisation = null
            }
        }
    } else if (!(await loadOrgFromUniqueSlug())) {
        organisation = null
    }

    const isProduction = Runtime.values.LUCKY_SCRIPTS_ENVIRONMENT === 'production'
    if (!organisation || (organisation.zone !== Runtime.zone.key && isProduction)) {
        // If you're on a custom domain and not logged in, the organisatin might not be able to load
        // so we route user to auth mode so he can log in first
        if (Runtime.customDomain && !Runtime.hasSession) {
            return { mode: 'authentication' }
        }

        if (organisationWasAutoChosen) {
            // Let user choose or create an organisation
            return { mode: 'chooseOrganisation' }
        }

        return { mode: 'error', error: 'invalidOrganisation' }
    }

    if (process.env.NODE_ENV !== 'test'
        && zone?.billing
        && (!organisation.subscriptions || !organisation.subscriptions.subscribed)) {
        await Runtime.apiMethod(`/organisations/${organisation.id}/stripe/setup`)
        await organisation.load()
    }

    return { organisationSlugOrId: organisation.slugOrId, organisation }
}

export default async context => {
    // If we don't have a global app then try to load organisation
    const currentAppIsGlobal = context.app && context.app.isGlobal
    //
    // Global app means we dont need to check for an organisation and an
    // local app to be loaded
    //
    if (currentAppIsGlobal) {
        return true
    }

    const defaultOrganisationSlugOrId = context.getDefaultOrganisationId()

    // check if there's a global root app
    const globalRootApp = context.globalRootApp

    if (globalRootApp && context.startRoute !== 'organisations') {
        // if we have no organisationSlugOrId and we have a global root app, set context as the app
        if (!context.organisationSlugOrId && !defaultOrganisationSlugOrId) {
            context.app = globalRootApp
            return true
        }

        const findOrganisationResult = await maybeLoadOrganisation(context, defaultOrganisationSlugOrId)
        context.organisation = findOrganisationResult.organisation || context.organisation
        context.organisationSlugOrId =
            findOrganisationResult.organisationSlugOrId || context.organisationSlugOrId

        // if organisation wasn't found and we have a global root app, set context as the app
        if (!context.organisation) {
            context.app = globalRootApp
            return true
        }
    }

    if (!context.organisation) {
        const loadOrganisationResult = await maybeLoadOrganisation(context, defaultOrganisationSlugOrId)

        context.error = loadOrganisationResult.error || context.error
        context.organisation = loadOrganisationResult.organisation || context.organisation
        context.organisationSlugOrId =
            loadOrganisationResult.organisationSlugOrId || context.organisationSlugOrId

        if (loadOrganisationResult.mode) {
            context.mode = loadOrganisationResult.mode
            context.routeToMode(context.mode)
            return false
        }
    }

    //
    // Loads service usages asynchronously so the app loads faster
    //
    context.loadServiceUsages()

    return true
}
