/* eslint-disable max-len  */
/* eslint-disable no-template-curly-in-string */
export default {
    navigation: {
        userSettings: 'My settings',
        changeOrganisation: 'Change ${this.name}',
        changeApp: 'Change application',
        login: 'Login',
        signup: 'Sign up',
        logout: 'Logout',
        menu: 'Menu',
    },
    app: {
        errors: {
            title: 'An error has occurred',
            backToHome: 'Back to home',
            invalidInvitationToken: 'The provided invitation link has already been used or is invalid',
            invalidOrganisation: 'The requested organisation can not be found.',
            tooManyOrganisations: 'You have exceeded the maximum number of organisations you can be member of. To be able to join this or other organisation, you first need to leave one of your current organisations.',
            noAppAvailable: 'No applications are available for the chosen organisation.',
            appNotAccessible: 'You have no access to the requested application.',
            customDomainNotFound: 'The custom domain was not found.',
            unknown: 'Unknown error',
        },
        availableApps: 'Available applications',
        trialAlert: 'Your trial is ending on ${this.date}.',
        trialAction: 'Go to billing',
        subscription: {
            warning: {
                owner: {
                    title: {
                        permanent: 'Your organisation is blocked.',
                        temporary: 'The access to your organisation was temporarly suspended.',
                    },
                    past_due: 'We were unable to automatically renew your subscription.',
                    unpaid: 'We were unable to automatically renew your subscription.',
                    canceled: 'Your subscription was canceled.',
                    incomplete: 'Your subscription was not correctly activated.',
                    incomplete_expired: 'Your subscription was not correctly activated.',
                    noSubscription: 'Your trial period has ended and you do not have an active subscription.',
                    contactSupport: {
                        permanent:
                            'Get in contact with us to renew your subscription or manually start a new subscription. If a new subscription is not started until ${this.date}, the organisation will be permanently deleted.',
                        temporary: 'Get in contact with us to unlock your organisation again.',
                    },
                    actionSupport: 'Get in contact',
                    actionCreate: 'Start a new subscription',
                },
                user: {
                    title: {
                        permanent: 'Your organisation is blocked.',
                        temporary: 'The access to your organisation was temporarly suspended.',
                    },
                    text:
                        'Further actions from the organisation owner or billing administrator must be taken to restore access to the organisation.',
                },
            },
        },
    },
    auth: {
        logout: 'Logout',
        chooseAction: {
            title: 'Do you have a ${this.name} account?',
            acceptInvitation: 'Please login or signup now to accept the provided invitation:',
            loginAction: 'I already have an account',
            signUpAction: 'I want to create an account',
        },
        login: {
            title: 'Login to ${this.name}',
            action: 'Login now',
            email: 'EMail-Address',
            password: 'Password',
            passwordForgotten: 'Forgot your password?',
            noAccountSignUp: 'No account yet? Create an account now.',
            errors: {
                invalidEmail: 'No account found for the given EMail-Address.',
                invalidPassword: 'Invalid password for the given EMail-Address.',
                invalidCredentials: 'Invalid EMail-Address or password.',
                invalidZone: 'The user does not have access to this zone.',
            },
        },
        signUp: {
            title: 'Signup to ${this.name}',
            action: 'Create account',
            email: 'Your EMail-Address',
            password: 'Choose a Password',
            alreadyRegisteredLogin: 'Already have an account? Login here',
            errors: {
                invalidEmail: 'The given EMail address is invalid.',
                invalidPassword: 'Invalid password, requires min. six characters.',
                alreadySignedUp: 'There already is an account with the given EMail-Address.',
                alreadyLoggedIn: 'You are already logged in. Logout to be able to create a new account.',
            },
        },
        resetPasswordQuery: {
            title: 'Reset your ${this.name} password',
            action: 'Send EMail to reset',
            email: 'EMail-Address you registered with',
            success:
                'An EMail has been sent to ${this.email} with instructions on how to reset your password.',
            rememberPasswordLogin: 'Remember your password again? Then login here.',
            errors: {
                invalidEmail: 'There is no account registered for the given EMail-Address.',
                unknownError: 'An unknown error has occurred',
            },
        },
        resetPasswordAction: {
            title: 'Set your new ${this.name} password',
            action: 'Set new password',
            email: 'EMail-Address you registered with',
            password: 'New password',
            passwordConfirm: 'Repeat new password',
            success: 'Your new password has successfully been set. You can now login with your new password.',
            loginNow: 'Login with your new credentials now',
            errors: {
                passwordMissmatch: 'The passwords do not match.',
                expiredToken: 'The password-reset link has expired. Please reset your password again.',
                invalidToken: 'The password-reset link is invalid. Please reset your password again.',
                invalidEmail: 'There is no account registered for the given EMail-Address.',
                invalidPassword: 'Invalid password, requires min. six characters.',
            },
        },
    },
    manageOrganisations: {
        newOrganisation: 'Create a new organisation',
        yourOrganisations: 'Your organisations',
        emptySearch: 'No organisation found',
        moveOrganisation: 'Move organisation',
        leaveOrganisation: 'Organisation verlassen',
        create: {
            label: 'Create your organisation',
            description: '',
            name: 'Name of your organisation',
            country: 'In which country is your organisation located?',
            action: 'Create organisation',
        },
        leaveOrganisationPrompt: {
            title: 'Leave ${this.organisationName}',
            text: 'Are you sure you want to leave the "${this.organisationName}" organisation?',
            action: 'Yes, leave',
            errors: {
                onlyOwner:
                    'You cannot leave this organisation because you are the only active owner. What you can do is delete the organisation in the organisation settings.',
            },
        },
    },
    organisationSettings: {
        profile: {
            title: 'Profile',
            uploadAvatarButton: 'Upload new picture',
            uniqueSlug: {
                title: 'Unique name',
                description:
                    'The unique name is used to generate readable URLs as well as it is used as sender name in Mails. Caution: If you already are using your URLs somewhere and changing this name will render them invalid.',
                action: 'Set unique name',
                errors: {
                    invalidSlug: 'The name is invalid. Please choose another one.',
                    forbidden: 'You have no permissions to change the unique name.',
                    alreadyClaimed: 'This unique name is already used by someone else.',
                    unknown: 'An unknown error has ocurred.',
                },
                success: 'The unique name was set and your URLs have been changed.',
            },
            information: {
                title: 'Profile settings',
            },
            dangerZone: {
                title: 'Dangerzone',
                deleteOrganisation: {
                    title: 'Delete ${this.organisationName}',
                    description:
                        'Delete all data from "${this.organisationName}". Caution: This can not be made undo.',
                    action: 'Delete',
                },
                deleteOrganisationModal: {
                    title: 'Delete ${this.organisationName}',
                    text:
                        'Are you sure you want to delete all data from "${this.organisationName}"? This can not be made undo.',
                    input: {
                        text: 'To proceed, please enter the text "${this.organisationName}" in the field:',
                        action: 'Delete now',
                    },
                },
            },
        },
        billing: {
            title: 'Billing',
            products: {
                pricing: {
                    flat: {
                        month: '${this.amount} / month',
                        year: '${this.amount} / year',
                    },
                    metered: '${this.amount} per unit / month',
                    tiered:
                        '${this.flatAmount} for the first ${this.flatLimit} units. ${this.meteredAmount} for each additional unit.',
                    tieredLabel: 'Based on usage',
                },
            },
            subscription: {
                title: 'Subscription',
                empty: 'No subscription available.',
                priceLink: 'Open price information',
                trial: {
                    title: 'Subscription on trial',
                    text:
                        'The subscription "${this.name}" is on trial until ${this.date}. To keep accessing your organization, you must finish the subscription by the trial end.',
                    action: 'Subscribe now!',
                    price: 'Price: ${this.price}',
                    priceLink: 'Open price information',
                },
                status: {
                    none: 'No subscription',
                    incomplete: 'Incomplete',
                    incomplete_expired: 'Expired',
                    trialing: 'Trial until ${this.date}',
                    active: 'Active',
                    past_due: 'Past due',
                    canceled: 'Canceled',
                    unpaid: 'Unpaid',
                    will_be_canceled: 'Cancelation on ${this.date}',
                },
                switchPrice: 'Switch price plan',
                createSubscription: 'Subscribe',
                newSubscription: 'Add a new subscription',
                reactivateSubscription: 'Reactivate canceled subscription',
                cancel: {
                    action: 'Cancel subscription',
                    prompt:
                        'Are you sure you want to cancel this subscription? It will be canceled on the end of the current period. This operation cannot be reversed.',
                },
                activate: {
                    action: 'Activate subscription',
                },
                editor: {
                    product: 'Product',
                    price: 'Price plan',
                    title: 'Select the product and the price plan you desire:',
                    serviceTitle:
                        'The service "${this.service}" is in use but your organisation is not subscribed to it. Subscribe now to one of the service subscriptions to continue:',
                },
                unitAmount: '${this.amount} per unit',
                service: {
                    usage: 'Usage: ${this.usage}',
                    costs: 'Costs: ${this.costs}',
                },
                applyDiscount: {
                    action: 'Redeem discount coupon',
                    tooltip: '${this.hoursLeft} hours left',
                    dialog: {
                        title: 'Please, provide the code for the coupon you want to reedem:',
                        action: 'Redeem coupon',
                    },
                    errors: {
                        invalidPromotion: 'This coupon is not valid anymore.',
                        codeNotFound: 'This coupon does not exist.',
                        unknownError: 'An unknown error happened. Please, try again.',
                    },
                },
            },
            credits: {
                title: 'Credits',
                current: 'Current credits: ${this.credits}',
                charge: 'Charge credit',
                amount: 'Amount to recharge',
                minimun: 'Minimum required: ${this.amount}',
            },
            services: {
                title: 'Services und usage',
                emptyHistory: 'There are no previous entries for this service',
                noCosts: 'Costs are only calculated once you have an active subscription for the service',
                history: 'Usage history for service ${this.service}',
                sections: {
                    allTime: 'All-time usage',
                    monthly: 'Usage for ${this.month}.${this.year}',
                },
                table: {
                    service: 'Service',
                    usage: 'Usage',
                    costs: 'Costs',
                    period: 'Period',
                    lastUpdated: 'Last updated',
                    subscription: 'Subscription',
                    actions: {
                        seeHistory: 'See service usage history',
                    },
                },
            },
            paymentMethods: {
                title: 'Payment methods',
                typeValue: {
                    card:
                        'Credit card (${this.card.brand}) **** ${this.card.last4} - valid until ${this.card.exp_month}/${this.card.exp_year}',
                    sepa_debit: 'Sepa-Debit **** ${this.sepa_debit.last4}',
                },
                default: 'Default',
                makeDefault: 'Set payment method as default',
                delete: {
                    action: 'Delete payment method',
                    prompt:
                        'Are you sure you want to delete this payment method? This action cannot be undone.',
                },
                add: 'Add payment method',
            },
            details: {
                title: 'Billing details',
                email: 'E-Mail for invoices',
                name: 'Invoice recipient',
                address: {
                    title: 'Invoice address',
                    line1: 'Address line 1',
                    line2: 'Address line 2',
                    postal_code: 'Postal code',
                    city: 'City',
                    country: 'Country',
                },
            },
            invoices: {
                title: 'Invoice history',
                number: 'Invoice No.',
                amountPaid: 'Paid',
                amountTotal: 'Total',
                date: 'Date',
            },
        },
        users: {
            title: 'User Management',
            table: {
                name: 'Name',
                email: 'E-Mail address',
                status: 'Status',
                statusValue: {
                    created: 'Created',
                    invite: 'Pending',
                    invited: 'Invited',
                    active: 'Active',
                    inactive: 'Inactive',
                },
                createdAt: 'Created at',
                isOwner: 'Owner',
                loadMore: 'Load more users...',
                editUser: 'Edit user',
                deleteUser: 'Delete user...',
                getLink: 'Copy invitation link',
            },
            toolbar: {
                userCount: {
                    single: 'User',
                    multi: 'Users',
                },
                search: 'Search for name or E-Mail',
                addUser: 'Invite user',
                language: 'Preferred language',
            },
            userSettings: {
                name: 'Name of user',
                email: 'E-Mail address',
                inviteUser: 'Invite user',
                eMailAlreadyExists: 'There already exists an user with the E-Mail address "${this.email}".',
                permissions: {
                    owner: {
                        checkbox: 'Make user an owner',
                        hint:
                            'Owners have all permissions and can read and edit everything without any restrictions.',
                        deniedHint: 'You do not have enough permissions to set an owner',
                    },
                    header: {
                        permission: 'Read',
                        edit: 'Edit',
                    },
                    toggleCustomTooltip: 'Toggle custom permissions',
                    customOptions: {
                        read: 'Read',
                        insert: 'Insert',
                        edit: 'Edit',
                        remove: 'Remove',
                    },
                    test: 'Test permissions',
                    testTooltip:
                        'Test in real time how the permissions you set impact on the user experience',
                    debugContext: {
                        title: 'User permissions live test',
                        finish: 'Finish',
                    },
                    apps: {
                        accessTo: 'Allows Access to',
                        noAccess: 'No access, click to allow access',
                        hasAccess: 'Access allowed',
                    },
                },
            },
            deleteUser: {
                title: 'Delete user',
                text: 'Are you sure you want to delete ${this.userName} from ${this.orgName}?',
                action: 'Delete user',
            },
            copyInvitationLink: {
                title: 'Invitation link',
                text:
                    'The invitation link can be used by the invited user to accept the invitation and join ${this.orgName}.',
                tooltipCopy: 'Copy to clipboard',
                tooltipCopied: 'Link copied to clipboard!',
            },
            debug: {
                description:
                    'Any modifications on the permissions will be reflected in real time on your current session. The changes will be reverted once you close this dialog.',
                transferUser: 'Transfer user',
                transferUserHint:
                    'If marked, once the test window is closed, these permissions will be transfered to the user you were creating/editing.',
            },
        },
    },
    userSettings: {
        profile: {
            title: 'Profile',
            name: 'Your name (visible to others)',
            email: 'E-Mail address',
            bio: 'About you',
            language: 'Preferred language',
            deleteAccount: {
                title: 'Dangerzone',
                description: 'Delete my account (${this.email}). Caution: This can not be made undo.',
                action: 'Delete',
            },
            deleteAccountModal: {
                title: 'Delete ${this.email}',
                text:
                    'Are you sure you want to delete your account (${this.email})? This can not be made undo.',
                input: {
                    verifyWord: 'delete',
                    text: 'To proceed, please enter the text "${this.verifyWord}" in the field:',
                    action: 'Delete now',
                },
            },
        },
        developer: {
            title: 'Developer + API',
            apiDocumentation: {
                title: 'API-Documentation',
                intro:
                    'The API-Reference contains all Documentation about all functionalities. You are required to have an API-Key to test the samples.',
                link: 'Go to the API-Documentation',
            },
            apiKeys: {
                title: 'API-Keys',
                intro:
                    'Every API-Key has the same permissions as your user account. If you need seperate permissions then create a new user account first.',
                newKeyAction: 'Create new key',
                newKeyName: 'Name of the key',
                copyWarning: 'Please copy and save this key as it will never be shown again.',
                copyKey: 'Copy key',
                copyKeyDone: 'Key copied to clipboard!',
                deleteKeyTitle: 'Delete key',
                deleteKeyPrompt:
                    'Are you sure you want to delete the key? This can not be undone and all apps using this key will no longer be able to access.',
                deleteKeyAction: 'Yes, delete key',
            },
        },
    },
}
