/* eslint-disable max-len */
import deepmerge from 'deepmerge'

import compileLanguage from './compileLanguage'

export default (languages, language, properties, fallbackLanguage) => {
    compileLanguage(properties)

    const combineMerge = (target, source) => {
        const sourceCopy = source.slice()

        target.forEach((item, index) => {
            if (sourceCopy[index] === undefined) {
                sourceCopy.push(item)
            }
        })

        return sourceCopy
    }

    if (languages[language] !== undefined) {
        // Merge languages together
        languages[language] = deepmerge(languages[language], properties, { arrayMerge: combineMerge })
    } else {
        languages[language] = properties
    }

    if (fallbackLanguage && fallbackLanguage !== language) {
        languages[language] = deepmerge(languages[fallbackLanguage], languages[language], { arrayMerge: combineMerge })
    }
}
