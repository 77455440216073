/* eslint-disable import/first */
import axios from 'axios'
import AxiosHttpAdapter from 'axios/lib/adapters/http'
import nanoid from 'nanoid'
import EventSourcePolyfill from 'eventsource'

// Must be set before loading browser
if (typeof window !== 'undefined') {
    window.EventSource = EventSourcePolyfill
}

import RuntimeBrowser from './Runtime.browser'

export default class TestRuntimeBrowser extends RuntimeBrowser {
    sentMails = {}

    constructor() {
        super()
        this.environment = 'browser-test'
    }

    // eslint-disable-next-line class-methods-use-this
    getApiUrl(path) {
        return `http://localhost:3000${path || ''}`
    }

    _initFromConfiguration() {
        // Assign unique id for models to not clanch with other tests
        process.env.MODEL_TEST_UUID = nanoid()

        // we run in jsdom env but ensure to use nodejs adapter to avoid CORS issues on axios
        axios.defaults.adapter = AxiosHttpAdapter

        super._initFromConfiguration()
    }

    async loginWithTestUser(testUserName) {
        await this.logout()

        const loginResult = await this.loginWithEMail(`cotest-${testUserName}@campai.com`, 'test1234')
        if (loginResult !== null) {
            const signupResult = await this.signUpWithEMail(
                `cotest-${testUserName}@campai.com`,
                'test1234',
                'test1234'
            )
            if (signupResult != null) {
                throw new Error('Unable to sign test user up')
            }
        }

        return this.userId
    }

    isTestUserLoggedIn(testUserName) {
        return (
            this.user?.profile?.email &&
            this.user.profile.email.toLowerCase() === `cotest-${testUserName}@campai.com`.toLowerCase()
        )
    }

    async _performSendEmail(args) {
        const ToAddresses = args.Destination.ToAddresses

        ToAddresses.forEach(toAddresse => {
            if (!this.sentMails[toAddresse]) this.sentMails[toAddresse] = []

            this.sentMails[toAddresse].push(args)
        })

        return true
    }
}
