import { makeObservable, observable, computed, action } from 'mobx'
import { createBrowserHistory } from 'history'
import * as Sentry from '@sentry/browser'
import nanoid from 'nanoid'
import { matchPath } from 'react-router'

import Model from '@newspaces/lucky-base/Model'
import Runtime from '@newspaces/lucky-base/Runtime'
import DateTime from '@newspaces/lucky-base/DateTime'

import handleInvitationToken from './handleInvitationToken'
import userHasAccessToApp from './userHasAccessToApp'
import setI18nDefaultLocale from './setI18nDefaultLocale'
import waitForNewOrganisation from './waitForNewOrganisation'
import tryChooseApplication from './tryChooseApplication'
import configureSentry from './configureSentry'
import updateZonesOnUserData from './updateZonesOnUserData'
import setupNotifications from './setupNotifications'
import handleIosSwipeBack from './handleIosSwipeBack.mobile'
import getPlatform from './getPlatform'
import updateUserLastSeenAt from './updateUserLastSeenAt'
import getDefaultOrganisationId from './getDefaultOrganisationId'
import getDefaultApplication from './getDefaultApplication'
import handleLoadServiceUsages from './handleLoadServiceUsages'
import updateDocumentTitle from './updateDocumentTitle'
import getRootUrl from './getRootUrl'
import buildRoute from './buildRoute'
import routeTo from './routeTo'
import handleLoadOrganisation from './handleLoadOrganisation'
import importAndStartAppState from './importAndStartAppState'
import handleLoadUserData from './handleLoadUserData'
import updateTheme from './updateTheme'
import reloadUserData from './reloadUserData'

export class AppContext {
    zone = null

    allApps = []

    app = null

    appTheme = {}

    appLoader = false

    organisationSlugOrId = null

    invitationToken = null

    resetPasswordToken = null

    // URL to redirect the user after accepting an invitation
    returnTo = null

    //
    // web
    // android
    // ios
    //
    platform = 'unknown'

    //
    // initializing = app is still loading
    // error = app is in error mode and the error is written in the error property
    // authentication = authentication is required
    // chooseOrganisation = force user to choose an organisation or create one
    // chooseApp = force user to choose an active application
    // started = app is loaded and fully functional
    //
    mode = 'initializing'

    //
    // invalidInvitationToken = A provided invitation token was invalid
    // invalidOrganisation = an invalid organisation was provided or user may not access it
    // tooManyOrganisations = user has too many organisations already and can not add anymore
    // noAppAvailable = an authenticated user has no access to any app of the current organisation
    // appNotFound = an invalid or non existent app was provided
    // appNotAccessible = the app exists but the user may not access it for the given org
    //
    error = null

    //
    // Global browser history object usable from anywhere
    //
    history = null

    historyListener = null

    userData = null

    organisation = null

    modals = []

    startRoute = null

    serviceUsages = []

    debugContext = null

    debugOrganisationUser = null

    listeners = []

    settings = {}

    // used to show a custom React component as content of the
    // AppLayout. if not provided, then the default content is
    // used
    content = null

    constructor() {
        makeObservable(this, {
            availableApps: computed,
            zone: observable.ref,
            app: observable.ref,
            appTheme: observable.ref,
            appLoader: observable,
            organisationSlugOrId: observable,
            invitationToken: observable,
            mode: observable,
            error: observable,
            userData: observable.ref,
            organisation: observable.ref,
            modals: observable,
            userScopes: computed,
            userIsOwner: computed,
            appsUserHasAccessTo: computed,
            canLogout: computed,
            serviceUsages: observable,
            trialDays: computed,
            trialEnd: computed,
            isOnTrial: computed,
            debugContext: observable,
            debugOrganisationUser: observable,
            settings: observable,
            content: observable,
        })
    }

    //
    // AA 11/2020: !! ONLY TOUCH THIS IF YOU KNOW WHAT YOU ARE DOING !!
    //
    // Initialize the app context with different logic
    //
    async init() {
        const done = async () => {
            this.updateDocumentTitle()
            try {
                await this.fireListeners('appContextIntialized')
            } catch {
                // does nothing
            }
        }

        const appDefinedOnUrl = !!this.app && Runtime.currentSubdomainType !== 'app'

        //
        // Checks if the app is running on web, android or ios
        //
        this.platform = await getPlatform()

        //
        // Setup some stuff specifically for the current platform
        //
        this.setupPlatform()

        //
        // Create our global app history object now and normalize the current url
        //
        this.history = createBrowserHistory()

        // Listen for changes to the history
        this.historyListener = this.history.listen((location, historyAction) => {
            if (this.listenerExists('historyChange')) {
                this.fireListeners('historyChange', {
                    action: historyAction,
                    location
                })
            }
        })

        //
        // When running on mobile enables iOS swipe back
        //
        if (this.isMobile) {
            this.enableIosSwipeBack()
        }

        //
        // If error is already set (on bootstrap for example), we move to error page
        //
        if (this.error) {
            this.mode = 'error'
            this.routeToMode(this.mode)
            return done()
        }

        //
        // If we received a resetPasswordToken then immediately go into authentication mode
        //
        if (this.resetPasswordToken) {
            this.mode = 'authentication'
            this.history.replace(`/auth/reset-password?resetPasswordToken=${this.resetPasswordToken}`)
            return done()
        }

        //
        // Before loading userData and organisation in paralell, we first
        // initiate a guestUserData in case there's not current user at all
        // this will allow loadOrganisation to find the organisation if scops / permissions allow to
        // which couldn't be done in case Runtime.currentUser was null
        //
        if (!Runtime.client.currentUser) {
            this.userData = await Runtime.maybeInitGuestUserData()
        }

        //
        // Loads userData and organisation at the same time, so the app loads faster
        //
        const [userData, organisationWasLoaded] = await Promise.all([
            handleLoadUserData(this),
            handleLoadOrganisation(this),
        ])
        this.userData = userData
        let organisationLoaded = organisationWasLoaded

        if (this.mode === 'authentication') {
            return done()
        }

        // Checks if organisation matches subdomain
        if (Runtime.customDomain && !this.organisationMatchesCustomDomain()) {
            return done()
        }

        //
        // Updates lastSeenAt on userData
        // No need to wait for it
        //
        updateUserLastSeenAt(this.userData)

        //
        // Setup i18n depending on user language and organisation country
        //
        setI18nDefaultLocale(this.userData, this.organisation)

        //
        // Now try to handle any eventual invitation token
        //
        const invitationResult = await handleInvitationToken(this, this.invitationToken)
        if (invitationResult) {
            this.error = invitationResult.error
            this.organisationSlugOrId = invitationResult.organisationSlugOrId || this.organisationSlugOrId

            if (invitationResult.mode) {
                this.mode = invitationResult.mode
                this.routeToMode(this.mode, { returnTo: this.returnTo })
                return done()
            }

            // Loads the new organisation and refresh user with new organisationIds
            organisationLoaded = await handleLoadOrganisation(this)
            setI18nDefaultLocale(this.userData, this.organisation)

            if (this.returnTo) {
                // force reload on returnTo url
                window.location.href = `${decodeURIComponent(this.returnTo)}`
            }
        }

        //
        // Try to update the zones array on the userData
        // This code need to be after the invitation handle
        //
        await updateZonesOnUserData(this.userData)

        if (!organisationLoaded) {
            return done()
        }

        // Check if the user have access to the app
        if (!(await this.userHaveAccessToApp())) {
            return done()
        }

        // Try to choose an application
        if (!(await this.tryChooseApplication())) {
            return done()
        }

        // Make sure to have  a guest session coming here to use any of mongo
        this.userData = await Runtime.maybeInitGuestUserData()

        // Import app module and initializes its state
        if (!(await importAndStartAppState(this))) {
            return done()
        }

        // Some scopes for tracing proper errors
        configureSentry(this.userData, this.organisation, this.app)

        // Finally we're started yay
        this.mode = 'started'

        const currentPathMatchesCurrentApp = matchPath(window.location.pathname, {
            path: this.buildRoute('/'),
            exact: false,
            strict: false,
        })

        if (!appDefinedOnUrl && this.app && !currentPathMatchesCurrentApp) {
            // if the app was not initiated with app defined on the url
            // reaching this point means that the app was autochosen
            // and then we need to push to rootUrl in case the current route
            // does not match the current app
            this.history.replace(this.rootUrl)
        }

        //
        // Setup push notifications and updates userData with fcm token
        // this needs to be done at the end, because if there are notification
        // listeners, they need to run after AppContext was initiated
        //
        await setupNotifications(this, this.userData)

        return done()
    }

    async userHaveAccessToApp() {
        if (this.app?.requiresOrganisation && !this.organisation) {
            this.mode = 'error'
            this.error = 'invalidOrganisation'
            this.routeToMode(this.mode)
            return false
        }

        // If there's an valid app but user has no access to it then error out here
        if (this.app && !this.userHasAccessToApp(this.app.key)) {
            //
            // If not authenticated let user authenticate first
            //
            if (!Runtime.hasSession) {
                this.mode = 'authentication'
                this.routeToMode(this.mode)
                return false
            }

            this.mode = 'error'
            this.error = 'appNotAccessible'
            this.routeToMode(this.mode)
            return false
        }

        return true
    }

    async routeToMode(mode, { returnTo } = {}) {
        const routeIfNotCurrentRoute = (pathToCheck, replaceRoute) => {
            const isCurrentRoute = window.location?.pathname?.toLowerCase().startsWith(pathToCheck)
            // avoid routing to /auth in case current route is /auth/login for example
            if (!isCurrentRoute) {
                this.history.replace(replaceRoute)
            }
        }

        if (mode === 'chooseApp') {
            this.history.replace(`/${this.organisationSlugOrId}/apps`)
        } else if (mode === 'chooseOrganisation') {
            routeIfNotCurrentRoute('/organisations', '/organisations')
        } else if (mode === 'error') {
            this.history.replace('/error')
        } else if (mode === 'authentication') {
            if (returnTo) {
                routeIfNotCurrentRoute('/auth', `/auth?returnTo=${returnTo}`)
            } else {
                routeIfNotCurrentRoute('/auth', '/auth')
            }
        }
    }

    async tryChooseApplication() {
        // If we have no app and an organisation try to choose a default one for the user
        if (!this.app && this.organisation) {
            const defaultApp = this.getDefaultApplication()
            const appResult = tryChooseApplication(this, defaultApp)
            this.app = appResult.app || this.app
            this.error = appResult.error || this.error

            if (appResult.mode) {
                this.mode = appResult.mode
                this.routeToMode(this.mode)
                return false
            }
        }

        return true
    }

    async release() {
        // Cleanup history listener
        if (this.historyListener) {
            this.historyListener()
        }

        // Clear any scopes from Sentry
        Sentry.configureScope(scope => scope.clear())
    }

    hasAuth(redirect) {
        if (!this.canLogout) {
            if (redirect) {
                const redirectMode = ['login', 'signup'].includes(redirect) ? redirect : ''
                this.history.push(
                    `/auth/${redirectMode}?returnTo=${encodeURIComponent(window.location.href)}`
                )
            }
            return false
        }
        return true
    }

    get canLogout() {
        return Runtime.hasSession && !Runtime.hasGuestSession
    }

    async logout() {
        await Runtime.logout()

        Sentry.configureScope(scope => scope.clear())

        // Clear localstorage to avoid cache issues
        window.localStorage.clear()

        // -- enforce reload
        window.location.href = '/'
    }

    waitForNewOrganisation(organisationId) {
        return waitForNewOrganisation(this, organisationId)
    }

    get userScopes() {
        return Runtime.getUserScopes(this.organisation?.id, this.debugOrganisationUser)
    }

    get userIsOwner() {
        return this.userScopes.$owner === true
    }

    userHasAllScopes(...scopes) {
        return Runtime.userHasAllScopes(this.userScopes, null, ...scopes)
    }

    userHasAnyScopes(...scopes) {
        return Runtime.userHasAnyScopes(this.userScopes, null, ...scopes)
    }

    loggedUserHasAnyScopes(...scopes) {
        // bypasses debugOrganisationUser
        return Runtime.userHasAnyScopes(this.organisation?.id, null, ...scopes)
    }

    userHasAccessToApp(appKey, organisationId) {
        const app = this.availableApps.find(({ key }) => key === appKey)
        if (!app) {
            return false
        }

        if (app.isGuestOnly && this.canLogout) {
            return false
        }

        const userScopes = Runtime.getUserScopes(organisationId || this.organisation?.id, this.debugOrganisationUser)

        return userHasAccessToApp(userScopes, app)
    }

    get regularRootApp() {
        const app = this.availableApps?.find(_app => !_app.isGlobal && _app.isRoot)
        if (!app) {
            return null
        }

        if (!this.userHasAccessToApp(app.key)) {
            return null
        }

        return app
    }

    get globalRootApp() {
        const app = this.availableApps?.find(_app => _app.isGlobal && _app.isRoot)
        if (!app) {
            return null
        }

        if (!this.userHasAccessToApp(app.key)) {
            return null
        }

        return app
    }

    get availableApps() {
        return this.allApps.filter(({ mobile, available }) => {
            if (typeof mobile === 'boolean' && mobile !== this.isMobile) {
                return false
            }

            if (available === false) {
                return false
            }

            return true
        })
    }

    // Returns all apps the user has access to in the context of the current organisation
    get appsUserHasAccessTo() {
        if (!this.availableApps) {
            return []
        }

        const mustBeGlobal = !this.organisation

        return this.availableApps.filter(({ key, isGlobal, isHidden }) => {
            if (isHidden) {
                return false
            }
            if (mustBeGlobal && !isGlobal) {
                return false
            }
            if (!mustBeGlobal && isGlobal) {
                return false
            }
            return this.userHasAccessToApp(key)
        })
    }

    // Returns all apps the user has theoretically access to independant of current org
    get allAppsUserHasAccessTo() {
        if (!this.availableApps) {
            return []
        }

        return this.availableApps.filter(({ key, isHidden }) => {
            if (isHidden) {
                return false
            }

            // Try with none or global scopes first
            if (userHasAccessToApp(this.userData ? this.userData.scopes : {}, key)) {
                return true
            }

            // Iterate now with scopes of each accessible organisation
            if (this.userData) {
                for (const organisationId of this.userData.organisationIds) {
                    const organisationData = this.userData.getOrganisationData(organisationId)
                    if (organisationData) {
                        const { id, owner, ...scopes } = organisationData
                        const userScopes = { ...scopes, $owner: owner }

                        if (userHasAccessToApp(userScopes, key)) {
                            return true
                        }
                    }
                }
            }

            return false
        })
    }

    getRootUrl(appKey, organisationSlugOrId) {
        return getRootUrl(this, appKey, organisationSlugOrId)
    }

    get rootUrl() {
        return this.getRootUrl(this.app?.key, this.organisation?.slugOrId)
    }

    buildRoute(route) {
        return buildRoute(this, route)
    }

    routeTo(route) {
        return routeTo(this, route)
    }

    replaceRoute(route, state = {}) {
        this.history.replace(this.routeTo(route), state)
    }

    pushRoute(route, state = {}) {
        this.history.push(this.routeTo(route), state)
    }

    userGetSettingKey(settingKey, scope = 'app') {
        if (!settingKey) {
            throw new Error('Missing setting key.')
        }

        const result = []

        if (['organisation', 'app'].includes(scope) && this.organisation) {
            result.push(this.organisation.id)
        }

        if (scope === 'app' && this.app) {
            result.push(this.app.id)
        }

        if (!settingKey) {
            throw new Error('Missing setting key for user settings.')
        }

        result.push(settingKey.replace(/\./g, '_'))

        return result.join('_')
    }

    userGetSetting(settingKey, scope) {
        if (!this.userData) {
            return false
        }

        const key = this.userGetSettingKey(settingKey, scope)
        return this.userData.settings[key] || null
    }

    async userSetSetting(settingKey, settingValue, scope) {
        if (!this.userData) {
            return false
        }

        const key = this.userGetSettingKey(settingKey, scope)

        if (settingValue === undefined) {
            delete this.userData.settings[key]
        } else {
            this.userData.settings[key] = settingValue
        }

        if (!(await this.userData.save())) {
            this.userData.revert()
            return false
        }

        // reload userData to cache new changes
        this.reloadUserData()

        return true
    }

    /**
     * Returns first subscription that has a failing status
     */
    get failedSubscription() {
        if (!this.organisation) {
            return false
        }

        const { subscriptions } = this.organisation

        if (!subscriptions) {
            return false
        }

        const { subscription } = subscriptions

        const failedStatus = ['unpaid', 'incomplete', 'incomplete_expired']

        if (failedStatus.includes(subscription?.status)) {
            return subscription
        }

        return null
    }

    get mainSubscription() {
        if (!this.organisation?.subscriptions?.subscription?.subscriptionId) {
            return null
        }

        return this.organisation.subscriptions.subscription
    }

    get subscribeableServices() {
        if (!this.organisation || !this.zone?.billing?.serviceUsages?.length) {
            return []
        }

        return this.zone.billing.serviceUsages.filter(service => {
            return !!service.productId
        })
    }

    get trialDays() {
        return this.zone?.billing?.subscription?.trialDays || 0
    }

    get isOnTrial() {
        if (process.env.NODE_ENV === 'development') {
            return false
        }

        return this.organisation?.subscriptions?.subscription?.status === 'trialing' && !!this.trialEnd
    }

    get trialEnd() {
        const trialEnd = this.organisation?.subscriptions?.subscription?.trialEnd
        if (!trialEnd) {
            return null
        }

        return new DateTime.Date(trialEnd)
    }

    get hasBilling() {
        // currently billing is only used for organisation so check that aswell
        return !!this.zone?.billing && !!this.organisation
    }

    get canManageBilling() {
        return this.userHasAllScopes('organisation.edit')
    }

    getDefaultOrganisationId() {
        return getDefaultOrganisationId()
    }

    getDefaultApplication(options) {
        return getDefaultApplication(this, options)
    }

    async loadServiceUsages() {
        this.serviceUsages = await handleLoadServiceUsages(this, this.organisation)
    }

    updateTheme() {
        return updateTheme(this)
    }

    updateDocumentTitle(prefix) {
        return updateDocumentTitle(this, prefix)
    }

    //
    // Push a new dialog that'll be shown as modal on top of all others:
    //
    // {
    //   title: String,             // Optional title for dialog
    //   text: String,              // Optional intro text for dialog
    //   Component: React,          // Actual dialog content component
    //   padding: Boolean,          // If false the content has no padding. Defaults to true
    //   fullScreen: Boolean,       // If true makes the dialog full screen. Defaults to false
    //   actions: [
    //     'close'                  // Simple key action. It closes the dialog and returns the key as arg to
    //                              // the onClose handler. The key must be a valid i18n string in i18n().misc
    //
    //     {                        // More complex action supporting async custom actions
    //       label: String,         // Label for the action
    //       action: async function // If async the button shows a loading indicator until finished.
    //                              // The result of this action will be returned as arg to the onClose handler.
    //     }
    //   ],
    //   primaryAction: Number,      // Index of a primary action
    //   deleteAction: Number,       // Index of a delete action
    //   onClose: function (arg)     // Function called after dialog has closed. Arg see actions before.
    // }
    //
    pushDialog(dialogProperties) {
        this.modals.push({
            type: 'dialog',
            id: nanoid(),
            ...this._normalizeProperties(dialogProperties),
        })
    }

    //
    // Push a new modal that'll be shown as modal on top of all others:
    //
    // {
    //   Component: React,          // Actual modal component (i.e. <Dialog />)
    //   onClose: function (arg)    // Function called after dialog has closed. Arg see actions before.
    //   [...properties]            // More properties to be directly delivered to the modal instance
    // }
    //
    pushModal(modalProperties) {
        this.modals.push({
            type: 'modal',
            id: nanoid(),
            ...this._normalizeProperties(modalProperties),
        })
    }

    //
    // Push a new progress modal that can be controlled from the outer side to update
    //
    // {
    //   noCloseAlert: Boolean,     // If set to true shows a warning to not close the browser (defaults to true)
    //   [...]                      // Default properties of progress (see returned object)
    // }
    //
    // Returns
    // {
    //   status: String,            // The current status of the progress (progress | success | error)
    //   title: String,             // Title for the current status
    //   text: String               // Text for the current status
    //   progress: Number,          // If set to a number between 0 <> 100 defines a determinated progress
    //   actions: [                 // Custom actions, only available when status is not in progress
    //     {
    //       label:String,          // Label / Content of button children
    //       ...ButtonProperties    // Other button properties like variant, etc.
    //       onClick                // If provided, the result of the onClick handler will be returnd to the onClose handler
    //     }
    //  ]
    // }
    pushProgress({ status, title, text, secondaryText, progress, actions, ...progressProperties } = {}) {
        const progressStore = observable({
            status: status || 'work',
            title,
            text,
            secondaryText,
            progress,
            actions,
        })

        this.modals.push({
            type: 'progress',
            id: nanoid(),
            ...this._normalizeProperties(progressProperties),
            store: progressStore,
        })

        return progressStore
    }

    /**
     * Turn function properties into mobx actions
     * so they can be correctly propagated to dialogs and modals
     */
    _normalizeProperties(properties) {
        const normalizedProperties = {}
        Object.keys(properties).forEach(key => {
            if (typeof properties[key] === 'function') {
                normalizedProperties[key] = action(properties[key])
            } else {
                normalizedProperties[key] = properties[key]
            }
        })
        return normalizedProperties
    }

    clearModal(modal) {
        this.modals = this.modals.filter(m => m !== modal)
    }

    get isMobile() {
        return process.env.RUNTIME_TARGET === 'mobile'
    }

    get deviceLanguage() {
        let language = navigator.language.toUpperCase()
        if (language.includes('-')) {
            language = language.split('-')[1]
        }
        return language
    }

    async enableIosSwipeBack() {
        await handleIosSwipeBack.enable()
    }

    async disableIosSwipeBack() {
        await handleIosSwipeBack.disable()
    }

    setupPlatform() {
        if (process.env.NODE_ENV === 'test') {
            return
        }

        // When is not mobile, add a class that will be used together
        // with :hover, so we only have hover effects on browser
        const root = document.querySelector('#root')
        if (root) {
            root.classList.add(this.isMobile ? 'touch' : 'no-touch')
        }
    }

    removeListener(listenerName) {
        this.listeners = this.listeners.filter(listener => listener.name !== listenerName)
    }

    addListener(listenerName, onExecute) {
        this.listeners.push({ name: listenerName, execute: onExecute })
    }

    listenerExists(listenerName) {
        return !!this.listeners.find(listener => listener.name === listenerName)
    }

    async fireListeners(listenerName, data) {
        if (!this.listeners?.length) {
            return null
        }

        return Promise.all(
            this.listeners
                .filter(listener => listener.name === listenerName)
                .map(listener => listener.execute(data))
        )
    }

    async reloadUserData(options) {
        return reloadUserData(this, options)
    }

    organisationMatchesCustomDomain() {
        // If not running under custom domain everything is fine
        if (!Runtime.customDomain) {
            return true
        }

        const clearCachedInformation = () => {
            window.localStorage.setItem('CustomDomainInfo', '')
        }

        const { organisation } = this

        // If organisation was not found, sends to error
        // since we must ensure only users with that organisation log in
        if (!organisation) {
            clearCachedInformation()
            this.mode = 'error'
            this.error = 'invalidOrganisation'
            this.routeToMode(this.mode)
            return false
        }

        const { status, hostname } = organisation.customDomain || {}
        if (status !== 'active' || hostname?.toLowerCase() !== Runtime.customDomain.toLowerCase()) {
            clearCachedInformation()
            window.location.reload()
            return false
        }

        return true
    }

    cleanupCache(type) {
        if (type === 'organisation') {
            if (!this.organisation) {
                return false
            }

            // cleanup cache from both queries that might be used to load
            // the current organisation
            Runtime.Organisation.createQuery({
                uniqueSlug: Model.stringSearchTerm(this.organisation.uniqueSlug, 'exact')
            }).cleanupCache()
            Runtime.Organisation.createQuery({
                _id: Model.id(this.organisation.id)
            }).cleanupCache()

            return true
        }

        return false
    }
}

export default new AppContext()
