import Runtime from '@newspaces/lucky-base/Runtime'

import extractSlugFromUrl from '../extractSlugFromUrl'

export default (href, apps) => {
    if (Runtime.isAppSubdomain) {
        const slug = extractSlugFromUrl(href, { allowAppKeys: true })
        const subdomainApp = slug && apps.find(({ key }) => key.toLowerCase() === slug.toLowerCase())
        if (subdomainApp) {
            if (!subdomainApp.keyAsSubdomain) {
                throw new Error(`App "${subdomainApp.key}" can't be used as subdomain.`)
            }

            return { type: 'app', slug }
        }
    }

    return { type: null, slug: null }
}