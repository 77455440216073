export default fields => {
    return fields.map((field, idx) => {
        const { parentFieldIsArray } = field

        if (!parentFieldIsArray) {
            return null
        }

        return {
            path: `$values.value_${idx}`,
            preserveNullAndEmptyArrays: true
        }
    }).filter(Boolean)
}