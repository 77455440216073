import parseDate from './parseDate'

export default (localeObject, type, value, emptyValue = null, invalidValue = null) => {
    if (value === null || value === undefined || value === '') {
        return emptyValue
    }

    if (type === 'int' || type === 'float') {
        const number = type === 'int' ? parseInt(value, 10) : localeObject.parseNumber(value)

        if (Number.isNaN(number)) {
            return invalidValue
        }

        return number
    }

    if (type === 'date' || type === 'time' || type === 'datetime') {
        const date = parseDate(localeObject.locale, value, { type })

        if (!date) {
            return invalidValue
        }

        return date
    }

    return value || emptyValue
}
