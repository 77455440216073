import qs from 'query-string'
import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'

import Alert from '@material-ui/lab/Alert'

import { makeStyles } from '@material-ui/core/styles'

import Runtime from '@newspaces/lucky-base/Runtime'
import Button from '@newspaces/lucky-components/Button'
import TextInput from '@newspaces/lucky-components/TextInput'

import i18n from '../i18n'

import AuthContainer from './AuthContainer'

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    error: {
        flexShrink: 0,
        flexGrow: 0,
        margin: theme.spacing(0, 0, 1, 0),
    },
    success: {
        marginBottom: theme.spacing(3),
    },
    form: {
        flexGrow: 1,
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}))

export default observer(() => {
    const classes = useStyles()

    const [isSuccess, setSuccess] = useState(false)
    const [isSubmitting, setSubmitting] = useState(false)

    const [email, setEMail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')

    const [errorMessage, setErrorMessage] = useState('')

    const { resetPasswordToken } = qs.parse(window.location.search)

    const handleFormSubmit = async evt => {
        evt.preventDefault()

        setSubmitting(true)

        setSuccess(false)
        setErrorMessage('')

        const { error, success } = await Runtime.resetPassword(
            password,
            passwordConfirm,
            email,
            resetPasswordToken
        )

        setSuccess(success)

        if (error) {
            setErrorMessage(i18n().auth.resetPasswordAction.errors[error])
        }

        setSubmitting(false)
    }

    let content

    if (isSuccess) {
        content = (
            <>
                <Alert severity="success" variant="filled" className={classes.success}>
                    {i18n().auth.resetPasswordAction.success}
                </Alert>
                <Button fullWidth color="primary" variant="contained" to="/auth/login">
                    {i18n().auth.resetPasswordAction.loginNow}
                </Button>
            </>
        )
    } else {
        content = (
            <>
                {errorMessage && (
                    <Alert severity="error" variant="filled" className={classes.alert}>
                        {errorMessage}
                    </Alert>
                )}

                <form className={classes.form} onSubmit={handleFormSubmit}>
                    <TextInput
                        disabled={isSuccess}
                        variant="outlined"
                        margin="normal"
                        label={i18n().auth.resetPasswordAction.email}
                        autoComplete="email"
                        type="email"
                        value={email}
                        onChange={value => setEMail(value)}
                        required
                        autoFocus
                        fullWidth
                    />

                    <TextInput
                        disabled={isSuccess}
                        variant="outlined"
                        margin="normal"
                        label={i18n().auth.resetPasswordAction.password}
                        type="password"
                        value={password}
                        onChange={value => setPassword(value)}
                        required
                        fullWidth
                    />

                    <TextInput
                        disabled={isSuccess}
                        variant="outlined"
                        margin="normal"
                        label={i18n().auth.resetPasswordAction.passwordConfirm}
                        type="password"
                        value={passwordConfirm}
                        onChange={value => setPasswordConfirm(value)}
                        required
                        fullWidth
                    />

                    <Button
                        disabled={!passwordConfirm || !password || !email}
                        loading={isSubmitting}
                        type="submit"
                        fullWidth
                        size="large"
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        {i18n().auth.resetPasswordAction.action}
                    </Button>
                </form>
            </>
        )
    }
    return (
        <AuthContainer mode="resetPasswordAction">
            <div className={classes.root}>
                {content}
            </div>
        </AuthContainer>
    )
})
