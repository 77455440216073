import { useCallback } from 'react'

export default () => {
    const getOptionLabel = useCallback(option => option.label, [])

    const getOptionDisabled = useCallback(option => option.disabled || false, [])

    const groupBy = useCallback(option => option.group || '', [])

    return { getOptionLabel, getOptionDisabled, groupBy }
}
