import Runtime from './Runtime'

//
// This bootstraps the Runtime
//
let wasBootstrapped = false

export default async ({ Organisation, OrganisationUser, UserData, zones, zone, initUserData = true }) => {
    if (wasBootstrapped) {
        return
    }

    wasBootstrapped = true

    if (typeof zone === 'string') {
        const zoneKey = zone

        zone = zones.find(({ key }) => key === zoneKey)

        if (!zone) {
            throw new Error(`No zone for "${zoneKey}" found.`)
        }
    }

    if (!zone) {
        throw new Error('No zone provided.')
    }

    Runtime.zone = zone
    Runtime.Organisation = Organisation
    Runtime.OrganisationUser = OrganisationUser
    Runtime.UserData = UserData
    Runtime.configure()

    if (initUserData) {
        await Runtime.initUserData()
    }
}
