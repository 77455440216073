import DateTime from './DateTime'

export default class Date extends DateTime {
    constructor(date, settings) {
        super(date, settings, 'date')
    }

    fromObject({ year = 0, month = 0, date = 0 } = {}) {
        if (typeof year !== 'number' || typeof month !== 'number' || typeof date !== 'number') {
            return false
        }

        this._year = year
        this._month = month
        this._date = date

        return true
    }
}
