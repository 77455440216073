export default async (modelsQuery, skip, limit) => {
    const models = await modelsQuery.execute(limit, skip)

    if (!models?.length) {
        return null
    }

    return {
        skip: skip + models.length,
        models
    }
}
