import React from 'react'
import { observer } from 'mobx-react-lite'
import { makeStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'

import AppContext from '../AppContext'

import i18n from '../i18n'

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            background: `${theme.palette.background.dark} !important`,
            [theme.breakpoints.down('sm')]: {
                background: `${theme.palette.background.default} !important`,
            },
        },
        '@media print': {
            html: {
                height: 'max-content'
            },
        }
    },
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(4),
        },
    },
    logo: {
        height: 32,
        color: theme.palette.text.primary,
        cursor: 'pointer',
    },
    box: {
        flexShrink: 0,
        background: theme.palette.background.default,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shape.panelShadow,
        overflow: 'hidden',
        marginTop: theme.spacing(8),
        padding: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            boxShadow: 'none',
            borderRadius: 0,
            borderLeft: '0px solid transparent',
            borderRight: '0px solid transparent',
            marginTop: theme.spacing(6),
            width: '100%',
        },
    },
    footer: {
        marginTop: theme.spacing(4),
        fontSize: 14,
        color: theme.palette.text.secondary,
    },
    footerLink: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
        '&:active': {
            color: theme.palette.text.primary,
        },
    },
}))

export default observer(({ box = true, footer = true, children }) => {
    const classes = useStyles()

    const { zone, canLogout } = AppContext

    let logoutElement
    if (canLogout) {
        logoutElement = (
            <span className={classes.footerLink} onClick={AppContext.logout}>
                {i18n().navigation.logout}
            </span>
        )
    }

    let footerElement
    if (footer) {
        footerElement = (
            <div className={classes.footer}>
                <span>
                    <span>Copyright © </span>
                    <Link color="inherit" href={zone.website} className={classes.footerLink}>
                        {zone.vendor}
                    </Link>
                    <span>. </span>
                </span>
                {logoutElement}
            </div>
        )
    }

    return (
        <div className={classes.root}>
            <Link href="/">
                <zone.Logo className={classes.logo} alt={zone.name} />
            </Link>
            {box && <div className={classes.box}>{children}</div>}
            {!box && children}
            {footerElement}
        </div>
    )
})
