import Model from '@newspaces/lucky-base/Model'
import ServiceUsage from '@newspaces/lucky-base/models/ServiceUsage'
import DateTime from '@newspaces/lucky-base/DateTime'

export default async (context, organisation) => {
    if (!context.userHasAllScopes('organisation.read')) {
        return null
    }

    const now = new DateTime.Date()
    const currentYear = now.year
    const currentMonth = now.month + 1

    return ServiceUsage.createQuery({
        $and: [
            {
                $or: [{ year: currentYear }, { year: null }, { year: { $exists: false } }],
            },
            {
                $or: [{ month: currentMonth }, { month: null }, { month: { $exists: false } }],
            },
            {
                $and: [{ usage: { $exists: true } }, { usage: { $gt: 0 } }],
            },
        ],
        organisation: Model.id(organisation.id),
    }).execute()
}
