/* eslint-disable import/prefer-default-export */
import React, { useMemo } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core/styles'
import { fade } from '@newspaces/lucky-components/utils'


// eslint-disable-next-line no-unused-vars
const useColors = baseColor => {
    const theme = useTheme()

    const color = theme.palette[baseColor]?.main || baseColor || theme.palette.primary.main

    return useMemo(() => ({
        color1: fade(color, 0.54, 'black'),
        color2: fade(color, 0.24, 'white'),
        color3: fade(color, 0.08, 'white'),
    }), [color])
}
    

export const AddToListIcon = React.forwardRef(({ color, ...props }, ref) => {
    const svg = `<g>
  <path fill="currentColor" fill-opacity="0.3" d="M9.66995 42.9885C10.472 45.4102 12.7942 46.8734 15.3072 46.4339C17.711 46.0134 21.178 45.2861 25.7976 44.0329C30.4551 42.7695 33.8341 41.6363 36.1248 40.7811C38.4901 39.898 39.7558 37.4961 39.262 35.02C38.6376 31.8898 37.5378 26.918 35.6774 19.8829C33.8281 12.8895 32.3339 8.04246 31.3301 5.01153C30.528 2.5898 28.2058 1.12661 25.6928 1.56614C23.289 1.9866 19.822 2.71395 15.2024 3.96706C10.5449 5.23048 7.16588 6.36371 4.87523 7.2189C2.50988 8.10197 1.24416 10.5039 1.73802 12.98C2.36236 16.1102 3.46221 21.082 5.32257 28.1171C7.17193 35.1105 8.66609 39.9575 9.66995 42.9885Z"/>
  <path fill="currentColor" fill-rule="evenodd" d="M32.6965 28.6881C32.779 27.157 34.0518 26 35.5852 26H36.4148C37.9482 26 39.221 27.157 39.3035 28.6881C39.3572 29.6865 39.4112 30.963 39.449 32.5176C41.258 32.5441 42.6031 32.5976 43.5875 32.6558C45.0001 32.7394 46 33.9 46 35.3151V36.6849C46 38.1 45.0001 39.2606 43.5875 39.3442C42.6091 39.4021 41.2743 39.4552 39.4819 39.4819C39.4552 41.2743 39.4021 42.6091 39.3442 43.5875C39.2606 45.0001 38.1 46 36.6849 46H35.3151C33.9 46 32.7394 45.0001 32.6558 43.5875C32.5976 42.6031 32.5441 41.258 32.5176 39.449C30.963 39.4112 29.6865 39.3572 28.6881 39.3035C27.157 39.221 26 37.9482 26 36.4148V35.5852C26 34.0518 27.157 32.779 28.6881 32.6965C29.6935 32.6424 30.9809 32.588 32.5503 32.5503C32.588 30.9809 32.6424 29.6935 32.6965 28.6881Z" clip-rule="evenodd"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 48 48" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const ArrowDownIcon = React.forwardRef(({ color, ...props }, ref) => {
    const svg = `<g>
  <path fill="currentColor" fill-rule="evenodd" d="M24.3514 24.3888C24.1596 24.568 23.8629 24.5682 23.6709 24.3892C22.2297 23.0465 16.3662 17.5781 9.00789 10.6382C7.89336 9.58702 6.35982 8.96609 5.00755 9.68616C4.41263 10.0029 3.7517 10.4661 3.09081 11.1434C2.24176 12.0135 1.7375 12.8836 1.43801 13.6102C0.971697 14.7414 1.36621 15.97 2.0956 16.9524C7.66346 24.452 15.7059 31.8772 20.3415 35.9084C22.4594 37.7501 25.5638 37.7505 27.6818 35.909C32.3183 31.8776 40.3605 24.4509 45.9105 16.9501C46.6361 15.9695 47.0282 14.7447 46.5644 13.6165C46.2652 12.8886 45.7605 12.0161 44.9091 11.1435C44.2486 10.4667 43.5882 10.0035 42.9936 9.68665C41.641 8.96573 40.1065 9.58661 38.9916 10.6385C31.6369 17.5772 25.7894 23.0452 24.3514 24.3888Z" clip-rule="evenodd"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 48 48" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const ArrowLeftIcon = React.forwardRef(({ color, ...props }, ref) => {
    const svg = `<g>
  <path fill="currentColor" fill-rule="evenodd" d="M23.6112 24.3514C23.432 24.1597 23.4318 23.8629 23.6108 23.6709C24.9535 22.2297 30.4219 16.3662 37.3618 9.00789C38.413 7.89336 39.0339 6.35982 38.3138 5.00755C37.9971 4.41263 37.5339 3.7517 36.8566 3.09081C35.9865 2.24176 35.1164 1.7375 34.3898 1.43801C33.2586 0.971697 32.03 1.36621 31.0476 2.0956C23.548 7.66346 16.1228 15.706 12.0916 20.3415C10.2499 22.4594 10.2495 25.5638 12.091 27.6818C16.1224 32.3184 23.5491 40.3605 31.0499 45.9105C32.0305 46.6361 33.2553 47.0282 34.3835 46.5644C35.1114 46.2652 35.9839 45.7605 36.8565 44.9091C37.5333 44.2486 37.9965 43.5882 38.3133 42.9936C39.0343 41.641 38.4134 40.1065 37.3615 38.9916C30.4228 31.6369 24.9548 25.7894 23.6112 24.3514Z" clip-rule="evenodd"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 48 48" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const ArrowRightIcon = React.forwardRef(({ color, ...props }, ref) => {
    const svg = `<g>
  <path fill="currentColor" fill-rule="evenodd" d="M24.3888 24.3514C24.568 24.1597 24.5682 23.8629 24.3892 23.6709C23.0465 22.2297 17.5781 16.3662 10.6382 9.00789C9.58702 7.89336 8.96609 6.35982 9.68616 5.00755C10.0029 4.41263 10.4661 3.7517 11.1434 3.09081C12.0135 2.24176 12.8836 1.7375 13.6102 1.43801C14.7414 0.971697 15.97 1.36621 16.9524 2.0956C24.452 7.66346 31.8772 15.706 35.9084 20.3415C37.7501 22.4594 37.7505 25.5638 35.909 27.6818C31.8776 32.3184 24.4509 40.3605 16.9501 45.9105C15.9695 46.6361 14.7447 47.0282 13.6165 46.5644C12.8886 46.2652 12.0161 45.7605 11.1435 44.9091C10.4667 44.2486 10.0035 43.5882 9.68665 42.9936C8.96573 41.641 9.58661 40.1065 10.6385 38.9916C17.5772 31.6369 23.0452 25.7894 24.3888 24.3514Z" clip-rule="evenodd"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 48 48" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const ArrowUpIcon = React.forwardRef(({ color, ...props }, ref) => {
    const svg = `<g>
  <path fill="currentColor" fill-rule="evenodd" d="M24.3514 23.6112C24.1596 23.432 23.8629 23.4318 23.6709 23.6108C22.2297 24.9535 16.3662 30.4219 9.00789 37.3618C7.89336 38.413 6.35982 39.0339 5.00755 38.3138C4.41263 37.9971 3.7517 37.5339 3.09081 36.8566C2.24176 35.9865 1.7375 35.1164 1.43801 34.3898C0.971697 33.2586 1.36621 32.03 2.0956 31.0476C7.66346 23.548 15.7059 16.1228 20.3415 12.0916C22.4594 10.2499 25.5638 10.2495 27.6818 12.091C32.3183 16.1224 40.3605 23.5491 45.9105 31.0499C46.6361 32.0305 47.0282 33.2553 46.5644 34.3835C46.2652 35.1114 45.7605 35.9839 44.9091 36.8565C44.2486 37.5333 43.5882 37.9965 42.9936 38.3133C41.641 39.0343 40.1065 38.4134 38.9916 37.3615C31.6369 30.4228 25.7894 24.9548 24.3514 23.6112Z" clip-rule="evenodd"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 48 48" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const CheckedIcon = React.forwardRef(({ color, ...props }, ref) => {
    const svg = `<g>
  <path fill="currentColor" fill-opacity="0.3" d="M45.4187 7.30406C45.2065 4.74913 43.2509 2.79349 40.6959 2.58134C37.3286 2.30173 31.8713 2 24 2C16.1288 1.99999 10.6714 2.30173 7.30407 2.58133C4.74914 2.79348 2.79349 4.74912 2.58135 7.30405C2.30174 10.6714 2 16.1287 2 24C2 31.8712 2.30174 37.3286 2.58134 40.6959C2.79349 43.2509 4.74913 45.2065 7.30406 45.4187C10.6714 45.6983 16.1287 46 24 46C31.8712 46 37.3286 45.6983 40.6959 45.4187C43.2509 45.2065 45.2065 43.2509 45.4187 40.6959C45.6983 37.3286 46 31.8712 46 24C46 16.1288 45.6983 10.6714 45.4187 7.30406Z"/>
  <path fill="currentColor" fill-rule="evenodd" d="M35.2311 15.9403C35.8123 16.7329 35.6824 17.8002 35.1457 18.6237C30.813 25.2717 27.5594 29.598 25.5287 32.1244C24.1986 33.7794 21.83 33.9027 20.318 32.4122C18.4534 30.5742 15.8474 27.8431 13.1494 24.4994C12.4359 23.6152 12.2884 22.3741 12.9859 21.4772C13.5319 20.7752 14.1475 20.1663 14.7145 19.6729C15.7632 18.7605 17.2997 18.9646 18.2463 19.9825C20.9745 22.9162 22.6154 24.9565 22.6154 24.9565C22.6154 24.9565 25.3443 20.9168 29.7273 14.6937C30.4451 13.6745 31.738 13.1987 32.817 13.8229C33.6119 14.2828 34.5158 14.9648 35.2311 15.9403Z" clip-rule="evenodd"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 48 48" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const CloseIcon = React.forwardRef(({ color, ...props }, ref) => {
    const svg = `<g>
  <circle cx="24" cy="24" r="22" fill="currentColor" fill-opacity="0.3"/>
  <path fill="currentColor" fill-rule="evenodd" d="M13.3564 31.509C12.7756 30.7002 12.9641 29.6726 13.6375 28.9392C14.5004 27.9995 15.9766 26.4325 18.3673 24.0005C15.9763 21.5681 14.4999 20.001 13.6369 19.0612C12.9635 18.3278 12.775 17.3001 13.3558 16.4914C13.6889 16.0276 14.1568 15.4598 14.8088 14.8078C15.4608 14.1558 16.0285 13.6879 16.4924 13.3548C17.3011 12.7741 18.3288 12.9625 19.0621 13.6359C20.0019 14.4989 21.569 15.9753 24.0014 18.3663C26.4339 15.9753 28.001 14.4989 28.9408 13.6359C29.6742 12.9625 30.7018 12.774 31.5105 13.3548C31.9744 13.6879 32.5421 14.1558 33.1941 14.8078C33.8462 15.4598 34.314 16.0276 34.6471 16.4914C35.2279 17.3001 35.0395 18.3278 34.366 19.0612C33.503 20.001 32.0266 21.5681 29.6356 24.0005C32.0263 26.4326 33.5025 27.9995 34.3654 28.9392C35.0388 29.6726 35.2273 30.7002 34.6465 31.509C34.3134 31.9728 33.8455 32.5406 33.1935 33.1926C32.5415 33.8446 31.9738 34.3124 31.5099 34.6455C30.7012 35.2263 29.6736 35.0379 28.9402 34.3644C28.0004 33.5015 26.4335 32.0253 24.0015 29.6347C21.5694 32.0253 20.0025 33.5015 19.0627 34.3644C18.3294 35.0379 17.3017 35.2263 16.493 34.6455C16.0291 34.3124 15.4614 33.8446 14.8094 33.1926C14.1574 32.5406 13.6895 31.9728 13.3564 31.509Z" clip-rule="evenodd"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 48 48" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const CopyClipboardIcon = React.forwardRef(({ color, ...props }, ref) => {
    const svg = `<g>
  <path fill="currentColor" fill-opacity="0.3" d="M39.2593 5.51718C41.5579 5.71707 43.2759 7.4149 43.4777 9.71339C43.7349 12.6435 44 17.5837 44 25.5C44 33.4163 43.7349 38.3564 43.4777 41.2866C43.2759 43.5851 41.5579 45.2829 39.2593 45.4828C36.3459 45.7362 31.5093 46 24 46C16.4907 46 11.6541 45.7362 8.74072 45.4828C6.44208 45.2829 4.72407 43.5851 4.52231 41.2866C4.2651 38.3565 4 33.4163 4 25.5C4 17.5837 4.2651 12.6435 4.52231 9.71339C4.72407 7.41491 6.44208 5.71707 8.74073 5.51718C11.6541 5.26383 16.4907 5 24 5C31.5093 5 36.3459 5.26383 39.2593 5.51718Z"/>
  <path fill="currentColor" fill-rule="evenodd" d="M29.4448 2.05703C31.1836 2.09589 32.4966 3.62596 32.3046 5.35454L31.996 8.1313C31.8272 9.6506 30.543 10.8 29.0144 10.8H18.9846C17.456 10.8 16.1718 9.65059 16.003 8.13129L15.6945 5.35455C15.5024 3.62596 16.8154 2.09589 18.5542 2.05703C20.167 2.02099 21.9753 2 23.9995 2C26.0238 2 27.832 2.02099 29.4448 2.05703Z" clip-rule="evenodd"/>
  <path fill="currentColor" fill-rule="evenodd" d="M14.8216 20.0006C13.1011 20.0132 12 21.2795 12 23C12 24.7205 13.1011 25.9868 14.8216 25.9994C14.8799 25.9998 14.9393 26 15 26C15.0607 26 15.1201 25.9998 15.1784 25.9994C16.8989 25.9868 18 24.7206 18 23C18 21.2795 16.8989 20.0132 15.1784 20.0006C15.1201 20.0002 15.0607 20 15 20C14.9393 20 14.8799 20.0002 14.8216 20.0006ZM14.8216 30.0006C13.1011 30.0132 12 31.2795 12 33C12 34.7205 13.1011 35.9868 14.8216 35.9994C14.8799 35.9998 14.9393 36 15 36C15.0607 36 15.1201 35.9998 15.1784 35.9994C16.8989 35.9868 18 34.7206 18 33C18 31.2795 16.8989 30.0132 15.1784 30.0006C15.1201 30.0002 15.0607 30 15 30C14.9393 30 14.8799 30.0002 14.8216 30.0006ZM33.9662 20.0923C35.1299 20.1449 35.966 20.9893 35.9913 22.1539C35.9967 22.4068 36 22.6882 36 23C36 23.3187 35.9966 23.6054 35.9909 23.8622C35.9653 25.0134 35.1434 25.8481 33.9932 25.9028C32.8913 25.9552 31.1689 26 28.5 26C25.8528 26 24.1367 25.9576 23.0338 25.9077C21.8701 25.8551 21.034 25.0107 21.0087 23.8461C21.0033 23.5932 21 23.3118 21 23C21 22.6882 21.0033 22.4068 21.0087 22.1539C21.034 20.9893 21.8701 20.1449 23.0338 20.0923C24.1367 20.0424 25.8528 20 28.5 20C31.1472 20 32.8633 20.0424 33.9662 20.0923ZM30.9922 32.0288C30.9735 30.9308 30.2403 30.1178 29.1435 30.0631C28.4183 30.0269 27.4062 30 26 30C24.5938 30 23.5817 30.0269 22.8565 30.0631C21.7597 30.1178 21.0265 30.9308 21.0078 32.0288C21.003 32.3131 21 32.6358 21 33C21 33.3642 21.003 33.6869 21.0078 33.9712C21.0265 35.0692 21.7597 35.8822 22.8565 35.9369C23.5817 35.9731 24.5938 36 26 36C27.4268 36 28.4478 35.9712 29.1752 35.9327C30.2554 35.8756 30.9729 35.0733 30.9918 33.9917C30.9969 33.7028 31 33.3733 31 33C31 32.6358 30.997 32.3131 30.9922 32.0288Z" clip-rule="evenodd"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 48 48" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const DeleteIcon = React.forwardRef(({ color, ...props }, ref) => {
    const svg = `<g>
  <path fill="currentColor" fill-opacity="0.3" d="M6 12H42C40.2954 29.7283 39.0556 38.158 38.4394 41.7273C38.1854 43.1982 37.277 44.4256 35.8373 44.8193C33.8179 45.3716 30.1657 46 24 46C17.8343 46 14.1821 45.3716 12.1627 44.8193C10.723 44.4256 9.81459 43.1982 9.56063 41.7273C8.94436 38.158 7.70464 29.7283 6 12Z"/>
  <path fill="currentColor" fill-rule="evenodd" d="M21.1616 2C19.8703 2 18.7239 2.82629 18.3155 4.05132L17.6359 6.09025C13.6465 6.20723 10.5935 6.41871 8.4886 6.60835C6.25725 6.80938 4.25159 8.07507 3.64394 10.2315C3.45808 10.8911 3.29105 11.6434 3.1736 12.4807C2.95925 14.009 3.9503 15.2513 5.48514 15.4119C8.11857 15.6875 13.434 16 23.9993 16C34.5656 16 39.881 15.6874 42.5142 15.4118C44.0487 15.2512 45.0397 14.0094 44.8256 12.4815C44.7082 11.6431 44.5409 10.8899 44.3548 10.2298C43.7471 8.07429 41.742 6.80947 39.5115 6.60848C37.4066 6.41881 34.3531 6.20726 30.3627 6.09025L29.6831 4.05132C29.2747 2.82629 28.1283 2 26.837 2H21.1616Z" clip-rule="evenodd"/>
  <path fill="currentColor" d="M15.2794 24.4801C15.2003 23.1477 15.9152 21.9532 17.2419 21.8067C17.3221 21.7978 17.4049 21.7896 17.4905 21.7821C17.5761 21.7746 17.6591 21.7683 17.7396 21.7631C19.0715 21.677 19.983 22.7293 20.1365 24.0551C20.2728 25.2322 20.4556 26.9887 20.6782 29.5338C20.9009 32.0789 21.0259 33.8404 21.0961 35.0233C21.1751 36.3556 20.4603 37.5502 19.1336 37.6967C19.0534 37.7055 18.9705 37.7137 18.885 37.7212C18.7994 37.7287 18.7164 37.735 18.6359 37.7402C17.3039 37.8263 16.3925 36.774 16.239 35.4482C16.1027 34.2711 15.9199 32.5147 15.6972 29.9696C15.4746 27.4245 15.3496 25.663 15.2794 24.4801Z"/>
  <path fill="currentColor" d="M32.7206 24.4801C32.7997 23.1477 32.0848 21.9532 30.7581 21.8067C30.6779 21.7978 30.5951 21.7896 30.5095 21.7821C30.4239 21.7746 30.3409 21.7683 30.2604 21.7631C28.9285 21.677 28.017 22.7293 27.8635 24.0551C27.7272 25.2322 27.5444 26.9887 27.3218 29.5338C27.0991 32.0789 26.9741 33.8404 26.9039 35.0233C26.8249 36.3556 27.5397 37.5502 28.8664 37.6967C28.9466 37.7055 29.0295 37.7137 29.115 37.7212C29.2006 37.7287 29.2836 37.735 29.3641 37.7402C30.6961 37.8263 31.6075 36.774 31.761 35.4482C31.8973 34.2711 32.0801 32.5147 32.3028 29.9696C32.5254 27.4245 32.6504 25.663 32.7206 24.4801Z"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 48 48" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const DownloadIcon = React.forwardRef(({ color, ...props }, ref) => {
    const svg = `<g>
  <path fill="currentColor" fill-opacity="0.3" d="M14.6287 30.3289C14.4642 29.3392 13.5918 28.6245 12.5892 28.6594C9.28295 28.7745 5.98536 28.9016 3.96996 28.981C2.8776 29.024 2.02682 29.9313 2.07601 31.0234C2.21485 34.1062 2.57832 40.0678 3.40411 43.3519C3.74414 44.7041 4.94634 45.4647 6.3366 45.5711C8.82403 45.7614 13.9475 45.9999 24.0012 45.9999C34.0549 45.9999 39.1784 45.7614 41.6658 45.5711C43.0561 45.4647 44.2583 44.7041 44.5983 43.3519C45.4241 40.0678 45.7876 34.1062 45.9264 31.0234C45.9756 29.9313 45.1248 29.024 44.0325 28.981C42.0171 28.9016 38.7195 28.7745 35.4133 28.6594C34.4106 28.6245 33.5382 29.3392 33.3737 30.3289L33.058 32.2277C32.8976 33.1924 32.0631 33.8997 31.0851 33.8997H16.9173C15.9394 33.8997 15.1048 33.1924 14.9444 32.2277L14.6287 30.3289Z"/>
  <path fill="currentColor" fill-rule="evenodd" d="M28.6996 14.6357C30.4746 14.6805 31.8727 14.7486 32.9654 14.8233C34.8397 14.9515 35.6589 16.7367 34.5285 18.2373C33.6681 19.3797 32.4755 20.8694 30.8471 22.7407C28.547 25.3839 26.8267 26.8854 25.6858 27.7247C24.6547 28.4832 23.3478 28.4833 22.3167 27.7249C21.1758 26.8856 19.4553 25.3841 17.1555 22.7407C15.5278 20.8698 14.3354 19.3803 13.475 18.238C12.3444 16.7372 13.1637 14.9514 15.0383 14.8232C16.1311 14.7485 17.5293 14.6804 19.3043 14.6357C19.2986 13.9063 19.2955 13.104 19.2955 12.2223C19.2955 8.62622 19.3486 6.35061 19.4032 4.96227C19.4528 3.69805 20.0549 2.53938 21.2891 2.26092C21.9512 2.11153 22.836 2 24.002 2C25.1679 2 26.0527 2.11154 26.7148 2.26092C27.949 2.53938 28.5511 3.69805 28.6007 4.96227C28.6553 6.35061 28.7084 8.62622 28.7084 12.2223C28.7084 13.104 28.7053 13.9064 28.6996 14.6357Z" clip-rule="evenodd"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 48 48" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const FullScreenIcon = React.forwardRef(({ color, ...props }, ref) => {
    const svg = `<g>
  <path fill="currentColor" fill-opacity="0.3" fill-rule="evenodd" d="M45.6487 7.12769C45.515 4.62312 43.6952 2.69241 41.1945 2.49861C38.0038 2.25133 32.6364 2 23.9998 2C15.3635 2 9.99623 2.25131 6.80541 2.49858C4.30458 2.69239 2.48462 4.62333 2.35103 7.1281C2.1787 10.3592 2 15.739 2 24.156V24.156C2 24.7496 2.49523 25.2225 3.08849 25.2017C4.07799 25.1671 5.21212 25.1388 6.50839 25.1209C7.05764 25.1134 7.5 24.6663 7.5 24.117V24.117C7.5 15.9506 7.72444 11.5962 7.87263 9.61128C7.93487 8.77756 8.5442 8.12702 9.37508 8.0342C11.3115 7.81789 15.5883 7.5 24.0002 7.5C32.4118 7.5 36.6886 7.81787 38.6251 8.03419C39.4561 8.12701 40.0654 8.77763 40.1276 9.61142C40.2757 11.5964 40.5 15.9508 40.5 24.117C40.5 32.1376 40.2836 36.4811 40.1356 38.5142C40.0712 39.3991 39.3986 40.08 38.5138 40.1453C36.5268 40.292 32.2542 40.5 24.0002 40.5V40.5C23.3882 40.5 22.8905 40.9938 22.8842 41.6057C22.8719 42.8006 22.8532 43.8603 22.8304 44.7969C22.8144 45.4551 23.3414 46 23.9998 46V46C32.3439 46 37.6364 45.8406 40.8628 45.6782C43.5211 45.5445 45.5294 43.5208 45.6655 40.8626C45.8326 37.6005 46 32.2999 46 24.156C46 15.7387 45.8212 10.3588 45.6487 7.12769Z" clip-rule="evenodd"/>
  <path fill="currentColor" fill-rule="evenodd" d="M5.03227 28.5279C3.36261 28.618 2.15373 29.8918 2.0904 31.5627C2.04075 32.8725 2 34.7216 2 37.2628C2 39.6695 2.03655 41.4555 2.08259 42.7504C2.14565 44.5238 3.47696 45.8608 5.25036 45.9215C6.54207 45.9657 8.33933 46.0004 10.8001 46.0004C13.2607 46.0004 15.0578 45.9657 16.3495 45.9215C18.1231 45.8608 19.4545 44.5236 19.5175 42.7502C19.5635 41.4552 19.6 39.6694 19.6 37.2628C19.6 34.7217 19.5593 32.8727 19.5097 31.5629C19.4464 29.8919 18.2374 28.618 16.5676 28.5279C15.2626 28.4574 13.4023 28.4004 10.8001 28.4004C8.19767 28.4004 6.33735 28.4574 5.03227 28.5279ZM7.91613 32.8641C7.0813 32.9092 6.47686 33.5461 6.4452 34.3815C6.42038 35.0364 6.4 35.961 6.4 37.2316C6.4 38.435 6.41828 39.3279 6.4413 39.9754C6.47282 40.8621 7.13848 41.5306 8.02518 41.5609C8.67104 41.5831 9.56966 41.6004 10.8 41.6004C12.0303 41.6004 12.9289 41.5831 13.5748 41.5609C14.4615 41.5306 15.1272 40.862 15.1587 39.9753C15.1817 39.3278 15.2 38.4349 15.2 37.2316C15.2 35.9611 15.1796 35.0366 15.1548 34.3817C15.1232 33.5462 14.5187 32.9092 13.6838 32.8641C13.0313 32.8289 12.1012 32.8004 10.8 32.8004C9.49883 32.8004 8.56867 32.8289 7.91613 32.8641Z" clip-rule="evenodd"/>
  <path fill="currentColor" fill-rule="evenodd" d="M24.4084 11.3691C23.0959 11.5878 22.5636 12.9641 23.4331 13.9713C23.9625 14.5846 24.6549 15.3556 25.5563 16.3129C23.8317 18.2842 21.4473 21.3493 20.0783 23.1351C19.4072 24.0106 19.283 25.1933 19.9614 26.0633C20.2151 26.3886 20.5225 26.7454 20.8879 27.1108C21.2534 27.4763 21.6101 27.7837 21.9355 28.0374C22.8054 28.7157 23.9881 28.5916 24.8636 27.9205C26.6495 26.5515 29.7146 24.1671 31.6858 22.4425C32.6433 23.3441 33.4144 24.0366 34.0277 24.5661C35.0349 25.4356 36.4113 24.9034 36.63 23.5909C37.5405 18.1263 37.1565 14.2454 36.8598 12.4633C36.7451 11.7743 36.2246 11.2539 35.5357 11.1393C33.7535 10.8427 29.8726 10.4588 24.4084 11.3691Z" clip-rule="evenodd"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 48 48" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const LockIcon = React.forwardRef(({ color, ...props }, ref) => {
    const svg = `<g>
  <path fill="currentColor" fill-rule="evenodd" d="M10.6056 13.2544C10.9739 6.93462 16.159 2 22.4312 2H25.5688C31.841 2 37.0261 6.93462 37.3944 13.2544L37.9943 23.5476C38.0991 25.3454 36.7401 26.8885 34.9589 26.9943C33.1777 27.1 31.6488 25.7283 31.544 23.9305L30.9441 13.6373C30.7767 10.7647 28.4198 8.52171 25.5688 8.52171H22.4312C19.5802 8.52171 17.2233 10.7647 17.0559 13.6373L16.456 23.9305C16.3512 25.7283 14.8223 27.1 13.0411 26.9943C11.2599 26.8885 9.9009 25.3454 10.0057 23.5476L10.6056 13.2544Z" clip-rule="evenodd"/>
  <path fill="currentColor" fill-opacity="0.3" d="M4.35131 41.2718C4.5906 43.7611 6.59764 45.5405 9.09498 45.671C12.1748 45.832 17.0533 46 24 46C30.9467 46 35.8252 45.832 38.905 45.671C41.4024 45.5405 43.4094 43.7611 43.6487 41.2718C43.8386 39.2962 44 36.5678 44 33C44 29.4322 43.8386 26.7038 43.6487 24.7282C43.4094 22.2389 41.4024 20.4595 38.905 20.329C35.8252 20.168 30.9467 20 24 20C17.0533 20 12.1748 20.168 9.09499 20.329C6.59764 20.4595 4.5906 22.2389 4.35131 24.7282C4.1614 26.7038 4 29.4322 4 33C4 36.5678 4.1614 39.2962 4.35131 41.2718Z"/>
  <path fill="currentColor" fill-rule="evenodd" d="M25.8133 33.5664C27.1111 32.9052 28 31.5564 28 30C28 27.7909 26.2091 26 24 26C21.7909 26 20 27.7909 20 30C20 31.5564 20.8889 32.9052 22.1867 33.5664L21.2996 38.0021C21.1227 38.8865 21.5792 39.7585 22.4711 39.8922C22.8909 39.9551 23.4005 40 24 40C24.5995 40 25.1091 39.9551 25.5289 39.8922C26.4208 39.7585 26.8773 38.8865 26.7004 38.0021L25.8133 33.5664Z" clip-rule="evenodd"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 48 48" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const LockedIcon = React.forwardRef(({ color, ...props }, ref) => {
    const svg = `<g>
  <path fill="currentColor" fill-rule="evenodd" d="M10.6056 13.2544C10.9739 6.93462 16.159 2 22.4312 2H25.5688C31.841 2 37.0261 6.93462 37.3944 13.2544L37.9943 23.5476C38.0991 25.3454 36.7401 26.8885 34.9589 26.9943C33.1777 27.1 31.6488 25.7283 31.544 23.9305L30.9441 13.6373C30.7767 10.7647 28.4198 8.52171 25.5688 8.52171H22.4312C19.5802 8.52171 17.2233 10.7647 17.0559 13.6373L16.456 23.9305C16.3512 25.7283 14.8223 27.1 13.0411 26.9943C11.2599 26.8885 9.9009 25.3454 10.0057 23.5476L10.6056 13.2544Z" clip-rule="evenodd"/>
  <path fill="currentColor" fill-opacity="0.3" d="M4.35131 41.2718C4.5906 43.7611 6.59764 45.5405 9.09498 45.671C12.1748 45.832 17.0533 46 24 46C30.9467 46 35.8252 45.832 38.905 45.671C41.4024 45.5405 43.4094 43.7611 43.6487 41.2718C43.8386 39.2962 44 36.5678 44 33C44 29.4322 43.8386 26.7038 43.6487 24.7282C43.4094 22.2389 41.4024 20.4595 38.905 20.329C35.8252 20.168 30.9467 20 24 20C17.0533 20 12.1748 20.168 9.09499 20.329C6.59764 20.4595 4.5906 22.2389 4.35131 24.7282C4.1614 26.7038 4 29.4322 4 33C4 36.5678 4.1614 39.2962 4.35131 41.2718Z"/>
  <path fill="currentColor" fill-rule="evenodd" d="M23.3689 35.9088C22.2646 38.3229 19.8282 40.0001 17 40.0001C13.134 40.0001 10 36.866 10 33.0001C10 29.1341 13.134 26.0001 17 26.0001C19.8281 26.0001 22.2645 27.6772 23.3688 30.0912C24.6689 30.0402 26.4899 30 29 30C32.9117 30 35.1498 30.0976 36.3508 30.1815C37.1361 30.2363 37.7938 30.6951 37.9051 31.4744C37.9603 31.861 38 32.3611 38 33C38 33.6389 37.9603 34.139 37.9051 34.5256C37.7938 35.3049 37.1361 35.7637 36.3508 35.8185C35.9963 35.8433 35.5514 35.8692 34.9999 35.8935L35 36C35 36.7857 34.9801 37.4195 34.9505 37.9275C34.8804 39.1297 34.013 39.9563 32.8092 39.9895C32.5682 39.9961 32.2993 40 32 40C31.7007 40 31.4318 39.9961 31.1908 39.9895C29.987 39.9563 29.1196 39.1297 29.0495 37.9275C29.0199 37.4195 29 36.7857 29 36C26.4899 36 24.6689 35.9598 23.3689 35.9088ZM19.5471 32.9998C19.5471 34.4056 18.4075 35.5453 17.0016 35.5453C15.5958 35.5453 14.4562 34.4056 14.4562 32.9998C14.4562 31.594 15.5958 30.4544 17.0016 30.4544C18.4075 30.4544 19.5471 31.594 19.5471 32.9998Z" clip-rule="evenodd"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 48 48" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const MoreDotsIcon = React.forwardRef(({ color, ...props }, ref) => {
    const svg = `<g>
  <path fill="currentColor" fill-opacity="0.84" d="M2 24C2 20.5589 4.20217 18.0263 7.64318 18.0013C7.75976 18.0004 7.87869 18 8 18C8.12131 18 8.24024 18.0004 8.35682 18.0013C11.7978 18.0263 14 20.5589 14 24V24V24C14 27.4411 11.7978 29.9737 8.35682 29.9987C8.24024 29.9996 8.12131 30 8 30C7.87869 30 7.75976 29.9996 7.64318 29.9987C4.20217 29.9737 2 27.4411 2 24V24V24Z"/>
  <path fill="currentColor" fill-opacity="0.84" d="M18 24C18 20.5589 20.2022 18.0263 23.6432 18.0013C23.7598 18.0004 23.8787 18 24 18C24.1213 18 24.2402 18.0004 24.3568 18.0013C27.7978 18.0263 30 20.5589 30 24V24V24C30 27.4411 27.7978 29.9737 24.3568 29.9987C24.2402 29.9996 24.1213 30 24 30C23.8787 30 23.7598 29.9996 23.6432 29.9987C20.2022 29.9737 18 27.4411 18 24V24V24Z"/>
  <path fill="currentColor" fill-opacity="0.84" d="M34 24C34 20.5589 36.2022 18.0263 39.6432 18.0013C39.7598 18.0004 39.8787 18 40 18C40.1213 18 40.2402 18.0004 40.3568 18.0013C43.7978 18.0263 46 20.5589 46 24V24V24C46 27.4411 43.7978 29.9737 40.3568 29.9987C40.2402 29.9996 40.1213 30 40 30C39.8787 30 39.7598 29.9996 39.6432 29.9987C36.2022 29.9737 34 27.4411 34 24V24V24Z"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 48 48" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const RemoveIcon = React.forwardRef(({ color, ...props }, ref) => {
    const svg = `<g>
  <path fill="currentColor" fill-opacity="0.3" d="M2.58134 40.6959C2.79349 43.2509 4.74913 45.2065 7.30406 45.4187C10.6714 45.6983 16.1287 46 24 46C31.8712 46 37.3286 45.6983 40.6959 45.4187C43.2509 45.2065 45.2065 43.2509 45.4187 40.6959C45.6983 37.3286 46 31.8713 46 24C46 16.1288 45.6983 10.6714 45.4187 7.30406C45.2065 4.74913 43.2509 2.79349 40.6959 2.58134C37.3286 2.30174 31.8712 2 24 2C16.1288 2 10.6714 2.30174 7.30406 2.58134C4.74914 2.79348 2.79349 4.74913 2.58134 7.30406C2.30174 10.6714 2 16.1288 2 24C2 31.8712 2.30174 37.3286 2.58134 40.6959Z"/>
  <path fill="currentColor" d="M14.1803 20.1562C13.1588 20.2011 12.2899 20.8271 12.1421 21.8388C12.061 22.3945 12 23.1086 12 24C12 24.8914 12.061 25.6055 12.1421 26.1612C12.2899 27.1729 13.1588 27.7989 14.1803 27.8438C15.8058 27.9154 18.8226 28 24 28C29.1774 28 32.1942 27.9154 33.8197 27.8438C34.8412 27.7989 35.7101 27.1729 35.8579 26.1612C35.939 25.6055 36 24.8914 36 24C36 23.1086 35.939 22.3945 35.8579 21.8388C35.7101 20.8271 34.8412 20.2011 33.8197 20.1562C32.1942 20.0846 29.1774 20 24 20C18.8226 20 15.8058 20.0846 14.1803 20.1562Z"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 48 48" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const SettingsIcon = React.forwardRef(({ color, ...props }, ref) => {
    const svg = `<g>
  <path fill="currentColor" fill-opacity="0.3" d="M45.4187 7.30406C45.2065 4.74913 43.2509 2.79349 40.6959 2.58134C37.3286 2.30173 31.8713 2 24 2C16.1288 1.99999 10.6714 2.30173 7.30407 2.58133C4.74914 2.79348 2.79349 4.74912 2.58135 7.30405C2.30174 10.6714 2 16.1287 2 24C2 31.8712 2.30174 37.3286 2.58134 40.6959C2.79349 43.2509 4.74913 45.2065 7.30406 45.4187C10.6714 45.6983 16.1287 46 24 46C31.8712 46 37.3286 45.6983 40.6959 45.4187C43.2509 45.2065 45.2065 43.2509 45.4187 40.6959C45.6983 37.3286 46 31.8712 46 24C46 16.1288 45.6983 10.6714 45.4187 7.30406Z"/>
  <path fill="currentColor" fill-rule="evenodd" d="M8.01518 15.4353C8.07782 14.3242 9.03024 13.7093 10.1424 13.6702C10.7417 13.6491 11.5163 13.626 12.4976 13.6038C13.4226 11.4827 15.5381 10 18 10C20.4216 10 22.5081 11.4345 23.456 13.5002L24 13.5C31.5936 13.5 35.78 13.597 37.8576 13.6702C38.9698 13.7093 39.9222 14.3242 39.9848 15.4353C39.9945 15.6072 40 15.795 40 16C40 16.205 39.9945 16.3928 39.9848 16.5647C39.9222 17.6758 38.9698 18.2907 37.8576 18.3298C35.78 18.403 31.5936 18.5 24 18.5L23.456 18.4998C22.5081 20.5655 20.4216 22 18 22C15.5381 22 13.4226 20.5173 12.4976 18.3962C11.5163 18.374 10.7417 18.3509 10.1424 18.3298C9.03025 18.2907 8.07782 17.6758 8.01518 16.5647C8.00549 16.3928 8 16.205 8 16C8 15.795 8.00549 15.6072 8.01518 15.4353ZM18 18C19.1046 18 20 17.1046 20 16C20 14.8954 19.1046 14 18 14C16.8954 14 16 14.8954 16 16C16 17.1046 16.8954 18 18 18Z" clip-rule="evenodd"/>
  <path fill="currentColor" fill-rule="evenodd" d="M8.01518 31.4353C8.07782 30.3242 9.03024 29.7093 10.1424 29.6702C12.22 29.597 16.4064 29.5 24 29.5L24.544 29.5002C25.4919 27.4345 27.5784 26 30 26C32.4619 26 34.5774 27.4827 35.5024 29.6038C36.4837 29.626 37.2583 29.6491 37.8576 29.6702C38.9698 29.7093 39.9222 30.3242 39.9848 31.4353C39.9945 31.6072 40 31.795 40 32C40 32.205 39.9945 32.3928 39.9848 32.5647C39.9222 33.6758 38.9698 34.2907 37.8576 34.3298C37.2583 34.3509 36.4837 34.374 35.5024 34.3962C34.5774 36.5173 32.4619 38 30 38C27.5784 38 25.4919 36.5655 24.544 34.4998L24 34.5C16.4064 34.5 12.22 34.403 10.1424 34.3298C9.03025 34.2907 8.07782 33.6758 8.01518 32.5647C8.00549 32.3928 8 32.205 8 32C8 31.795 8.00549 31.6072 8.01518 31.4353ZM30 34C31.1046 34 32 33.1046 32 32C32 30.8954 31.1046 30 30 30C28.8954 30 28 30.8954 28 32C28 33.1046 28.8954 34 30 34Z" clip-rule="evenodd"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 48 48" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const SortDownIcon = React.forwardRef(({ color, ...props }, ref) => {
    const svg = `<g>
  <path fill="currentColor" fill-opacity="0.3" d="M46 24C46 36.1503 36.1503 46 24 46C11.8497 46 2 36.1503 2 24C2 11.8497 11.8497 2 24 2C36.1503 2 46 11.8497 46 24Z"/>
  <path fill="currentColor" fill-rule="evenodd" d="M19.7787 12.9513C19.9002 11.4453 20.9144 10.1969 22.42 10.0701C22.9231 10.0277 23.5066 10 24.1724 10C24.8379 10 25.4021 10.0277 25.876 10.0701C27.2273 10.1909 28.0863 11.3108 28.1977 12.663C28.3686 14.7365 28.6301 18.3769 28.9013 23.9075C28.9039 23.9605 28.9044 24.013 28.9029 24.0651C30.3691 24.108 31.5511 24.1683 32.4981 24.2342C34.3446 24.3626 35.1586 26.1146 34.0692 27.611C33.2462 28.7416 32.1048 30.2169 30.5456 32.0706C28.4175 34.6005 26.8084 36.073 25.7182 36.9178C24.6733 37.7275 23.3268 37.7276 22.2819 36.918C21.1916 36.0732 19.5825 34.6007 17.4547 32.0706C15.896 30.2172 14.7549 28.7422 13.9319 27.6117C12.8423 26.115 13.6563 24.3625 15.5031 24.2341C16.4499 24.1683 17.6317 24.108 19.0973 24.0651C19.0958 24.013 19.0963 23.9605 19.0989 23.9075C19.358 18.6248 19.608 15.0667 19.7787 12.9513Z" clip-rule="evenodd"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 48 48" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const SortUpIcon = React.forwardRef(({ color, ...props }, ref) => {
    const svg = `<g>
  <path fill="currentColor" fill-opacity="0.3" d="M46 24C46 11.8497 36.1503 2 24 2C11.8497 2 2 11.8497 2 24C2 36.1503 11.8497 46 24 46C36.1503 46 46 36.1503 46 24Z"/>
  <path fill="currentColor" fill-rule="evenodd" d="M34.0692 20.3892C35.1586 21.8856 34.3446 23.6376 32.4981 23.766C31.5511 23.8319 30.3691 23.8922 28.9029 23.9351C28.9044 23.9872 28.9039 24.0397 28.9013 24.0927C28.6301 29.6233 28.3686 33.2637 28.1977 35.3372C28.0863 36.6894 27.2273 37.8093 25.876 37.9302C25.4021 37.9725 24.8379 38.0002 24.1724 38.0002C23.5066 38.0002 22.9231 37.9725 22.42 37.9301C20.9144 37.8033 19.9002 36.5549 19.7787 35.0489C19.608 32.9335 19.358 29.3754 19.0989 24.0927C19.0963 24.0397 19.0958 23.9872 19.0973 23.9351C17.6317 23.8922 16.4499 23.8319 15.5031 23.7661C13.6563 23.6377 12.8423 21.8852 13.9319 20.3885C14.7549 19.258 15.896 17.783 17.4547 15.9296C19.5825 13.3995 21.1916 11.927 22.2819 11.0822C23.3268 10.2726 24.6733 10.2727 25.7182 11.0824C26.8084 11.9272 28.4175 13.3997 30.5456 15.9296C32.1048 17.7833 33.2462 19.2586 34.0692 20.3892Z" clip-rule="evenodd"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 48 48" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const TreeArrowDownIcon = React.forwardRef(({ color, ...props }, ref) => {
    const svg = `<g>
  <path fill="currentColor" fill-opacity="0.3" d="M46 24C46 36.1503 36.1503 46 24 46C11.8497 46 2 36.1503 2 24C2 11.8497 11.8497 2 24 2C36.1503 2 46 11.8497 46 24Z"/>
  <path fill="currentColor" fill-rule="evenodd" d="M24.3537 24.1464C24.1585 24.3417 23.8419 24.3417 23.6466 24.1465L17.148 17.6479C16.1784 16.6784 14.6616 16.4792 13.6096 17.3585C12.7711 18.0593 11.9363 18.8946 11.3345 19.7503C10.626 20.7577 10.9582 22.0521 11.7815 22.9683C14.7232 26.2418 19.1098 30.5705 21.9927 32.7468C23.2105 33.6661 24.7898 33.6661 26.0076 32.7467C28.8903 30.5704 33.2768 26.2418 36.2185 22.9683C37.0418 22.0521 37.374 20.7577 36.6655 19.7503C36.0638 18.8947 35.2289 18.0593 34.3905 17.3586C33.3384 16.4792 31.8216 16.6784 30.852 17.648L24.3537 24.1464Z" clip-rule="evenodd"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 48 48" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const TreeArrowLeftIcon = React.forwardRef(({ color, ...props }, ref) => {
    const svg = `<g>
  <path fill="currentColor" fill-opacity="0.3" d="M24 2C11.8497 2 2 11.8497 2 24C1.99999 36.1503 11.8497 46 24 46C36.1503 46 46 36.1503 46 24C46 11.8497 36.1503 2 24 2Z"/>
  <path fill="currentColor" fill-rule="evenodd" d="M23.8536 23.6463C23.6583 23.8415 23.6583 24.1581 23.8535 24.3534L30.3521 30.852C31.3216 31.8216 31.5208 33.3384 30.6415 34.3904C29.9407 35.2289 29.1054 36.0637 28.2497 36.6655C27.2423 37.374 25.9479 37.0418 25.0317 36.2185C21.7582 33.2768 17.4295 28.8902 15.2532 26.0073C14.3339 24.7895 14.3339 23.2102 15.2533 21.9924C17.4296 19.1097 21.7582 14.7232 25.0317 11.7815C25.9479 10.9582 27.2423 10.626 28.2497 11.3345C29.1053 11.9362 29.9407 12.7711 30.6414 13.6095C31.5208 14.6616 31.3216 16.1784 30.352 17.148L23.8536 23.6463Z" clip-rule="evenodd"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 48 48" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const TreeArrowRightIcon = React.forwardRef(({ color, ...props }, ref) => {
    const svg = `<g>
  <path fill="currentColor" fill-opacity="0.3" d="M24 2C36.1503 2 46 11.8497 46 24C46 36.1503 36.1503 46 24 46C11.8497 46 2 36.1503 2 24C2 11.8497 11.8497 2 24 2Z"/>
  <path fill="currentColor" fill-rule="evenodd" d="M24.1464 23.6463C24.3417 23.8415 24.3417 24.1581 24.1465 24.3534L17.6479 30.852C16.6784 31.8216 16.4792 33.3384 17.3585 34.3904C18.0593 35.2289 18.8946 36.0637 19.7503 36.6655C20.7577 37.374 22.0521 37.0418 22.9683 36.2185C26.2418 33.2768 30.5705 28.8902 32.7468 26.0073C33.6661 24.7895 33.6661 23.2102 32.7467 21.9924C30.5704 19.1097 26.2418 14.7232 22.9683 11.7815C22.0521 10.9582 20.7577 10.626 19.7503 11.3345C18.8947 11.9362 18.0593 12.7711 17.3586 13.6095C16.4792 14.6616 16.6784 16.1784 17.648 17.148L24.1464 23.6463Z" clip-rule="evenodd"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 48 48" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const UnlockedIcon = React.forwardRef(({ color, ...props }, ref) => {
    const svg = `<g>
  <path fill="currentColor" fill-rule="evenodd" d="M10.5968 12.8042C10.9597 6.73724 16.0694 2 22.2504 2H25.3713C31.5361 2 36.6324 6.72482 36.9944 12.7759C37.0977 14.5018 35.7584 15.9832 34.0031 16.0847C32.2478 16.1862 30.7411 14.8694 30.6379 13.1435C30.4738 10.4017 28.1646 8.26084 25.3713 8.26084H22.2504C19.4409 8.26084 17.1183 10.4141 16.9533 13.1718L16.3621 23.0533C16.2589 24.7792 14.7522 26.096 12.9969 25.9945C11.2416 25.893 9.90234 24.4116 10.0056 22.6857L10.5968 12.8042Z" clip-rule="evenodd"/>
  <path fill="currentColor" fill-opacity="0.3" d="M4.35131 41.2718C4.5906 43.7611 6.59764 45.5405 9.09498 45.671C12.1748 45.832 17.0533 46 24 46C30.9467 46 35.8252 45.832 38.905 45.671C41.4024 45.5405 43.4094 43.7611 43.6487 41.2718C43.8386 39.2962 44 36.5678 44 33C44 29.4322 43.8386 26.7038 43.6487 24.7282C43.4094 22.2389 41.4024 20.4595 38.905 20.329C35.8252 20.168 30.9467 20 24 20C17.0533 20 12.1748 20.168 9.09499 20.329C6.59764 20.4595 4.5906 22.2389 4.35131 24.7282C4.1614 26.7038 4 29.4322 4 33C4 36.5678 4.1614 39.2962 4.35131 41.2718Z"/>
  <path fill="currentColor" d="M26.951 35.4571C28.0721 35.4187 28.9561 34.654 28.9918 33.5328C28.997 33.3678 29 33.1904 29 33C29 32.8096 28.997 32.6322 28.9918 32.4672C28.9561 31.346 28.0721 30.5813 26.951 30.5429C26.2384 30.5185 25.2769 30.5 24 30.5C22.7231 30.5 21.7616 30.5185 21.049 30.5429C19.9279 30.5813 19.0439 31.346 19.0082 32.4672C19.003 32.6322 19 32.8096 19 33C19 33.1904 19.003 33.3678 19.0082 33.5328C19.0439 34.654 19.9279 35.4187 21.049 35.4571C21.7616 35.4815 22.7231 35.5 24 35.5C25.2769 35.5 26.2384 35.4815 26.951 35.4571Z"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 48 48" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const UploadIcon = React.forwardRef(({ color, ...props }, ref) => {
    const svg = `<g>
  <path fill="currentColor" fill-opacity="0.3" d="M14.6275 30.348C14.4627 29.3585 13.5905 28.644 12.588 28.6788C9.28161 28.7938 5.98375 28.9208 3.96817 29.0001C2.87575 29.0431 2.0249 29.9504 2.07417 31.0425C2.21309 34.1222 2.57653 40.0736 3.40197 43.3531C3.74213 44.7046 4.944 45.4644 6.33356 45.5706C8.82063 45.7606 13.9443 45.999 24 45.999C34.0557 45.999 39.1794 45.7606 41.6664 45.5706C43.056 45.4644 44.2579 44.7046 44.598 43.3531C45.4235 40.0736 45.7869 34.1222 45.9258 31.0425C45.9751 29.9504 45.1243 29.0431 44.0318 29.0001C42.0163 28.9208 38.7184 28.7938 35.412 28.6788C34.4095 28.644 33.5373 29.3585 33.3725 30.348L33.0574 32.2414C32.8968 33.2059 32.0623 33.913 31.0845 33.913H16.9155C15.9377 33.913 15.1032 33.2059 14.9426 32.2414L14.6275 30.348Z"/>
  <path fill="currentColor" fill-rule="evenodd" d="M28.6993 15.6434C30.4728 15.5988 31.8701 15.5308 32.9624 15.4563C34.8377 15.3283 35.6571 13.542 34.5251 12.0414C33.6649 10.901 32.4733 9.41446 30.8471 7.54797C28.546 4.90672 26.825 3.4068 25.6841 2.56873C24.6537 1.81181 23.3484 1.81171 22.3179 2.56857C21.177 3.4066 19.4559 4.90654 17.155 7.54797C15.5295 9.41407 14.3382 10.9004 13.4779 12.0407C12.3457 13.5415 13.1652 15.3283 15.0409 15.4563C16.1332 15.5309 17.5304 15.5988 19.3036 15.6434C19.298 16.3718 19.2948 17.173 19.2948 18.0534C19.2948 21.642 19.3479 23.9141 19.4024 25.3011C19.4521 26.5663 20.0551 27.7254 21.2903 28.0035C21.9523 28.1525 22.8365 28.2637 24.0015 28.2637C25.1664 28.2637 26.0507 28.1525 26.7126 28.0035C27.9478 27.7254 28.5508 26.5663 28.6005 25.3011C28.655 23.9141 28.7081 21.642 28.7081 18.0534C28.7081 17.173 28.7049 16.3718 28.6993 15.6434Z" clip-rule="evenodd"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 48 48" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})
