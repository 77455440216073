import React, { useRef, useImperativeHandle, useCallback } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import initials from 'initials'
import randomColor from 'randomcolor'

const variantBorderRadii = {
    circle: '100%',
    square: '25%',
    none: '0',
}

const useStyles = makeStyles(theme => ({
    avatar: ({ variant }) => ({
        position: 'relative',
        borderRadius: variantBorderRadii[variant],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        lineHeight: 1,
        overflow: 'hidden',
        userSelect: 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    }),
    overlay: ({ variant, isMobile }) => {
        const style = {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0,0,0,0.54)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: variantBorderRadii[variant],
            cursor: 'pointer',
            opacity: 0,
        }

        if (!isMobile) {
            style['&:hover'] = {
                opacity: 0.75,
            }
            style['&:active'] = {
                opacity: isMobile ? 0 : 1.0,
            }
        }

        return style
    },
    content: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

const SelectOverlay = ({ className, onClick }) => {
    return (
        <div onClick={onClick} className={className}>
            <svg width="24" height="24">
                <path
                    d="M21,0H3A1,1,0,0,0,2,1V23a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V1A1,1,0,0,0,21,0ZM16,12.414l-3-3V17H11V9.414l-3,3L6.586,11l4.707-4.707a1,1,0,0,1,1.414,0L17.414,11Z"
                    fill="white"
                />
            </svg>
        </div>
    )
}

export default React.forwardRef(
    (
        { name, src, title, size, variant = 'circle', onSelect, className, style, children, ...otherProps },
        ref
    ) => {
        const isMobile = process.env.RUNTIME_TARGET === 'mobile'
        const classes = useStyles({ variant, src, isMobile })

        const fileInput = useRef(null)

        const handleSelectAvatar = useCallback(() => {
            if (fileInput.current) {
                fileInput.current.click()
            }
        }, [])

        const handleFileChange = useCallback(
            evt => {
                const files = evt.target.files
                if (files && files.length) {
                    onSelect(evt.target.files[0])
                }
                evt.target.value = null
            },
            [onSelect]
        )

        useImperativeHandle(ref, () => ({
            selectAvatar: handleSelectAvatar,
        }))

        const avatarStyle = { ...style }
        const avatarSize = size || 40

        avatarStyle.width = avatarSize
        avatarStyle.height = avatarSize
        avatarStyle.fontSize = Math.floor(avatarSize / 2.3)

        if (!src) {
            if (name) {
                avatarStyle.backgroundColor = randomColor({
                    luminosity: 'dark',
                    format: 'rgba',
                    alpha: 0.74,
                    seed: name,
                })
                avatarStyle.color = 'white'
            }
        } else {
            avatarStyle.backgroundImage = `url("${src}")`
        }

        const avatarElement = (
            <div className={classNames(className, classes.avatar)} style={avatarStyle} {...otherProps}>
                {!src && !children && initials((name || '').toUpperCase()).substr(0, 2)}
                {children && <div className={classes.content}>{children}</div>}
                {!!onSelect && (
                    <>
                        <input
                            onChange={handleFileChange}
                            ref={fileInput}
                            type="file"
                            accept="image/*"
                            style={{ display: 'none', opacity: '0' }}
                        />
                        <SelectOverlay className={classes.overlay} onClick={handleSelectAvatar} />
                    </>
                )}
            </div>
        )

        if (title) {
            return <Tooltip title={title}>{avatarElement}</Tooltip>
        }

        return avatarElement
    }
)
