import Runtime from '@newspaces/lucky-base/Runtime'

export default (context, route) => {
    route = route || ''

    const { currentSubdomainType } = Runtime

    if (route === '$root') {
        if (currentSubdomainType === 'app') {
            return '/'
        }

        if (context.globalRootApp) {
            return context.getRootUrl(context.globalRootApp.key, null)
        }

        if (!context.canLogout) {
            return '/auth'
        }

        const defaultOrganisationId = context.getDefaultOrganisationId()

        if (!defaultOrganisationId) {
            return '/organisations'
        }

        if (context.regularRootApp) {
            return context.getRootUrl(context.regularRootApp.key, defaultOrganisationId)
        }

        const defaultAppKey = context.getDefaultApplication()

        if (!defaultAppKey) {
            return `/${defaultOrganisationId}/apps`
        }

        return context.getRootUrl(defaultAppKey.key, defaultOrganisationId)
    }

    let rootUrl = context.rootUrl

    if (route.startsWith('//')) {
        // Extract app key which comes next
        const appKey = route.split('/')[2]
        rootUrl = context.getRootUrl(appKey, context.organisation?.slugOrId)
        route = route.substring(2 + (appKey?.length || 0))
    }

    if (rootUrl === '/') {
        rootUrl = ''
    }

    if (route.startsWith('/')) {
        route = route.substring(1)
    }

    return `${rootUrl}/${route}`
}
