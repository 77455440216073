import Runtime from '@newspaces/lucky-base/Runtime'

export default async (userData) => {
    if (!userData || !Runtime.hasSession) {
        return
    }

    const zone = Runtime.zone.key

    // ignoreUpdateFields because we need to use $push insted $set
    // not preventReload to force a reload of userData and keep it updated
    // this run for each user on each zone once
    if (!userData.zones.includes(zone)) {
        await Runtime.apiMethod('/user/addZone', { zone })
    }
}
