import Model from '../../Model'

import DateTime from '../../DateTime'

export default class OrganisationCustomDomain extends Model {
    static Fields = {
        hostname: {
            type: String,
            serialize: false,
        },
        status: {
            type: String,
            enum: ['pending', 'active', 'failed'],
            serialize: false,
        },
        error: {
            type: String,
            serialize: false,
        },
        setupAt: {
            type: DateTime,
            serialize: false,
        }
    }

    constructor(data) {
        super(OrganisationCustomDomain.Fields, data)
    }

    static StatusColorMap = {
        pending: 'info',
        active: 'success',
        failed: 'error',
    }
}
