import loadVideo from './loadVideo'

export default async (
    source,
    { thumbnails = 1, returnThumbnails = false, startAt = 1, maxWidth, jpeg, compress } = {}
) => {
    const { video, isObjectUrl } = await loadVideo(source, { clearUrl: false })

    return new Promise((resolve, reject) => {
        const returnHandler = func => {
            if (isObjectUrl) {
                URL.revokeObjectURL(video.src)
            }

            video.parentNode.removeChild(video)

            func()
        }

        if (startAt >= video.duration) {
            startAt = 0
        }

        // We need to append the video to the dom to make it properly work
        video.width = maxWidth
        video.style = 'opacity:0;visibility:hidden;position:absolute'
        document.body.appendChild(video)

        // Prepare drawing canvas
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')

        canvas.width = video.clientWidth
        canvas.height = video.clientHeight
        context.clearRect(0, 0, canvas.width, canvas.height)

        const thumbnailsPerRow = returnThumbnails ? 1 : Math.sqrt(thumbnails)
        const thumbnailWidth = canvas.width * (1 / thumbnailsPerRow)
        const thumbnailHeight = canvas.height * (1 / thumbnailsPerRow)

        const blobs = []

        const imageType = jpeg ? 'image/jpeg' : 'image/png'

        const frameShift = (video.duration - startAt) / thumbnails
        let currentFrame = 0

        if (!Number.isInteger(thumbnailsPerRow) && !returnThumbnails) {
            throw new Error('Invalid number of thumbnails, must be sqrt(number)')
        }

        video.addEventListener('error', () => returnHandler(() => reject(video.error)))

        // Our seek-listener will do the hard-work
        video.addEventListener(
            'seeked',
            async () => {
                if (returnThumbnails) {
                    // clear canvas for new thumbnail
                    canvas.clearRect(0, 0, canvas.width, canvas.height)
                }

                context.drawImage(
                    // - source -
                    video,
                    0,
                    0,
                    video.videoWidth,
                    video.videoHeight,
                    // - target -
                    (currentFrame % thumbnailsPerRow) * thumbnailWidth,
                    (Math.ceil((currentFrame + 1) / thumbnailsPerRow) - 1) * thumbnailHeight,
                    thumbnailWidth,
                    thumbnailHeight
                )

                // If returning thumbnails we need to store it here
                if (returnThumbnails) {
                    const blob = await new Promise(res =>
                        canvas.toBlob(res, imageType, compress ? 0.75 : 1.0)
                    )
                    blobs.push(blob)
                }

                // Jump to next frame or finish but do this in idle mode
                // so the browser has a chance to finalize painting the current content
                window.requestAnimationFrame(async () => {
                    currentFrame += 1

                    if (currentFrame < thumbnails) {
                        video.currentTime = currentFrame * frameShift
                    } else {
                        // We're on last frame so return now
                        const blob = await new Promise(res =>
                            canvas.toBlob(res, imageType, compress ? 0.75 : 1.0)
                        )

                        returnHandler(() =>
                            resolve({
                                blob,
                                blobs,
                                mimeType: imageType,
                                width: canvas.width,
                                height: canvas.height,
                                originalWidth: video.videoWidth,
                                originalHeight: video.videoHeight,
                                duration: video.duration,
                            })
                        )
                    }
                })
            },
            false
        )

        // Start seeking
        video.currentTime = startAt
    })
}
