import React from 'react'
import { observer } from 'mobx-react-lite'
import { makeStyles } from '@material-ui/core/styles'

import Button from '@newspaces/lucky-components/Button'

import i18n from '../../i18n'

const useStyles = makeStyles(theme => ({
    root: {
        background: 'transparent',
        padding: theme.spacing(4),
        width: 600,
        flex: 1,
        '&.isFullScreen': {
            width: '100%',
            overflow: 'auto',
        },
    },
    actions: {
        margin: theme.spacing(5, 0, 2, 0),
        '& > *:not(:first-child)': {
            marginLeft: theme.spacing(1),
        },
    },
}))

export default observer(({ status, actions, onClose }) => {
    const classes = useStyles()

    const handleClick = onClick => async () => {
        onClose(await onClick())
    }

    const actionButtons = (actions || []).map(({ label, onClick, ...buttonProperties }, index) => (
        <Button key={index} onClick={onClick && handleClick(onClick)} {...buttonProperties}>
            {label}
        </Button>
    ))

    return (
        <div className={classes.actions}>
            <Button
                variant={actionButtons.length ? '' : 'contained'}
                color={actionButtons.length ? '' : status}
                onClick={onClose}
            >
                {i18n().misc.close}
            </Button>
            {actionButtons}
        </div>
    )
})
