import React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

const useInputLabelStyles = makeStyles(theme => ({
    root: ({ contained }) => ({
        fontSize: 13,
        fontWeight: 500,
        color: theme.palette.text.secondary,
        transform: contained ? 'translate(10px, 10px) !important' : 'none !important',
        position: contained ? 'absolute' : 'static',
        marginBottom: contained ? 0 : 7,
        pointerEvents: contained ? 'none' : 'all',
        '&:hover .label-marker': {
            visibility: 'visible',
        },
    }),
    asterisk: {
        color: theme.palette.warning.main,
    },
}))

const useStyles = makeStyles(theme => ({
    marker: ({ shiftMarker }) => ({
        position: 'absolute',
        left: '100%',
        top: -2,
        borderRadius: 9,
        marginLeft: shiftMarker ? 15 : 5,
        width: 18,
        height: 18,
        lineHeight: '18px',
        textAlign: 'center',
        fontSize: 12,
        fontWeight: 600,
        pointerEvents: 'all',
        visibility: 'hidden',
        '&, & *': {
            cursor: 'pointer',
        },
    }),
    errorMarker: {
        background: theme.palette.error.light,
        color: 'white',
        '&:hover': {
            background: theme.palette.error.main,
        },
    },
    infoMarker: {
        background: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
        '&:hover': {
            background: theme.palette.primary.main,
        },
    },
    labelWrapper: {
        display: 'inline-block',
        position: 'relative',
        '& .MuiFormLabel-asterisk, & .MuiInputLabel-asterisk': {
            color: theme.palette.warning.main,
        },
    },
}))

const TooltipTitle = ({ text }) => {
    const lines = text.split(/(?:\r\n|\r|\n)/)

    return (
        <>
            {lines.map((line, index) => {
                if (index === lines.length - 1) {
                    return line
                }

                return <>{line}<br/></>
            })}
        </>
    )
}

export default React.forwardRef(
    ({ contained, infoText, errorText, children, required, renderRequired, ...inputLabelProps }, ref) => {
        const inputLabelClasses = useInputLabelStyles({ contained })
        const classes = useStyles({ shiftMarker: required && renderRequired })

        let marker
        if (typeof errorText === 'string' && errorText) {
            marker = (
                <Tooltip title={<TooltipTitle text={errorText}/>}>
                    <span className={classNames(classes.marker, classes.errorMarker, 'label-marker')}>!</span>
                </Tooltip>
            )
        } else if (infoText) {
            const tooltipTitle = typeof infoText === 'string' ? <TooltipTitle text={infoText}/> : infoText
            marker = (
                <Tooltip title={tooltipTitle}>
                    <span className={classNames(classes.marker, classes.infoMarker, 'label-marker')}>?</span>
                </Tooltip>
            )
        }

        return (
            <InputLabel
                ref={ref}
                {...inputLabelProps}
                classes={inputLabelClasses}
                required={!!(required && renderRequired)}
            >
                <div className={classes.labelWrapper}>
                    {children}
                    {marker}
                </div>
            </InputLabel>
        )
    }
)
