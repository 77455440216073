import isInstanceOfObjectId from '../../utils/objectId/isInstanceOf'

export default (value, isRequired, ModelClass) => {
    if (isRequired && !value) {
        return 'required'
    }

    if (!value) {
        return null
    }

    if (isInstanceOfObjectId(value)) {
        return null
    }

    if (ModelClass.isRootModel(value)) {
        // we allow root models as valid ObjectId value since
        // on serialize they are turned into ObjectId
        return null
    }

    return 'invalidType'
}
