/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
// eslint-disable-next-line import/no-unresolved
import { Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'

import { fade } from './utils'

const useStyles = makeStyles(theme => ({
    link: {
        cursor: 'pointer',
        textDecoration: 'underline',
        textUnderlineOffset: '5px',
        textDecorationColor: fade(theme.palette.primary.main, 0.18),
        color: theme.palette.primary.main,
        borderRadius: theme.shape.smallBorderRadius,
        '&:hover': {
            color: theme.palette.primary.dark,
            textDecorationColor: theme.palette.primary.dark,
        },
        '&:active': {
            color: theme.palette.primary.dark,
            boxShadow: `0 0 0 3.5px ${theme.palette.action.selected}`,
            textDecorationColor: 'transparent',
        },
    },
    secondary: {
        cursor: 'pointer',
        textDecoration: 'underline',
        textUnderlineOffset: '5px',
        textDecorationColor: fade(theme.palette.text.secondary, 0.18),
        color: theme.palette.text.secondary,
        borderRadius: theme.shape.smallBorderRadius,
        '&:hover': {
            color: theme.palette.text.primary,
            textDecorationColor: theme.palette.text.primary,
        },
        '&:active': {
            color: theme.palette.text.primary,
            boxShadow: `0 0 0 3.5px ${fade(theme.palette.text.primary, 0.12)}`,
            textDecorationColor: 'transparent',
        },
    },
}))

export default React.forwardRef(
    ({ to, children, variant = 'inherit', underline = 'none', ...properties }, ref) => {
        const classes = useStyles({ variant })

        let linkClasses = {}
        if (classes[variant]) {
            linkClasses = { root: classes[variant] }
        }

        if (to) {
            return (
                <Link
                    classes={linkClasses}
                    component={RouterLink}
                    ref={ref}
                    to={to}
                    underline={underline}
                    color="inherit"
                    {...properties}
                >
                    {children}
                </Link>
            )
        }

        return (
            <Link classes={linkClasses} ref={ref} underline={underline} color="inherit" {...properties}>
                {children}
            </Link>
        )
    }
)
