/* eslint-disable max-len  */
/* eslint-disable no-template-curly-in-string */

export default {
    language: 'Lingua',
    languageName: 'Tedesco',
    months: [
        'Gennaio',
        'Febbraio',
        'Marzo',
        'Aprile',
        'Maggio',
        'Giugno',
        'Luglio',
        'Agosto',
        'Settembre',
        'Ottobre',
        'Novembre',
        'Dicembre',
    ],
    monthsShort: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Sep', 'Ott', 'Nov', 'Dic'],
    weekdays: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
    weekdaysShort: ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'],
    weekdaysMin: ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'],
    relativeTime: {
        yesterday: 'Ieri',
        today: 'Oggi',
        tomorrow: 'Domani',
        daysAgo: '"${this.days}" giorni fa',
        daysIn: 'Fra "${this.days}" giorni',
    },
    misc: {
        yesNo: {
            yes: 'Sì',
            no: 'No',
        },
        errors: {
            unknown: 'Si è verificato un errore sconosciuto, si prega di contattare il supporto.',
        },
        noCloseAlert: {
            title: 'Non chiudere la finestra!',
            message:
                'Si prega di non chiudere la finestra o la scheda del browser fino a quando questo processo sarà completato, altrimenti verrà annullato.',
        },
        close: 'Chiudi',
        saveChanges: 'Salva le modifiche',
        collapseAll: 'Riduci tutto',
        expandAll: 'Espandi tutto',
        create: 'Crea',
        edit: 'Modifica',
        remove: 'Rimuovi',
        delete: 'Elimina',
        cancel: 'Cancella',
        save: 'Salva',
        view: 'Visualizza',
        send: 'Invia',
        choose: 'Seleziona',
        download: 'Scarica',
        showMore: 'Mostra di più',
        showLess: 'Mostra meno',
        createdAtBy: 'Creato "${this.date}" da "${this.name}"',
        updatedAtBy: 'Modificato il "${this.date}" da "${this.name}"',
        unknown: 'Sconosciuto',
        missingField: '"${this.field}" mancante!',
        today: 'Oggi',
        for: 'per',
        or: 'o',
        back: 'Indietro',
        next: 'Avanti',
        exportToExcel: 'Esporta in Excel...',
        clearSelection: 'Annulla selezione',
        maxFileSizeMB: 'Il file non deve superare "${this.megabyte}" MB.',
        search: 'Cerca',
        makeFavorite: 'Seleziona come preferito',
        clearFavorite: 'Elimina da preferiti',
        noColor: 'Nessun colore',
    },
    resetPassword: {
        subject: 'Ripristina la password',
        text: 'Segui il link per impostare una nuova password: "${this.resetPasswordLink}"',
    },
    sendInvitation: {
        subject: 'Sei stato invitato a "${this.orgName}',
        text:
            'Salve "${this.name}, sei stato invitato a unirti a "${this.orgName}". Clicca sul seguente link per accettare il tuo invito: "${this.invitationLink}"',
    },
    emailUnsubscription: {
        title: 'Smetti di ricevere e-mail',
        text: "Hai annullato l'iscrizione e non riceverai più email",
    },
    organisation: {
        modelName: {
            single: 'Organizzazione',
            multi: 'Organizzazioni',
        },
        avatar: 'Immagine del profilo',
        primaryColor: 'Colore principale',
        primaryTextColor: 'Colore testo',
        name: 'Nome',
        street: 'Via + numero civico',
        zip: 'Codice postale',
        city: 'Città',
        country: 'Paese',
    },
    organisationUser: {
        modelName: {
            single: 'Utente',
            multi: 'Utenti',
        },
    },
}
