import MediaBrowser from './media.browser'

// eslint-disable-next-line
let media

if (process.env.RUNTIME_TARGET === 'browser') {
    media = MediaBrowser
}

export default media
