import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import AppContext from '../AppContext'

import AppModal from './AppModal'
import AppDialog from './AppDialog'
import AppProgress from './AppProgress'

export default observer(() => {
    const { modals } = AppContext

    const history = useHistory()

    useEffect(
        () =>
            history.listen(() => {
                // Clear all modals when routing away
                AppContext.modals = []
            }),
        [history]
    )

    if (!modals.length) {
        return null
    }

    return modals.map(modal => {
        switch (modal.type) {
            case 'dialog':
                return <AppDialog key={modal.id} modal={modal} />
            case 'modal':
                return <AppModal key={modal.id} modal={modal} />
            case 'progress':
                return <AppProgress key={modal.id} modal={modal} />
            default:
                console.error(new Error(`Unknown modal type "${modal.type}".`))
                return null
        }
    })
})
