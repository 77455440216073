import Runtime from '@newspaces/lucky-base/Runtime'
import baseUtils from '@newspaces/lucky-base/utils'

//
// Wait until a new organisation has arrived to the current user
// which usually happens after accepting an invitation or creating a new one
//
export default async (context, organisationId) => {
    await baseUtils.async.waitFor(async () => {
        if (await context.reloadUserData({ refreshUserData: false }) === true) {
            return !!context.userData.getOrganisationData(organisationId)
        }
        return false
    }, 2500)

    const isRefreshed = await Runtime.refreshUserData(true)
    if (!isRefreshed) {
        throw new Error(
            `Unable to refresh user custom data for userId "${
                context.userData.userId
            }" after creation of organisation "${organisationId.toString()}".`
        )
    }
}
