import React, { useMemo } from 'react'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import randomColor from 'randomcolor'

import { fade } from './utils'

const useRegularStyles = makeStyles(theme => ({
    root: {
        lineHeight: '1',
        verticalAlign: '1px',
        position: 'relative',
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: theme.shape.smallBorderRadius,
        padding: '5px 7px',
        fontSize: 12,
        fontWeight: 700,
        letterSpacing: '.3px',
        '&.margin': {
            marginRight: 5,
        },
        '&.uppercase': {
            textTransform: 'uppercase',
        },
        '&.icon': {
            paddingLeft: 27,
        },
        '&.outlined': {
            padding: '4px 6px',
        },
    },
    icon: {
        position: 'absolute',
        left: 4,
    },
}))

const RegularLabel = React.forwardRef(
    ({ className, outlined, label, Icon, baseColor, ...divProperties }, ref) => {
        const classes = useRegularStyles()

        const style = useMemo(() => {
            const result = {}

            if (outlined) {
                result.color = baseColor
                result.backgroundColor = fade(baseColor, 0.01, 'white')
                result.border = `1px solid ${fade(baseColor, 0.54, 'white')}`
            } else {
                result.color = baseColor
                result.backgroundColor = fade(baseColor, 0.08)
            }

            return result
        }, [baseColor, outlined])

        return (
            <div
                {...divProperties}
                ref={ref}
                className={classNames(classes.root, className, { icon: !!Icon, outlined })}
                style={style}
            >
                {Icon && <Icon className={classes.icon} fontSize="small" />}
                {label}
            </div>
        )
    }
)

const useDotStyles = makeStyles(() => ({
    root: {
        lineHeight: '1',
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        '&.margin': {
            marginRight: 16,
        },
        '&.uppercase': {
            textTransform: 'uppercase',
        },
    },
    dot: {
        width: 10,
        height: 10,
        borderRadius: 5,
        marginRight: 7,
    },
}))

const DotLabel = React.forwardRef(({ className, label, baseColor, ...divProperties }, ref) => {
    const classes = useDotStyles()

    return (
        <div {...divProperties} ref={ref} className={classNames(classes.root, className)}>
            <div className={classes.dot} style={{ backgroundColor: baseColor }} />
            {label}
        </div>
    )
})

const BUILTIN_COLORS = ['primary', 'secondary', 'error', 'warning', 'info', 'success', 'neutral']

export default React.forwardRef(
    (
        {
            className,
            label,
            Icon,
            variant = 'contained',
            uppercase = false,
            margin = false,
            color,
            ...divProperties
        },
        ref
    ) => {
        const theme = useTheme()

        const baseColor = useMemo(() => {
            if (BUILTIN_COLORS.includes(color)) {
                return theme.palette[color].main
            }

            if (!color) {
                return randomColor({
                    luminosity: 'dark',
                    format: 'hex',
                    seed: (label || '').toLowerCase(),
                })
            }

            return color
        }, [color, label, theme])

        className = classNames(className, { uppercase, margin })

        switch (variant) {
            case 'dot':
                return (
                    <DotLabel
                        ref={ref}
                        className={className}
                        label={label}
                        baseColor={baseColor}
                        {...divProperties}
                    />
                )

            case 'contained':
            case 'outlined':
            default:
                return (
                    <RegularLabel
                        ref={ref}
                        className={className}
                        outlined={variant === 'outlined'}
                        label={label}
                        Icon={Icon}
                        baseColor={baseColor}
                        {...divProperties}
                    />
                )
        }
    }
)
