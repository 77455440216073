import { useCallback } from 'react'

export default ({ filterOptionsBy }) => {
    const filterOptions = useCallback(
        (options, { inputValue }) => {
            if (typeof filterOptionsBy === 'function') {
                return filterOptionsBy(options, inputValue)
            }

            inputValue = inputValue && inputValue.toLowerCase()
            return (options || []).filter(({ label }) => {
                if (inputValue) {
                    if (!label) {
                        return false
                    }

                    label = label.toLowerCase()

                    switch (filterOptionsBy) {
                        case 'startsWith':
                        default:
                            return label.startsWith(inputValue)
                        case 'endsWith':
                            return label.endsWith(inputValue)
                        case 'contains':
                            return label.includes(inputValue)
                    }
                }

                return true
            })
        },
        [filterOptionsBy]
    )

    return filterOptions
}
