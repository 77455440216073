import parseQueryString from './parseQueryString'
import queryAsString from './queryAsString'
import getForbiddenSubdomains from './getForbiddenSubdomains'
import extractSubdomainKeys from './extractSubdomainKeys'
import isDevelopmentHostname from './isDevelopmentHostname'

export default {
    queryAsString,
    parseQueryString,
    getForbiddenSubdomains,
    extractSubdomainKeys,
    isDevelopmentHostname,
}
