import React, { useState, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import Alert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation } from 'react-router-dom'

import Runtime from '@newspaces/lucky-base/Runtime'
import TextInput from '@newspaces/lucky-components/TextInput'
import Button from '@newspaces/lucky-components/Button'

import AppContext from '../AppContext'

import i18n from '../i18n'

import AuthContainer from './AuthContainer'

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    error: {
        flexShrink: 0,
        flexGrow: 0,
        margin: theme.spacing(0, 0, 1, 0),
    },
    form: {
        flexGrow: 1,
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    footer: {
        flexGrow: 0,
        flexShrink: 0,
    },
    link: {
        cursor: 'pointer',
        color: theme.palette.text.secondary,
        '&:hover': {
            textDecoration: 'underline',
        },
        '&:active': {
            color: theme.palette.text.primary,
        },
    },
}))

export default observer(() => {
    const classes = useStyles()
    const location = useLocation()

    const returnToUrl = useMemo(() => {
        const params = new URLSearchParams(location.search)
        return params.get('returnTo')
    }, [location])

    const signUpUrl = useMemo(() => {
        return `/auth/signup${location.search || ''}`
    }, [location])

    const forgotPasswordUrl = useMemo(() => {
        return `/auth/forgot-password${location.search || ''}`
    }, [location])

    const [email, setEMail] = useState('')
    const [password, setPassword] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [isSubmitting, setSubmitting] = useState(false)

    const handleLogin = async evt => {
        evt.preventDefault()

        setErrorMessage('')
        setSubmitting(true)

        const error = await Runtime.loginWithEMail(email, password, AppContext.invitationToken)

        if (error) {
            setSubmitting(false)
            setErrorMessage(i18n().auth.login.errors[error] || i18n().misc.errors.unknown)
        } else if (AppContext.invitationToken) {
            let reloadUrl = `/?invitationToken=${AppContext.invitationToken}`
            if (returnToUrl) {
                reloadUrl += `&returnTo=${returnToUrl}`
            }
            // forces reload
            window.location.href = reloadUrl
        } else if (returnToUrl) {
            window.location.replace(returnToUrl)
        } else {
            // forces reload
            window.location.href = '/'
        }
    }

    return (
        <AuthContainer mode="login">
            <div className={classes.root}>
                {errorMessage && (
                    <Alert className={classes.error} severity="error" variant="filled">
                        {errorMessage}
                    </Alert>
                )}
                <form className={classes.form} onSubmit={handleLogin}>
                    <TextInput
                        variant="contained"
                        margin="normal"
                        label={i18n().auth.login.email}
                        autoComplete="email"
                        type="email"
                        value={email}
                        onChange={value => setEMail(value)}
                        autoFocus
                        required
                        fullWidth
                    />
                    <TextInput
                        variant="contained"
                        margin="normal"
                        label={i18n().auth.login.password}
                        autoComplete="current-password"
                        type="password"
                        value={password}
                        onChange={value => setPassword(value)}
                        required
                        fullWidth
                    />
                    <span className={classes.link} onClick={() => AppContext.history.push(forgotPasswordUrl)}>
                        {i18n().auth.login.passwordForgotten}
                    </span>
                    <Button
                        disabled={!email || !password}
                        loading={isSubmitting}
                        type="submit"
                        fullWidth
                        size="large"
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        {i18n().auth.login.action}
                    </Button>
                </form>
                <div className={classes.footer}>
                    <span className={classes.link} onClick={() => AppContext.history.push(signUpUrl)}>
                        {i18n().auth.login.noAccountSignUp}
                    </span>
                </div>
            </div>
        </AuthContainer>
    )
})
