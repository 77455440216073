import RuntimeBrowser from './Runtime.browser'
import TestRuntimeBrowser from './TestRuntime.browser'
import RuntimeBackend from './Runtime.backend'
import TestRuntimeBackend from './TestRuntime.backend'
import RuntimeServer from './Runtime.server'
import TestRuntimeServer from './TestRuntime.server'

// eslint-disable-next-line import/no-mutable-exports
let RuntimeInstance

if (process.env.RUNTIME_TARGET === 'browser') {
    if (process.env.NODE_ENV === 'test') {
        RuntimeInstance = new TestRuntimeBrowser()
    } else {
        RuntimeInstance = new RuntimeBrowser()
    }
}

if (process.env.RUNTIME_TARGET === 'backend') {
    if (process.env.RUNTIME_SERVER === 'true') {
        if (!global.RuntimeInstance) {
            if (process.env.NODE_ENV === 'test') {
                global.RuntimeInstance = new TestRuntimeServer()
            } else {
                global.RuntimeInstance = new RuntimeServer()
            }
        }
        RuntimeInstance = global.RuntimeInstance
    } else {
        // eslint-disable-next-line no-lonely-if
        if (process.env.NODE_ENV === 'test') {
            RuntimeInstance = new TestRuntimeBackend()
        } else {
            RuntimeInstance = new RuntimeBackend()
        }
    }
}

if (!RuntimeInstance) {
    throw new Error(`Invalid RUNTIME_TARGET environment variable value "${process.env.RUNTIME_TARGET}".`)
}

export default RuntimeInstance
