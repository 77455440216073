import Runtime from '../../Runtime'

import DateTime from '../../DateTime'

import validateBoolean from './validateBoolean'
import validateDateTime from './validateDateTime'
import validateString from './validateString'
import validateNumber from './validateNumber'
import validateObject from './validateObject'
import validateObjectId from './validateObjectId'

export default function validate(type, value, isRequired, validationSettings, ModelClass) {
    if (type === String) {
        return validateString(value, isRequired, validationSettings)
    }
    if (type === Number) {
        return validateNumber(value, isRequired, validationSettings)
    }
    if (type === Boolean) {
        return validateBoolean(value, isRequired, validationSettings)
    }
    if ([DateTime, DateTime.Date, DateTime.Time].includes(type)) {
        return validateDateTime(value, isRequired, validationSettings, type)
    }
    if (type === Object) {
        return validateObject(value, isRequired, validationSettings)
    }
    if (type === Runtime.BSON.ObjectId) {
        return validateObjectId(value, isRequired, ModelClass)
    }

    throw new Error(`Unknown validator type provided "${type}"`)
}
