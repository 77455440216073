import i18n from '@newspaces/lucky-components/i18n'

import deLanguage from './languages/de'
import enLanguage from './languages/en'
import itLanguage from './languages/it'
import frLanguage from './languages/fr'

i18n.registerLanguage('en', enLanguage)
i18n.registerLanguage('de', deLanguage)
i18n.registerLanguage('it', itLanguage)
i18n.registerLanguage('fr', frLanguage)

export default i18n
