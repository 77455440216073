import Runtime from '../../Runtime'

import Model from '../../Model'

import DateTime from '../../DateTime'

import UserDataEmailSubscriptions from './UserDataEmailSubscriptions'

class UserData extends Model {
    static ApiDescription = `
    An userdata keeps all information related to one user account
    `

    static CollectionName = 'userData'

    static Permissions = {
        read: {
            who: 'owner',
            fields: [
                'language',
                'avatar',
                'name',
                'email',
                'settings',
                'emailVerified',
                'scopes',
                'organisations',
                'organisationIds',
                'zones',
                'emailSubscriptions',
                'pushToken',
                'lastSeenAt',
                'bio',
            ],
        },
        edit: {
            who: 'owner',
            fields: [
                'language',
                'avatar',
                'name',
                'email',
                'settings',
                'emailSubscriptions',
                'pushToken',
                'lastSeenAt',
                'bio',
            ],
        },
        remove: false,
    }

    static Fields = {
        //
        // Mongo Realm User-Id
        //
        userId: {
            type: String,
            required: true,
        },
        language: {
            type: String,
            required: true,
        },
        avatar: {
            type: Model.Resource,
            default: () =>
                new Model.Resource({
                    image: {
                        maxWidth: 256,
                        maxHeight: 256,
                    },
                }),
        },
        name: {
            type: String,
            required: true,
        },
        email: {
            type: String,
            format: 'email',
            required: true,
        },
        bio: {
            type: String,
            max: 140,
        },
        //
        // Different saved custom settings for the user
        // {
        //   [settingsKey] = settingsValue
        // }
        settings: {
            type: Object,
            default: () => ({}),
        },
        //
        // if unverified then date is null,
        // otherwise date specifies date of verification.
        //
        emailVerified: {
            serialize: false,
            type: DateTime,
        },
        // Global scopes
        scopes: {
            serialize: false,
            type: Object,
            //   [scope]: {
            //     insert: Boolean
            //     edit: Boolean
            //     remove: Boolean
            //   }
        },
        //
        // {
        //   id: ObjectId(Organisation),
        //   owner: Boolean,
        //   [scope]: {
        //     read: Boolean
        //     insert: Boolean
        //     edit: Boolean
        //     remove: Boolean
        //   }
        // }
        organisations: {
            serialize: false,
            type: [Object],
        },
        // This is simply an array with all organisation ids
        // that we have on the organisations array. It is used
        // to correctly apply rule filters in realm
        organisationIds: {
            serialize: false,
            type: [Runtime.BSON.ObjectId],
        },
        zones: {
            type: [String],
        },
        emailSubscriptions: {
            type: [UserDataEmailSubscriptions],
        },
        //
        // This is only stored temporarily and if set it defines
        // that the given user is a guest session and its for the first
        // time that this guest user was ever seen
        //
        isNewGuestSession: {
            type: Boolean,
            default: false,
            serialize: false,
        },
        pushToken: {
            type: String,
        },
        lastSeenAt: {
            type: DateTime,
        },
    }

    static hasScope(scope, organisation) {
        if (!organisation) {
            return false
        }

        if (organisation.owner) {
            // -- owners are god
            return true
        }

        const scopeRef = organisation[scope]
        if (!scopeRef) {
            return false
        }

        const parts = scope.split('.')
        const mode = parts[1] || 'read'

        return scopeRef[mode] === true
    }

    constructor(Fields, data) {
        super({ ...UserData.Fields, ...Fields }, data)
    }

    get resourcePath() {
        if (!this.userId) {
            return null
        }

        return `user/${this.userId}`
    }

    get numberOfOrganisations() {
        return this.organisations.length
    }

    getOrganisationData(orgKey) {
        return this.organisations.find(organisation => {
            return organisation.id && organisation.id.toString() === `${orgKey}`
        })
    }
}

Model.declareModel(UserData)

export default UserData
