import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { makeStyles } from '@material-ui/core/styles'

import AppContext from '../AppContext'

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
    },
    logo: {
        height: 100,
        [theme.breakpoints.down('sm')]: {
            height: 70,
        },
        color: theme.palette.text.primary,
    },
}))

export default observer(() => {
    const classes = useStyles()

    const { zone } = AppContext

    const [isVisible, setVisible] = useState(false)

    useEffect(() => {
        // We wait one second before showing the splash to avoid 
        // bad flickering and only showing when loading is taking longer
        let timerId = setTimeout(() => {
            timerId = null
            setVisible(true)
        }, 1000)

        return () => {
            if (timerId !== null) {
                clearTimeout(timerId)
            }
        }
    }, [])

    if (!isVisible) {
        return null
    }

    return (
        <div className={classes.root}>
            <zone.Logo className={classes.logo} alt={zone.name} />
        </div>
    )
})
