export default (ModelClass, projection) => {
    if (!projection.length) {
        return []
    }

    // instantiate model to correctly populate fields
    const model = new ModelClass()

    return projection.filter(unhandledField => {
        let field = unhandledField

        if (field.startsWith('!')) {
            field = field.substring(1, field.length)
        }

        const pathes = field.split('.')

        let currentField = model.fields[pathes[0]]
        pathes.some((path, index) => {
            // first index is already handled
            if (index === 0) {
                return false
            }

            // if we ever encounter an Object, then simply return as
            // we need to check only that object deserialize state
            if (currentField.type === Object) {
                return true
            }

            // if currentField does not exist, end it
            if (!currentField) {
                return true
            }

            if (Array.isArray(currentField.type)) {
                if (!ModelClass.isSubModel(currentField.type[0])) {
                    // if not submodel we need to use the array field
                    return true
                }
                const arrayModel = new currentField.type[0]()
                currentField = arrayModel.fields[path]
            } else if (ModelClass.isSubModel(currentField.type)) {
                // eslint-disable-next-line new-cap
                const subModel = new currentField.type()
                currentField = subModel.fields[path]
            } else {
                // if there's no submodels anymore, use the current field
                return false
            }

            return false
        })

        if (!currentField) {
            // if for some reason, we end up with a invalid object,
            // return false
            return false
        }

        return currentField.deserialize !== false
    })
}
