import Runtime from '@newspaces/lucky-base/Runtime'
import baseUtils from '@newspaces/lucky-base/utils'

// eslint-disable-next-line import/no-named-as-default
import AppContext from './AppContext'

export default (stringUrl, { forceCheckMobile, allowAppKeys = false } = {}) => {
    if (!stringUrl) {
        return ''
    }

    const url = new URL(stringUrl)
    const { hostname } = url

    if (process.env.RUNTIME_TARGET === 'mobile' && !forceCheckMobile) {
        return ''
    }

    const hostnamesToIgnore = ['localhost']
    if (hostnamesToIgnore.some(hostnameToIgnore => hostname.indexOf(hostnameToIgnore) === 0)) {
        return ''
    }

    const isValidIpRegex = new RegExp(/^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)(\.(?!$)|$)){4}$/)
    const hostnameIsIPAddress = isValidIpRegex.test(hostname)
    if (hostnameIsIPAddress) {
        return ''
    }

    const slug = hostname.split('.')[0]
    
    const zones = JSON.parse(Runtime.values.ZONES)
    const fobiddenSubdomains = baseUtils.url.getForbiddenSubdomains(zones)
    if (fobiddenSubdomains.includes(slug.toLowerCase())) {
        return ''
    }

    if (!allowAppKeys && AppContext.allApps.find(({ key }) => key.toLowerCase() === slug.toLowerCase())) {
        return ''
    }

    return slug
}