// Returns the quarter from 1..4 for a given date
function quarter(month) {
    if (month <= 2) return 1
    if (month <= 5) return 2
    if (month <= 8) return 3
    return 4
}

quarter.beginingMonths = [0, 3, 6, 9]
quarter.endingMonths = [2, 5, 8, 11]

export default quarter
