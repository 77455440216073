import countries from '../utils/countries/countries'

import deLanguage from './language/de'
import enLanguage from './language/en'
import itLanguage from './language/it'
import frLanguage from './language/fr'

import registerLanguage from './language/registerLanguage'

import formatNumber from './utils/formatNumber'
import formatBytes from './utils/formatBytes'
import formatValue from './utils/formatValue'
import formatString from './utils/formatString'
import formatDate from './utils/formatDate'
import formatNumberInThousandAndMillions from './utils/formatNumberInThousandAndMillions'

import parseNumber from './utils/parseNumber'
import parseDate from './utils/parseDate'
import parseValue from './utils/parseValue'

import getLocale from './locales'

const languages = {}
const cachedLocales = {}
let defaultLocale = null
let defaultFallbackLanguage = null

const CURRENCY_SYMBOLS = {
    USD: '$',
    GBP: '£',
    EUR: '€',
}

const buildLocale = locale => {
    const localeInfo = getLocale(locale)
    if (!localeInfo) {
        throw new Error(`No locale "${locale}" available.`)
    }

    const parts = locale.split('-')
    const language = parts[0]
    const country = parts[1] || null

    if (!languages[language]) {
        throw new Error(`No language "${language}" for locale "${locale}" available.`)
    }

    let localeCurrency = null
    if (countries[country] && countries[country].currency) {
        localeCurrency = countries[country].currency
    }

    const localeObject = {
        // -- language strings --
        ...languages[language],

        // -- information --
        locale,
        language,
        country,
        currency: localeCurrency,

        // -- format --

        formatValue: (...args) => formatValue(localeObject, ...args),

        formatString: (...args) => formatString(locale, ...args),

        formatDate: (...args) => formatDate(locale, localeObject, ...args),

        formatNumber: (number, { currency, ...otherOptions } = {}, ...args) => {
            if (currency === true) {
                if (localeObject.currency) {
                    currency = localeObject.currency
                } else {
                    throw new Error(`Locale "${locale}" has no currency set.`)
                }
            }

            return formatNumber(locale, number, { currency, ...otherOptions }, ...args)
        },

        formatNumberInThousandAndMillions: (...args) => formatNumberInThousandAndMillions(locale, ...args),

        formatBytes: (...args) => formatBytes(locale, ...args),

        // - parse -

        parseValue: (...args) => parseValue(localeObject, ...args),

        parseDate: (...args) => parseDate(locale, ...args),

        parseNumber: (...args) => parseNumber(locale, ...args),

        CURRENCY_SYMBOLS,
    }

    return localeObject
}

function i18n(locale) {
    if (!locale) {
        locale = defaultLocale && defaultLocale.locale

        if (!locale) {
            throw new Error('No default locale available.')
        }
    }

    if (!cachedLocales[locale]) {
        cachedLocales[locale] = buildLocale(locale)
    }

    return cachedLocales[locale]
}

i18n.languages = [] // to be filed by calling app

i18n.getDefaultLocale = () => {
    return defaultLocale.locale
}

i18n.setDefaultLocale = (defLocale, defFallbackLanguage) => {
    if (!defLocale) {
        throw new Error('Expected valid locale for setDefaultLocale')
    }

    if (defFallbackLanguage) {
        defaultFallbackLanguage = defFallbackLanguage
    }

    defaultLocale = i18n(defLocale) // throws if not existent

    const parts = defLocale.split('-')
    const language = parts[0]
    if (defaultFallbackLanguage && defaultFallbackLanguage !== language) {
        i18n.registerLanguage(language, languages[language])
    }
}

i18n.trySetDefaultLocale = () => {
    if (typeof window !== 'undefined') {
        const { navigator } = window
        let browserLocale

        if (navigator.languages && navigator.languages.length) {
            browserLocale = navigator.languages[0]
        } else {
            browserLocale = navigator.userLanguage || navigator.language || navigator.browserLanguage
        }

        if (browserLocale) {
            const language = browserLocale.split('-')[0]
            if (i18n.languages.indexOf(language) >= 0) {
                try {
                    i18n.setDefaultLocale(browserLocale)
                } catch (e) {
                    i18n.setDefaultLocale(language)
                }
            }
        }
    }
}

i18n.getDefaultLanguage = () => {
    return (defaultLocale && defaultLocale.language) || null
}

i18n.getDefaultCountry = () => {
    return (defaultLocale && defaultLocale.country) || null
}

i18n.registerLanguage = (language, properties) => {
    registerLanguage(languages, language, properties, defaultFallbackLanguage)

    // -- clear all cached locales with the given language so they get rebuilt
    for (const cachedLocaleKey in cachedLocales) {
        if (cachedLocales[cachedLocaleKey].language === language) {
            delete cachedLocales[cachedLocaleKey]
        }
    }
}

i18n.create = (...languagesToRegister) => {
    let moduleInitialized = false

    const initializeI18n = () => {
        if (moduleInitialized) {
            moduleInitialized = false
            return
        }

        languagesToRegister.forEach(([languageKey, languageObj]) =>
            i18n.registerLanguage(languageKey, languageObj)
        )
    }

    initializeI18n()
    moduleInitialized = true

    return initializeI18n
}

// Register our default languages
i18n.registerLanguage('de', deLanguage)
i18n.registerLanguage('en', enLanguage)
i18n.registerLanguage('it', itLanguage)
i18n.registerLanguage('fr', frLanguage)

// Set english as default locale
i18n.setDefaultLocale('en-US')

// eslint-disable-next-line import/no-mutable-exports
let i18nInstance = i18n

if (process.env.RUNTIME_SERVER === 'true') {
    if (global.i18nInstance) {
        i18nInstance = global.i18nInstance
    } else {
        global.i18nInstance = i18nInstance
    }
}

export default i18nInstance
