import Runtime from '@newspaces/lucky-base/Runtime'

export default (context, defaultApp) => {
    // All apps that the user has access are available, even hidden ones
    // thats why we dont use context.appsUserHasAccessTo
    const availableApps = context.availableApps.filter(({ key }) => {
        return context.userHasAccessToApp(key)
    })

    // If there's no app available for the user then if we have no valid
    // user enforce an authentication first to figure the proper app. Otherwise
    // if we already have an user go into error mode as the user has no apps for
    // the given organisation.
    if (availableApps.length === 0) {
        if (Runtime.hasSession) {
            // User is authenticated but has no apps available so error out
            return { mode: 'error', error: 'noAppAvailable' }
        }

        // Enforce authentication first to understand which apps are available
        return { mode: 'authentication' }
    }

    if (defaultApp) {
        return { app: defaultApp }
    }

    return { mode: 'chooseApp' }
}
