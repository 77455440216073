export default (str, mode = 'datetime', defaultValue = null) => {
    let datePart = null
    let timePart = null

    if (str.indexOf('T') > 0) {
        ;[datePart, timePart] = str.split('T')
    } else {
        datePart = str
    }

    if (mode === 'date' || mode === 'time') {
        if (datePart && timePart) {
            return null
        }

        if (mode === 'time') {
            timePart = datePart
            datePart = null
        }
    } else if (mode === 'datetime') {
        if (!datePart) {
            return null
        }
    } else {
        throw new Error(`Unknown mode ${mode}.`)
    }

    let year = defaultValue
    let month = defaultValue
    let date = defaultValue
    let hours = defaultValue
    let minutes = defaultValue
    let seconds = defaultValue
    let milliseconds = defaultValue

    if (datePart) {
        const parts = datePart.split('-')

        // -- year
        if (parts.length > 0) {
            const yearNumber = Number.parseInt(parts[0], 10)
            if (Number.isNaN(yearNumber)) {
                return null
            }

            year = yearNumber
        }

        // -- month
        if (parts.length > 1) {
            const monthNumber = Number.parseInt(parts[1], 10)
            if (Number.isNaN(monthNumber) || monthNumber < 1 || monthNumber > 12) {
                return null
            }

            month = monthNumber - 1
        }

        // -- date
        if (parts.length > 2) {
            const dateNumber = Number.parseInt(parts[2], 10)
            if (Number.isNaN(dateNumber) || dateNumber < 1 || dateNumber > 31) {
                return null
            }

            date = dateNumber
        }
    }

    if (timePart) {
        let timeStr = timePart
        let zoneStr = null
        let zoneIndex = -1

        if (timeStr.indexOf('+') > 0) {
            zoneIndex = timeStr.indexOf('+')
        } else if (timeStr.indexOf('-') > 0) {
            zoneIndex = timeStr.indexOf('-')
        } else if (timeStr.indexOf('Z') > 0) {
            zoneIndex = timeStr.indexOf('Z')
        }

        if (zoneIndex > 0) {
            zoneStr = timeStr.substr(zoneIndex)
            timeStr = timeStr.substring(0, zoneIndex)

            if (zoneStr !== 'Z' && !/[+-]0+$/.test(zoneStr)) {
                // We only support UTC / +|- 0000 timezones
                return null
            }
        }

        const parts = timeStr.split(':')

        // -- hours
        if (parts.length > 0) {
            const hoursNumber = Number.parseInt(parts[0], 10)
            if (Number.isNaN(hoursNumber) || hoursNumber < 0 || hoursNumber > 23) {
                return null
            }

            hours = hoursNumber
        }

        // -- minutes
        if (parts.length > 1) {
            const minutesNumber = Number.parseInt(parts[1], 10)
            if (Number.isNaN(minutesNumber) || minutesNumber < 0 || minutesNumber > 59) {
                return null
            }

            minutes = minutesNumber
        }

        // -- seconds[.milliseconds][TimeZone]
        if (parts.length > 2) {
            const secondsParts = parts[2].split('.')

            // -- seconds
            const secondsNumber = Number.parseInt(secondsParts[0], 10)
            if (Number.isNaN(secondsNumber) || secondsNumber < 0 || secondsNumber > 59) {
                return null
            }

            seconds = secondsNumber

            // -- milliseconds
            if (secondsParts.length > 1) {
                const millisecondsNumber = Number.parseInt(secondsParts[1], 10)
                if (Number.isNaN(millisecondsNumber) || millisecondsNumber < 0 || millisecondsNumber > 999) {
                    return null
                }

                milliseconds = millisecondsNumber
            }
        }
    }

    return { year, month, date, hours, minutes, seconds, milliseconds }
}
