import remark from 'remark'
import html from 'remark-html'
import breaks from 'remark-breaks'
import externalLinks from 'remark-external-links'

import emoji from './plugins/emojiPlugins'

import { ignoreOrderedLists, unignoreOrderedLists } from './handleOrderedLists'

//
// options
// sanitize: true to prevent attacks, setting sanitize to false can be unsafe
//
export default (content, { openLinksInNewWindow = false, sanitize = true } = {}) => {
    let finalContent = `${content}`
    if (content === null || content === undefined) {
        finalContent = ''
    }

    finalContent = ignoreOrderedLists(finalContent)

    const { contents } = remark()
        .use(emoji, {
            emoticon: true,
        })
        .use(breaks)
        .use(externalLinks, {
            target: openLinksInNewWindow ? '_blank' : false,
            rel: [openLinksInNewWindow ? 'noopener noreferrer' : ''],
        })
        .use(html, { sanitize })
        .processSync(finalContent)

    return unignoreOrderedLists(contents)
}
