import extractSubdomainKeys from './extractSubdomainKeys'

const forbiddenSubdomains = ['app','dev','localhost']
export { forbiddenSubdomains }

export default zones => {
    if (!zones || typeof zones !== 'object' || Array.isArray(zones)) {
        throw new Error('Zones is not a valid object')
    }

    const result = [...forbiddenSubdomains]

    Object.keys(zones).forEach(zoneKey => {
        const zone = zones[zoneKey]

        // appUrl
        const subdomainKeys = extractSubdomainKeys(zone.appUrl)
        result.push(...subdomainKeys)
        // apiUrl
        const apiUrlParts = zone.apiUrl?.split('.') || []
        result.push(apiUrlParts[0])
        // webhookUrl
        const webhookUrlParts = zone.webhookUrl?.split('.') || []
        result.push(webhookUrlParts[0])
        // help
        const helpUrlParts = zone.help?.split('.') || []
        result.push(helpUrlParts[0])
    })

    return result.filter(x => !!x)
}