import React, { useState, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import Alert from '@material-ui/lab/Alert'
import { useLocation } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import Runtime from '@newspaces/lucky-base/Runtime'
import TextInput from '@newspaces/lucky-components/TextInput'
import Button from '@newspaces/lucky-components/Button'

import AppContext from '../AppContext'

import i18n from '../i18n'

import AuthContainer from './AuthContainer'

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    error: {
        flexShrink: 0,
        flexGrow: 0,
        margin: theme.spacing(0, 0, 1, 0),
    },
    alert: {
        marginBottom: theme.spacing(2),
    },
    form: {
        flexGrow: 1,
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    footer: {
        flexGrow: 0,
        flexShrink: 0,
    },
    link: {
        cursor: 'pointer',
        color: theme.palette.text.secondary,
        '&:hover': {
            textDecoration: 'underline',
        },
        '&:active': {
            color: theme.palette.text.primary,
        },
    },
}))

export default observer(() => {
    const classes = useStyles()
    const location = useLocation()

    const loginUrl = useMemo(() => {
        return `/auth/login${location.search || ''}`
    }, [location])

    const [isSuccess, setSuccess] = useState(false)
    const [isSubmitting, setSubmitting] = useState(false)

    const [email, setEMail] = useState('')

    const [errorMessage, setErrorMessage] = useState('')

    const handleFormSubmit = async evt => {
        evt.preventDefault()

        setSubmitting(true)

        setSuccess(false)
        setErrorMessage('')

        const { error, success } = await Runtime.sendResetPasswordEmail(email)

        setSuccess(success)

        if (error) {
            setErrorMessage(i18n().auth.resetPasswordQuery.errors[error])
        }

        setSubmitting(false)
    }

    let content

    if (isSuccess) {
        content = (
            <Alert className={classes.alert} severity="success" variant="filled">
                {i18n().auth.resetPasswordQuery.success({ email })}
            </Alert>
        )
    } else {
        content = (
            <>
                {errorMessage && (
                    <Alert severity="error" variant="filled">
                        {errorMessage}
                    </Alert>
                )}
                <form className={classes.form} onSubmit={handleFormSubmit}>
                    <TextInput
                        disabled={isSuccess}
                        variant="contained"
                        margin="normal"
                        label={i18n().auth.resetPasswordQuery.email}
                        autoComplete="email"
                        type="email"
                        value={email}
                        onChange={value => setEMail(value)}
                        required
                        autoFocus
                        fullWidth
                    />
                    <Button
                        disabled={!email}
                        loading={isSubmitting}
                        type="submit"
                        fullWidth
                        size="large"
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        {i18n().auth.resetPasswordQuery.action}
                    </Button>
                </form>
            </>
        )
    }

    return (
        <AuthContainer mode="resetPasswordQuery">
            <div className={classes.root}>
                {content}
                <div className={classes.footer}>
                    <span className={classes.link} href="#" onClick={() => AppContext.history.push(loginUrl)}>
                        {i18n().auth.resetPasswordQuery.rememberPasswordLogin}
                    </span>
                </div>
            </div>
        </AuthContainer>
    )
})
