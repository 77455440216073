import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import { amber } from '@material-ui/core/colors'
import { makeStyles, fade } from '@material-ui/core/styles'

import i18n from '../../i18n'

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(5, -3, -1.1, -3),
        paddingBottom: theme.spacing(1),
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        background: fade(amber['500'], 0.2),
    },
}))

export default observer(() => {
    const classes = useStyles()

    useEffect(() => {
        const handleBeforeunload = evt => {
            evt.preventDefault()
            evt.returnValue = i18n().misc.noCloseAlert.title
            return evt.returnValue
        }

        window.addEventListener('beforeunload', handleBeforeunload)

        return () => {
            window.removeEventListener('beforeunload', handleBeforeunload)
        }
    }, [])

    return (
        <Alert className={classes.root} severity="warning" icon={false}>
            <AlertTitle>{i18n().misc.noCloseAlert.title}</AlertTitle>
            {i18n().misc.noCloseAlert.message}
        </Alert>
    )
})
