import Model from '../../Model'

export default class UserDataEmailSubscriptions extends Model {
    static Fields = {
        zone: {
            type: String,
            required: true,
        },
        app: {
            type: String,
            required: true,
        },
        subscribed: {
            type: Boolean,
            required: true,
            default: true
        },
    }

    constructor(data) {
        super(UserDataEmailSubscriptions.Fields, data)
    }
}
