export default (ModelClass, fieldMappings, { getField } = {}) => {
    const model = new ModelClass()

    return fieldMappings.map(({ path, ...otherFieldProperties }) => {
        let parentFieldRef = null
        let fieldRef = null

        if (typeof getField === 'function') {
            const {
                fieldRef: customFieldRef,
                parentFieldRef: customParentFieldRef
            } = getField(path)

            fieldRef = customFieldRef
            parentFieldRef = customParentFieldRef
        }

        if (!fieldRef && !parentFieldRef) {
            if (path.indexOf('.') > 0) {
                parentFieldRef = model.getField(path.split('.')[0], false)
            }

            fieldRef = model.getField(path, false)
        }

        if (!fieldRef) {
            throw new Error(`Invalid field path "${path}"`)
        }

        const fieldIsArray = Array.isArray(fieldRef.type)
        const parentFieldIsArray = parentFieldRef && Array.isArray(parentFieldRef.type)
        const fieldType = fieldIsArray ? fieldRef.type[0] : fieldRef.type

        if (fieldIsArray && parentFieldIsArray) {
            throw new Error('breakdown of nested arrays is unsupported')
        }

        return {
            path,
            ...otherFieldProperties,
            fieldRef,
            fieldType,
            fieldIsArray,
            parentFieldIsArray
        }
    })
}
