import Model from './Model'
import Query from './Query'
import Resource from './Resource'
import ValidationError from './ValidationError'
import validate from './validate'
import declareModel from './declareModel'
import decorateModel from './decorateModel'
import mergePermissions from './mergePermissions'
import stringSearchTerm from './stringSearchTerm'
import convertFilterToQuery from './convertFilterToQuery'
import convertUpdateDocument from './convertUpdateDocument'

Model.declareModel = declareModel
Model.decorateModel = decorateModel
Model.mergePermissions = mergePermissions
Model.Query = Query
Model.Resource = Resource
Model.ValidationError = ValidationError
Model.validate = validate
Model.stringSearchTerm = stringSearchTerm
Model.convertFilterToQuery = convertFilterToQuery
Model.convertUpdateDocument = convertUpdateDocument

// eslint-disable-next-line import/no-mutable-exports
let ModelInstance = Model

if (process.env.RUNTIME_SERVER === 'true') {
    if (global.ModelInstance) {
        ModelInstance = global.ModelInstance
    } else {
        global.ModelInstance = ModelInstance
    }
}

export default ModelInstance
