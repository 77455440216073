import getLocale from '../locales'

// uuuugly...
const CURRENCY_SYMBOLS = {
    USD: '$',
    GBP: '£',
    EUR: '€',
}

export default (
    locale,
    number,
    { currency, seperateThousands = true, minimumFractionDigits, maximumFractionDigits = 2 } = {}
) => {
    const { thousandSeparator, decimalSeparator, currencyPosition } = getLocale(locale)

    // Assume currencies always have fraction digits even for zero
    if (minimumFractionDigits === undefined && currency) {
        minimumFractionDigits = 2
    }

    const numberFixed = number % 1 === 0 ? number.toString() : number.toFixed(maximumFractionDigits)
    const numberParts = numberFixed.split('.')

    let fixedNumber = numberParts[0]

    // Setup digits honoring minimum fraction digits
    let digitsNumber = numberParts[1]?.replace(/0+$/, '') || ''
    if (minimumFractionDigits > 0 && digitsNumber.length < minimumFractionDigits) {
        for (let i = digitsNumber.length; i < minimumFractionDigits; i += 1) {
            digitsNumber += '0'
        }
    }

    // Split thousands if desired
    if (seperateThousands) {
        fixedNumber = fixedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator)
    }

    // Format the number now
    let numberString = fixedNumber

    // Add the digits if any
    if (digitsNumber) {
        numberString += `${decimalSeparator}${digitsNumber}`
    }

    // Add currency if any
    if (currency) {
        const currencySymbol = CURRENCY_SYMBOLS[currency] || currency

        switch (currencyPosition) {
            case 'start':
                numberString = `${currencySymbol} ${numberString}`
                break
            case 'start-noSpace':
                numberString = `${currencySymbol}${numberString}`
                break
            case 'end':
                numberString = `${numberString} ${currencySymbol}`
                break
            case 'end-noSpace':
                numberString = `${numberString}${currencySymbol}`
                break
            default:
                throw new Error('Missing currency position.')
        }
    }

    return numberString
}
