import { fade, decomposeColor, recomposeColor } from '@material-ui/core/styles'

export default (color, alpha, backgroundColor) => {
    if (!backgroundColor) {
        return fade(color, alpha)
    }

    alpha = Math.min(Math.max(0, alpha), 1)
    color = decomposeColor(color)

    switch (backgroundColor) {
        case 'white':
            backgroundColor = decomposeColor('rgba(255,255,255)')
            break
        case 'black':
            backgroundColor = decomposeColor('rgba(0,0,0)')
            break
        default:
            backgroundColor = decomposeColor(backgroundColor)
            break
    }

    if (color.type === 'rgba' || color.type === 'hsla') {
        color.values[3] = 1.0
    }

    if (color.type === 'color') {
        color.values[3] = `/1`
    }

    color.values[0] = (1 - alpha) * backgroundColor.values[0] + alpha * color.values[0]
    color.values[1] = (1 - alpha) * backgroundColor.values[1] + alpha * color.values[1]
    color.values[2] = (1 - alpha) * backgroundColor.values[2] + alpha * color.values[2]

    return recomposeColor(color)
}
