import React, { useCallback } from 'react'
import nanoid from 'nanoid'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

import { fade } from './utils'

import InputLabel from './InputLabel'

const baseColor = fade('#000', 0.04, 'white')
const hoverColor = fade('#000', 0.08, 'white')

const useControlStyles = makeStyles(() => ({
    root: ({ fullHeight }) => ({
        height: fullHeight && '100%',
        '&:hover .label-marker': {
            visibility: 'visible',
        },
    }),
}))

const useInputStyles = makeStyles(theme => ({
    root: ({ variant, fullHeight, active }) => {
        const style = {
            height: fullHeight && '100%',
            border: `1px solid ${baseColor}`,
            borderColor: baseColor,
            borderRadius: theme.shape.borderRadius,
            overflow: 'hidden',
            backgroundColor: baseColor,
            padding: '0 !important',
            font: 'inherit',
            transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow'], {
                duration: 150,
            }),
            '& .MuiInputAdornment-root': {
                visibility: 'visible',
            },
            '& .MuiInputAdornment-filled': {
                marginLeft: '7px !important',
                marginRight: '-5px !important',
                marginTop: '-1px !important',

                '&.MuiInputAdornment-positionEnd': {
                    marginRight: '8px !important',
                },
            },
            '&:not(.Mui-disabled):hover': {
                borderColor: hoverColor,
                backgroundColor: hoverColor,
            },
            '&:not(.Mui-disabled):hover .MuiInputAdornment-root, &.Mui-focused .MuiInputAdornment-root': {
                visibility: 'visible',
            },
            '& input::placeholder': {
                color: theme.palette.text.primary,
                opacity: 0.58,
                fontWeight: 400,
            },
        }

        if (active) {
            style.backgroundColor = fade(theme.palette.primary.main, 0.08, 'white')
            style.borderColor = fade(theme.palette.primary.main, 0.38, 'white')
            style['& input::placeholder'] = {
                color: theme.palette.primary.dark,
            }

            style['&:not(.Mui-disabled):hover'] = {
                borderColor: fade(theme.palette.primary.main, 0.54, 'white'),
                backgroundColor: fade(theme.palette.primary.main, 0.16, 'white'),
            }
        }

        switch (variant) {
            case 'inlined':
                style.backgroundColor = 'transparent !important'
                style.border = '0px solid transparent !important'
                style.borderRadius = 0
                style.transition = ''

                if (active) {
                    style.color = theme.palette.primary.main
                }
                break
            case 'hovered':
                if (!active) {
                    style.backgroundColor = 'transparent'
                    style.border = '1px solid transparent'
                    style.borderColor = 'transparent'
                }
                // disabled styles need important annotation otherwise
                // they are overwritten by material uis own styles
                style['&.Mui-disabled'] = {
                    backgroundColor: 'transparent !important',
                    borderColor: 'transparent !important',
                    color: theme.palette.text.primary,
                }
                style['& .MuiInputAdornment-root'].visibility = 'hidden'
                style['&:not(.Mui-disabled):hover'].borderColor = baseColor
                style['&:not(.Mui-disabled):hover'].backgroundColor = baseColor
                break
            case 'contained':
                style['& .MuiInputAdornment-filled'].marginTop = '20px !important'
                break
            default:
                break
        }

        return style
    },
    error: ({ variant }) => {
        if (variant === 'inlined') {
            return {
                color: theme.palette.error.main,
                '& input::placeholder': {
                    color: theme.palette.error.dark,
                },
            }
        }

        return {
            borderColor: `${fade(theme.palette.error.main, 0.38, 'white')}`,
            backgroundColor: `${fade(theme.palette.error.main, 0.08, 'white')}`,
            '& input::placeholder': {
                color: theme.palette.error.dark,
            },

            '&:not(.Mui-disabled):hover': {
                borderColor: `${fade(theme.palette.error.main, 0.54, 'white')}`,
                backgroundColor: `${fade(theme.palette.error.main, 0.16, 'white')}`,
            },
        }
    },
    focused: ({ variant }) => {
        const style = {
            borderColor: `${theme.palette.primary.main} !important`,
            background: '#fff !important',
            boxShadow: `${fade(theme.palette.primary.main, 0.5)} 0 0 0 3px`,
        }

        switch (variant) {
            case 'inlined':
                delete style.borderColor
                delete style.boxShadow
                break
            default:
                break
        }

        return style
    },
    input: ({ variant, multiline }) => {
        const style = {
            fontWeight: 500,
            padding: 10,
        }

        if (multiline && variant === 'contained') {
            style.padding = '0px 10px 10px 10px'
            style.marginTop = '30px'

            return style
        }

        switch (variant) {
            case 'contained':
                style.padding = '30px 10px 10px 10px'
                break
            case 'inlined':
                style.padding = 0
                break
            default:
                break
        }

        return style
    },
}))

const TextInput = React.forwardRef(
    (
        {
            InputProps,
            InputLabelProps,
            inputProps,
            fullHeight,
            variant,
            type,
            size,
            info,
            error,
            active,
            nativeOnChange,
            onKeyDown,
            onChange,
            onSubmit,
            multiline,
            preventNewLines,
            ...properties
        },
        ref
    ) => {
        if (type === 'search') {
            throw new Error('For type=search use dedicated TextSearchInput instead.')
        }

        const controlClasses = useControlStyles({ fullHeight })
        const inputClasses = useInputStyles({ variant, active, fullHeight, multiline })

        const handleChange = useCallback(
            evt => {
                if (onChange) {
                    if (preventNewLines && evt.target.value) {
                        evt.target.value = evt.target.value.replace(/(?:\r\n|\r|\n)/g, '')
                    }
                    if (nativeOnChange) {
                        onChange(evt)
                    } else {
                        onChange(evt.target.value)
                    }
                }
            },
            [nativeOnChange, onChange, preventNewLines]
        )

        const handleKeyDown = useCallback(
            evt => {
                if (evt.keyCode === 13 && onSubmit) {
                    onSubmit(evt)
                }

                if (onKeyDown) {
                    onKeyDown(evt)
                }
            },
            [onSubmit, onKeyDown]
        )

        const fieldId = React.useMemo(nanoid, [])

        return (
            <TextField
                {...properties}
                error={!!error}
                ref={ref}
                type={type}
                variant="filled"
                classes={controlClasses}
                InputProps={{
                    classes: inputClasses,
                    ...InputProps,
                    disableUnderline: true,
                    id: fieldId,
                }}
                inputProps={{ size: size || null, autoComplete: 'new-password', ...inputProps }}
                InputLabelProps={{
                    ...InputLabelProps,
                    contained: variant === 'contained',
                    shrink: true,
                    htmlFor: fieldId,
                    infoText: info,
                    errorText: error,
                    component: InputLabel,
                }}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                multiline={multiline}
            />
        )
    }
)

TextInput.baseColor = fade('#000', 0.04, 'white')
TextInput.hoverColor = fade('#000', 0.08, 'white')
TextInput.useControlStyles = useControlStyles
TextInput.useInputStyles = useInputStyles

export default TextInput
