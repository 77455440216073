import React, { useState, useMemo, useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import Alert from '@material-ui/lab/Alert'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation } from 'react-router-dom'

import Runtime from '@newspaces/lucky-base/Runtime'
import TextInput from '@newspaces/lucky-components/TextInput'
import Button from '@newspaces/lucky-components/Button'
import Link from '@newspaces/lucky-components/Link'

import AppContext from '../AppContext'

import i18n from '../i18n'

import AuthContainer from './AuthContainer'

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    error: {
        flexShrink: 0,
        flexGrow: 0,
        margin: theme.spacing(0, 0, 1, 0),
    },
    form: {
        flexGrow: 1,
    },
    checkboxes: {
        '& > *': {
            marginRight: 0,
            marginTop: theme.spacing(1),
        },
        '& *': {
            fontSize: '0.9rem',
        },
    },
    invisibleCheckbox: {
        visibility: 'hidden'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    footer: {
        flexGrow: 0,
        flexShrink: 0,
    },
    link: {
        cursor: 'pointer',
        color: theme.palette.text.secondary,
        '&:hover': {
            textDecoration: 'underline',
        },
        '&:active': {
            color: theme.palette.text.primary,
        },
    },
}))

export default observer(() => {
    const classes = useStyles()
    const location = useLocation()

    const returnToUrl = useMemo(() => {
        const params = new URLSearchParams(location.search)
        return params.get('returnTo')
    }, [location])

    const loginUrl = useMemo(() => {
        return `/auth/login${location.search || ''}`
    }, [location])

    const [email, setEMail] = useState('')
    const [password, setPassword] = useState('')
    const [checks, setChecks] = useState(() => {
        const signUpChecks = i18n().auth.signUp.checks?.[AppContext.zone.key] || {}
        const allChecksObj = {}
        Object.keys(signUpChecks).forEach(key => {
            allChecksObj[key] = false
        })
        return allChecksObj
    })
    const [errorMessage, setErrorMessage] = useState('')
    const [isSubmitting, setSubmitting] = useState(false)

    const canSignUp = email && password && Object.keys(checks).every(key => !!checks[key])
    const handleSignup = async evt => {
        evt.preventDefault()

        setErrorMessage('')
        setSubmitting(true)

        const error = await Runtime.signUpWithEMail(email, password, password, AppContext.invitationToken)

        if (error) {
            setSubmitting(false)
            setErrorMessage(i18n().auth.signUp.errors[error] || i18n().misc.errors.unknown)
        } else {
            // Make sure our user data is being created on behalf of the user
            const userData = await Runtime.createUserData()

            if (!userData) {
                alert(i18n().misc.errors.unknown)
                throw new Error(`Unable to save userData on signup for user "${Runtime.userId}".`)
            } else {
                try {
                    await AppContext.fireListeners('signUpSuccess', { userData })
                } catch {
                    // does nothing
                }

                if (AppContext.invitationToken) {
                    let reloadUrl = `/?invitationToken=${AppContext.invitationToken}`
                    if (returnToUrl) {
                        reloadUrl += `&returnTo=${returnToUrl}`
                    }
                    // forces reload
                    window.location.href = reloadUrl
                } else if (returnToUrl) {
                    window.location.replace(returnToUrl)
                } else { // forces reload
                    window.location.href = '/'
                }
            }
        }
    }

    const makeLinkLabels = useCallback((i18nLabel, urls) => (
        i18nLabel((string, isToken, { tokenIndex }) => {
            if (isToken) {
                return (
                    <Link key={urls[tokenIndex]} className={classes.link} target="_blank" href={urls[tokenIndex]}>
                        {string}
                    </Link>
                )
            }
            return string
        })
    ) , [classes.link])

    const checkBoxes = useMemo(() => {
        const signUpChecks = i18n().auth.signUp.checks?.[AppContext.zone.key] || {}
        return Object.keys(signUpChecks).map(key => {
            const { text, urls } = signUpChecks[key]
            return (
                <FormControlLabel
                    key={key}
                    label={makeLinkLabels(text, urls)}
                    control={
                        <Checkbox
                            color="primary"
                            checked={!!checks[key]}
                            onChange={() => setChecks(prev =>({ ...prev, [key]: !prev[key]}))}
                        />
                    }
                />
            )
        })
    }, [checks, makeLinkLabels])

    const information = useMemo(() => {
        const signUpInformation = i18n().auth.signUp.information?.[AppContext.zone.key]
        if (!signUpInformation) {
            return []
        }

        return Object.keys(signUpInformation).map(key => {
            const { text, urls } = signUpInformation[key]
            return (
                <FormControlLabel
                    key={key}
                    label={makeLinkLabels(text, urls)}
                    control={
                        <Checkbox
                            color="primary"
                            className={classes.invisibleCheckbox}
                        />
                    }
                />
            )
        })
    }, [makeLinkLabels, classes.invisibleCheckbox])

    return (
        <AuthContainer mode="signUp">
            <div className={classes.root}>
                {errorMessage && (
                    <Alert className={classes.error} severity="error" variant="filled">
                        {errorMessage}
                    </Alert>
                )}
                <form className={classes.form} onSubmit={handleSignup}>
                    <TextInput
                        variant="contained"
                        margin="normal"
                        label={i18n().auth.signUp.email}
                        autoComplete="email"
                        type="email"
                        value={email}
                        onChange={value => setEMail(value)}
                        autoFocus
                        required
                        fullWidth
                    />
                    <TextInput
                        variant="contained"
                        margin="normal"
                        label={i18n().auth.signUp.password}
                        autoComplete="current-password"
                        type="password"
                        value={password}
                        onChange={value => setPassword(value)}
                        required
                        fullWidth
                    />
                    <div className={classes.checkboxes}> 
                        {checkBoxes}
                        {information}
                    </div>
                    <Button
                        disabled={!canSignUp}
                        loading={isSubmitting}
                        type="submit"
                        fullWidth
                        size="large"
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        {i18n().auth.signUp.action}
                    </Button>
                </form>
                <div className={classes.footer}>
                    <span className={classes.link} onClick={() => AppContext.history.push(loginUrl)}>
                        {i18n().auth.signUp.alreadyRegisteredLogin}
                    </span>
                </div>
            </div>
        </AuthContainer>
    )
})
