export default hostname => {
    const normalizedHostname = hostname.toLowerCase()

    // On Capacitor we get have an IP as hostname when running on Android
    const isValidIpRegex = new RegExp(/^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)(\.(?!$)|$)){4}$/)
    const isIpAddress = isValidIpRegex.test(normalizedHostname)

    const isLocalHost = normalizedHostname.includes('localhost')

    const hasPort = normalizedHostname.includes(':3000')

    return isLocalHost || isIpAddress || hasPort
}