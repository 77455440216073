export default theme => ({
    html: {
        fontSize: theme.typography.htmlFontSize,
    },
    'html, body, #root': {
        fontSmoothing: 'auto !important',
    },
    'html, body': {
        height: '100%',
    },
    body: {
        background: '#ffffff',
    },
    ':root': {
        // Variables used to get the notch size
        '--sat': 'env(safe-area-inset-top)',
        '--sar': 'env(safe-area-inset-right)',
        '--sab': 'env(safe-area-inset-bottom)',
        '--sal': 'env(safe-area-inset-left)',
    },
    '#root': {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        paddingTop: 'env(safe-area-inset-top)',
        paddingBottom: 'env(safe-area-inset-bottom)',
        paddingLeft: 'env(safe-area-inset-left)',
        paddingRight: 'env(safe-area-inset-right)',
    },
    'input[type="search"]': {
        '-webkit-appearance': 'textfield',
    },
    'input[type="search"]::-webkit-search-decoration': {
        '-webkit-appearance': 'none',
    },
    '*::selection': {
        background: theme.palette.action.selected,
    },
    '.ag-watermark': {
        display: 'none',
    },
    a: {
        '-webkit-touch-callout': 'none',
    },
})
