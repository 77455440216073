export default fields =>
    fields.reduce((res, field, index) => {
        let initialValue = { $ifNull: [`$${field.path}`, null] }

        if (field.fieldIsArray) {
            // Arrays must have different default for null so its actually considered in our groupings
            initialValue = { $ifNull: [`$${field.path}`, [null]] }
        }

        return {
            values: {
                ...res.values,
                [`value_${index}`]: initialValue,
                [`value_map_${index}`]: initialValue,
            },
        }
    }, {})
