export default {
    group: {
        move: "Déplacer",
        expand: "Déplier",
        collapse: "Fermer",
    },
    dataFilter: {
        searchField: "Rechercher un champ...",
        addFilter: "Ajouter un filtre",
        addFilterToGroup: "Ajouter un filtre à un groupe",
        removeFilter: "Supprimer le filtre",
        missingValue: "valeur manquante",
        done: "Fait",
        operator: {
            and: "et",
            or: "ou",
        },
        compare: {
            list: {
                contains: "contient",
                notContains: "ne contient pas",
                empty: "est vide",
                notEmpty: "n'est pas vide",
            },
            string: {
                equals: "est",
                notEquals: "n'est pas",
                contains: "contient",
                notContains: "ne contient pas",
                startsWith: "commence par",
                endsWith: "se termine par",
                exists: "contient une valeur quelconque",
                notExists: "ne contient aucune valeur",
            },
            number: {
                equals: "est",
                notEquals: "n'est pas",
                greaterThan: "plus grand que",
                lessThan: "plus petit que",
                exists: "contient une valeur quelconque",
                notExists: "ne contient aucune valeur",
            },
            date: {
                moreThanDaysAgo: "il y a plus de",
                exactlyDaysAgo: "il y a exactement ",
                lessThanDaysAgo: "il y a moins de",
                moment: " ",
                after: "après le",
                equals: "le",
                before: "avant le",
                exists: "contient une valeur quelconque",
                notExists: "ne contient aucune valeur",
                groupLabels: {
                    relative: "Relatif",
                    custom: "Personnalisé",
                    absolute: "Absolu",
                },
                postfixLabels: {
                    days: "Jours(s)",
                },
                momentValues: {
                    beforeToday: "Avant aujourd'hui",
                    beforeOrAtToday: "Aujourd'hui ou avant",
                    afterToday: "Après aujourd'hui",
                    afterOrAtToday: "Aujourd'hui ou après",
                },
            },
            bool: {
                isTrue: "Est vrai",
                isFalse: "Est faux",
                exists: "contient une valeur quelconque",
                notExists: "ne contient aucune valeur",
            },
        },
    },
    dataGrid: {
        enabled: "Activé",
        columnMenu: "Regrouper, afficher et plus encore",
        pinColumn: "Épingler la colonne",
        valueAggregation: "Agréger",
        autosizeThiscolumn: "Adapter la largeur au contenu",
        autosizeAllColumns: "Adapter toutes les colonnes au contenu",
        groupBy: "Regrouper par",
        ungroupBy: "Dégrouper de",
        resetColumns: "Réinitialiser toutes les colonnes",
        expandAll: "Tout déplier",
        collapseAll: "Tout fermer",
        pinLeft: "Épingler les liens",
        pinRight: "Épingler à droite",
        noPin: "Ne pas épingler",
        searchOoo: "Recherche en cours...",
        loadingOoo: "Chargement en cours...",
        noRowsToShow: "Aucune donnée disponible",
        emptyGroup: "non défini",
        expandGroup: "Ouvrir un groupe",
        collapseGroup: "Fermer le groupe",
        sortAscending: "Trier par ordre croissant",
        sortDescending: "Trier par ordre décroissant",
    },
    fileInput: {
        upload: "Télécharger le fichier",
        remove: "Supprimer un fichier",
    },
    select: {
        initialLoading: "En cours de chargement...",
    },
    loadMoreList: {
        loadMoreCount: "+${this.more} autres",
        loadMoreInfinite: "Charger autres",
    },
}
