export default (dt1, dt2, unit) => {
    if (['millisecond', 'milliseconds'].includes(unit)) {
        return dt2.valueOf() - dt1.valueOf()
    }

    if (['second', 'seconds'].includes(unit)) {
        return Math.floor((dt2.valueOf() - dt1.valueOf()) / 1000)
    }

    if (['minute', 'minutes'].includes(unit)) {
        return Math.floor((dt2.valueOf() - dt1.valueOf()) / 1000 / 60)
    }

    if (['hour', 'hours'].includes(unit)) {
        return Math.floor((dt2.valueOf() - dt1.valueOf()) / 1000 / 60 / 60)
    }

    if (['day', 'days'].includes(unit)) {
        const millisecondsPerDay = 24 * 60 * 60 * 1000
        return (
            (Date.UTC(dt2.year, dt2.month, dt2.date) - Date.UTC(dt1.year, dt1.month, dt1.date)) /
            millisecondsPerDay
        )
    }

    if (['day360', 'days360'].includes(unit)) {
        const getDate = date => {
            // Strip to max. 30 days
            let result = Math.min(date.date, 30)

            // If february, increase to 30 days if 28th/29th
            if (date.month === 1) {
                const lastDateInFeb = new Date(date.year, 2, 0).getDate()
                if (result === lastDateInFeb) {
                    result = 30
                }
            }

            return result
        }

        const startYear = dt1.year
        const endYear = dt2.year

        const startMonth = dt1.month
        const endMonth = dt2.month

        const startDate = getDate(dt1)
        const endDate = getDate(dt2)

        return 360 * (endYear - startYear) + 30 * (endMonth - startMonth) + (endDate - startDate + 1)
    }

    if (['month', 'months'].includes(unit)) {
        const years = dt2.year - dt1.year
        const months = dt2.month + 1 - (dt1.month + 1)

        return years * 12 + months
    }

    if (['year', 'years'].includes(unit)) {
        return dt2.year - dt1.year
    }

    if (['yearRelative', 'yearsRelative'].includes(unit)) {
        if (dt2.valueOf() < dt1.valueOf()) {
            throw new Error('Second date may not be less than first date.')
        }

        const year1 = dt1.year
        const month1 = dt1.month
        const date1 = dt1.date

        const year2 = dt2.year
        const month2 = dt2.month
        const date2 = dt2.date

        if (month2 > month1 || (month2 === month1 && date2 > date1)) {
            // already beyond month and date so include current year
            return year2 - year1
        }

        // Not yet beyond month/date so don't count current year
        return year2 - year1 - 1
    }

    throw new Error(`Unknown date unit ${unit}`)
}
