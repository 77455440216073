import getAccumulators from './getAccumulators'

export default (fields, currentFieldIndex) => {
    const project = {
        _id: 0,
    }

    const storedAccumulators = {}
    const storedValues = {}

    fields.forEach((field, index) => {
        const isAncestor = index < currentFieldIndex
        const isDirectAncestor = index + 1 === currentFieldIndex
        const isDescendant = index > currentFieldIndex

        if (isAncestor) {
            // Get value from result of previous group
            project[`value_${index}`] = `$_id.value_${index}`

            getAccumulators(field.fieldType).forEach(({ accumulator }) => {
                // If direct ancestor we take accumulators from result of previous
                // group otherwise we take from stored accumulators in the group
                project[`${accumulator}_${index}`] = isDirectAncestor
                    ? 1
                    : `$accumulators.${accumulator}_${index}`

                // Always store accumulator into next group accumulators
                storedAccumulators[`${accumulator}_${index}`] = `$${accumulator}_${index}`
            })
        }

        if (isDescendant) {
            // Upcoming fields get mapped from the previous values and will store
            // themself into the future values of the next group
            project[`value_${index}`] = `$values.value_${index}`
            project[`value_map_${index}`] = `$values.value_map_${index}`

            storedValues[`value_${index}`] = `$value_${index}`
            storedValues[`value_map_${index}`] = `$value_map_${index}`
        }
    })

    const group = {}

    if (Object.keys(storedAccumulators).length) {
        group.accumulators = { $first: storedAccumulators }
    }

    if (Object.keys(storedValues).length) {
        group.values = { $push: storedValues }
    }

    return { project, group }
}
