import getLocale, { locales } from '../locales'

const makeMatchPattern = (thousandSeparators, decimalSeparators, enforceGroupSize) => {
    const tse = thousandSeparators.map(sep => `\\${sep}`).join('')
    const dse = decimalSeparators.map(sep => `\\${sep}`).join('')
    const no = enforceGroupSize ? 3 : 1

    return new RegExp(`^\\s*([+-]?(?:(?:\\d{1,3}(?:[${tse}]\\d{${no},3})+)|\\d*))(?:[${dse}](\\d*))?\\s*$`)
}

const patterns = {}

// add our cached "match all" pattern

// first collect all potential separators
const separators = {
    thousands: [],
    decimals: [],
}

Object.values(locales).forEach(({ thousandSeparator, decimalSeparator }) => {
    if (thousandSeparator && separators.thousands.indexOf(thousandSeparator) < 0) {
        separators.thousands.push(thousandSeparator)
    }

    if (decimalSeparator && separators.decimals.indexOf(decimalSeparator) < 0) {
        separators.decimals.push(decimalSeparator)
    }
})

patterns['*'] = makeMatchPattern(separators.thousands, separators.decimals, true /*!*/)

const validNumberCharacters = ['-', '+', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9']

const matchString = (string, pattern) => {
    const result = string.match(pattern)

    if (!(result != null && result.length === 3)) {
        return 0 / 0
    }

    const integerPart = result[1]
        .split('')
        .filter(c => validNumberCharacters.indexOf(c) >= 0)
        .join('')
    const fractionPart = result[2]

    const number = parseFloat(`${integerPart}.${fractionPart}`)

    return number
}

export default (locale, string) => {
    const { thousandSeparator, decimalSeparator } = getLocale(locale)

    const patternKey = `${thousandSeparator}${decimalSeparator}`
    if (!patterns[patternKey]) {
        patterns[patternKey] = makeMatchPattern([thousandSeparator], [decimalSeparator], false /*!*/)
    }

    // First try to match our locale pattern
    let number = matchString(string, patterns[patternKey])
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(number)) {
        // Now try to match our "eat it all" pattern
        number = matchString(string, patterns['*'])
    }

    return number
}
