import Runtime from '../Runtime'

import Model from '../Model'

import Organisation from './Organisation'

class OrganisationUser extends Model {
    static ApiDescription = `
    An organisation user links an <a href="#organisations">organisation</a> and
    an <a href="#userdata>userdata</a> together.
    `

    static CollectionName = 'organisationUsers'

    static Permissions = {
        insert: {
            who: 'owner',
            scope: 'organisationUser.insert',
            fields: [
                'status',
                'invitationToken',
                'name',
                'email',
                'avatarPath',
                'bio',
                'customInvitationLink',
            ],
        },
        read: {
            who: 'owner',
            scope: 'organisationUser.read',
            fields: [
                'status',
                'name',
                'email',
                'avatarPath',
                'owner',
                'scopes',
                'attachedUserId',
                'bio',
            ],
        },
        edit: [
            {
                who: 'owner',
                scope: 'organisationUser.edit',
                fields: ['name', 'email', 'avatarPath', 'bio', 'invitationToken'],
            },
        ],
        remove: [
            {
                who: 'owner',
                scope: 'organisationUser.remove',
            },
        ],
    }

    static EditableScopes = {
        read: false,
    }

    static Fields = {
        organisation: {
            type: Organisation,
            required: true,
            serialize: 'insert',
        },
        //
        // created - the user was created but not invited yet
        // invite - the user is ready to be invited
        // invited - the user was invited but didn't accept yet
        // failed - failed sending the invite to the user
        // active - the user accepted the invitation
        // inactive - the user has opted-out of the organisation
        // blocked - the user is currently blocked from accessing the organisation
        // deleted - the user has deleted his user account
        //
        status: {
            type: String,
            enum: ['created', 'invite', 'invited', 'failed', 'active', 'inactive', 'blocked', 'deleted'],
            required: true,
            serialize: 'insert',
        },
        // Generated invitation token
        invitationToken: {
            type: String,
            serialize: 'insert',
        },
        // Customized invitation link to be used on special cases (ie Firebase Dynamic Link for invitations)
        customInvitationLink: {
            type: String,
            serialize: 'insert',
        },
        email: {
            type: String,
            format: 'email',
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        avatarPath: {
            type: String,
        },
        bio: {
            type: String,
            max: 140,
        },
        // If set can do everything. Only owners can make owners. In
        // this case, all scopes are ignored (aka full-access)
        owner: {
            type: Boolean,
            default: false,
            serialize: false,
        },
        scopes: {
            type: [String],
            serialize: false,
        },
        // The user attached to organisation user.
        // Only set for when status is active
        attachedUserId: {
            type: String,
            serialize: false,
        },
    }

    static StatusColorMap = {
        created: 'neutral',
        invite: 'info',
        invited: 'info',
        active: 'success',
        inactive: 'neutral',
        blocked: 'warning',
        deleted: 'error',
    }

    constructor(Fields, data) {
        super({ ...OrganisationUser.Fields, ...Fields }, data)
    }

    get avatarUrl() {
        return this.avatarPath ? Runtime.getStorageUrl(this.avatarPath) : null
    }
}

Model.declareModel(OrganisationUser)

export default OrganisationUser
