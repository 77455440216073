import DateTime from '../../../DateTime'

import i18n from '../../../i18n'

const getStringProjection = (valuePath, { fieldRef }) => {
    if (fieldRef.enum) {
        return {
            $cond: {
                if: {
                    $or: [
                        { $eq: [ `$${valuePath}`, null ] },
                        { $eq: [ `$${valuePath}`, '' ] },
                    ]
                },
                then: null,
                else: `$${valuePath}`
            }
        }
    }

    return { $toLower: `$${valuePath}` }
}

const getNumberProjection = (valuePath, { ranges }) => {
    if (ranges && ranges.length) {
        return {
            $switch: {
                branches: ranges.map(([from, to]) => ({
                    case: {
                        $and: [
                            {
                                $gte: [`$${valuePath}`, from],
                            },
                            {
                                $lte: [`$${valuePath}`, to],
                            },
                        ],
                    },
                    then: `${i18n().formatNumber(from)} - ${i18n().formatNumber(to)}`,
                })),
                default: null,
            },
        }
    }

    return `$${valuePath}`
}

// format = $dateToString format
const getDateProjection = (valuePath, { format = '%d-%m-%Y' }, isTime) => {
    const formatTimezoneOffset = () => {
        const tzo = -new Date().getTimezoneOffset()
        const sign = tzo >= 0 ? '+' : '-'

        const hours = Math.floor(Math.abs(tzo) / 60)
            .toFixed(0)
            .padStart(2, '0')
        const mins = (Math.abs(tzo) % 60).toFixed(0).padStart(2, '0')

        return `${sign}${hours}:${mins}`
    }

    return {
        $dateToString: {
            date: `$${valuePath}`,
            format,
            timezone: isTime ? formatTimezoneOffset() : '+00:00',
            onNull: null,
        },
    }
}

export default (valuePath, field) => {
    const { fieldType } = field

    if (fieldType === String) {
        return getStringProjection(valuePath, field)
    }

    if (fieldType === Number) {
        return getNumberProjection(valuePath, field)
    }

    if (fieldType === DateTime || fieldType === DateTime.Date || fieldType === DateTime.Time) {
        return getDateProjection(valuePath, field, fieldType === DateTime || fieldType === DateTime.Time)
    }

    // By default just use the value as-is
    return `$${valuePath}`
}
