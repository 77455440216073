import React, { useMemo, useRef, useEffect } from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'
import composeRefs from '@seznam/compose-react-refs'
import { makeStyles } from '@material-ui/core/styles'

import TextInput from './TextInput'

const useStyles = makeStyles(theme => ({
    startAdornment: ({ active }) => ({
        color: active ? theme.palette.primary.main : theme.palette.text.secondary,
    }),
    adornmentText: {
        paddingTop: 1,
    }
}))

export default React.forwardRef(
    ({ onSubmit, fullWidth, inputRef, InputProps, inputProps, active, searchPrefix, ...properties }, ref) => {
        const classes = useStyles({ active })

        const localInputRef = useRef(null)

        useEffect(() => {
            const handleSearch = evt => {
                // Support clearing search input from some browsers,
                // rest will be handled by the outer form submit handler
                if (evt.target.value === '') {
                    if (onSubmit) {
                        onSubmit()
                    }
                }
            }

            const inputReference = localInputRef.current
            inputReference.addEventListener('search', handleSearch)

            return () => {
                inputReference.removeEventListener('search', handleSearch)
            }
        }, [localInputRef, onSubmit])

        const handleSearchSubmit = useMemo(
            () => evt => {
                evt.preventDefault()

                if (onSubmit) {
                    onSubmit()
                }

                return false
            },
            [onSubmit]
        )

        // Enclose search fields with form as otherwise it will
        // not correctly be handled on mobile clients. iOS also
        // requires an action attribute to be set.
        return (
            <form action="." onSubmit={handleSearchSubmit} style={{ width: fullWidth ? '100%' : '' }}>
                <TextInput
                    {...properties}
                    active={active}
                    fullWidth={fullWidth}
                    inputRef={composeRefs(localInputRef, inputRef)}
                    InputProps={{
                        ...InputProps,
                        startAdornment: (
                            <InputAdornment
                                position="start"
                                disablePointerEvents
                                className={classes.startAdornment}
                            >
                                <SearchOutlinedIcon />
                                {!!searchPrefix && <span>&nbsp;</span>}
                                <span className={classes.adornmentText}>{searchPrefix}</span>
                            </InputAdornment>
                        ),
                    }}
                    inputProps={{ type: 'search', ...inputProps }}
                    ref={ref}
                />
            </form>
        )
    }
)
