import flatten from 'flatley'

import isInstanceOfObjectId from '../utils/objectId/isInstanceOf'

export default (doc, safe = true) => {
    const filters = [
        {
            test: (key, value) => {
                // Object ids should not be converted but kept as-is
                if (isInstanceOfObjectId(value)) {
                    return true
                }

                return false
            },
        },
    ]

    return flatten(doc, { filters, safe })
}
