import { useState, useEffect, useCallback, useMemo } from 'react'
import equal from 'fast-deep-equal'
// import baseUtils from '@newspaces/lucky-base/utils'

export default ({
    sourceValue,
    sourceInputValue,
    onInputChange,
    options,
    optionsLoading,
    // loadOptions,
    freeSolo,
}) => {
    const [inputValue, setInputValue] = useState(sourceInputValue || '')

    /*
    const handleLoadOptions = useCallback(
        baseUtils.async.debounce(optionsValue => {
            loadOptions(optionsValue, false)
        }, 500),
        [loadOptions]
    )
    */

    const handleInputChange = useCallback(
        (evt, newValue /* , reason */) => {
            setInputValue(newValue)

            // TODO : Implement calling handleLoadOptions correctly without clearing
            // the current value(), inputValue and preventing for multiple calls taking too long

            /*
            if (reason !== 'reset' && loadOptions) {
                // handleLoadOptions(newValue || null, false)
            }
            */
        },
        [
            /* loadOptions, handleLoadOptions */
        ]
    )

    const value = useMemo(() => {
        const mapOption = val => {
            const result = options.find(({ value: v }) => equal(v, val)) || null

            if (!result && val) {
                return freeSolo ? { value: val, label: val } : null
            }

            return result
        }

        if (Array.isArray(sourceValue)) {
            const valueOptions = sourceValue.map(mapOption).filter(x => x !== null)
            return valueOptions
        }

        return mapOption(sourceValue)
    }, [sourceValue, options, freeSolo])

    useEffect(() => {
        if (onInputChange || optionsLoading) {
            return
        }

        if (Array.isArray(value)) {
            setInputValue('')
        } else {
            setInputValue(value?.label || '')
        }
    }, [value, onInputChange, optionsLoading])

    return {
        value,
        inputValue: onInputChange ? sourceInputValue : inputValue,
        handleInputChange: onInputChange || handleInputChange,
    }
}
