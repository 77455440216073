import { useEffect, useState, useCallback } from 'react'

export default ({ autoSize, multiple, options, fullWidth, inputClasses, autoSizeInputRef }) => {
    const [inputWidth, setInputWidth] = useState(0)

    const calculateInputWidth = useCallback(
        theOptions => {
            const spanElement = document.createElement('span')
            spanElement.style = 'padding: 0;position:absolute;visibility:hidden'
            spanElement.className = inputClasses.input
            document.body.appendChild(spanElement)

            let longestElementWidth = 0
            theOptions.forEach(({ label, variant: optionVariant }) => {
                if (!label) {
                    return
                }

                spanElement.textContent = label
                let elementWidth = spanElement.offsetWidth

                // hacky but for variant dot we need to calculate for the dot width
                if (optionVariant === 'dot') {
                    elementWidth += 20
                }

                longestElementWidth = Math.max(longestElementWidth, elementWidth)
            })

            document.body.removeChild(spanElement)

            return longestElementWidth + 3 // give a few px tolerance
        },
        [inputClasses]
    )

    const autoSizeInput = useCallback(() => {
        const doAutoSize = autoSize && !multiple && options && !!options.length && !fullWidth
        if (doAutoSize) {
            setInputWidth(calculateInputWidth(options) || 0)
        }
    }, [autoSize, calculateInputWidth, fullWidth, multiple, options])

    useEffect(() => {
        // Try to auto-size on initial mount only
        autoSizeInput()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    autoSizeInputRef.current = autoSizeInput

    return { inputWidth }
}
