import React from 'react'
import { observer } from 'mobx-react-lite'
import { Router } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import AppModals from '../AppModals'
import AppContext from '../AppContext'

import AppGlobalStyles from './AppGlobalStyles'
import AppRoutes from './AppRoutes'

const useStyles = makeStyles(theme => ({
    '@global': AppGlobalStyles(theme),
}))

export default observer(properties => {
    useStyles()

    // We got a history so provide a router here
    return (
        <Router history={AppContext.history}>
            <AppRoutes {...properties} />
            <AppModals {...properties} />
        </Router>
    )
})
