export default (base, permissions) => {
    const permissionKeys = [...new Set(Object.keys(base).concat(Object.keys(permissions)))]

    const getRules = rules => {
        if (rules || rules === false) {
            if (Array.isArray(rules)) {
                return rules
            }

            return [rules]
        }

        return []
    }

    const mergeRules = (target, rules) =>
        rules.forEach(rule => {
            // Try to find a rule that matches the scope/who. If found we
            // merge them together, otherwise we add this rule
            const existingRule = target.find(
                r => (r.who || null) === (rule.who || null) && (r.scope || null) === (rule.scope || null)
            )

            if (existingRule) {
                // Merge fields together and be done here
                if (existingRule.fields || rule.fields) {
                    existingRule.fields = [...new Set((existingRule.fields || []).concat(rule.fields || []))]
                }

                return
            }

            // Add our rule as it has no rule to merge into
            target.push(rule)
        })

    return permissionKeys.reduce((result, permissionKey) => {
        const rules = []

        mergeRules(rules, getRules(base[permissionKey]))
        mergeRules(rules, getRules(permissions[permissionKey]))

        if (rules.length === 0) {
            return { ...result }
        }

        if (rules.length === 1) {
            return { ...result, [permissionKey]: rules[0] }
        }

        const hasConditionalRules = rules.some(rule => rule !== false)

        // eslint-disable-next-line max-len
        return { ...result, [permissionKey]: hasConditionalRules ? rules.filter(rule => rule !== false) : false }
    }, {})
}
