import React from 'react'
import { observer } from 'mobx-react-lite'
import { makeStyles, fade } from '@material-ui/core/styles'

import i18n from '../i18n'

import AppContext from '../AppContext'

import AppFrame from '../App/AppFrame'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        minHeight: 475,
        [theme.breakpoints.down('sm')]: {
            minHeight: 'auto',
        },
    },
    cover: {
        position: 'relative',
        width: 400,
        borderRadius: theme.shape.borderRadius,
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    coverImage: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center bottom',
        backgroundRepeat: 'no-repeat',
        filter: 'grayscale(100%)',
    },
    coverOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: fade(theme.palette.primary.main, 0.58),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    coverName: {
        fontSize: 20,
        fontWeight: 'bold',
        letterSpacing: 2,
        color: 'rgba(255, 255, 255, 1)',
    },
    coverTitle: {
        fontSize: 40,
        fontWeight: 'bold',
        letterSpacing: 2,
        lineHeight: '1em',
        color: 'rgba(255, 255, 255, 1)',
        textAlign: 'center',
    },
    content: {
        width: 400,
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: theme.spacing(4, 2),
        },
    },
    title: {
        fontSize: 18,
        fontWeight: 500,
        marginBottom: theme.spacing(4),
        flexGrow: 0,
        flexShrink: 0,
    },
}))

export default observer(({ mode, children }) => {
    const classes = useStyles()

    const { zone } = AppContext

    return (
        <AppFrame>
            <div className={classes.root}>
                <div className={classes.cover}>
                    <div className={classes.coverImage} style={{ backgroundImage: `url(${zone.Cover})` }} />
                    <div className={classes.coverOverlay}>
                        <div className={classes.coverName}>{zone.name}</div>
                        <div className={classes.coverTitle}>{zone.slogan}</div>
                    </div>
                </div>
                <div className={classes.content}>
                    <div className={classes.title}>{i18n().auth[mode].title({ name: zone.name })}</div>
                    {children}
                </div>
            </div>
        </AppFrame>
    )
})
