import escapeHtml from 'escape-html'

import i18n from '../../i18n'
import DateTime from '../../DateTime'

const convertMergeTags = (mergeTags, replaceModel) => {
    // Convert our incoming merge tags into something the preview can actually use
    const result = {
        currentDate: i18n().formatDate(new DateTime.Date())
    }

    // eslint-disable-next-line guard-for-in
    for (const key in mergeTags) {
        if (mergeTags[key] === null || mergeTags[key] === undefined) {
            result[key] = ''
            continue
        }

        let { model } = mergeTags[key]

        if (!model) {
            result[key] = mergeTags[key]
            continue
        }

        if (replaceModel) {
            model = replaceModel(model)
        }

        const modelMergeTags = model.mergeTags

        if (key) {
            result[key] = {}
        }

        for (const property in modelMergeTags) {
            if (key) {
                result[key][property] = modelMergeTags[property]
            } else {
                result[property] = modelMergeTags[property]
            }
        }
    }

    return result
}

export default (source, { mergeTags, replacer, doEscapeHtml = false, replaceModel } = {}) => {
    mergeTags = mergeTags ? convertMergeTags(mergeTags, replaceModel) : mergeTags

    return source.replace(/\{[^}]+\}\}/gm, match => {
        const token = match.substr(2, match.length - 4)

        const tryEscape = value => {
            if (!doEscapeHtml || !value) {
                return value
            }

            return escapeHtml(value)
        }

        if (mergeTags) {
            const parts = token.split('.')
            let mergeTag = mergeTags

            for (const part of parts) {
                mergeTag = typeof mergeTag === 'object' ? mergeTag[part] : undefined
            }

            if (typeof mergeTag === 'string') {
                return tryEscape(mergeTag)
            }
        }

        if (replacer) {
            return tryEscape(replacer(token, match))
        }

        return match
    })
}
