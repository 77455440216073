import { observable, computed, _autoAction, makeObservable } from 'mobx'

import Model from './Model'

function getPropertyDescriptor(obj, key) {
    if (!obj) return null
    return Object.getOwnPropertyDescriptor(obj, key) || getPropertyDescriptor(Object.getPrototypeOf(obj), key)
}

export default function decorateModel(modelClass) {
    // If already decorated leave early
    if (Model.decorators.has(modelClass)) {
        return modelClass
    }

    const Fields = modelClass.Fields

    if (!Fields) {
        throw new Error(`Model ${modelClass.name} has no static Fields declaration.`)
    }

    let decoratorProps = {}

    // Go up the hierachy chain, decorate parent classes first and merge their decorators
    for (
        let baseClass = Object.getPrototypeOf(modelClass);
        baseClass && baseClass !== Object && baseClass.name;
        baseClass = Object.getPrototypeOf(baseClass)
    ) {
        decorateModel(baseClass)

        decoratorProps = {
            ...Model.decorators.get(baseClass).decoratorProps,
            ...decoratorProps,
        }
    }

    // Decorate static fields as observables
    for (const fieldName in Fields) {
        if (Object.prototype.hasOwnProperty.call(Fields, fieldName)) {
            const fieldInfo = Fields[fieldName]
            const fieldType = Array.isArray(fieldInfo.type) ? fieldInfo.type[0] : fieldInfo.type
            const isComputedProperty = fieldInfo.get || false

            if (!fieldType) {
                throw new Error(`Missing type on field ${fieldName} declared in ${modelClass.name}`)
            }

            if (isComputedProperty) {
                // Make sure we also have a valid getter available for the field but this getter
                // can actually also come from the extended classes so go up the chain
                const propDesc = getPropertyDescriptor(modelClass.prototype, fieldName)
                if (!propDesc || !propDesc.get) {
                    throw new Error(`${modelClass.name} is missing a getter for field ${fieldName}`)
                }
            } else {
                // Make sure we have no property on this current class yet so we can define it later
                const propDesc = Object.getOwnPropertyDescriptor(modelClass.prototype, fieldName)
                if (propDesc) {
                    throw new Error(`${modelClass.name} already has a property for field ${fieldName}`)
                }
            }

            // The fieldType !== modelClass is important to avoid endless loop
            // when a class references itself in its properties
            if (Model.isSubModel(fieldType) && fieldType !== modelClass) {
                decorateModel(fieldType)
            }

            if (!isComputedProperty) {
                decoratorProps[fieldName] = observable
            }
        }
    }

    // Decorate dynamic getters as computed and action functions as actions
    const propertyDescriptors = Object.getOwnPropertyDescriptors(modelClass.prototype)
    if (propertyDescriptors) {
        for (const property in propertyDescriptors) {
            if (property === 'constructor') {
                continue
            }

            if (Object.prototype.hasOwnProperty.call(propertyDescriptors, property)) {
                const propertyDesc = propertyDescriptors[property]
                if (propertyDesc.get) {
                    decoratorProps[property] = computed
                } else if (typeof propertyDesc.value === 'function') {
                    decoratorProps[property] = _autoAction
                }
            }
        }
    }

    Model.decorators.set(modelClass, {
        decoratorProps,
        decorate: self => makeObservable(self, decoratorProps),
    })

    return modelClass
}
