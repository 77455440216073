import loadDataUrl from './loadDataUrl'

export default source =>
    new Promise((resolve, reject) => {
        let isObjectUrl = false

        const image = new Image()

        const returnHandler = func => {
            if (isObjectUrl) {
                URL.revokeObjectURL(image.src)
            }
            func()
        }

        image.onload = () => {
            returnHandler(() => resolve(image))
        }

        image.onerror = () => {
            returnHandler(() => reject(new Error('Invalid image')))
        }

        if (typeof source === 'string') {
            // -- assume data url
            image.src = source
        } else if (source instanceof Blob || source instanceof File) {
            if (window.URL.createObjectURL) {
                isObjectUrl = true
                image.src = URL.createObjectURL(source)
            } else {
                loadDataUrl(source)
                    .then(src => {
                        image.src = src
                    })
                    .catch(err => reject(err))
            }
        } else {
            throw new Error('Unknown image source')
        }
    })
