import quarter from './quarter'
import dayOfYear from './dayOfYear'
import diff from './diff'
import isLeapYear from './isLeapYear'
import fromDate from './fromDate'

export default class DateTime {
    static quarter = quarter

    static TIME_UNITS = [
        'millisecond',
        'milliseconds',
        'second',
        'seconds',
        'minute',
        'minutes',
        'hour',
        'hours',
    ]

    static DATE_UNITS = ['day', 'days', 'month', 'months', 'year', 'years', 'yearRelative', 'yearsRelative']

    _type = null

    _year = null

    _month = null

    _date = null

    _hours = null

    _minutes = null

    _seconds = null

    _milliseconds = null

    constructor(date, settings, type = 'datetime') {
        this._type = type
        fromDate(this, date, settings)
    }

    fromObject({
        year = 0,
        month = 0,
        date = 1,
        hours = 0,
        minutes = 0,
        seconds = 0,
        milliseconds = 0,
    } = {}) {
        if (
            typeof year !== 'number' ||
            typeof month !== 'number' ||
            typeof date !== 'number' ||
            typeof hours !== 'number' ||
            typeof minutes !== 'number' ||
            typeof seconds !== 'number' ||
            typeof milliseconds !== 'number'
        ) {
            return false
        }

        this._year = year
        this._month = month
        this._date = date
        this._hours = hours
        this._minutes = minutes
        this._seconds = seconds
        this._milliseconds = milliseconds

        return true
    }

    toDate() {
        return new Date(this.valueOf())
    }

    toDateString() {
        return this.toDate().toDateString()
    }

    toTimeString() {
        return this.toDate().toTimeString()
    }

    toString() {
        switch (this._type) {
            case 'date':
                return this.toDateString()
            case 'datetime':
                return this.toDate().toString()
            case 'time':
                return this.toTimeString()
            default:
                return 'Invalid Date'
        }
    }

    toISOString() {
        return this.toDate().toISOString()
    }

    toJSON() {
        return this.toISOString()
    }

    valueOf() {
        switch (this._type) {
            case 'date':
                return Date.UTC(this._year, this._month, this._date, 0, 0, 0, 0)
            case 'datetime':
                return Date.UTC(
                    this._year,
                    this._month,
                    this._date,
                    this._hours,
                    this._minutes,
                    this._seconds,
                    this._milliseconds
                )
            case 'time':
                return (
                    this._hours * 60 * 60 * 1000 +
                    this._minutes * 60 * 1000 +
                    this._seconds * 1000 +
                    this._milliseconds
                )
            default:
                return Number.NaN
        }
    }

    get type() {
        return this._type
    }

    get year() {
        return this._year
    }

    setYear(value, correctLastDay) {
        const date = this.toDate()

        const delta = value * 12 - date.getUTCFullYear() * 12
        if (delta !== 0) {
            this._addOrRemoveMonths(date, delta, correctLastDay)
        }

        return new this.constructor(date, { forceUTC: true })
    }

    get month() {
        return this._month
    }

    setMonth(value, correctLastDay) {
        const date = this.toDate()

        const delta = value - date.getUTCMonth()
        if (delta !== 0) {
            this._addOrRemoveMonths(date, delta, correctLastDay)
        }

        return new this.constructor(date, { forceUTC: true })
    }

    get date() {
        return this._date
    }

    setDate(value) {
        const date = this.toDate()
        date.setUTCDate(value)
        return new this.constructor(date, { forceUTC: true })
    }

    get hours() {
        return this._hours
    }

    setHours(value) {
        const date = this.toDate()
        date.setUTCHours(value)
        return new this.constructor(date, { forceUTC: true })
    }

    get minutes() {
        return this._minutes
    }

    setMinutes(value) {
        const date = this.toDate()
        date.setUTCMinutes(value)
        return new this.constructor(date, { forceUTC: true })
    }

    get seconds() {
        return this._seconds
    }

    setSeconds(value) {
        const date = this.toDate()
        date.setUTCSeconds(value)
        return new this.constructor(date, { forceUTC: true })
    }

    get milliseconds() {
        return this._milliseconds
    }

    setMilliseconds(value) {
        const date = this.toDate()
        date.setUTCMilliseconds(value)
        return new this.constructor(date, { forceUTC: true })
    }

    get quarter() {
        return quarter(this._month)
    }

    get dayOfYear() {
        return dayOfYear(this._year, this._month, this._date, false)
    }

    get dayOfYear366() {
        return dayOfYear(this._year, this._month, this._date, true)
    }

    get totalDaysInMonth() {
        return new Date(Date.UTC(this._year, this._month + 1, 0)).getUTCDate()
    }

    get isLeapYear() {
        return isLeapYear(this._year)
    }

    get isFirstMonthOfYear() {
        return this._month === 0
    }

    get isLastMonthOfYear() {
        return this._month === 11
    }

    get isFirstMonthOfQuarter() {
        return quarter.beginingMonths.includes(this._month)
    }

    get isLastMonthOfQuarter() {
        return quarter.endingMonths.includes(this._month)
    }

    get lastMonthOfQuarter() {
        return quarter.endingMonths[this.quarter - 1]
    }

    get isFirstDayOfMonth() {
        return this._date === 1
    }

    get isLastDayOfMonth() {
        return this._date === this.totalDaysInMonth
    }

    diff(otherDate, unit, absolute = false) {
        const { isDateOnly } = this._verifyUnit(unit)

        // Use reasonable default
        if (!unit) {
            unit = isDateOnly ? 'day' : 'milliseconds'
        }

        const DateClass = this.constructor
        const delta = diff(new DateClass(new DateTime(otherDate), { forceUTC: true }), this, unit)

        return absolute ? Math.abs(delta) : delta
    }

    isSame(otherDate, unit) {
        return this.diff(otherDate, unit) === 0
    }

    isBefore(otherDate, unit) {
        return this.diff(otherDate, unit) < 0
    }

    isBeforeOrSame(otherDate, unit) {
        return this.diff(otherDate, unit) <= 0
    }

    isAfter(otherDate, unit) {
        return this.diff(otherDate, unit) > 0
    }

    isAfterOrSame(otherDate, unit) {
        return this.diff(otherDate, unit) >= 0
    }

    add(value, unit, correctLastDay = false) {
        if (!unit) {
            throw new Error('Missing unit for add.')
        }

        if (value < 0) {
            throw new Error('Can only add positive values.')
        }

        return this._addOrRemove(value, unit, correctLastDay)
    }

    subtract(value, unit, correctLastDay = false) {
        if (!unit) {
            throw new Error('Missing unit for subtract.')
        }

        if (value < 0) {
            throw new Error('Can only subtract positive values.')
        }

        return this._addOrRemove(-value, unit, correctLastDay)
    }

    _addOrRemove(value, unit, correctLastDay) {
        this._verifyUnit(unit)

        const date = this.toDate()

        switch (unit) {
            case 'millisecond':
            case 'milliseconds':
                date.setUTCMilliseconds(date.getUTCMilliseconds() + value)
                break
            case 'second':
            case 'seconds':
                date.setUTCSeconds(date.getUTCSeconds() + value)
                break
            case 'minute':
            case 'minutes':
                date.setUTCMinutes(date.getUTCMinutes() + value)
                break
            case 'hour':
            case 'hours':
                date.setUTCHours(date.getUTCHours() + value)
                break
            case 'day':
            case 'days':
                date.setUTCDate(date.getUTCDate() + value)
                break
            case 'month':
            case 'months':
                this._addOrRemoveMonths(date, value, correctLastDay)
                break
            case 'year':
            case 'years':
                this._addOrRemoveMonths(date, value * 12, correctLastDay)
                break
            default:
                break
        }

        return new this.constructor(date, { forceUTC: true })
    }

    _addOrRemoveMonths(date, monthsToAddOrRemove, correctLastDay) {
        if (correctLastDay) {
            // If our current day is bigger than the maximum days of the target
            // date then we correct this and set the last day of month of the target
            const newMonth = date.getUTCMonth() + monthsToAddOrRemove
            const maxDaysInDate = new Date(
                Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 1, 0)
            ).getUTCDate()
            const maxDaysInTarget = new Date(Date.UTC(this._year, newMonth + 1, 0)).getUTCDate()

            // If correcting date never let float beyond target month's maximum days
            let utcDate = Math.min(maxDaysInTarget, this._date)

            if (correctLastDay === 'last') {
                if (this.isLastDayOfMonth) {
                    // We're on the last day of our month so set the same to the target
                    utcDate = maxDaysInTarget
                }
            }

            // If new date is less than the max days in date set it first,
            // otherwise set it afterwards to avoid months shifting due last day
            if (utcDate <= maxDaysInDate) {
                date.setUTCDate(utcDate)
            }

            date.setUTCMonth(date.getUTCMonth() + monthsToAddOrRemove)

            if (utcDate > maxDaysInDate) {
                date.setUTCDate(utcDate)
            }
        } else {
            date.setUTCMonth(date.getUTCMonth() + monthsToAddOrRemove)
        }
    }

    _verifyUnit(unit, otherDate) {
        const isDateOnly =
            this._type === 'date' || (otherDate instanceof DateTime && otherDate.type === 'date')
        const isTimeOnly =
            this._type === 'time' || (otherDate instanceof DateTime && otherDate.type === 'time')

        if (DateTime.TIME_UNITS.includes(unit)) {
            if (isDateOnly) {
                throw new Error(`Unit ${unit} not possible for date only types.`)
            }
        } else if (DateTime.DATE_UNITS.includes(unit)) {
            if (isTimeOnly) {
                throw new Error(`Unit ${unit} not possible for time only types.`)
            }
        }

        return { isDateOnly, isTimeOnly }
    }
}
