// 95% copied from https://github.com/rhysd/remark-emoji
import visit from 'unist-util-visit'
import emoji from 'node-emoji'
import emoticon from 'emoticon'

const RE_EMOJI = /:\+1:|:-1:|:[\w-]+:/g
// contains short emoji at the end of the text
const RE_SHORT_EMOJI_AT_THE_END = /[$@|*'",;.=:\-)([\]\\/<>038BOopPsSdDxXzZ]{2,5}$/g
// contains short emoji at the middle of the text and ends with whitespace, "?" or "!"
const RE_SHORT_EMOJI_AT_THE_MIDDLE = /[$@|*'",;.=:\-)([\]\\/<>038BOopPsSdDxXzZ]{2,5}[\s/?/!]/g
// contains short emoji at the middle of the text and ends with dot
const RE_SHORT_EMOJI_AT_THE_MIDDLE_DOT = /[$@|*'",;.=:\-)([\]\\/<>038BOopPsSdDxXzZ]{2,5}[/.]/g
const RE_UNDERSTORE = /_/g

const DEFAULT_SETTINGS = {
    padSpaceAfter: false,
    emoticon: false,
}

export default options => {
    const settings = { ...DEFAULT_SETTINGS, ...options}
    const pad = !!settings.padSpaceAfter
    const emoticonEnable = !!settings.emoticon

    function getEmojiByShortCode(match, allowPartialMatch) {
        // find emoji by shortcode - full match or with-out last char as it could be from text e.g. :-),
        const iconFull = emoticon.find(e => e.emoticons.includes(match)) // full match
        const iconPart = allowPartialMatch && 
            emoticon.find(e => e.emoticons.includes(match.slice(0, -1))) // second search pattern

        if (!iconFull && !iconPart) {
            return match
        }

        const trimmedChar = iconPart ? match.slice(-1) : ''
        const addPad = pad ? ' ' : ''
        const icon = iconFull ? iconFull.emoji + addPad : iconPart && iconPart.emoji + addPad + trimmedChar
        return icon || match
    }

    function getEmoji(match) {
        let got = emoji.get(match)

        // Workaround for #19. :man-*: and :woman-*: are now :*_man: and :*_woman: on GitHub. node-emoji
        // does not support the new short codes. Convert new to old.
        // eslint-disable-next-line max-len
        // TODO: Remove this workaround when this PR is merged and shipped: https://github.com/omnidan/node-emoji/pull/112
        if (match.endsWith('_man:') && got === match) {
            // :foo_bar_man: -> man-foo-bar
            const old = `man-${  match.slice(1, -5).replace(RE_UNDERSTORE, '-')}`
            const s = emoji.get(old)
            if (s !== old) {
                got = s
            }
        } else if (match.endsWith('_woman:') && got === match) {
            // :foo_bar_woman: -> woman-foo-bar
            const old = `woman-${  match.slice(1, -7).replace(RE_UNDERSTORE, '-')}`
            const s = emoji.get(old)
            if (s !== old) {
                got = s
            }
        }

        if (pad && got !== match) {
            return `${got  } `
        }
        return got
    }

    function transformer(tree) {
        visit(tree, 'text', (node) => {
            node.value = node.value.replace(RE_EMOJI, getEmoji)
            if (emoticonEnable) {
                node.value = node.value.replace(
                    RE_SHORT_EMOJI_AT_THE_END,
                    (match) => getEmojiByShortCode(match, false)
                )
                node.value = node.value.replace(
                    RE_SHORT_EMOJI_AT_THE_MIDDLE,
                    (match) => getEmojiByShortCode(match, true)
                )
                node.value = node.value.replace(
                    RE_SHORT_EMOJI_AT_THE_MIDDLE_DOT,
                    (match) => getEmojiByShortCode(match, true)
                )
            }
        })
    }

    return transformer
}