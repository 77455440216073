const Accumulators = [
    {
        accumulator: 'count',
        available: () => true,
        create: () => ({ $count: {} }),
    },
    {
        accumulator: 'sum',
        available: fieldType => fieldType === Number,
        create: fieldPath => ({ $sum: `$${fieldPath}` }),
    },
    {
        accumulator: 'avg',
        available: fieldType => fieldType === Number,
        create: fieldPath => ({ $avg: `$${fieldPath}` }),
    },
    {
        accumulator: 'min',
        available: fieldType => fieldType === Number,
        create: fieldPath => ({ $min: `$${fieldPath}` }),
    },
    {
        accumulator: 'max',
        available: fieldType => fieldType === Number,
        create: fieldPath => ({ $max: `$${fieldPath}` }),
    },
]

export default fieldType => {
    return Accumulators.filter(({ available }) => available(fieldType))
}
