const EVERY_FOUR_CHARS = /(.{4})(?!$)/g

export default (locale, string, format) => {
    if (['iban', 'iban-mask'].includes(format)) {
        // Replace everything with XX expect first and last four chars if masked
        let iban = string

        if (format === 'iban-mask') {
            iban = iban
                .split('')
                .map((char, charIndex) => (charIndex > 3 && charIndex + 4 < iban.length ? 'X' : char))
                .join('')
        }

        return iban
            .split(EVERY_FOUR_CHARS)
            .filter(x => !!x)
            .join(' ')
    }

    return string
}
