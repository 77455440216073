import loadVideo from './loadVideo'

export default async source => {
    const { video } = await loadVideo(source)

    return {
        width: video.videoWidth,
        height: video.videoHeight,
        duration: video.duration,
    }
}
