/* eslint-disable max-len  */
/* eslint-disable no-template-curly-in-string */

export default {
    language: 'Sprache',
    languageName: 'Deutsch',
    months: [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember',
    ],
    monthsShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
    weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
    weekdaysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    weekdaysMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    relativeTime: {
        yesterday: 'Gestern',
        today: 'Heute',
        tomorrow: 'Morgen',
        daysAgo: 'Vor ${this.days} Tagen',
        daysIn: 'In ${this.days} Tagen',
    },
    year: {
        single: 'Jahr',
        multi: 'Jahre',
    },
    month: {
        single: 'Monat',
        multi: 'Monate',
    },
    week: {
        single: 'Woche',
        multi: 'Wochen',
    },
    day: {
        single: 'Tag',
        multi: 'Tage',
    },
    hour: {
        single: 'Stunde',
        multi: 'Stunden',
    },
    minute: {
        single: 'Minute',
        multi: 'Minuten',
    },
    second: {
        single: 'Sekunde',
        multi: 'Sekunden',
    },
    milliseconds: {
        single: 'Millisekunde',
        multi: 'Millisekunden',
    },
    misc: {
        yesNo: {
            yes: 'Ja',
            no: 'Nein',
        },
        errors: {
            unknown: 'Ein unbekannter Fehler ist aufgetreten, bitte kontaktiere den Support.',
        },
        noCloseAlert: {
            title: 'Fenster nicht schließen!',
            message:
                'Bitte das Browser-Fenster oder den Browser-Tab nicht schließen bis dieser Vorgang abgeschlossen ist da dieser sonst abgebrochen wird.',
        },
        close: 'Schließen',
        saveChanges: 'Änderungen speichern',
        collapseAll: 'Alles zuklappen',
        expandAll: 'Alles aufklappen',
        create: 'Erstellen',
        edit: 'Bearbeiten',
        duplicate: 'Duplizieren',
        remove: 'Entfernen',
        delete: 'Löschen',
        ok: 'Ok',
        cancel: 'Abbrechen',
        save: 'Speichern',
        view: 'Ansehen',
        send: 'Versenden',
        choose: 'Auswählen',
        download: 'Herunterladen',
        showMore: 'Mehr anzeigen',
        showLess: 'Weniger anzeigen',
        createdAtBy: 'Erstellt am ${this.date} von ${this.name}',
        updatedAtBy: 'Bearbeitet am ${this.date} von ${this.name}',
        unknown: 'Unbekannt',
        missingField: '${this.field} fehlt!',
        today: 'Heute',
        for: 'für',
        or: 'oder',
        back: 'Zurück',
        next: 'Weiter',
        moveUp: 'Nach oben',
        moveDown: 'Nach unten',
        settings: 'Einstellungen',
        exportToExcel: 'Export nach Excel...',
        clearSelection: 'Auswahl entfernen',
        maxFileSizeMB: 'Die Datei darf nicht größer als ${this.megabyte} MB sein.',
        search: 'Suchen',
        makeFavorite: 'Als Favorit setzen',
        clearFavorite: 'Als Favorit entfernen',
        noColor: 'Keine Farbe',
    },
    resetPassword: {
        subject: 'Passwort zurücksetzen',
        text: 'Folgen Sie diesem Link um ein neues Passwort zu setzen: ${this.resetPasswordLink}',
    },
    sendInvitation: {
        subject: 'Du wurdest zu ${this.orgName} eingeladen',
        text:
            'Hallo ${this.name},\n\nDu wurdest eingeladen, ${this.orgName} beizutreten.\n\nKlicke den folgenden Link um deine Einladung anzunehmen:\n${this.invitationLink}',
    },
    emailUnsubscription: {
        title: 'Keine E-Mails mehr erhalten',
        text: 'Du hast dich erfolgreich abgemeldet und erhältst nun keine E-Mails mehr',
    },
    organisation: {
        modelName: {
            single: 'Organisation',
            multi: 'Organisationen',
        },
        avatar: 'Profilbild',
        primaryColor: 'Hauptfarbe',
        primaryTextColor: 'Textfarbe',
        name: 'Name',
        street: 'Straße + Hausnummer',
        zip: 'Postleitzahl',
        city: 'Stadt',
        country: 'Land',
        customDomain: {
            status: 'Status',
            statusValue: {
                pending: 'Anstehend',
                active: 'Aktiv',
                failed: 'Fehler',
            },
        },
    },
    organisationUser: {
        modelName: {
            single: 'Mitglied',
            multi: 'Mitglieder',
        },
        name: 'Name',
        email: 'E-Mail Adresse',
        status: 'Status',
        statusValue: {
            created: 'Angelegt',
            invite: 'Wird eingeladen',
            invited: 'Einladung versandt',
            failed: 'Fehlgeschlagen',
            active: 'Aktiv',
            inactive: 'Verlassen',
            blocked: 'Geblockt',
            deleted: 'Benutzerkonto gelöscht',
        },
    },
}
