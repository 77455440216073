import { useState, useEffect, useMemo } from 'react'

const mapOptions = options =>
    (options || []).map(option => {
        if (typeof option === 'string') {
            return { value: option, label: option }
        }
        return option
    })

export default ({ sourceOptions, autoSizeInputRef }) => {
    const [optionsLoading, setOptionsLoading] = useState(null)
    const [options, setOptions] = useState(Array.isArray(sourceOptions) ? mapOptions(sourceOptions) : [])

    const loadOptions = useMemo(() => {
        if (typeof sourceOptions !== 'function') {
            return null
        }

        return async (inputValue, isInitial) => {
            setOptionsLoading(isInitial ? 'initial' : 'input')
            setOptions([])

            const loadedOptions = mapOptions(await sourceOptions({ inputValue, isInitial }))

            // Do not change the order here as other use effects are affected
            if (isInitial) {
                setOptionsLoading(null)
                setOptions(loadedOptions)
                autoSizeInputRef.current(loadedOptions)
            } else {
                setOptions(loadedOptions)
                setOptionsLoading(null)
            }
        }
    }, [sourceOptions, autoSizeInputRef])

    useEffect(() => {
        if (loadOptions) {
            loadOptions(null, true)
        } else {
            setOptions(Array.isArray(sourceOptions) ? mapOptions(sourceOptions) : [])
        }
    }, [sourceOptions, loadOptions])

    return { optionsLoading, options, loadOptions }
}
