import utils from '../utils'

import userHasScope from './userHasScope'

export default class RuntimeBase {
    _db = null

    environment = null

    values = {}

    BSON = null

    customDomain = null

    constructor({ bson } = {}) {
        this.BSON = bson
    }

    // eslint-disable-next-line class-methods-use-this
    get userId() {
        throw new Error('Not implemented.')
    }

    // eslint-disable-next-line class-methods-use-this
    get user() {
        throw new Error('Not implemented.')
    }

    // eslint-disable-next-line class-methods-use-this
    get userApiKeys() {
        throw new Error('Not implemented.')
    }

    // eslint-disable-next-line class-methods-use-this
    get customData() {
        throw new Error('Not implemented.')
    }

    get db() {
        return this._db
    }

    // eslint-disable-next-line class-methods-use-this
    call(/* functionName, ...args */) {
        throw new Error('Not implemented.')
    }

    getWebhookUrl(webhookName, query, useZoneDomain = false /* zone */) {
        if (useZoneDomain) {
            if (!this.zone || !this.zone.webhookUrl) {
                throw new Error('No zone available or zone has no webhookUrl property.')
            }

            const queryString = utils.url.queryAsString(query, true)

            const prefix = this.values.DOMAIN_PREFIX

            let prefixString = ''
            if (prefix) {
                prefixString = `${prefix}.`
            }
            return `https://${prefixString}${this.zone.webhookUrl}/${webhookName}${queryString}`
        }

        const queryString = utils.url.queryAsString(query, true)

        const webhookPrefix = this.values.WEBHOOK_REGION ? `${this.values.WEBHOOK_REGION}.aws.` : ''

        return `https://${webhookPrefix}data.mongodb-api.com/app/${this.values.APP_ID}/endpoint/${webhookName}${queryString}`
    }

    getStorageUrl(path, action = 'get', useZoneDomain = false) {
        return this.getWebhookUrl('storageUrl', { action, path }, useZoneDomain)
    }

    getApiUrl(path, { zone: zoneParam } = {}) {
        const zone = (zoneParam || this.zone)
        const domainPrefix = this.values.DOMAIN_PREFIX ? `${this.values.DOMAIN_PREFIX}.` : ''
        return `https://${domainPrefix}${zone.apiUrl}${path || ''}`
    }

    getAppUrl(path, { organisation, zone, appKey } = {}) {
        let result = []

        const getOrganisationCustomDomain = () => {
            const customDomain = organisation?.customDomain
            if (customDomain && customDomain.hostname && customDomain.status === 'active') {
                return customDomain.hostname
            }

            return null
        }

        const organisationCustomDomain = getOrganisationCustomDomain()

        const ignoreCurrentCustomDomain = !!organisation
        const customDomainUrl = organisationCustomDomain || (!ignoreCurrentCustomDomain && this.customDomain)
        const appUrl = customDomainUrl || (zone || this.zone).appUrl
        const parts = appUrl.split('.')
        const subdomainKeys = utils.url.extractSubdomainKeys(appUrl)

        if (subdomainKeys.length > 1) {
            let appPrefix = subdomainKeys.pop()

            if (subdomainKeys.includes('appkey') && appKey) {
                appPrefix = appKey
            }

            // removes [appkey|xxx] from parts
            parts.shift()

            result = [appPrefix]
        }

        if (!customDomainUrl) {
            result.push(this.values.DOMAIN_PREFIX)
        }

        result = [...result, ...parts].filter(x => !!x)

        return `https://${result.join('.')}${path || ''}`
    }

    get isAppSubdomain() {
        return utils.url.extractSubdomainKeys(this.zone.appUrl).includes('appkey')
    }

    getUserScopes(organisationId, organisationUser) {
        if (organisationUser) {
            // if an organisation user was provided, then normalize and consider those scopes instead
            const { owner = false, scopes = [] } = organisationUser

            const scopesObj = {
                $owner: owner,
            }

            scopes.forEach(scope => {
                const [key, type] = scope.split('.')

                if (!scopesObj[key]) {
                    scopesObj[key] = {}
                }

                scopesObj[key][type] = true
            })

            return scopesObj
        }

        if (!this.user) {
            // -- no user, no scopes --
            return {}
        }

        if (!organisationId) {
            // -- global scopes --
            return this.customData.scopes || {}
        }

        // -- scopes for this organisation --
        const orgData = this._getOrganisationData(organisationId)
        if (!orgData) {
            // -- no org, no scopes --
            return {}
        }

        const { id, owner, ...scopes } = orgData
        return { ...scopes, $owner: owner }
    }

    // eslint-disable-next-line class-methods-use-this
    _getOrganisationData(/** orgId */) {
        throw new Error('Not implemented.')
    }

    userHasAllScopes(organisationId, organisationUser, ...scopes) {
        const userScopes =
            typeof organisationId === 'object'
                ? organisationId
                : this.getUserScopes(organisationId, organisationUser)

        for (const scope of scopes) {
            if (!userHasScope(userScopes, scope)) {
                return false
            }
        }
        return true
    }

    userHasAnyScopes(organisationId, organisationUser, ...scopes) {
        const userScopes =
            typeof organisationId === 'object'
                ? organisationId
                : this.getUserScopes(organisationId, organisationUser)

        for (const scope of scopes) {
            if (userHasScope(userScopes, scope)) {
                return true
            }
        }
        return false
    }

    // eslint-disable-next-line class-methods-use-this
    async uploadFile(/* path, contentType, content, { filename, ...properties } = {} */) {
        throw new Error('Not implemented.')
    }

    // eslint-disable-next-line class-methods-use-this
    async downloadFile(/* path */) {
        throw new Error('Not implemented.')
    }

    // eslint-disable-next-line class-methods-use-this
    async deleteFile(/* path */) {
        throw new Error('Not implemented.')
    }

    // eslint-disable-next-line class-methods-use-this
    configure(...args) {
        this._readConfiguration(...args)

        this._validateConfiguration()

        if (process.env.NODE_ENV === 'test') {
            if (this.values.DB_NAME !== 'test') {
                throw new Error('You can only test against test databases.')
            }
        }

        this._initFromConfiguration(...args)
    }

    // eslint-disable-next-line class-methods-use-this
    _getRequiredConfigurationValues() {
        const result = ['APP_ID', 'DB_NAME', 'AWS_S3_BUCKET_NAME', 'AWS_S3_REGION', 'AWS_SES_REGION']

        if (process.env.NODE_ENV === 'test') {
            result.push('AWS_S3_ACCESS_KEY_ID', 'AWS_S3_SECRET_ACCESS_KEY')

            // TODO Make generic way to write the secret values to the env
            this.values.AWS_S3_SECRET_ACCESS_KEY = this.values.AWS_S3_SECRET_ACCESS_KEY_TEST_VALUE
        } else {
            result.push('DOMAIN_PREFIX')
        }

        return result
    }

    _validateConfiguration() {
        const requiredValues = this._getRequiredConfigurationValues()

        for (const rqValue of requiredValues) {
            if (this.values[rqValue] === undefined) {
                throw new Error(`Missing ${rqValue} configuration value.`)
            }
        }
    }

    // eslint-disable-next-line class-methods-use-this
    _readConfiguration(/* ...args */) {
        throw new Error('_readConfiguration not implemented.')
    }

    // eslint-disable-next-line class-methods-use-this
    _initFromConfiguration(/* ...args */) {
        throw new Error('_initFromConfiguration not implemented.')
    }

    // eslint-disable-next-line class-methods-use-this
    _convertMethodName(functionName) {
        return functionName
            .split('.')
            .map((part, index) => {
                const uppercasedPart = part.substr(0, 1).toUpperCase() + part.substr(1)
                if (index === 0) {
                    return `method${uppercasedPart}`
                }
                return uppercasedPart
            })
            .join('')
    }
}
