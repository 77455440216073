import React, { useCallback, useMemo } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import Button from '@newspaces/lucky-components/Button'

import AppContext from '../AppContext'

import i18n from '../i18n'

const useStyles = makeStyles(() => ({
    paperWidthFalse: ({ maxWidth }) => ({
        maxWidth,
        width: maxWidth ? '100%' : 'unset',
    }),
}))

export default observer(({ modal }) => {
    const {
        title,
        text,
        Component,
        maxWidth,
        padding = true,
        actions = [],
        primaryAction,
        deleteAction,
        fullScreen,
        onClose,
        dialogProps,
        ...componentProperties
    } = modal

    const classes = useStyles({ maxWidth })
    const theme = useTheme()

    const isfullScreen = fullScreen || useMediaQuery(theme.breakpoints.down('sm'))

    const dialogStore = useLocalObservable(() => ({
        workingActionIndex: -1,
    }))

    const handleClose = useCallback(
        arg => {
            // don't allow closing while working
            if (dialogStore.workingActionIndex >= 0) {
                return
            }

            if (onClose) {
                if (onClose(arg) === false) {
                    // allow preventing closing
                    return
                }
            }

            AppContext.clearModal(modal)
        },
        [modal, dialogStore, onClose]
    )

    let dialogContent = (
        <>
            {text && <DialogContentText>{text}</DialogContentText>}
            {Component && <Component {...componentProperties} onClose={handleClose} />}
        </>
    )

    if (padding) {
        dialogContent = <DialogContent>{dialogContent}</DialogContent>
    }

    const ActionComponents = useMemo(
        () =>
            (actions || []).map((action, actionIndex) =>
                observer(() => {
                    if (action.visible === false) {
                        return null
                    }

                    let label
                    let color = 'default'
                    let disabled = false
                    let onClick

                    if (primaryAction >= 0 && primaryAction === actionIndex) {
                        color = 'primary'
                    }

                    if (deleteAction >= 0 && deleteAction === actionIndex) {
                        color = 'danger'
                    }

                    if (typeof action === 'string') {
                        label = i18n().misc[action]
                        onClick = () => handleClose(action)
                    } else {
                        label = action.label
                        disabled = action.disabled
                        onClick = async () => {
                            dialogStore.workingActionIndex = actionIndex

                            const settings = { preventClose: false }
                            const func = action.execute ? action.execute : action.action
                            const result = await func(componentProperties, settings)
                            dialogStore.workingActionIndex = -1

                            if (!settings.preventClose) {
                                handleClose(result)
                            }
                        }
                    }

                    return (
                        <Button
                            onClick={onClick}
                            color={color}
                            loading={dialogStore.workingActionIndex === actionIndex}
                            disabled={disabled || dialogStore.workingActionIndex >= 0}
                        >
                            {label}
                        </Button>
                    )
                })
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [actions, handleClose]
    )

    const dialogMaxWidth = typeof maxWidth === 'number' ? false : dialogProps?.maxWidth

    return (
        <Dialog
            {...dialogProps}
            open
            classes={{
                ...classes,
                ...dialogProps?.classes,
            }}
            fullScreen={isfullScreen}
            maxWidth={dialogMaxWidth}
            onClose={handleClose}
        >
            {title && <DialogTitle>{title}</DialogTitle>}
            {dialogContent}
            {ActionComponents.length > 0 && (
                <DialogActions>
                    {ActionComponents.filter(Boolean).map((ActionComponent, index) => (
                        <ActionComponent key={index} />
                    ))}
                </DialogActions>
            )}
        </Dialog>
    )
})
