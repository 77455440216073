import DateTime from './DateTime'
import Date from './Date'
import Time from './Time'

DateTime.Date = Date
DateTime.Time = Time

// eslint-disable-next-line import/no-mutable-exports
let DateTimeInstance = DateTime

if (process.env.RUNTIME_SERVER === 'true') {
    if (global.DateTimeInstance) {
        DateTimeInstance = global.DateTimeInstance
    } else {
        global.DateTimeInstance = DateTimeInstance
    }
}

export default DateTimeInstance
