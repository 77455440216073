// !! TODO : REWRITE THIS COMPLETELY INCLUDING PROPER LOCALE SUPPORT and things like "Tomorrow 2am" !!!

import DateTime from '../../DateTime'

const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
const abbreviatedDayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
const shortestDayNames = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]
const abbreviatedMonthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
]

const regexDayNames = new RegExp(dayNames.join('|'), 'i')
const regexAbbreviatedDayNames = new RegExp(abbreviatedDayNames.join('|'), 'i')
const regexShortestDayNames = new RegExp(`\\b(${shortestDayNames.join('|')})\\b`, 'i')
const regexMonthNames = new RegExp(monthNames.join('|'), 'i')
const regexAbbreviatedMonthNames = new RegExp(abbreviatedMonthNames.join('|'), 'i')

const regexFirstSecondThirdFourth = /(\d+)(st|nd|rd|th)\b/i
const regexEndian = /(\d{1,4})([/.-])(\d{1,2})[/.-](\d{1,4})/

const regexTimezoneOneZ = /((\+|-)\d\d:\d\d)$/
const regexTimezoneTwoZ = /((\+|-)\d\d\d\d)$/

const amOrPm = `(${['AM?', 'PM?'].join('|')})`
const regexHoursWithLeadingZeroDigitMinutesSecondsAmPm = new RegExp(
    `0\\d\\:\\d{1,2}\\:\\d{1,2}(\\s*)${amOrPm}`,
    'i'
)
const regexHoursWithLeadingZeroDigitMinutesAmPm = new RegExp(`0\\d\\:\\d{1,2}(\\s*)${amOrPm}`, 'i')
const regexHoursWithLeadingZeroDigitAmPm = new RegExp(`0\\d(\\s*)${amOrPm}`, 'i')
const regexHoursMinutesSecondsAmPm = new RegExp(`\\d{1,2}\\:\\d{1,2}\\:\\d{1,2}(\\s*)${amOrPm}`, 'i')
const regexHoursMinutesAmPm = new RegExp(`\\d{1,2}\\:\\d{1,2}(\\s*)${amOrPm}`, 'i')
const regexHoursAmPm = new RegExp(`\\d{1,2}(\\s*)${amOrPm}`, 'i')

const regexISO8601HoursWithLeadingZeroMinutesSecondsMilliseconds = /\d{2}:\d{2}:\d{2}\.\d{3}/
const regexISO8601HoursWithLeadingZeroMinutesSecondsCentiSeconds = /\d{2}:\d{2}:\d{2}\.\d{2}/
const regexISO8601HoursWithLeadingZeroMinutesSecondsDeciSeconds = /\d{2}:\d{2}:\d{2}\.\d{1}/
const regexHoursWithLeadingZeroMinutesSeconds = /0\d:\d{2}:\d{2}/
const regexHoursWithLeadingZeroMinutes = /0\d:\d{2}/
const regexHoursMinutesSeconds = /\d{1,2}:\d{2}:\d{2}/
const regexHoursMinutesSecondsMilliseconds = /\d{1,2}:\d{2}:\d{2}\.\d{3}/
const regexHoursMinutesSecondsCentiSeconds = /\d{1,2}:\d{2}:\d{2}\.\d{2}/
const regexHoursMinutesSecondsDeciSeconds = /\d{1,2}:\d{2}:\d{2}\.\d{1}/
const regexHoursMinutes = /\d{1,2}:\d{2}/
const regexYearLong = /\d{4}/
const regexDayLeadingZero = /0\d/
const regexDay = /\d{1,2}/
const regexYearShort = /\d{2}/

const regexDayShortMonthShort = /^([1-9])\/([1-9]|0[1-9])$/
const regexDayShortMonth = /^([1-9])\/(1[012])$/
const regexDayMonthShort = /^(0[1-9]|[12][0-9]|3[01])\/([1-9])$/
const regexDayMonth = /^(0[1-9]|[12][0-9]|3[01])\/(1[012]|0[1-9])$/

const regexMonthShortYearShort = /^([1-9])\/([1-9][0-9])$/
const regexMonthYearShort = /^(0[1-9]|1[012])\/([1-9][0-9])$/

const formatIncludesMonth = /([/][M]|[M][/]|[MM]|[MMMM])/

const regexFillingWords = /\b(at)\b/i

const regexUnixMillisecondTimestamp = /\d{13}/
const regexUnixTimestamp = /\d{10}/

const regexOnlyDaySpecialCase = /^\d{1,2}$/
const regexDayMonthSpecialCase = /^(\d{1,2})([/.-])(\d{1,2})$/

// option defaults
const defaultOrder = {
    '/': 'MDY',
    '.': 'DMY',
    '-': 'YMD',
}

// if we can't find an endian based on the separator, but
// there still is a short date with day, month & year,
// we try to make a smart decision to identify the order
function replaceEndian(options, matchedPart, first, separator, second, third) {
    const hasSingleDigit = Math.min(first.length, second.length, third.length) === 1
    const hasQuadDigit = Math.max(first.length, second.length, third.length) === 4
    let preferredOrder =
        typeof options.preferredOrder === 'string'
            ? options.preferredOrder
            : options.preferredOrder[separator]

    first = parseInt(first, 10)
    second = parseInt(second, 10)
    third = parseInt(third, 10)
    const parts = [first, second, third]
    preferredOrder = preferredOrder.toUpperCase()

    // If first is a year, order will always be Year-Month-Day
    if (first > 31) {
        parts[0] = hasQuadDigit ? 'YYYY' : 'YY'
        parts[1] = hasSingleDigit ? 'M' : 'MM'
        parts[2] = hasSingleDigit ? 'D' : 'DD'
        return parts.join(separator)
    }

    // Second will never be the year. And if it is a day,
    // the order will always be Month-Day-Year
    if (second > 12) {
        parts[0] = hasSingleDigit ? 'M' : 'MM'
        parts[1] = hasSingleDigit ? 'D' : 'DD'
        parts[2] = hasQuadDigit ? 'YYYY' : 'YY'
        return parts.join(separator)
    }

    // if third is a year ...
    if (third > 31) {
        parts[2] = hasQuadDigit ? 'YYYY' : 'YY'

        // ... try to find day in first and second.
        // If found, the remaining part is the month.
        if (preferredOrder[0] === 'M' && first < 13) {
            parts[0] = hasSingleDigit ? 'M' : 'MM'
            parts[1] = hasSingleDigit ? 'D' : 'DD'
            return parts.join(separator)
        }
        parts[0] = hasSingleDigit ? 'D' : 'DD'
        parts[1] = hasSingleDigit ? 'M' : 'MM'
        return parts.join(separator)
    }

    // if we had no luck until here, we use the preferred order
    parts[preferredOrder.indexOf('D')] = hasSingleDigit ? 'D' : 'DD'
    parts[preferredOrder.indexOf('M')] = hasSingleDigit ? 'M' : 'MM'
    parts[preferredOrder.indexOf('Y')] = hasQuadDigit ? 'YYYY' : 'YY'

    return parts.join(separator)
}

function parseFormat(dateString, options) {
    let format = dateString.toString()

    if (format.match(regexOnlyDaySpecialCase)) {
        // if only day is found, use regular format
        return 'DD.MM.YYYY'
    }
    if (format.match(regexDayMonthSpecialCase)) {
        let separatorFound
        Object.keys(defaultOrder).some(separator => {
            if (format.indexOf(separator) > 0) {
                separatorFound = separator
                return true
            }
            return false
        })
        if (separatorFound) {
            let order = defaultOrder[separatorFound]
            if (order.startsWith('Y')) {
                // even if the default order starts with year, since we're using DD.MM or MM.DD format here
                // we will enforce regular format then
                order = options.locale === 'de' ? 'DMY' : 'MDY'
            }
            return order
                .split('')
                .map((unit, idx) => {
                    let part = ''
                    if (unit === 'Y') {
                        part += 'YYYY'
                    } else {
                        part += `${unit}${unit}`
                    }
                    if (idx < 2) {
                        part += separatorFound
                    }
                    return part
                })
                .join('')
        }
    }

    // default options
    options = options || {}
    options.preferredOrder = options.preferredOrder || defaultOrder

    // Unix Millisecond Timestamp ☛ x
    format = format.replace(regexUnixMillisecondTimestamp, 'x')
    // Unix Timestamp ☛ X
    format = format.replace(regexUnixTimestamp, 'X')

    // escape filling words
    format = format.replace(regexFillingWords, '[$1]')

    //  DAYS

    // Monday ☛ dddd
    format = format.replace(regexDayNames, 'dddd')
    // Mon ☛ ddd
    format = format.replace(regexAbbreviatedDayNames, 'ddd')
    // Mo ☛ dd
    format = format.replace(regexShortestDayNames, 'dd')

    // 1st, 2nd, 23rd ☛ do
    format = format.replace(regexFirstSecondThirdFourth, 'Do')

    // MONTHS

    // January ☛ MMMM
    format = format.replace(regexMonthNames, 'MMMM')
    // Jan ☛ MMM
    format = format.replace(regexAbbreviatedMonthNames, 'MMM')

    // replace endians, like 8/20/2010, 20.8.2010 or 2010-8-20
    format = format.replace(regexEndian, replaceEndian.bind(null, options))

    // TIME

    // timezone +02:00 ☛ Z, timezone +0200 ☛ ZZ
    format = regexTimezoneOneZ.test(format)
        ? format.replace(regexTimezoneOneZ, 'Z')
        : format.replace(regexTimezoneTwoZ, 'ZZ')
    // 23:39:43.331 ☛ 'HH:mm:ss.SSS'
    format = format.replace(regexISO8601HoursWithLeadingZeroMinutesSecondsMilliseconds, 'HH:mm:ss.SSS')
    // 23:39:43.33 ☛ 'HH:mm:ss.SS'
    format = format.replace(regexISO8601HoursWithLeadingZeroMinutesSecondsCentiSeconds, 'HH:mm:ss.SS')
    // 23:39:43.3 ☛ 'HH:mm:ss.S'
    format = format.replace(regexISO8601HoursWithLeadingZeroMinutesSecondsDeciSeconds, 'HH:mm:ss.S')
    function replaceWithAmPm(timeFormat) {
        return function(match, whitespace, amPm) {
            return timeFormat + whitespace + (amPm[0].toUpperCase() === amPm[0] ? 'A' : 'a')
        }
    }
    // 05:30:20pm ☛ hh:mm:ssa
    format = format.replace(regexHoursWithLeadingZeroDigitMinutesSecondsAmPm, replaceWithAmPm('hh:mm:ss'))
    // 10:30:20pm ☛ h:mm:ssa
    format = format.replace(regexHoursMinutesSecondsAmPm, replaceWithAmPm('h:mm:ss'))
    // 05:30pm ☛ hh:mma
    format = format.replace(regexHoursWithLeadingZeroDigitMinutesAmPm, replaceWithAmPm('hh:mm'))
    // 10:30pm ☛ h:mma
    format = format.replace(regexHoursMinutesAmPm, replaceWithAmPm('h:mm'))
    // 05pm ☛ hha
    format = format.replace(regexHoursWithLeadingZeroDigitAmPm, replaceWithAmPm('hh'))
    // 10pm ☛ ha
    format = format.replace(regexHoursAmPm, replaceWithAmPm('h'))
    // 05:30:20 ☛ HH:mm:ss
    format = format.replace(regexHoursWithLeadingZeroMinutesSeconds, 'HH:mm:ss')
    // 5:30:20.222 ☛ H:mm:ss.SSS
    format = format.replace(regexHoursMinutesSecondsMilliseconds, 'H:mm:ss.SSS')
    // 5:30:20.22 ☛ H:mm:ss.SS
    format = format.replace(regexHoursMinutesSecondsCentiSeconds, 'H:mm:ss.SS')
    // 5:30:20.2 ☛ H:mm:ss.S
    format = format.replace(regexHoursMinutesSecondsDeciSeconds, 'H:mm:ss.S')
    // 10:30:20 ☛ H:mm:ss
    format = format.replace(regexHoursMinutesSeconds, 'H:mm:ss')
    // 05:30 ☛ H:mm
    format = format.replace(regexHoursWithLeadingZeroMinutes, 'HH:mm')
    // 10:30 ☛ HH:mm
    format = format.replace(regexHoursMinutes, 'H:mm')

    // do we still have numbers left?

    // Lets check for 4 digits first, these are years for sure
    format = format.replace(regexYearLong, 'YYYY')

    // check if both numbers are < 13, then it must be D/M
    format = format.replace(regexDayShortMonthShort, 'D/M')

    // check if first number is < 10 && last < 13, then it must be D/MM
    format = format.replace(regexDayShortMonth, 'D/MM')

    // check if last number is < 32 && last < 10, then it must be DD/M
    format = format.replace(regexDayMonthShort, 'DD/M')

    // check if both numbers are > 10, but first < 32 && last < 13, then it must be DD/MM
    format = format.replace(regexDayMonth, 'DD/MM')

    // check if first < 10 && last > 12, then it must be M/YY
    format = format.replace(regexMonthShortYearShort, 'M/YY')

    // check if first < 13 && last > 12, then it must be MM/YY
    format = format.replace(regexMonthYearShort, 'MM/YY')

    // to prevent 9.20 gets formated to D.Y, we format the complete date first, then go for the time
    if (format.match(formatIncludesMonth)) {
        const regexHoursDotWithLeadingZeroOrDoubleDigitMinutes = /0\d.\d{2}|\d{2}.\d{2}/
        const regexHoursDotMinutes = /\d{1}.\d{2}/

        format = format.replace(regexHoursDotWithLeadingZeroOrDoubleDigitMinutes, 'H.mm')
        format = format.replace(regexHoursDotMinutes, 'h.mm')
    }

    // now, the next number, if existing, must be a day
    format = format.replace(regexDayLeadingZero, 'DD')
    format = format.replace(regexDay, 'D')

    // last but not least, there could still be a year left
    format = format.replace(regexYearShort, 'YY')

    if (format.length < 1) {
        format = undefined
    }

    return format
}

const parseTime = (value, format) => {
    // D => Assume full 24-hours
    // ha => Full hour am/pm
    if (format === 'D' || format === 'ha') {
        let hours = parseInt(value, 10)

        if (Number.isNaN(hours)) {
            return null
        }

        if (format === 'ha') {
            // convert into 24-hours
            if (value.trim().endsWith('pm')) {
                hours += 12
            }
        }

        return { hours, minutes: 0 }
    }

    // h:mma => Hours + minutes am/pm
    // h:mm => Hours + minutes 24-hours
    // H:mm => Hours + minutes 24-hours
    if (format === 'h:mma' || format === 'h:mm' || format === 'H:mm' || format === 'HH:mm') {
        const values = value.split(':')
        let hours = parseInt(values[0], 10)
        const minutes = parseInt(values[1], 10)

        if (Number.isNaN(hours) || Number.isNaN(minutes)) {
            return null
        }

        if (format === 'h:mma') {
            // convert into 24-hours
            if (value.trim().endsWith('pm')) {
                hours += 12
            }
        }

        return { hours, minutes }
    }

    return null
}

export default (locale, value, { defaultYear, type = 'date' } = {}) => {
    const now = new DateTime().toDate()

    let format = parseFormat(value, { locale })

    // If there's no default year take current one
    // TODO : Improve if year >= 00 && year <= current_century_max then use current
    // century otherwise last one!!
    if (!defaultYear) {
        defaultYear = now.getFullYear()
    }

    let year = null
    let month = null
    let day = null

    if (!format || !value) {
        return null
    }

    let time = null

    // Extract and parse any time parts first
    if (format.indexOf(' ') > 0 && value.indexOf(' ') > 0) {
        // Extract time parts first
        const formatSplit = format.split(' ')
        const valueSplit = value.split(' ')
        format = formatSplit[0] // date parts
        value = valueSplit[0]

        if (type === 'datetime') {
            time = parseTime(valueSplit[1], formatSplit[1])
        }
    } else {
        time = parseTime(value, format)
    }

    if (type === 'time') {
        if (!time) {
            return null
        }

        return new DateTime.Time(time)
    }

    let formatTokens = []
    let valueTokens = []

    // Check which separator has been used
    // For the sake of sanity, lets not mix them
    if (format.includes('.')) {
        formatTokens = format.split('.')
        valueTokens = value.split('.')
    } else if (format.includes('/')) {
        formatTokens = format.split('/')
        valueTokens = value.split('/')
    } else if (format.includes('-')) {
        formatTokens = format.split('-')
        valueTokens = value.split('-')
    }

    // Assume we mean D -> month i.e. on 1.12 as Day Year makes no sense
    if (formatTokens.length === 2 && formatTokens[0] === 'D' && formatTokens[1] === 'YY') {
        formatTokens[1] = 'M'
    }

    // check if we have valid values for each token found otherwise add them before moving on
    formatTokens.forEach((token, idx) => {
        if (token.includes('M') && !valueTokens[idx] && valueTokens[idx] !== 0) {
            valueTokens[idx] = now.getMonth() + 1
        }
        if (token.includes('Y') && !valueTokens[idx]) {
            valueTokens[idx] = `${defaultYear}` // add as string for correct check further on
        }
    })

    // A date has three values, obviously, but we can take the current
    // default year in case we only have day and month
    if (formatTokens.length < 2 || valueTokens.length < 2 || formatTokens.length !== valueTokens.length) {
        return null
    }

    // Check if we have a valid day
    if (!formatTokens.find(token => token.includes('D'))) {
        return null
    }
    day = valueTokens[formatTokens.findIndex(token => token.includes('D'))]

    // Check if we have a valid month or number in case no year was discovered which is ok
    if (!formatTokens.find(token => token.includes('M')) && Number.isNaN(parseInt(formatTokens[1], 10))) {
        return null
    }
    month = valueTokens[formatTokens.findIndex(token => token.includes('M'))]

    // If no year is given then we fake one using the default year
    if (formatTokens.length < 3 && valueTokens.length < 3) {
        year = defaultYear
    } else if (valueTokens[2].length !== 2 && valueTokens[2].length !== 4) {
        return null
    } else {
        year = valueTokens[2]

        if (year.length === 2) {
            // If year has only two values, check current year and define
            // whether or not use last century or this century
            const thisYear = now.getFullYear().toString()
            const thisYearPrefix = parseInt(thisYear.substr(0, 2), 10)
            const thisYearSuffix = parseInt(thisYear.substr(2, 2), 10)
            year = year > thisYearSuffix + 10 ? `${thisYearPrefix - 1}${year}` : `${thisYearPrefix}${year}`
        }
    }

    // DO NOT allow 0 values on any of the fields, it's stupid
    let yearInt = parseInt(year, 10)
    let monthInt = parseInt(month, 10)
    let dayInt = parseInt(day, 10)

    if (!yearInt || yearInt < 0) {
        yearInt = now.getFullYear()
    }

    if (!monthInt || monthInt < 0 || monthInt > 12) {
        monthInt = 1
    }

    if (!dayInt || dayInt < 0 || dayInt > 31) {
        dayInt = 1
    }

    if (type === 'date') {
        return new DateTime.Date({ year: yearInt, month: monthInt - 1, date: dayInt })
    }

    return new DateTime(
        new Date(
            yearInt,
            monthInt - 1,
            dayInt,
            time ? time.hours : now.getUTCHours(),
            time ? time.minutes : now.getUTCMinutes(),
            time ? 0 : now.getUTCSeconds(),
            0
        )
    )
}
