export default (value, isRequired) => {
    if (isRequired && value !== true && value !== false) {
        return 'required'
    }

    if (value !== null && value !== undefined && value !== true && value !== false) {
        return 'invalidType'
    }

    return null
}
