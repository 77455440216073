import Model from '../../Model'

import DateTime from '../../DateTime'

export default class OrganisationSubscriptions extends Model {
    static Fields = {
        // The customer's id at stripe if any
        customerId: {
            type: String,
            serialize: false,
        },
        /**
         * The main subscription: {
         *      subscriptionId,
         *      status = stripe-status,
         *      productId,
         *      priceId,
         *      service (if any),
         *      trialEnd (if on trial)
         *      metadata (if any)
         * }
         */
        subscription: {
            type: Object,
            serialize: false,
        },
        /**
         * A mapping of { [service]: {
         *      subscriptionId,
         *      status = stripe-status,
         *      productId,
         *      priceId,
         *      service (if any),
         *      trialEnd (if on trial)
         *      metadata (if any)
         * }
         */
        serviceSubscriptions: {
            type: Object,
            serialize: false,
        },
        subscriptionEndsAt: {
            type: DateTime.Date,
            serialize: false,
        },
        subscribed: {
            type: Boolean,
            serialize: false,
        },
    }

    constructor(data) {
        super(OrganisationSubscriptions.Fields, data)
    }
}
