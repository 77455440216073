/* eslint-disable no-console */
import axios from 'axios'
import * as Realm from 'realm-web'
import * as mobx from 'mobx'
import * as Sentry from '@sentry/browser'

import i18n from '../i18n'

import baseUtils from '../utils'

import DateTime from '../DateTime'

import RuntimeBase from './Runtime.base'

export default class RuntimeBrowser extends RuntimeBase {
    client = null

    aws = null

    zone = null

    mobx = mobx

    userData = null

    // null => regular subdomain like lucky.luckyshot.io
    // app => app key is the subdomain like forms.luckyshot.io
    currentSubdomainType = null

    constructor() {
        super({ bson: Realm.BSON })
        this.environment = 'browser'
    }

    get hasGuestSession() {
        return (
            this.client.currentUser &&
            this.client.currentUser.isLoggedIn &&
            this.client.currentUser.providerType === 'anon-user'
        )
    }

    get hasSession() {
        return this.user !== null
    }

    get userId() {
        if (this.user) {
            return this.user.id
        }

        return null
    }

    get userApiKeys() {
        if (!this.user) {
            return null
        }

        const apiKeyClient = this.client.currentUser.apiKeys

        return {
            list() {
                return apiKeyClient.fetchAll()
            },
            get(keyId) {
                return apiKeyClient.fetch(keyId)
            },
            create(keyName) {
                return apiKeyClient.create(keyName)
            },
            delete(keyId) {
                return apiKeyClient.delete(keyId)
            },
            enable(keyId) {
                return apiKeyClient.enable(keyId)
            },
            disable(keyId) {
                return apiKeyClient.disable(keyId)
            },
        }
    }

    get user() {
        if (!this.client.currentUser) {
            return null
        }

        // Don't return a user for a guest session
        if (this.client.currentUser.isLoggedIn && this.client.currentUser.providerType === 'anon-user') {
            return null
        }

        return this.client.currentUser || null
    }

    get customData() {
        return this.user.customData
    }

    async refreshUserData(waitForRefresh = false) {
        const initialCustomDataString = JSON.stringify(this.customData)

        const maxTries = waitForRefresh ? 10 : 1

        for (let i = 0; i < maxTries; i += 1) {
            // eslint-disable-next-line
            await new Promise(resolve => setTimeout(resolve, 1000))

            // eslint-disable-next-line
            await this.client.currentUser.refreshCustomData()

            const updatedCustomData = JSON.stringify(this.customData)

            if (updatedCustomData !== initialCustomDataString) {
                return true
            }
        }

        return false
    }

    async maybeInitKnownUserData({ cache = false } = {}) {
        if (!this.userData || this.userData.id !== this.userId) {
            if (!this.hasSession) {
                return null
            }

            const forceLogout = async () => {
                try {
                    await this.logout()
                } catch (e) {
                    console.error('Error forcing logout', e)
                }
                // -- enforce reload
                window.location.href = `/auth/login?returnTo=${encodeURIComponent(window.location.href)}`
            }

            const checkAndRefreshUserData = async userToBeChecked => {
                const cachedValue = window.localStorage.getItem(`UserUpdatedAt:${this.userId}`) || null
                const cachedUpdatedAt = cachedValue ? new DateTime(cachedValue) : null
                const userUpdatedAt = userToBeChecked.updatedAt || userToBeChecked.createdAt

                const userWasUpdated = !cachedUpdatedAt || cachedUpdatedAt.isBefore(userUpdatedAt)

                if (userWasUpdated) {
                    try {
                        await this.refreshUserData()
                        window.localStorage.setItem(`UserUpdatedAt:${this.userId}`, userUpdatedAt.toISOString() || null)
                    } catch (e) {
                        // eslint-disable-next-line no-console
                        console.warn('error refreshing user data', e)
                    }
                }
            }

            let userData = new this.UserData()
            let userLoaded = false
            try {
                userLoaded = await userData.load({ userId: this.userId }, {
                    cache,
                    onCacheUpdated: checkAndRefreshUserData,
                })
            } catch (e) {
                console.error(`Unable to load user-data for user-id ${this.userId}`, e)
                return forceLogout()
            }

            if (!userLoaded) {
                // it may be that the userData was not created yet
                // try to create a new one before throwing
                const newUserData = await this.createUserData()
                if (!newUserData) {
                    console.error(`Unable to create user-data for user-id ${this.userId}`)
                    return forceLogout()
                }

                userData = newUserData
            }

            await checkAndRefreshUserData(userData)

            this.userData = userData
        }

        return this.userData
    }

    async maybeInitGuestUserData() {
        if (!this.userData) {
            if (this.hasGuestSession) {
                this.userData = new this.UserData({
                    isNewGuestSession: false,
                })
            } else if (!this.hasSession) {
                await this.loginWithGuest()

                this.userData = new this.UserData({
                    isNewGuestSession: true,
                })
            }
        }

        return this.userData
    }

    async initUserData() {
        const userData = await this.maybeInitKnownUserData()
        if (userData) {
            return userData
        }

        return this.maybeInitGuestUserData()
    }

    async call(functionName, ...args) {
        try {
            if (!this.client.currentUser) {
                if (typeof window !== 'undefined') {
                    window.location.href = `/auth/login?returnTo=${encodeURIComponent(window.location.href)}`
                    return null
                }
                throw new Error('Current user not defined, function call aborted.')
            }

            const result = await this.client.currentUser.functions[this._convertMethodName(functionName)](
                ...args
            )
            return result
        } catch (e) {
            if (e.errorCode === 'FunctionExecutionError') {
                let errorObj = null

                try {
                    errorObj = JSON.parse(e.error)
                } catch (jsonError) {
                    throw e
                }

                if (errorObj && errorObj.message) {
                    const errorToThrow = new Error(errorObj.message)
                    errorToThrow.code = errorObj.code
                    throw errorToThrow
                }
            }

            throw e
        }
    }

    getLibraryScriptCode(library, initCode) {
        const libPath = this.getAppUrl('/lib')

        const code = []

        code.push(
            `var w=window;d=document;w.lucky=w.lucky||{zone:'${this.zone.key}',libs:{}};ls=['common','${library}'];`
        )
        code.push(
            `for (var l of ls){if(!w.lucky.libs[l]){w.lucky.libs[l]=true;d.write('<scr'+'ipt src="${libPath}/'+l+'.js"></sc'+'ript>')}}`
        )

        if (initCode) {
            code.push(...initCode.split('\n'))
        }

        return `<script type="text/javascript">\n${code.map(s => `  ${s}`).join('\n')}\n</script>`
    }

    async uploadFile(path, contentType, content, { filename, ...properties } = {}) {
        let body = content
        let metadata = {}

        if (properties) {
            for (const propKey in properties) {
                if (Object.prototype.hasOwnProperty.call(properties, propKey)) {
                    if (/^([a-z_]+)$/.test(propKey) === false) {
                        throw new Error(
                            `Only lowercase letters and underscores allowed for file properties but found "${propKey}".`
                        )
                    }

                    if (typeof properties[propKey] !== 'string') {
                        throw new Error(
                            `Only string values for file properties allowed but found "${propKey}=${properties[propKey]}".`
                        )
                    }

                    metadata[propKey] = properties[propKey]
                }
            }
        }

        if (content instanceof Blob) {
            body = await new Promise(resolve => {
                const fileReader = new FileReader()
                fileReader.onload = evt =>
                    resolve(new Realm.BSON.Binary(new Uint8Array(evt.target.result), 0))
                fileReader.readAsArrayBuffer(content)
            })
            metadata = { ...metadata, __binary: 'yes' }
        } else if (content instanceof ArrayBuffer) {
            body = new Realm.BSON.Binary(new Uint8Array(content), 0)
            metadata = { ...metadata, __binary: 'yes' }
        }

        const args = {
            contentType,
            path,
            body,
            metadata,
        }

        if (filename) {
            args.contentDisposition = `inline; filename="${filename}"`
        }

        return this.call('aws.callService', 'uploadFile', args)
    }

    async downloadFile(path, returnBlob = true) {
        const args = {
            path,
        }

        const result = await this.call('aws.callService', 'downloadFile', args)

        if (result && result.Body && result.ContentType && result.LastModified) {
            const properties = {}
            let __binary = false

            if (result.Metadata) {
                for (const metaKey in result.Metadata) {
                    if (Object.prototype.hasOwnProperty.call(result.Metadata, metaKey)) {
                        const value = result.Metadata[metaKey]

                        if (metaKey === '__binary') {
                            __binary = true
                        } else {
                            properties[metaKey.toLowerCase()] = value
                        }
                    }
                }
            }

            if (result.ContentDisposition) {
                properties.ContentDisposition = result.ContentDisposition
            }

            const content = new Realm.BSON.Binary(result.Body.buffer, result.Body.sub_type).value(__binary)

            if (returnBlob) {
                return new Blob([content], { type: result.ContentType })
            }

            return {
                type: result.ContentType,
                content,
                properties,
                modified: new DateTime(result.LastModified).toDate(),
            }
        }

        return null
    }

    async deleteFile(path) {
        const args = {
            path,
        }

        const result = await this.call('aws.callService', 'deleteFile', args)

        return !!result
    }

    // -- we always assume running in a CRA-Context for browser
    _readConfiguration({ envPrefix = 'REACT_APP_' } = {}) {
        // Fill values from environment variables
        for (const envVarKey in process.env) {
            if (Object.prototype.hasOwnProperty.call(process.env, envVarKey)) {
                const realKeyName =
                    envPrefix && envVarKey.indexOf(envPrefix) >= 0
                        ? envVarKey.substr(envPrefix.length)
                        : envVarKey

                this.values[realKeyName] = process.env[envVarKey]
            }
        }
    }

    _getRequiredConfigurationValues() {
        const result = super._getRequiredConfigurationValues()

        if (process.env.NODE_ENV === 'production') {
            // Enforce valid Sentry setup for production builds
            result.push('SENTRY_DSN', 'SENTRY_RELEASE')
        }

        return result
    }

    _initFromConfiguration() {
        // Setup Sentry now on production builds.
        if (process.env.NODE_ENV === 'production') {
            Sentry.init({
                dsn: this.values.SENTRY_DSN,
                release: this.values.SENTRY_RELEASE,
                maxValueLength: 1000,
            })

            if (this.zone) {
                Sentry.configureScope(scope => {
                    scope.setTag('zone', this.zone.name)
                })
            }
        }

        this.client = Realm.App.getApp(this.values.APP_ID)
        this._initDb()
        this._createApiRequest()
    }

    apiMethod(url, data, { controller = null, throwAbortError = false } = {}) {
        if (controller === true) {
            // eslint-disable-next-line no-undef
            controller = new AbortController()
        }

        if (controller?.signal.aborted) {
            if (throwAbortError) {
                // eslint-disable-next-line no-undef
                return Promise.reject(new DOMException('Aborted', 'AbortError'))
            }

            // Just leave here never returning a promise
            return null
        }

        const axiosArgs = {}

        if (controller) {
            axiosArgs.signal = controller.signal
        }

        const promise = new Promise((resolve, reject) => {
            let [method, urlPath] = url.split(':')

            if (!urlPath) {
                method = 'post'
                urlPath = url
            }

            let responsePromise

            if (['post', 'put', 'patch'].includes(method)) {
                responsePromise = this.apiRequest[method](
                    urlPath,
                    Realm.BSON.EJSON.serialize(data),
                    axiosArgs
                )
            } else {
                const queryArgs = data ? baseUtils.url.queryAsString(data, true) : ''
                responsePromise = this.apiRequest[method](`${urlPath}${queryArgs}`, axiosArgs)
            }

            responsePromise
                .then(response => {
                    return resolve(Realm.BSON.EJSON.deserialize(response.data))
                })
                .catch(errorCatch => {
                    // Test if this is an abort exception from axios
                    if (errorCatch.constructor?.name === 'Cancel' || errorCatch.name === 'AbortError') {
                        if (throwAbortError) {
                            return reject(errorCatch)
                        }

                        // silently ignore otherwise
                        return null
                    }

                    const responseData = errorCatch.response && errorCatch.response.data

                    if (responseData) {
                        const { error, ...errorData } = responseData

                        if (error === true) {
                            const customError = new Error()
                            Object.entries(errorData).forEach(([key, value]) => {
                                customError[key] = value
                            })

                            return reject(customError)
                        }

                        if (typeof error === 'string') {
                            return reject(new Error(error))
                        }
                    }

                    return reject(errorCatch)
                })
        })

        if (controller) {
            promise.abort = () => controller.abort()
        }

        return promise
    }

    _createApiRequest() {
        const isTokenExpired = token => {
            const [, rawData] = token.split('.')
            const data = JSON.parse(Buffer.from(rawData, 'base64'))
            const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000)
            // Check if the current time is greater than (expiration - 30s)
            // get 30 seconds of tolerance to avoid problems
            return currentTimeInSeconds > (data.exp - 30)
        }

        // create axios api instance
        const apiRequest = axios.create({
            baseURL: this.getApiUrl(),
            // we can't use timeout here, because the Chrome only process
            // 6 requests at same time to the same domain, when this limit
            // is reached the requests become stalled and the stalled status
            // is considered to timeout the request
            headers: {
                'Accept-Type': 'ejson',
            },
        })

        // create a interceptor that before each request will check the token,
        // renew it if needed and apply it to axios instance
        apiRequest.interceptors.request.use(async config => {
            const currentUser = this.client?.currentUser
            let authorizationToken = currentUser?.accessToken

            if (authorizationToken) {
                // if token is expired refresh the user data to create a new token
                if (isTokenExpired(authorizationToken)) {
                    console.log('token expired. refreshing custom token...')
                    const refreshResult = await this.client.currentUser.refreshCustomData()
                    console.log('refresh result:', refreshResult)
                    authorizationToken = this.client.currentUser.accessToken
                }
                config.headers.Authorization = authorizationToken
            } else if (currentUser) {
                console.log('token not found. refreshing token...')
                const refreshResult = await this.client.currentUser.refreshCustomData()
                console.log('refresh result:', refreshResult)
                config.headers.Authorization = this.client.currentUser.accessToken
            } else {
                console.log('currentUser not defined')
            }

            return config
        })

        this.apiRequest = apiRequest
    }

    _initDb() {
        if (this.client.currentUser) {
            this._db = this.client.currentUser.mongoClient('database').db(this.values.DB_NAME)
        }
    }

    async loginWithGuest() {
        await this.logout()
        await this.client.logIn(Realm.Credentials.anonymous())
        this._initDb()
    }

    // eslint-disable-next-line class-methods-use-this
    async createUserData() {
        const userEmail = this.user?.profile?.email

        if (!userEmail) {
            return null
        }

        const newUserData = new this.UserData({
            userId: this.userId,
            language: i18n().language,
            email: userEmail,
            name: userEmail.substr(0, userEmail.indexOf('@')),
            zones: [this.zone.key],
        })

        // serialize userData
        const userDataDocument = newUserData.serialize({
            mode: 'save',
            addTestUUID: process.env.NODE_ENV === 'test',
        })

        // remove _id
        delete userDataDocument._id

        let saved = false
        try {
            saved =
                (await this.call('user.createUserData', userDataDocument)) &&
                (await this.refreshUserData()) &&
                (await newUserData.load({ userId: this.userId }))
        } catch (e) {
            saved = false
        }

        return saved ? newUserData : null
    }

    async loginWithEMail(email, password, invitationToken) {
        await this.logout()

        try {
            email = email ? email.toLowerCase() : email

            if (this.zone.verifyEmail) {
                // if email is invalid it throws
                await this.verifyEmail(email, invitationToken)
            }

            const credential = Realm.Credentials.emailPassword(email, password)
            await this.client.logIn(credential)
            this._initDb()

            const relatedZones = this.zone.relatedZones || []

            if (this.user?.customData?.zones && !this.user.customData.zones.includes(this.zone.key)) {
                const hasRelatedZone = this.user.customData.zones.some(userZone => {
                    return relatedZones.includes(userZone)
                })

                if (!hasRelatedZone) {
                    await this.logout()
                    return 'invalidZone'
                }
            }

            return null
        } catch (e) {
            switch (e.error) {
                case 'invalid username':
                    return 'invalidEmail'
                case 'invalid password':
                    return 'invalidPassword'
                case 'invalid username/password':
                    return 'invalidCredentials'
                default:
                    // eslint-disable-next-line no-console
                    console.error('Unknown loginWithEMail error', e)
                    return 'unknownError'
            }
        }
    }

    async checkSignUpEmail(email) {
        await this.call('mailgun.verifyEmail', email)
    }

    async signUpWithEMail(email, password, passwordRepeat, invitationToken) {
        if (this.hasSession) {
            return 'alreadyLoggedIn'
        }

        if (password && passwordRepeat && password !== passwordRepeat) {
            return 'passwordMissmatch'
        }

        try {
            email = email ? email.toLowerCase() : email

            if (this.zone.verifyEmail) {
                // if email is invalid it throws
                await this.verifyEmail(email, invitationToken)
            }

            if (this.zone.checkSignupMail && process.env.NODE_ENV !== 'test') {
                await this.checkSignUpEmail(email)
            }

            await this.client.emailPasswordAuth.registerUser({ email, password })
            return this.loginWithEMail(email, password)
        } catch (e) {
            const error = e.error || e.message
            switch (error) {
                case 'email invalid':
                case 'invalidEmail':
                    return 'invalidEmail'
                case 'password must be between 6 and 128 characters':
                    return 'invalidPassword'
                case 'name already in use':
                    return 'alreadySignedUp'
                default:
                    // eslint-disable-next-line no-console
                    console.error('Unknown signUpWithEMail error', e)
                    return 'unknownError'
            }
        }
    }

    async verifyEmail(email, invitationToken) {
        return new Promise((resolve, reject) => {
            // eslint-disable-next-line no-undef
            const xhr = new XMLHttpRequest()

            xhr.open(
                'GET',
                this.getWebhookUrl('verifyEmail', {
                    email,
                    invitationToken,
                    zone: this.zone.key,
                })
            )

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        resolve()
                    } else {
                        reject(new Error('permission denied'))
                    }
                }
            }

            xhr.onerror = reject

            xhr.send()
        })
    }

    async sendResetPasswordEmail(email) {
        try {
            email = email ? email.toLowerCase() : email
            await this.client.logIn(Realm.Credentials.anonymous())

            const resetPasswordLink = this.getAppUrl('?resetPasswordToken=$resetPasswordToken')

            await this.call(
                'user.requestResetPasswordToken',
                email,
                this.zone.mailerFromAddress,
                i18n().resetPassword.subject,
                `${i18n().resetPassword.text({
                    email,
                    resetPasswordLink,
                })}`
            )

            await this.logout()

            return { success: true, error: '' }
        } catch (e) {
            await this.logout()

            let error = ''

            if (!e && !e.error && !e.message) {
                // eslint-disable-next-line no-console
                console.warn('invalid error object', e)
                error = 'unknownError'
            } else {
                let message = e.error || e.message

                // Can be a stringified json for some reason. Realm is weird
                if (typeof message === 'string' && message.startsWith('{')) {
                    message = JSON.parse(e.error).message
                }

                switch (message) {
                    case 'email not registered':
                        error = 'invalidEmail'
                        break
                    default:
                        // eslint-disable-next-line no-console
                        console.error('Unknown resetPasswordEmail error', e)
                        error = 'unknownError'
                }
            }

            return { success: false, error }
        }
    }

    async resetPassword(password, passwordConfirm, email, resetPasswordToken) {
        try {
            email = email ? email.toLowerCase() : email
            if (password !== passwordConfirm) throw new Error('password mismatch')

            await this.client.emailPasswordAuth.callResetPasswordFunction(email, password, resetPasswordToken)

            return { success: true, error: '' }
        } catch (e) {
            let error = ''

            const errorMessage = e.error || e.message
            switch (errorMessage) {
                case 'password mismatch':
                    error = 'passwordMissmatch'
                    break
                case 'password must be between 6 and 128 characters':
                    error = 'invalidPassword'
                    break
                case 'invalid token':
                    error = 'invalidToken'
                    break
                case 'token expired':
                    error = 'expiredToken'
                    break
                case 'user not found':
                    error = 'invalidEmail'
                    break

                default:
                    // eslint-disable-next-line no-console
                    console.error('Unknown resetPasswordEmail error', e)
                    error = 'unknownError'
            }

            return { success: false, error }
        }
    }

    async _performSendEmail(args) {
        return this.call('aws.callService', 'sendEmail', args)
    }

    async sendEMail(receivers, subject, text, html, attachments) {
        const ToAddresses = Array.isArray(receivers) ? receivers : [receivers]

        const Body = {}
        if (text) {
            Body.Text = {
                Charset: 'UTF-8',
                Data: `${text}\n\n--\n${this.zone.website}\n${this.zone.vendorImprint}`,
            }
        } else {
            Body.Html = {
                Charset: 'UTF-8',
                Data: html,
            }
        }

        const args = {
            Destination: {
                ToAddresses,
            },
            Message: {
                Body,
                Subject: {
                    Charset: 'UTF-8',
                    Data: subject,
                },
            },
            Source: this.zone.mailerFromAddress,
        }

        if (attachments?.length) {
            args.Attachments = await Promise.all(
                attachments.map(async (file, index) => ({
                    type: file.type,
                    filename: file.name || `attachment${index}`,
                    data: await baseUtils.media.fileToBase64(file),
                }))
            )
        }

        return this._performSendEmail(args)
    }

    async logout() {
        if (this.client.currentUser) {
            await this.client.currentUser.logOut()
        }
        return true
    }

    hasFeature(feature) {
        if (!this.zone || !this.zone.features) {
            // if no zone, simply allow the feature
            return true
        }

        if (this.zone.features[feature] !== undefined) {
            return !!this.zone.features[feature]
        }

        return true
    }

    // shouldnt be called from the outside since this returns raw data from realm
    // UserData.getOrganisationData should be used for that purpose
    _getOrganisationData(orgId) {
        if (!this.user) {
            return null
        }

        return (this.customData.organisations || []).find(organisation => {
            // IMPORTANT!!! since we're acessing the customData directly
            // we need to read the $oid field itself rather than only the id.
            return organisation.id && organisation.id.$oid === `${orgId}`
        })
    }

    get organisationIdOnUrl() {
        return !this.customDomain
    }
}
