import utils from '../utils'

export default function stringSearchTerm(search, mode = 'contains', caseSensitive = false) {
    if (!search) {
        return search
    }

    // escape search term to not allow regexp
    const searchText = utils.string.escapeRegEx(search)
    let regExpTerm = null

    switch (mode) {
        case 'contains':
            regExpTerm = searchText
            break
        case 'containsAny':
            regExpTerm = searchText.split(' ').join('|')
            break
        case 'containsAll':
            regExpTerm = searchText
                .split(' ')
                .map(s => `(?=.*${s})`)
                .join('')
            break
        case 'startsWith':
            regExpTerm = `^${searchText}`
            break
        case 'endsWith':
            regExpTerm = `${searchText}$`
            break
        case 'like':
            regExpTerm = `^${searchText.split('\\*').join('(.*?)')}$`
            break
        case 'exact':
            regExpTerm = `^${searchText}$`
            break
        default:
            throw new Error('Unknown search mode')
    }

    return new RegExp(regExpTerm, caseSensitive ? '' : 'i')
}
