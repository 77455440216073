import React from 'react'
import { observer } from 'mobx-react-lite'
import { Switch, Route, Redirect } from 'react-router-dom'
import ChooseAction from './ChooseAction'
import Login from './Login'
import SignUp from './SignUp'
import ResetPasswordQuery from './ResetPasswordQuery'
import ResetPasswordAction from './ResetPasswordAction'

export default observer(() => {
    return (
        <Switch>
            <Route exact path="/auth" component={ChooseAction} />
            <Route path="/auth/login" component={Login} />
            <Route path="/auth/signup" component={SignUp} />
            <Route path="/auth/forgot-password" component={ResetPasswordQuery} />
            <Route path="/auth/reset-password" component={ResetPasswordAction} />
            <Route path="/auth">
                <Redirect to="/auth" />
            </Route>
        </Switch>
    )
})
