import React from 'react'
import { observer } from 'mobx-react-lite'
import { Switch, Route, Redirect } from 'react-router-dom'

import Auth from '../Auth'

import ManageOrganisations from '../ManageOrganisations'
import AppContext from '../AppContext'

import AppError from './AppError'
import ChooseApp from './ChooseApp'

export default observer(properties => {
    const { app, canLogout } = AppContext

    return (
        <Switch>
            <Route path="/auth" component={Auth} />

            <Route path="/organisations" component={ManageOrganisations} />

            <Route path="/error">
                <AppError {...properties} />
            </Route>
            
            <Route exact path="/:organisationId/apps">
                <ChooseApp renderAppFrame {...properties} />
            </Route>

            <Route path={AppContext.buildRoute('/')}>
                {app && app.Component && <app.Component {...properties} />}
            </Route>

            <Route path="/">
                <Redirect to={canLogout ? '/organisations' : '/auth'} />
            </Route>
        </Switch>
    )
})
