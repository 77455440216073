export default async context => {
    context.appLoader = context.app.loader !== false

    context.updateTheme()

    // Wait for the app module to be loaded now
    const appModule = await context.app.import()
    context.app.Component = appModule.App
    context.app.State = appModule.AppState

    // Give our app state a chance to load itself now as well
    const initResult = await context.app.State.init()

    context.appLoader = false

    // If not true then go into error now. If we received a string we take
    // that string as the error key.
    if (initResult !== true) {
        context.error = typeof initResult === 'string' ? initResult : 'unknown'
        context.mode = 'error'
        context.routeToMode(context.mode)
        return false
    }

    return true
}
