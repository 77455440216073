import Runtime from '@newspaces/lucky-base/Runtime'

export default async () => {
    let userData = await Runtime.maybeInitKnownUserData({ cache: true })

    // Init guest session if necessary to access mongo
    if (!userData) {
        userData = await Runtime.maybeInitGuestUserData()
    }

    return userData
}
