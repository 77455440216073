import i18n from '../i18n'

export default (userData, organisation) => {
    let locale = i18n().language

    if (userData && userData.language && i18n.languages.indexOf(userData.language) >= 0) {
        locale = userData.language
    }

    if (organisation && organisation.country) {
        locale += `-${organisation.country}`
    }

    if (locale) {
        i18n.setDefaultLocale(locale)
    }
}
