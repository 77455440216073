import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import AppContext from '../AppContext'

const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '--loader-color-1': theme.palette.primary.main,
        '--loader-color-2': theme.palette.primary.main,

        '&.inverted': {
            background: theme.palette.primary.main,
            '--loader-color-1': theme.palette.primary.contrastText,
            '--loader-color-2': theme.palette.primary.contrastText,
        },
    },
    loader: {
        position: 'relative',
        width: '2.5em',
        height: '2.5em',
        transform: 'rotate(0deg)',

        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            left: '50%',
            display: 'block',
            width: '0.5em',
            height: '0.5em',
            borderRadius: '0.25em',
            transform: 'translate(-50%, -50%)',
        },

        '&:before': {
            animation: '$before 2s infinite',
        },

        '&:after': {
            animation: '$after 2s infinite',
        },
    },
    '@keyframes before': {
        '0%': {
            width: '0.5em',
            boxShadow: '1em -1em var(--loader-color-1), -1em 1em var(--loader-color-1)',
        },
        '35%': {
            width: '2.5em',
            boxShadow: '0 -1em var(--loader-color-1), 0 1em var(--loader-color-1)',
        },
        '70%': {
            width: '0.5em',
            boxShadow: '-1em -1em var(--loader-color-1), 1em 1em var(--loader-color-1)',
        },
        '100%': {
            boxShadow: '1em -1em var(--loader-color-1), -1em 1em var(--loader-color-1)',
        },
    },
    '@keyframes after': {
        '0%': {
            height: '0.5em',
            boxShadow: '1em 1em var(--loader-color-2), -1em -1em var(--loader-color-2)',
        },
        '35%': {
            height: '2.5em',
            boxShadow: '1em 0 var(--loader-color-2), -1em 0 var(--loader-color-2)',
        },
        '70%': {
            height: '0.5em',
            boxShadow: '1em -1em var(--loader-color-2), -1em 1em var(--loader-color-2)',
        },
        '100%': {
            boxShadow: '1em 1em var(--loader-color-2), -1em -1em var(--loader-color-2)',
        },
    },
}))

export default observer(() => {
    const classes = useStyles()

    const [isVisible, setVisible] = useState(false)

    useEffect(() => {
        // We first wait one second before showing the app loader
        // to avoid any bad flickering and only showing when loading is taking longer
        let timerId = setTimeout(() => {
            timerId = null
            setVisible(true)
        }, 1000)

        return () => {
            if (timerId !== null) {
                clearTimeout(timerId)
            }
        }
    }, [])

    if (!isVisible) {
        return null
    }

    return (
        <div className={classNames(classes.root, AppContext.appTheme.appLoaderStyle)}>
            <div className={classes.loader} />
        </div>
    )
})
