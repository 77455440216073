import Model from '../Model'

import Organisation from './Organisation'

//
// To write this on backend for monthly usage use:
//
// serviceUsages.updateOne(
//   {
//     organisation: Organisation-Id,
//     year: new Date().getFullYear(),
//     month: new Date().getMonth() + 1, // 1-based !!!
//     service: 'sms'
//   },
//   {
//     $inc: { usage: 1, costs: 10 }
//   },
//   { upsert: true }
// )
//
// If the usage is all time (like a quota) simply set year and month to null
//
// The usage type depends on the service ie counter or storage or whatever.
// The costs is handled in the organisation's own currency
//

class ServiceUsage extends Model {
    static CollectionName = 'serviceUsages'

    static Permissions = {
        read: [
            {
                who: 'owner',
                scope: 'organisation.read'
            }
        ]
    }

    static Fields = {
        organisation: {
            type: Organisation,
            required: true,
            serialize: 'insert',
        },
        year: {
            type: Number,
            serialize: 'insert',
        },
        month: {
            type: Number,
            serialize: 'insert',
        },
        service: {
            type: String,
            serialize: 'insert',
        },
        usage: {
            type: Number,
            serialize: 'insert',
        },
        costs: {
            type: Number,
            serialize: 'insert',
        },
    }

    constructor(data) {
        super(ServiceUsage.Fields, data)
    }
}

Model.declareModel(ServiceUsage)

export default ServiceUsage
