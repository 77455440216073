export default {
    group: {
        move: "Sposta",
        expand: "Espandi",
        collapse: "Riduci",
    },
    dataFilter: {
        searchField: "Campo di ricerca...",
        addFilter: "Aggiungi filtri",
        addFilterToGroup: "Aggiungi filtro al gruppo",
        removeFilter: "Rimuovi il filtro",
        missingValue: "valore mancante",
        done: "fatto",
        operator: {
            and: "e",
            or: "o",
        },
        compare: {
            list: {
                contains: "contiene",
                notContains: "non contiene",
                empty: "è vuoto",
                notEmpty: "non è vuoto",
            },
            string: {
                equals: "è",
                notEquals: "non è",
                contains: "contiene",
                notContains: "non contiene",
                startsWith: "inizia con",
                endsWith: "termina con",
                exists: "ha un valore assegnato",
                notExists: "non ha alcun valore",
            },
            number: {
                equals: "è",
                notEquals: "non è",
                greaterThan: "maggiore di",
                lessThan: "inferiore a",
                exists: "ha un valore assegnato",
                notExists: "non ha alcun valore",
            },
            date: {
                moreThanDaysAgo: "da più di",
                exactlyDaysAgo: "esattamente da",
                lessThanDaysAgo: "da meno di",
                moment: "",
                after: "dopo",
                equals: "il",
                before: "prima",
                exists: "ha un valore assegnato",
                notExists: "non ha alcun valore",
                groupLabels: {
                    relative: "Relativamente",
                    custom: "Personalizzato",
                    absolute: "Assoluto",
                },
                postfixLabels: {
                    days: "Giorno/i",
                },
                momentValues: {
                    beforeToday: "Prima di oggi",
                    beforeOrAtToday: "Oggi o prima",
                    afterToday: "Dopo di oggi",
                    afterOrAtToday: "Oggi o in futuro",
                }
            },
            bool: {
                isTrue: "è vero",
                isFalse: "è sbagliato",
                exists: "ha un valore assegnato",
                notExists: "non ha alcun valore",
            },
        },
    },
    dataGrid: {
        enabled: "Attivato",
        columnMenu: "Raggruppamento, visibilità e altro",
        pinColumn: "Appunta colonna",
        valueAggregation: "Aggrega",
        autosizeThiscolumn: "Adatta la larghezza al contenuto",
        autosizeAllColumns: "Adatta tutte le colonne al contenuto",
        groupBy: "Raggruppa per",
        ungroupBy: "Dividi per",
        resetColumns: "Ripristina tutte le colonne",
        expandAll: "Espandi tutto",
        collapseAll: "Riduci tutto",
        pinLeft: "Appunta a sinistra",
        pinRight: "Appunta a destra",
        noPin: "Non appuntare",
        searchOoo: "Ricerca in corso...",
        loadingOoo: "Caricamento in corso...",
        noRowsToShow: "Nessun dato disponibile",
        emptyGroup: "non impostato",
        expandGroup: "Apri un gruppo",
        collapseGroup: "Chiudi un gruppo",
        sortAscending: "Ordina in ordine crescente",
        sortDescending: "Ordina in ordine decrescente",
    },
    fileInput: {
        upload: "Carica file",
        remove: "Rimuovi file",
    },
    select: {
        initialLoading: "Caricamento in corso...",
    },
    loadMoreList: {
        loadMoreCount: "+${this.more} altro",
        loadMoreInfinite: "Carica altro",
    },
}
