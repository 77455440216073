export default context => {
    const appTheme = context.app?.getTheme?.(context.organisation) || {}

    context.appTheme = {
        ...appTheme,
        title: appTheme.title || context.zone.name,
        favicon: appTheme.favicon || context.zone.FavIcon,
    }

    context.updateDocumentTitle()
}
