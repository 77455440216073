import React, { useCallback } from 'react'
import TextField from '@material-ui/core/TextField'

import InputLabel from '../InputLabel'

export default ({
    handleBlurChange,
    variant,
    controlClasses,
    inputClasses,
    fullWidth,
    inputWidth,
    error,
    info,
    InputProps,
    InputLabelProps,
    ref,
    inputRef,
    onKeyDown,
    onSubmit,
    onBlur,
    ...otherProperties
}) => {
    const handleKeyDown = useCallback(
        evt => {
            if (evt.keyCode === 13 && onSubmit) {
                onSubmit(evt)
            }

            if (onKeyDown) {
                onKeyDown(evt)
            }
        },
        [onSubmit, onKeyDown]
    )

    const handleBlur = useCallback(
        evt => {
            handleBlurChange()

            if (onBlur) {
                onBlur(evt)
            }
        },
        [handleBlurChange, onBlur]
    )

    const renderInput = useCallback(
        autoCompleteProperties => {
            return (
                <TextField
                    {...otherProperties}
                    {...autoCompleteProperties}
                    fullWidth={fullWidth}
                    classes={controlClasses}
                    ref={ref}
                    inputRef={inputRef}
                    variant="filled"
                    error={!!error}
                    inputProps={{
                        ...autoCompleteProperties.inputProps,
                        style: {
                            ...autoCompleteProperties.inputProps?.style,
                            width: inputWidth ? `${inputWidth}px` : '0',
                        },
                        autoComplete: 'new-password',
                    }}
                    InputProps={{
                        ...InputProps,
                        ...autoCompleteProperties.InputProps,
                        classes: inputClasses,
                        disableUnderline: true,
                    }}
                    InputLabelProps={{
                        ...InputLabelProps,
                        ...autoCompleteProperties.InputLabelProps,
                        contained: variant === 'contained',
                        shrink: true,
                        infoText: info,
                        errorText: error,
                        component: InputLabel,
                    }}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                />
            )
        },
        [
            InputLabelProps,
            InputProps,
            controlClasses,
            error,
            fullWidth,
            info,
            inputClasses,
            inputWidth,
            otherProperties,
            ref,
            inputRef,
            variant,
            handleKeyDown,
            handleBlur,
        ]
    )

    const renderInputWrapper = useCallback(
        autoCompleteProperties => {
            if (variant === 'popup') {
                return <div className="input-container">{renderInput(autoCompleteProperties)}</div>
            }

            return renderInput(autoCompleteProperties)
        },
        [renderInput, variant]
    )

    return renderInputWrapper
}
