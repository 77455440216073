import isURL from 'validator/lib/isURL'
import isEmail from 'validator/lib/isEmail'
import isMobilePhone from 'validator/lib/isMobilePhone'
import isIBAN from 'validator/lib/isIBAN'
import isBIC from 'validator/lib/isBIC'
import isHexColor from 'validator/lib/isHexColor'
import isUUID from 'validator/lib/isUUID'

const PHONE_REGEX = /^[0-9-\s+]+$/i

export default (value, isRequired, validationSettings) => {
    if (isRequired && !value) {
        return 'required'
    }

    if (value !== null && value !== undefined && typeof value !== 'string') {
        return 'invalidType'
    }

    // if there's no value given we're done here
    if (!value) {
        return null
    }

    if (validationSettings.enum) {
        let valid = false
        if (typeof validationSettings.enum === 'function') {
            valid = validationSettings.enum(value)
        } else {
            valid = validationSettings.enum.includes(value)
        }

        if (!valid) {
            return 'invalidEnum'
        }
    }

    if (typeof validationSettings.min === 'number' && value.length < validationSettings.min) {
        return 'lengthMin'
    }
    if (typeof validationSettings.max === 'number' && value.length > validationSettings.max) {
        return 'lengthMax'
    }

    if (validationSettings.format) {
        switch (validationSettings.format) {
            case 'url':
                if (!isURL(value)) {
                    return 'invalidUrl'
                }
                break
            case 'email':
                if (!isEmail(value)) {
                    return 'invalidEmail'
                }
                break
            case 'phone':
                // TODO : This is more of a dummy phone reg-ex
                if (!PHONE_REGEX.test(value)) {
                    return 'invalidPhone'
                }
                break
            case 'mobilePhone':
                if (!isMobilePhone(value)) {
                    return 'invalidMobilePhone'
                }
                break
            case 'iban':
                if (!isIBAN(value)) {
                    return 'invalidIBAN'
                }
                break
            case 'bic':
                if (!isBIC(value)) {
                    return 'invalidBIC'
                }
                break
            case 'hexColor':
                if (!isHexColor(value)) {
                    return 'invalidHexColor'
                }
                break
            case 'uuid':
                if (!isUUID(value)) {
                    return 'invalidUUID'
                }
                break
            default:
                if (validationSettings.format instanceof RegExp) {
                    if (validationSettings.format.test(value)) {
                        return null
                    }

                    return 'noPatternMatch'
                }
                throw new Error(`Invalid string format ${validationSettings.format}`)
        }
    }

    return null
}
