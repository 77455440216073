import DateTime from '../../DateTime'

export default (
    localeObject,
    type,
    value,
    emptyValue = null,
    { digits = null, format, ...otherSettings } = {}
) => {
    if (type === 'float' && typeof value === 'number') {
        return localeObject.formatNumber(value, {
            maximumFractionDigits: typeof digits === 'number' ? digits : 2,
            minimumFractionDigits: typeof digits === 'number' ? digits : 2,
            ...otherSettings,
        })
    }

    if (type === 'int' && typeof value === 'number') {
        return localeObject.formatNumber(value, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
            ...otherSettings,
        })
    }

    if (type === 'date' && value instanceof DateTime) {
        return localeObject.formatDate(value, { format, ...otherSettings })
    }

    if (type === 'boolean' && typeof value === 'boolean') {
        return localeObject.misc.yesNo[value ? 'yes' : 'no']
    }

    if (type === 'string' && typeof value === 'string') {
        if (format) {
            return localeObject.formatString(value, format)
        }

        return value
    }

    return emptyValue
}
