import * as Sentry from '@sentry/browser'

export default (userData, organisation, app) => {
    if (process.env.NODE_ENV === 'production') {
        Sentry.configureScope(scope => {
            scope.setTag('organisation', organisation ? organisation.uniqueSlug : 'global')
            scope.setTag('application', app.name)

            if (userData.email && userData.id) {
                scope.setUser({ id: userData.id, email: userData.email })
            }
        })
    }
}
