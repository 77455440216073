export const locales = {
    de: {
        dateFormat: 'DD.MM.YYYY',
        thousandSeparator: '.',
        decimalSeparator: ',',
        currencyPosition: 'end',
    },
    'de-AT': {
        dateFormat: 'DD.MM.YYYY',
        currencyPosition: 'start',
    },
    'de-CH': {
        dateFormat: 'DD.MM.YYYY',
        currencyPosition: 'start',
        thousandSeparator: '’',
        decimalSeparator: '.',
    },
    fr: {
        dateFormat: 'DD.MM.YYYY',
        thousandSeparator: ' ',
        decimalSeparator: ',',
        currencyPosition: 'end',
    },
    'fr-CH': {
        dateFormat: 'DD.MM.YYYY',
        decimalSeparator: '.',
    },
    it: {
        dateFormat: 'DD.MM.YYYY',
        thousandSeparator: '.',
        decimalSeparator: ',',
        currencyPosition: 'end',
    },
    'it-CH': {
        dateFormat: 'DD.MM.YYYY',
        thousandSeparator: '’',
        decimalSeparator: '.',
        currencyPosition: 'start',
    },
    en: {
        dateFormat: 'DD/MM/YYYY',
        thousandSeparator: ',',
        decimalSeparator: '.',
        currencyPosition: 'start-noSpace',
    },
    'en-DE': {
        dateFormat: 'DD.MM.YYYY',
        thousandSeparator: '.',
        decimalSeparator: ',',
        currencyPosition: 'end',
    },
    'en-US': {
        dateFormat: 'MM/DD/YYYY',
        timeAMPM: true,
    },
}

export default function getLocale(locale) {
    if (!locale) {
        throw new Error(`Missing locale.`)
    }

    const parts = locale.split('-')
    const language = parts[0]
    const country = parts[1]

    let result = locales[language]

    if (country && locales[locale]) {
        result = {
            ...result,
            ...locales[locale],
        }
    }

    if (!result) {
        throw new Error(`Locale format not found for "${locale}".`)
    }

    return result || null
}
