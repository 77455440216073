import isInstanceOfObjectId from '../../utils/objectId/isInstanceOf'

import DateTime from '../../DateTime'

const isObject = value => {
    return (
        typeof value === 'object' &&
        value !== null &&
        !isInstanceOfObjectId(value) &&
        !(value instanceof Date)
    )
}

const isDateTimeObject = value => {
    return value && typeof value === 'object' && ['date', 'datetime', 'time'].includes(value._type)
}

const normalizeQueryValues = (obj, path = '', settings) => {
    Object.keys(obj).forEach(property => {
        const propertyPath = path ? `${path}.${property}` : property
        const propertyValue = obj[property]

        // array and null are treated as object in our beloved js but we can handle
        // both the same as we iterate through their keys
        if (propertyValue instanceof DateTime) {
            obj[property] = propertyValue.toDate()
        } else if (propertyValue instanceof Date) {
            throw new Error('Date not supported in query, use DateTime.Date, DateTime.Time or DateTime')
        } else if (isDateTimeObject(propertyValue)) {
            // handle DateTime when they are just a regular Object instead of DateTime
            // needed when we parse queries as string
            const dateObject = new DateTime(
                { type: propertyValue._type, ...propertyValue },
                undefined,
                propertyValue._type
            )
            obj[property] = dateObject.toDate()
        } else if (isObject(propertyValue)) {
            normalizeQueryValues(obj[property], propertyPath, settings)
        }
    })
}

export default normalizeQueryValues
