export default (Model, modelsQuery = {}, removalGroup) => {
    // append organisation query on root since we need for permissions
    const organisation = modelsQuery.organisation
    
    // get removed models + models which were removed on the
    // current process
    const finalQuery = {
        $or: [
            {
                ...modelsQuery,
                removedAt: { $exists: false }
            },
            {
                removalGroup,
                removedAt: { $exists: true }
            }
        ],
        organisation
    }

    // query removed models aswell so we can correctly paginate
    return Model.createQuery(
        finalQuery,
        { _id: 1 },
        { allowRemoved: true }
    )
}
