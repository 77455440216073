import React from 'react'
import { observer } from 'mobx-react-lite'
import { Switch, Route, Redirect } from 'react-router-dom'

import CreateOrganisation from './CreateOrganisation'
import ChooseOrganisation from './ChooseOrganisation'

export default observer(() => {
    return (
        <Switch>
            <Route exact path="/organisations">
                <ChooseOrganisation renderAppFrame />
            </Route>
            
            <Route exact path="/organisations/create">
                <CreateOrganisation renderAppFrame />
            </Route>

            <Route path="/organisations">
                <Redirect to="/organisations" />
            </Route>
        </Switch>
    )
})
