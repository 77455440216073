/* eslint-disable max-len  */
/* eslint-disable no-template-curly-in-string */
export default {
    navigation: {
        userSettings: 'Mes paramètres',
        changeOrganisation: "Changer d'${this.name}",
        changeApp: "Changer d'application",
        login: 'Se connecter',
        signup: "S'enregistrer",
        logout: 'Se déconnecter',
        menu: 'Menu',
    },
    app: {
        errors: {
            title: "Nous sommes navrés, une erreur s'est produite",
            backToHome: "Revenir à la page d'accueil",
            invalidInvitationToken: "Le lien d'invitation utilisé a déjà été utilisé ou n'est pas valide.",
            invalidOrganisation: "Aucune entrée n'a pu être trouvée.",
            noAppAvailable: "Aucune application n'est disponible.",
            appNotAccessible: "Pas d'accès possible à l'application demandée.",
            unknown: 'Erreur inconnue',
        },
        availableApps: 'Applications disponibles',
        trialAlert: "Votre période d'essai prendra fin le ${this.date}.",
        trialAction: 'Aller à la facturation',
        subscription: {
            warning: {
                title: {
                    permanent: 'Votre accès est bloqué.',
                    temporary: 'Votre accès a été temporairement bloqué.',
                },
                past_due: "Nous n'avons pas pu renouveler votre abonnement automatiquement.",
                unpaid: "Nous n'avons pas pu renouveler votre abonnement automatiquement.",
                canceled: 'Votre abonnement a été annulé.',
                incomplete: "Votre abonnement n'a pas été correctement activé.",
                incomplete_expired: "Votre abonnement n'a pas été correctement activé.",
                noSubscription:
                    "Votre période d'essai est terminée et vous n'avez pas souscrit à un abonnement.",
                contactSupport: {
                    permanent:
                        'Prenez contact avec nous pour renouveler votre abonnement. Si vous ne renouvelez pas votre abonnement avant le ${this.date}, vos données seront définitivement supprimées.',
                    temporary: 'Prenez contact avec nous pour réactiver votre accès.',
                },
                actionSupport: 'Entrez en contact avec nous',
                actionCreate: 'Souscrire un nouvel abonnement',
            },
        },
    },
    auth: {
        chooseAction: {
            title: 'Possédez-vous déjà un compte ${this.name}?',
            acceptInvitation:
                "Pour accepter l'invitation, veuillez vous connecter ou créer un nouveau compte :",
            loginAction: "J'ai déjà un compte",
            signUpAction: 'Je souhaite créer un compte',
        },
        login: {
            title: 'Connectez-vous à ${this.name}',
            action: 'Connectez-vous dès maintenant',
            email: 'Adresse e-mail',
            password: 'Mot de passe',
            passwordForgotten: 'Vous avez oublié votre mot de passe?',
            noAccountSignUp: "Vous n'avez pas encore de compte? Créez un compte dès maintenant.",
            errors: {
                invalidEmail: 'Aucun compte ne correspond à cette adresse e-mail.',
                invalidPassword: "Mot de passe incorrect pour l'adresse e-mail indiquée.",
                invalidCredentials: 'Adresse e-mail ou mot de passe incorrects.',
                invalidZone: "L'utilisateur n'a pas accès à cet espace.",
            },
        },
        signUp: {
            title: 'Créer un nouveau compte ${this.name}',
            action: 'Créer un compte',
            email: 'Votre adresse e-mail',
            password: 'Choisissez un mot de passe',
            dataPrivacy:
                'Par la présente, je déclare avoir lu et accepter  <<la politique de confidentialité>>. Je déclare être informé que je peux retirer mon consentement à tout moment.',
            termsOfUse:
                "Par la présente, je déclare avoir lu et accepter  <<les Conditions générales d'utilisation>>.",
            dataContract: "<<Cliquez ici>> pour télécharger l'accord de protection des données de commande.",
            alreadyRegisteredLogin: 'Vous avez déjà un compte? Alors connectez-vous ici.',
            errors: {
                invalidEmail: "L'adresse e-mail que vous avez saisie n'est pas valide.",
                invalidPassword: 'Mot de passe non valide, nécessite au moins six caractères.',
                alreadySignedUp:
                    'Il existe déjà un compte avec cette adresse e-mail, veuillez vous connecter.',
            },
        },
        resetPasswordQuery: {
            title: 'Réinitialisez votre mot de passe ${this.name}',
            action: 'Envoyer un e-mail pour réinitialiser',
            email: 'Adresse e-mail enregistrée',
            success:
                'Un e-mail a été envoyé à ${this.email} avec des instructions sur la façon de réinitialiser votre mot de passe.',
            rememberPasswordLogin: 'Vous vous en souvenez encore ? Alors connectez-vous ici.',
            errors: {
                invalidEmail: "Il n'existe aucun compte pour l'adresse e-mail spécifiée.",
                unknownError: "Une erreur inconnue s'est produite",
            },
        },
        resetPasswordAction: {
            title: 'Définissez votre nouveau mot de passe pour ${this.name}',
            action: 'Définir le nouveau mot de passe',
            email: 'Adresse e-mail enregistrée',
            password: 'Nouveau mot de passe',
            passwordConfirm: 'Répéter le nouveau mot de passe',
            success:
                'Votre nouveau mot de passe a été défini avec succès. Vous pouvez désormais vous connecter en utilisant votre nouveau mot de passe.',
            loginNow: 'Connectez-vous maintenant avec vos nouveaux identifiants de connexion.',
            errors: {
                passwordMissmatch: 'Les mots de passe ne correspondent pas.',
                expiredToken: 'Le lien a expiré. Réinitialisez à nouveau votre mot de passe.',
                invalidToken: "Le lien n'est pas valide. Réinitialisez à nouveau votre mot de passe.",
                invalidEmail: "Il n'existe aucun compte pour l'adresse e-mail spécifiée.",
                invalidPassword: 'Mot de passe non valide, nécessite au moins six caractères.',
            },
        },
    },
    manageOrganisations: {
        newOrganisation: 'Créer une nouvelle organisation',
        yourOrganisations: 'Vos organisations',
        emptySearch: 'Aucune organisation trouvée',
        moveOrganisation: "Déplacer l'organisation",
        leaveOrganisation: "Quitter l'organisation",
        create: {
            label: 'Créer une nouvelle Organisation',
            description: '',
            name: 'Nom de votre organisation',
            country: 'Dans quel pays se trouve votre organisation?',
            action: 'Créer une organisation maintenant',
        },
        leaveOrganisationPrompt: {
            title: 'Quitter ${this.organisationName}',
            text: 'Êtes-vous sûr de vouloir quitter "${this.organisationName}"?',
            action: "Oui, quitter l'organisation",
            errors: {
                onlyOwner:
                    'Vous ne pouvez pas quitter cette organisation, car vous êtes le seul utilisateur. Si vous voulez, vous pouvez supprimer cette organisation dans les paramètres.',
            },
        },
    },
    organisationSettings: {
        profile: {
            title: 'Profil',
            uploadAvatarButton: 'Ajouter une nouvelle image de profil',
            information: {
                title: 'Paramètres du profil',
            },
            uniqueSlug: {
                title: 'Identifiant',
                description:
                    "L'identifiant est utilisé pour fournir une URL lisible et est également utilisé comme nom d'expéditeur dans les e-mails. ATTENTION: Si votre URL est déjà utilisée et que vous modifiez votre identifiant, les anciennes URL seront invalidées.",
                action: 'Définir un nouvel identifiant',
                errors: {
                    invalidSlug:
                        "Cet identifiant n'est pas valide. Veuillez en choisir un autre et réessayer.",
                    forbidden: "Vous n'êtes pas autorisé à modifier cet identifiant.",
                    alreadyClaimed: "Cet identifiant est déjà utilisé par quelqu'un d'autre.",
                    unknown: "Une erreur inconnue s'est produite.",
                },
                success: "L'identifiant a été défini avec succès et votre URL a été modifiée.",
            },
            dangerZone: {
                title: 'Zone à risque',
                deleteOrganisation: {
                    title: 'Supprimer ${this.organisationName}',
                    description:
                        'Supprime irrévocablement toutes les données de "${this.organisationName}". Attention, cette opération ne peut pas être annulée !',
                    action: 'Supprimer',
                },
                deleteOrganisationModal: {
                    title: 'Supprimer ${this.organisationName}',
                    text:
                        'Êtes-vous absolument sûr de vouloir supprimer "${this.organisationName}" ainsi que toutes les données associées ? Cette opération ne peut pas être annulée.',
                    input: {
                        text:
                            'Si vous voulez continuer, saisissez le texte "${this.organisationName}" dans le champ en dessous :',
                        action: 'Supprimer maintenant',
                    },
                },
            },
        },
        billing: {
            title: 'Facturation et abonnements',
            products: {
                pricing: {
                    flat: {
                        year: '${this.amount} / années',
                        month: '${this.amount} / mois',
                    },
                    metered: '${this.amount} par membre / mois',
                    tiered:
                        '${this.flatAmount} pour les ${this.flatLimit} premiers membres. ${this.meteredAmount} pour chaque membre supplémentaire.',
                    tieredLabel: "En fonction de l'utilisation",
                },
            },
            subscription: {
                title: 'Abonnement',
                empty: "Aucun abonnement n'est disponible.",
                priceLink: 'Ouvrir le plan tarifaire',
                trial: {
                    title: 'Abonnement à l’essai',
                    text:
                        "L'abonnement \"${this.name}\" est en cours d'essai jusqu'au ${this.date}. Pour conserver l'accès, vous devez vous abonner avant la fin de la période d'essai.",
                    action: 'Abonnez-vous dès maintenant!',
                    price: 'Prix: ${this.price}',
                    priceLink: 'Ouvrir le plan tarifaire',
                },
                status: {
                    none: "Pas d'abonnement",
                    incomplete: 'Incomplet',
                    incomplete_expired: 'Expiré',
                    trialing: "Période d'essai jusqu'au ${this.date}",
                    active: 'Actif',
                    past_due: 'En retard',
                    canceled: 'Annulé',
                    unpaid: 'Non payé',
                    will_be_canceled: 'Annulation le ${this.date}',
                },
                switchPrice: "Changer d'abonnement",
                createSubscription: "S'abonner",
                newSubscription: 'Souscrire à un nouvel abonnement',
                reactivateSubscription: 'Réactiver un abonnement annulé',
                cancel: {
                    action: "Résilier l'abonnement",
                    prompt:
                        'Voulez-vous vraiment résilier cet abonnement? Il sera résilié à la fin de la période en cours. Cette action ne peut pas être annulée.',
                },
                activate: {
                    action: "Activer l'abonnement",
                },
                editor: {
                    product: 'Product',
                    price: 'Abonnement',
                    title: "Sélectionnez le produit et l'abonnement que vous souhaitez:",
                    serviceTitle:
                        "Le service \"${this.service}\" est en cours d'utilisation sans avoir souscrit d'abonnement. Connectez-vous à l'un des services dès maintenant pour continuer:",
                },
                unitAmount: '${this.amount} par membre',
                service: {
                    usage: 'Utilisation: ${this.usage}',
                    costs: 'Coût: ${this.costs}',
                },
                applyDiscount: {
                    action: 'Utiliser votre code de réduction',
                    tooltip:
                        'Vous disposez encore de ${this.hoursLeft} heures pour profiter de votre code de réduction',
                    dialog: {
                        title: 'Saisissez le code du bon que vous souhaitez utiliser :',
                        action: 'Utilisez un code de réduction',
                    },
                    errors: {
                        codeNotFound: "Ce code de réduction n'existe pas",
                        unknownError:
                            "Une erreur inconnue est survenue. S'il vous plaît, veuillez réessayer.",
                        invalidPromotion: "Ce code de réduction n'est plus valable.",
                    },
                },
            },
            credits: {
                title: 'Crédit',
                current: 'Crédit actuel: ${this.credits}',
                charge: 'Recharger le crédit',
                amount: 'Montant à recharger',
                minimun: 'Montant minimum requis: ${this.amount}',
            },
            services: {
                title: 'Services et utilisation',
                emptyHistory: "Il n'y a pas d'autre entrée antérieure utilisant ce service",
                noCosts: "Le coût n'est calculé qu'une fois que vous avez un abonnement actif au service.",
                history: "Historique d'utilisation du service ${this.service}",
                sections: {
                    allTime: "Temps total d'utilisation",
                    monthly: 'Utilisation pour ${this.month}. ${this.year}',
                },
                table: {
                    service: 'Service',
                    usage: 'Utilisation',
                    costs: 'Frais',
                    period: 'Période',
                    lastUpdated: 'Dernière mise à jour',
                    subscription: 'Abonnement',
                    actions: {
                        seeHistory: "Consulter l'historique d'utilisation du service",
                    },
                },
            },
            paymentMethods: {
                title: 'Méthodes de paiement',
                typeValue: {
                    card:
                        "Carte de crédit (${this.card.brand}) **** ${this.card.last4} - valable jusqu'au ${this.card.exp_month} /${this.card.exp_year}",
                    sepa_debit: 'SEPA-Prélèvement automatique **** ${this.sepa_debit.last4}',
                },
                default: 'Standard',
                makeDefault: 'Définir le mode de paiement par défaut',
                delete: {
                    action: 'Supprimer le mode de paiement',
                    prompt:
                        'Voulez-vous vraiment supprimer ce mode de paiement? Cette action ne peut pas être annulée.',
                },
            },
            details: {
                title: 'Détails de la facture',
                email: 'E-mail pour les factures',
                name: 'Destinataire de la facture',
                address: {
                    title: 'Adresse de facturation',
                    line1: "Ligne d'adresse 1",
                    line2: "Ligne d'adresse 2",
                    postal_code: 'Code postal',
                    city: 'Ville',
                    country: 'Pays',
                },
            },
            invoices: {
                title: 'Historique des factures',
                number: 'No de facture',
                amountPaid: 'Payé',
                amountTotal: 'Au total',
                date: 'Date',
            },
        },
        users: {
            title: 'Gestion des utilisateurs',
            table: {
                name: 'Nom',
                email: 'Adresse e-mail',
                status: 'Statuts',
                statusValue: {
                    invited: 'Invité',
                    active: 'Actif',
                    inactive: 'Inactif',
                    invite: 'Invitation en attente',
                    created: 'Créé',
                },
                createdAt: 'Créé le',
                isOwner: 'Propriétaires',
                loadMore: 'Importer plus utilisateurs...',
                editUser: 'Modifier les utilisateurs',
                deleteUser: "Supprimer l'utilisateur...",
                getLink: "Copier le lien d'invitation",
            },
            toolbar: {
                userCount: {
                    single: 'Utilisateur',
                    multi: 'Utilisateurs',
                },
                search: 'Recherche par nom ou e-mail',
                addUser: 'Invitez des utilisateurs',
            },
            userSettings: {
                name: "Nom de l'utilisateur",
                email: 'Adresse e-mail',
                inviteUser: 'Invitez des utilisateurs',
                eMailAlreadyExists: 'Un utilisateur dont l\'adresse e-mail est "${this.email}" existe déjà.',
                permissions: {
                    owner: {
                        checkbox: 'Définir cet utilisateur en tant que propriétaire',
                        hint:
                            'Les propriétaires disposent de toutes les autorisations et peuvent tout afficher et modifier sans restrictions.',
                        deniedHint:
                            "Vous n'avez pas les autorisations nécessaires pour attribuer le statut de propriétaire",
                    },
                    header: {
                        permission: 'Lire à partir de',
                        edit: 'Modification autorisée',
                    },
                    toggleCustomTooltip: "Droits d'accès étendus",
                    customOptions: {
                        read: 'Lire',
                        insert: 'Créer',
                        edit: 'Modifier',
                        remove: 'Supprimer',
                    },
                    test: 'Mode live',
                    testTooltip:
                        "Testez en temps réel l'impact des modifications sur les droits des utilisateurs ajustés",
                    debugContext: {
                        title: 'Test en direct des droits des utilisateurs',
                        finish: 'Confirmer',
                    },
                    apps: {
                        accessTo: "Permet d'accéder à",
                        noAccess: "Pas d'accès - cliquez pour autoriser l'accès",
                        hasAccess: 'Accès autorisé',
                    },
                },
            },
            deleteUser: {
                title: 'Supprimer des utilisateurs',
                text: 'Êtes-vous sûr de vouloir supprimer ${this.userName} de ${this.orgName}?',
                action: 'Supprimer des utilisateurs',
            },
            copyInvitationLink: {
                title: "Lien d'invitation",
                text:
                    "L'utilisateur invité peut utiliser ce lien pour accepter l'invitation et rejoindre ${this.orgName}.",
                tooltipCopy: 'Copier le lien',
                tooltipCopied: 'Le lien a été copié dans le presse-papiers!',
            },
            debug: {
                description:
                    'Toutes les modifications des droits des utilisateurs sont reflétées en temps réel dans la session en cours. Les modifications sont annulées dès que la fenêtre est fermée.',
                transferUser: 'Attribuer des droits utilisateur',
                transferUserHint:
                    "Si la case est cochée, les modifications des droits de l'utilisateur sont transférées à l'utilisateur qui a été créé/modifié dès la fermeture de la fenêtre.",
            },
        },
    },
    userSettings: {
        profile: {
            title: 'Profil',
            name: 'Votre nom (visible par les autres)',
            email: 'Adresse e-mail',
            bio: 'À propos de vous',
            language: 'Langue préférée',
            deleteAccount: {
                title: 'Zone à risque',
                description:
                    'Supprimer mon compte lié à cet e-mail (${this.email}). ATTENTION: Attention! Cette action ne peut pas être annulée.',
                action: 'Supprimer',
            },
            deleteAccountModal: {
                title: 'Supprimer ${this.email}',
                text:
                    "Êtes-vous sûr de vouloir supprimer l'utilisateur avec l'e-mail (${this.email})? Attention! Cette action ne peut pas être annulée.",
                input: {
                    verifyWord: 'Supprimer',
                    text:
                        'Entrez le mot suivant dans la case pour confirmer le processus: "${this.verifyWord}"',
                    action: 'Supprimer maintenant',
                },
            },
        },
        developer: {
            title: 'Développeur+API',
            apiDocumentation: {
                title: 'Documentation API',
                intro:
                    "Le référentiel API contient toute la documentation nécessaire à l'utilisation et l'implémentation des fonctions.",
                link: 'Vers la documentation API',
            },
            apiKeys: {
                title: 'Clés API',
                intro:
                    'Si vous souhaitez obtenir des droits distincts, vous devrez créer un nouveau compte utilisateur auquel attribuer ces droits.',
                newKeyAction: 'Créer une nouvelle clé',
                newKeyName: 'Nom de la clé',
                copyWarning:
                    'Veuillez copier et enregistrer cette clé car elle ne sera plus jamais affichée par la suite.',
                copyKey: 'Copier la clé',
                copyKeyDone: 'La clé a été copiée dans le presse-papiers!',
                deleteKeyTitle: 'Supprimer la clé',
                deleteKeyPrompt:
                    "Voulez-vous vraiment supprimer la clé? Cela ne peut pas être annulé et toutes les applications qui utilisent la clé n'y auront plus accès.",
                deleteKeyAction: 'Oui, supprimez la clé',
            },
        },
    },
}
