export default async promises => {
    if (Array.isArray(promises)) {
        return Promise.all(promises)
    }

    if (typeof promises === 'object') {
        const keys = Object.keys(promises)
        const results = await Promise.all(keys.map(key => promises[key]))
        return keys.reduce((res, key, index) => ({ ...res, [key]: results[index] }), {})
    }

    throw new Error('Expected array of promises or object of promises')
}
