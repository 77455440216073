/* eslint-disable max-len  */
/* eslint-disable no-template-curly-in-string */

export default {
    language: 'Language',
    languageName: 'English',
    months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ],
    monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    weekdaysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    relativeTime: {
        yesterday: 'Yesterday',
        today: 'Today',
        tomorrow: 'Tomorrow',
        daysAgo: '${this.days} days ago',
        daysIn: 'In ${this.days} days',
    },
    year: {
        single: 'Year',
        multi: 'Years',
    },
    month: {
        single: 'Month',
        multi: 'Months',
    },
    week: {
        single: 'Week',
        multi: 'Weeks',
    },
    day: {
        single: 'Day',
        multi: 'Days',
    },
    hour: {
        single: 'Hour',
        multi: 'Hours',
    },
    minute: {
        single: 'Minute',
        multi: 'Minutes',
    },
    second: {
        single: 'Second',
        multi: 'Seconds',
    },
    milliseconds: {
        single: 'Millisecond',
        multi: 'Milliseconds',
    },
    misc: {
        yesNo: {
            yes: 'Yes',
            no: 'No',
        },
        errors: {
            unknown: 'An unknown error happened. Please contact the support.',
        },
        noCloseAlert: {
            title: 'Do not close the window!',
            message:
                'Please do not close the browser window or tab until this process has finished as it gets canceled otherwise.',
        },
        close: 'Close',
        saveChanges: 'Save changes',
        collapseAll: 'Collapse all',
        expandAll: 'Expand all',
        create: 'Create',
        edit: 'Edit',
        remove: 'Remove',
        delete: 'Delete',
        cancel: 'Cancel',
        save: 'Save',
        view: 'View',
        send: 'Send',
        choose: 'Choose',
        download: 'Download',
        showMore: 'Show more',
        showLess: 'Show less',
        createdAtBy: 'Created at ${this.date} by ${this.name}',
        updatedAtBy: 'Edited at ${this.date} by ${this.name}',
        unknown: 'Unknown',
        missingField: '${this.field} is missing!',
        today: 'Today',
        for: 'for',
        or: 'or',
        back: 'Back',
        next: 'Next',
        exportToExcel: 'Export to Excel...',
        clearSelection: 'Clear selection',
        maxFileSizeMB: 'The file may not be bigger than ${this.megabyte} MB.',
        search: 'Search',
        makeFavorite: 'Assign as Favorite',
        clearFavorite: 'Clear Favorite',
        noColor: 'No color',
    },
    resetPassword: {
        subject: 'Reset password',
        text: 'Please follow this link to reset your password: ${this.resetPasswordLink}',
    },
    sendInvitation: {
        subject: 'You have been invited to ${this.orgName}',
        text:
            'Hello ${this.name},\n\nYou have been invited to join ${this.orgName}.\n\nClick on the following link to accept your invitation:\n${this.invitationLink}',
    },
    emailUnsubscription: {
        title: 'Email unsubscription',
        text: "You're successfully unsubscribed",
    },
    organisation: {
        modelName: {
            single: 'Organisation',
            multi: 'Organisations',
        },
        avatar: 'Profile picture',
        primaryColor: 'Main-Color',
        primaryTextColor: 'Main Text-Color',
        name: 'Name',
        street: 'Address',
        zip: 'ZIP',
        city: 'City',
        country: 'Country',
        customDomain: {
            status: 'Status',
            statusValue: {
                pending: 'Pending',
                active: 'Active',
                failed: 'Failed',
            },
        },
    },
    organisationUser: {
        modelName: {
            single: 'User',
            multi: 'Users',
        },
        name: 'Name',
        email: 'EMail-Address',
        status: 'Status',
        statusValue: {
            created: 'Created',
            invite: 'Will be invited',
            invited: 'Invited',
            failed: 'Failed',
            active: 'Active',
            inactive: 'Inactive',
            blocked: 'Blocked',
            deleted: 'Deleted',
        },
    },
}
