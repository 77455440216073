export default {
    group: {
        move: 'Verschieben',
        expand: 'Ausklappen',
        collapse: 'Zuklappen',
    },
    dataFilter: {
        searchField: 'Feld suchen...',
        addFilter: 'Filter hinzufügen',
        addFilterToGroup: 'Filter zu Gruppe hinzufügen',
        removeFilter: 'Filter entfernen',
        missingValue: 'fehlt ein Wert',
        done: 'Erledigt',
        operator: {
            and: 'und',
            or: 'oder',
        },
        compare: {
            list: {
                contains: 'enthält',
                notContains: 'enthält nicht',
                empty: 'ist leer',
                notEmpty: 'ist nicht leer',
            },
            string: {
                equals: 'ist',
                notEquals: 'ist nicht',
                contains: 'enthält',
                notContains: 'enthält nicht',
                startsWith: 'beginnt mit',
                endsWith: 'endet mit',
                exists: 'hat irgendeinen Wert',
                notExists: 'hat keinen Wert',
            },
            number: {
                equals: 'ist',
                notEquals: 'ist nicht',
                greaterThan: 'größer als',
                lessThan: 'kleiner als',
                exists: 'hat irgendeinen Wert',
                notExists: 'hat keinen Wert',
            },
            date: {
                moreThanDaysAgo: 'vor mehr als',
                exactlyDaysAgo: 'vor exakt',
                lessThanDaysAgo: 'vor weniger als',
                moment: '',
                after: 'nach dem',
                equals: 'am',
                before: 'vor dem',
                exists: 'hat irgendeinen Wert',
                notExists: 'hat keinen Wert',
                groupLabels: {
                    relative: 'Relativ',
                    custom: 'Eigene',
                    absolute: 'Absolut',
                },
                postfixLabels: {
                    days: 'Tage(n)',
                },
                momentValues: {
                    beforeToday: 'Vor heute',
                    beforeOrAtToday: 'Heute oder davor',
                    afterToday: 'Nach heute',
                    afterOrAtToday: 'Heute oder danach',
                },
            },
            bool: {
                isTrue: 'Ist wahr',
                isFalse: 'Ist falsch',
                exists: 'hat irgendeinen Wert',
                notExists: 'hat keinen Wert',
            },
        },
    },
    dataGrid: {
        enabled: 'Aktiviert',
        columnMenu: 'Gruppieren, Sichtbarkeit und mehr',
        pinColumn: 'Spalte anheften',
        valueAggregation: 'Aggregieren',
        autosizeThiscolumn: 'Breite an Inhalt anpassen',
        autosizeAllColumns: 'Alle Spalten an Inhalt anpassen',
        groupBy: 'Gruppieren nach',
        ungroupBy: 'Ungruppieren von',
        resetColumns: 'Alle Spalten zurücksetzen',
        expandAll: 'Alles aufklappen',
        collapseAll: 'Alles zuklappen',
        pinLeft: 'Links anheften',
        pinRight: 'Rechts anheften',
        noPin: 'Nicht anheften',
        searchOoo: 'Suchen...',
        loadingOoo: 'Laden...',
        noRowsToShow: 'Keine Datensätze verfügbar',
        emptyGroup: 'nicht gesetzt',
        expandGroup: 'Gruppe öffnen',
        collapseGroup: 'Gruppe schließen',
        sortAscending: 'Aufsteigend sortieren',
        sortDescending: 'Absteigend sortieren',
    },
    fileInput: {
        upload: 'Datei hochladen',
        remove: 'Datei entfernen',
    },
    select: {
        initialLoading: 'Wird geladen...',
    },
    loadMoreList: {
        loadMoreCount: '+${this.more} weitere',
        loadMoreInfinite: 'Mehr laden',
    },
}
