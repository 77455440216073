const KNOWN_UPDATE_COMMANDS = [
    '$rename',
    '$inc',
    '$mul',
    '$push',
    '$pop',
    '$addToSet',
    '$pull',
    '$set',
    '$unset'
]

export default function convertUpdateDocument(updateData) {
    const updateCommands = Object.keys(updateData)
    if (!updateCommands.length) {
        // nothing to update so return immediately
        return {}
    }

    const finalUpdateData = {}
    let $setIsProvided = false

    for (const updateCommand of updateCommands) {
        if (updateCommand.charAt(0) === '$') {
            if (!KNOWN_UPDATE_COMMANDS.includes(updateCommand)) {
                throw new Error(
                    `Invalid update command ${updateCommand} found, use only one of ${KNOWN_UPDATE_COMMANDS.join(
                        '|'
                    )}`
                )
            }

            $setIsProvided = updateCommand === '$set'

            finalUpdateData[updateCommand] = updateData[updateCommand]
        } else {
            if ($setIsProvided) {
                throw new Error('Cannot provide regular data along with $set command')
            }

            if (updateData[updateCommand] === undefined) {
                // Consider all regular data fields with undefined value as a $UNSET operation
                // to not accidentially overwrite the whole document
                if (!finalUpdateData.$unset) {
                    finalUpdateData.$unset = {}
                }
    
                finalUpdateData.$unset[updateCommand] = undefined
            } else {
                // Consider all regular data fields with value as a $SET operation
                // to not accidentially overwrite the whole document
                if (!finalUpdateData.$set) {
                    finalUpdateData.$set = {}
                }
    
                finalUpdateData.$set[updateCommand] = updateData[updateCommand]
            }
        }
    }

    return finalUpdateData
}
