function calculateResizeWidth({ width, height, maxWidth, maxHeight }) {
    if (height > maxHeight || width > maxWidth) {
        const widthRatio = maxWidth / width
        const heightRatio = maxHeight / height

        const ratio = Math.min(widthRatio, heightRatio)

        return { width: Math.floor(width * ratio), height: Math.floor(height * ratio), ratio }
    }
    return { width: Math.floor(width), height: Math.floor(height), ratio: 1 }
}

export default {
    calculateResizeWidth,
}
