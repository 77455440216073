const compileLanguage = properties => {
    function tokenizeFunction(stringValue) {
        if (/<<.+>>/.test(stringValue)) {
            return function map(callback) {
                let tokenIndex = -1
                return stringValue
                    .split('<<')
                    .map(s => {
                        const r = s.split('>>')
                        if (r.length === 2) {
                            return [{ token: r[0] }, r[1]]
                        }
                        return s
                    })
                    .flat()
                    .filter(s => !!s.token || !!s)
                    .map((s) => {
                        const isToken = !!s.token
                        if (isToken) {
                            tokenIndex += 1
                        }
                        return callback(s.token || s, !!s.token, { tokenIndex: isToken && tokenIndex })
                    })
            }
        }
        return stringValue
    }

    function createTemplateFunction(templateString) {
        const matches = templateString.match(/\$\{[^}]+\}/gm) || []

        // eslint-disable-next-line no-new-func
        const templateFunction = function templateStringFunction() {
            const values = {}

            matches.forEach(match => {
                const fullPath = match.substring(2, match.length - 1)
                const pathes = fullPath.split('.')
                let value = this
                pathes.forEach((path, index) => {
                    // first index must always be this so ignore
                    if (index === 0) {
                        return true
                    }
                    if (value) {
                        value = value[path]
                    }
                    return true
                })
                if (value !== null && value !== undefined) {
                    values[match] = value
                    return true
                }

                return true
            })

            let finalString = templateString
            Object.keys(values).forEach(key => {
                while (finalString.indexOf(key) >= 0) {
                    finalString = finalString.replace(key, values[key])
                }
            })
            return finalString
        }

        // eslint-disable-next-line func-names
        return function(templateVars) {
            return tokenizeFunction(templateFunction.call(templateVars))
        }
    }

    function createMultiTemplateFunction({ single, multi, isMulti }) {
        const singleFunction = createTemplateFunction(single)
        const multiFunction = createTemplateFunction(multi)

        // eslint-disable-next-line func-names
        return function(templateVars, useMulti) {
            const needMulti = useMulti || (isMulti && isMulti(templateVars))
            return needMulti ? multiFunction(templateVars) : singleFunction(templateVars)
        }
    }

    for (const prop in properties) {
        if (Object.prototype.hasOwnProperty.call(properties, prop)) {
            const value = properties[prop]
            if (typeof value === 'string') {
                if (/\$\{.+\}/.test(value)) {
                    properties[prop] = createTemplateFunction(value)
                } else {
                    properties[prop] = tokenizeFunction(value)
                }
            } else if (value.single && value.multi) {
                properties[prop] = createMultiTemplateFunction(value)
            } else {
                compileLanguage(value)
            }
        }
    }
}

export default compileLanguage
