import loadDataUrl from './loadDataUrl'

export default (source, { clearUrl = true } = {}) =>
    new Promise((resolve, reject) => {
        let isObjectUrl = false

        const video = document.createElement('video')

        let loadedListener = null
        let errorListener = null

        const returnHandler = func => {
            if (isObjectUrl && clearUrl) {
                URL.revokeObjectURL(video.src)
            }

            video.removeEventListener('loadeddata', loadedListener)
            video.removeEventListener('error', errorListener)

            func()
        }

        loadedListener = () =>
            returnHandler(() =>
                resolve({
                    video,
                    isObjectUrl,
                })
            )

        errorListener = () => returnHandler(() => reject(new Error('Invalid video')))

        video.addEventListener('loadeddata', loadedListener, false)

        video.addEventListener('error', errorListener, false)

        const setVideoSource = newVideoSrc => {
            video.src = newVideoSrc
            // video.load() is required on Capacitor's when running on iOS
            video.load()
        }

        if (typeof source === 'string') {
            // -- assume data url
            setVideoSource(source)
        } else if (source instanceof Blob || source instanceof File) {
            if (window.URL.createObjectURL) {
                isObjectUrl = true
                setVideoSource(URL.createObjectURL(source))
            } else {
                loadDataUrl(source)
                    .then(src => {
                        setVideoSource(src)
                    })
                    .catch(err => reject(err))
            }
        } else {
            throw new Error('Unknown image source')
        }
    })
