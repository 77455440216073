/* eslint-disable max-len  */
/* eslint-disable no-template-curly-in-string */
export default {
    navigation: {
        userSettings: 'Meine Einstellungen',
        changeOrganisation: '${this.name} wechseln',
        changeApp: 'Anwendung wechseln',
        login: 'Anmelden',
        signup: 'Registrieren',
        logout: 'Abmelden',
        menu: 'Menü',
    },
    app: {
        errors: {
            title: 'Es ist leider ein Fehler aufgetreten',
            backToHome: 'Zurück zur Startseite',
            invalidInvitationToken: 'Der genutzte Einladungs-Link wurde bereits genutzt oder ist ungültig.',
            invalidOrganisation: 'Es konnte kein Eintrag gefunden werden.',
            tooManyOrganisations: 'Du hast die maximale Anzahl von Organisationen überschritten, denen du angehören kannst. Um dieser oder einer anderen Organisation beitreten zu können, musst du zunächst eine deiner aktuellen Organisationen verlassen.',
            noAppAvailable: 'Es stehen keine Anwendungen zur Verfügung.',
            appNotAccessible: 'Kein Zugriff auf die angeforderte Anwendung.',
            customDomainNotFound: 'Die eigene Domain wurde nicht gefunden.',
            unknown: 'Unbekannter Fehler',
        },
        availableApps: 'Verfügbare Anwendungen',
        trialAlert: 'Deine Testversion endet am ${this.date}.',
        trialAction: 'Zur Abrechnung',
        subscription: {
            warning: {
                owner: {
                    title: {
                        permanent: 'Dein Zugang ist blockiert.',
                        temporary: 'Dein Zugang wurde vorübergehend gesperrt.',
                    },
                    past_due: 'Wir konnten dein Abonnement nicht automatisch verlängern.',
                    unpaid: 'Wir konnten dein Abonnement nicht automatisch verlängern.',
                    canceled: 'Dein Abonnement wurde gekündigt.',
                    incomplete: 'Dein Abonnement wurde nicht korrekt aktiviert.',
                    incomplete_expired: 'Dein Abonnement wurde nicht korrekt aktiviert.',
                    noSubscription:
                        'Dein Testzeitraum ist abgelaufen und Du hast kein aktives Abonnement abgeschlossen.',
                    contactSupport: {
                        permanent:
                            'Setze Dich mit uns in Verbindung, um Dein Abonnement zu verlängern. Wenn das Abonnement nicht bis ${this.date} verlängert wird, werden deine Daten endgültig gelöscht.',
                        temporary: 'Nimm Kontakt mit uns auf, um Deinen Zugang wieder freizuschalten.',
                    },
                    actionSupport: 'Nimm Kontakt zu uns auf',
                    actionCreate: 'Neues Abo abschließen',
                },
                user: {
                    title: {
                        permanent: 'Dein Zugang ist blockiert.',
                        temporary: 'Dein Zugang wurde vorübergehend gesperrt.',
                    },
                    text:
                        'Aktuell liegt ein kein aktives Abonnement vor. Die Benutzerrechte deines Benutzerkontos reichen nicht aus um ein Abonnement abzuschließen. Bitte kontaktiere den Eigentümer dieser Organisation oder einen Benutzer mit ausreichenden Benutzerrechten, damit dieser ein Abonnement abschließen kann und somit der Zugang wieder aktiviert wird.',
                },
            },
        },
    },
    auth: {
        chooseAction: {
            title: 'Hast du bereits ein ${this.name} Konto?',
            acceptInvitation:
                'Bitte melde dich an oder erstelle ein neues Konto um die Einladung anzunehmen:',
            loginAction: 'Ich habe bereits ein Konto',
            signUpAction: 'Ich möchte ein Konto anlegen',
        },
        login: {
            title: 'Melde dich bei ${this.name} an',
            action: 'Jetzt anmelden',
            email: 'E-Mail Adresse',
            password: 'Passwort',
            passwordForgotten: 'Passwort vergessen?',
            noAccountSignUp: 'Noch kein Konto? Jetzt ein Konto erstellen.',
            errors: {
                invalidEmail: 'Kein Konto für die E-Mail Adresse gefunden.',
                invalidPassword: 'Falsches Passwort für die E-Mail Adresse.',
                invalidCredentials: 'Falsche E-Mail Adresse oder falsches Passwort.',
                invalidZone: 'Der Benutzer hat keinen Zugang zu diesem Bereich.',
            },
        },
        signUp: {
            title: 'Neues ${this.name} Konto anlegen',
            action: 'Konto anlegen',
            email: 'Deine E-Mail Adresse',
            password: 'Wählen ein Passwort',
            dataContract: '<<Hie klicken>>, um den Auftragsdatenschutzvertrag herunterzuladen.',
            alreadyRegisteredLogin: 'Hast du bereits ein Konto? Dann hier anmelden.',
            errors: {
                invalidEmail: 'Die angegebene E-Mail Adresse ist ungültig.',
                invalidPassword: 'Ungültiges Passwort, mindestens sechs Zeichen erforderlich.',
                alreadySignedUp: 'Es gibt bereits ein Konto mit der E-Mail Adresse, bitte melde dich an.',
                alreadyLoggedIn:
                    'Du bist bereits eingeloggt. Logge dich aus um einen neuen Account zu erstellen.',
            },
        },
        resetPasswordQuery: {
            title: 'Setze dein ${this.name} Passwort zurück',
            action: 'Sende EMail zum zurücksetzen',
            email: 'Registrierte E-Mail Adresse',
            success:
                'Eine EMail wurde an ${this.email} gesendet mit Anweisungen wie du dein Passwort zurücksetzen kannst.',
            rememberPasswordLogin: 'Erinnerst du dich wieder? Dann hier anmelden.',
            errors: {
                invalidEmail: 'Es gibt kein Konto für die angegebene E-Mail Adresse.',
                unknownError: 'An unknown error has occurred',
            },
        },
        resetPasswordAction: {
            title: 'Setze dein neues ${this.name} Passwort',
            action: 'Setze das neue Passwort',
            email: 'Registrierte E-Mail Adresse',
            password: 'Neues Passwort',
            passwordConfirm: 'Wiederhole das neue Passwort',
            success: 'Dein neues Passwort wurder erfolgreich gesetzt. Du kannst dich jetzt damit anmelden.',
            loginNow: 'Melde dich jetzt mit deinen neuen Zugangsdaten ein.',
            errors: {
                passwordMissmatch: 'Die Passwörter stimmen nicht überein.',
                expiredToken: 'Der Link ist abgelaufen. Bitte setze dein Passwort erneut zurück.',
                invalidToken: 'Der Link ist ungültig. Bitte setze dein Passwort erneut zurück.',
                invalidEmail: 'Es gibt kein Konto für die angegebene E-Mail Adresse.',
                invalidPassword: 'Ungültiges Passwort, mindestens sechs Zeichen erforderlich.',
            },
        },
    },
    manageOrganisations: {
        newOrganisation: 'Neue Organisation anlegen',
        yourOrganisations: 'Deine Organisationen',
        emptySearch: 'Keine Organisation gefunden',
        moveOrganisation: 'Organisation verschieben',
        leaveOrganisation: 'Organisation verlassen',
        create: {
            label: 'Erstelle eine neue Organisation',
            description: '',
            name: 'Name deiner Organisation',
            country: 'In welchem Land befindet sich deine Organisation?',
            action: 'Organisation jetzt erstellen',
        },
        leaveOrganisationPrompt: {
            title: '${this.organisationName} verlassen',
            text: 'Bist du dir sicher, dass du "${this.organisationName}" verlassen möchtest?',
            action: 'Ja, Organisation verlassen',
            errors: {
                onlyOwner:
                    'Du kannst diese Organisation nicht verlassen, da du der einzige Benutzer bist. Sofern gewünscht, kannst du diese Organisation in den Einstellungen löschen.',
            },
        },
    },
    organisationSettings: {
        profile: {
            title: 'Profil',
            uploadAvatarButton: 'Neues Bild hochladen',
            information: {
                title: 'Profil-Einstellungen',
            },
            uniqueSlug: {
                title: 'Kennung',
                description:
                    'Die Kennung wird verwendet, um eine gut lesbare URL bereitzustellen und wird auch als Absender-Name in E-Mails verwendet. Achtung: Falls deine URL schon verwendet wird und du änderst deine Kennung, so werden alle alten URLs ungültig.',
                action: 'Neue Kennung setzen',
                errors: {
                    invalidSlug:
                        'Diese Kennung ist ungültig. Wähle bitte eine andere und probiere es erneut.',
                    forbidden: 'Du hast keine Berechtigung um diese Kennung zu ändern.',
                    alreadyClaimed: 'Diese Kennung wird bereits von jemand anderem verwendet.',
                    unknown: 'Es ist ein unbekannter Fehler aufgetreten.',
                },
                success: 'Die Kennung wurde erfolgreich gesetzt und deine URL geändert.',
            },
            dangerZone: {
                title: 'Gefahrenzone',
                deleteOrganisation: {
                    title: '${this.organisationName} löschen',
                    description:
                        'Lösche unwiderruflich alle Daten von "${this.organisationName}". Achtung, dies kann nicht rückgängig gemacht werden!',
                    action: 'Löschen',
                },
                deleteOrganisationModal: {
                    title: '${this.organisationName} löschen',
                    text:
                        'Bist Du absolut sicher, dass Du "${this.organisationName}" und alle damit verbundenen Daten löschen willst? Dieser Vorgang kann nicht rückgängig gemacht werden.',
                    input: {
                        text:
                            'Wenn Du fortfahren möchtest, gib bitte den Text "${this.organisationName}" in das Feld darunter ein:',
                        action: 'Jetzt löschen',
                    },
                },
            },
        },
        billing: {
            title: 'Abrechnung & Abos',
            products: {
                pricing: {
                    flat: {
                        month: '${this.amount} / Monat',
                        year: '${this.amount} / Jahre',
                    },
                    metered: '${this.amount} pro Mitglied / Monat',
                    tiered:
                        '${this.flatAmount} für die ersten ${this.flatLimit} Mitglieder. ${this.meteredAmount} für jede weiteren Mitglieder.',
                    tieredLabel: 'Basierend auf der Nutzung',
                },
            },
            subscription: {
                title: 'Abonnement',
                empty: 'Kein Abonnement verfügbar.',
                priceLink: 'Preisplan öffnen',
                trial: {
                    title: 'Abonnement auf Probe',
                    text:
                        'Das Abonnement "${this.name}" ist bis ${this.date} auf Probe. Um weiterhin Zugriff zu haben, musst Du das Abonnement bis zum Ende der Testphase abschließen.',
                    action: 'Jetzt abonnieren!',
                    price: 'Preis: ${this.price}',
                    priceLink: 'Preisplan öffnen',
                },
                status: {
                    none: 'Kein Abonnement',
                    incomplete: 'Unvollständig',
                    incomplete_expired: 'Abgelaufen',
                    trialing: 'Probezeit bis ${this.date}',
                    active: 'Aktiv',
                    past_due: 'Überfällig',
                    canceled: 'Abgesagt',
                    unpaid: 'Unbezahlt',
                    will_be_canceled: 'Stornierung am ${this.date}',
                },
                switchPrice: 'Preisplan wechseln',
                createSubscription: 'Abonnieren',
                newSubscription: 'Neues Abonnement abschließen',
                reactivateSubscription: 'Abbestelltes Abonnement reaktivieren',
                cancel: {
                    action: 'Abonnement kündigen',
                    prompt:
                        'Bist du sicher, dass Du dieses Abonnement kündigen möchtest? Es wird zum Ende des laufenden Zeitraums gekündigt. Dieser Vorgang kann nicht rückgängig gemacht werden.',
                },
                activate: {
                    action: 'Abonnement freischalten',
                },
                editor: {
                    product: 'Product',
                    price: 'Preisplan',
                    title: 'Wähle das gewünschte Produkt und den Preisplan aus:',
                    serviceTitle:
                        'Der Dienst "${this.service}" wird verwendet aber wurde nicht abonniert. Melde Dich jetzt bei einem der Dienste an, um fortzufahren:',
                },
                unitAmount: '${this.amount} pro Mitglieder',
                service: {
                    usage: 'Verwendung: ${this.usage}',
                    costs: 'Kosten: ${this.costs}',
                },
                applyDiscount: {
                    action: 'Deinen Rabattcode einlösen',
                    tooltip: 'Du kannst noch ${this.hoursLeft} Stunden deinen Rabattcode einlösen',
                    dialog: {
                        title: 'Bitte gib deinen Gutscheincode ein, den du einlösen möchtest:',
                        action: 'Rabattcode einlösen',
                    },
                    errors: {
                        invalidPromotion: 'Dieser Rabattcode ist nicht mehr gültig.',
                        codeNotFound: 'Dieser Rabattcode existiert nicht',
                        unknownError:
                            'Ein unbekannter Fehler ist aufgetreten. Bitte versuche es noch einmal.',
                    },
                },
            },
            credits: {
                title: 'Guthaben',
                current: 'Aktuelles Guthhaben: ${this.credits}',
                charge: 'Guthaben aufladen',
                amount: 'Aufzuladender Betrag',
                minimun: 'Erforderlicher Mindestbetrag: ${this.amount}',
            },
            services: {
                title: 'Services und Nutzung',
                emptyHistory: 'Es gibt keine früheren Einträge für diesen Dienst',
                noCosts:
                    'Die Kosten werden erst berechnet, wenn Du ein aktives Abonnement für den Dienst hast.',
                history: 'Verwendungshistorie für den Dienst ${this.service}',
                sections: {
                    allTime: 'Allzeitnutzung',
                    monthly: 'Verwendung für ${this.month}.${this.year}',
                },
                table: {
                    service: 'Dienst',
                    usage: 'Verwendung',
                    costs: 'Kosten',
                    period: 'Zeitraum',
                    lastUpdated: 'Zuletzt aktualisiert',
                    subscription: 'Abonnement',
                    actions: {
                        seeHistory: 'siehe Verlauf der Servicenutzung',
                    },
                },
            },
            paymentMethods: {
                title: 'Zahlungsmethoden',
                typeValue: {
                    card:
                        'Kreditkarte (${this.card.brand}) **** ${this.card.last4} - gültig bis ${this.card.exp_month}/${this.card.exp_year}',
                    sepa_debit: 'Sepa-Lastschrift **** ${this.sepa_debit.last4}',
                },
                default: 'Standard',
                makeDefault: 'Zahlungsmethode als Standard festlegen',
                delete: {
                    action: 'Zahlungsmethode löschen',
                    prompt:
                        'Bist Du sicher, dass Du diese Zahlungsmethode löschen möchtest? Dieser Vorgang kann nicht rückgängig gemacht werden.',
                },
                add: 'Zahlungsmethode hinzufügen',
            },
            details: {
                title: 'Rechnungsdetails',
                email: 'E-Mail für Rechnungen',
                name: 'Rechnungsempfänger',
                address: {
                    title: 'Rechnungsanschrift',
                    line1: 'Anschriftszeile 1',
                    line2: 'Anschriftszeile 2',
                    postal_code: 'Postleitzahl',
                    city: 'Stadt',
                    country: 'Land',
                },
            },
            invoices: {
                title: 'Rechnungshistorie',
                number: 'Rechnung Nr.',
                amountPaid: 'Bezahlt',
                amountTotal: 'Insgesamt',
                date: 'Datum',
            },
        },
        users: {
            title: 'Benutzerverwaltung',
            table: {
                name: 'Name',
                email: 'E-Mail Adresse',
                status: 'Status',
                createdAt: 'Erstellt am',
                isOwner: 'Eigentümer',
                loadMore: 'Mehr Benutzer laden...',
                editUser: 'Benutzer bearbeiten',
                deleteUser: 'Benutzer löschen...',
                getLink: 'Einladungslink kopieren',
            },
            toolbar: {
                userCount: {
                    single: 'Benutzer',
                    multi: 'Benutzer',
                },
                search: 'Suche nach Name oder E-Mail',
                addUser: 'Benutzer einladen',
            },
            userSettings: {
                name: 'Name des Benutzers',
                email: 'E-Mail Adresse',
                inviteUser: 'Benutzer einladen',
                eMailAlreadyExists:
                    'Es existiert bereits ein Benutzer mit der E-Mail Adresse "${this.email}".',
                permissions: {
                    owner: {
                        checkbox: 'Diesen Benutzer zum Eigentümer machen',
                        hint:
                            'Eigentümer haben alle Berechtigungungen und können sich alles anzeigen lassen sowie bearbeiten ohne Beschränkungen.',
                        deniedHint:
                            'Du hast nicht die erforderlichen Berechtigungen um den Eigentümer zu setzen',
                    },
                    header: {
                        permission: 'Lesen von',
                        edit: 'Bearbeiten erlaubt',
                    },
                    toggleCustomTooltip: 'Erweiterte Zugriffsrechte',
                    customOptions: {
                        read: 'Lesen',
                        insert: 'Erstellen',
                        edit: 'Bearbeiten',
                        remove: 'Löschen',
                    },
                    test: 'Live Modus',
                    testTooltip:
                        'Teste in Echtzeit, wie sich die Änderungen auf die angepassten Benutzerrechte auswirken',
                    debugContext: {
                        title: 'Live-Test der Benutzerrechte',
                        finish: 'Bestätigen',
                    },
                    apps: {
                        accessTo: 'Erlaubt Zugriff auf',
                        noAccess: 'Kein Zugriff - Klick um Zugriff erlauben',
                        hasAccess: 'Zugriff erlaubt',
                    },
                },
            },
            deleteUser: {
                title: 'Benutzer löschen',
                text: 'Bist du dir sicher, dass du ${this.userName} aus ${this.orgName} löschen möchtest?',
                action: 'Benutzer löschen',
            },
            copyInvitationLink: {
                title: 'Einladungslink',
                text:
                    'Der eingeladene Benutzer kann diesen Link nutzen, um die Einladung anzunehmen und ${this.orgName} beizutreten.',
                tooltipCopy: 'Link kopieren',
                tooltipCopied: 'Link wurde in die Zwischenablage kopiert!',
            },
            debug: {
                description:
                    'Alle Änderungen der Benutzerrechte werden in Echtzeit in der aktuellen Sitzung widergespiegelt. Die Änderungen werden rückgängig gemacht, sobald das Fenster geschlossen wurde.',
                transferUser: 'Benutzerrechte zuweisen',
                transferUserHint:
                    'Sofern das Häkchen gesetzt ist, werden die Änderungen der Benutzerrechte nach dem Schließen des Fensters auf den Benutzer übertragen, der erstellt/bearbeitet wurde.',
            },
        },
    },
    userSettings: {
        profile: {
            title: 'Profil',
            name: 'Dein Name (sichtbar für Andere)',
            email: 'E-Mail Adresse',
            bio: 'Über dich',
            language: 'Bevorzugte Sprache',
            deleteAccount: {
                title: 'Gefahrenzone',
                description:
                    'Meinen Account mit der E-Mail (${this.email}) löschen. Achtung! Dieser Vorgang kann nicht rückgängig gemacht werden.',
                action: 'Löschen',
            },
            deleteAccountModal: {
                title: '${this.email} löschen',
                text:
                    'Bist du dir sicher, dass du den Benutzer mit der E-Mail (${this.email}) löschen möchtest? Achtung! Dieser Vorgang kann nicht rückgängig gemacht werden.',
                input: {
                    verifyWord: 'Löschen',
                    text:
                        'Trage das folgende Wort im Feld ein um den Vorgang zu bestätigen: "${this.verifyWord}"',
                    action: 'Jetzt löschen',
                },
            },
        },
        developer: {
            title: 'Entwickler + API',
            apiDocumentation: {
                title: 'API-Dokumentation',
                intro:
                    'Die API-Referenz enthält die Dokumentation über alle Funktionen. Du benötigst einen API-Schlüssel um die Beispiele auszuprobieren.',
                link: 'Zur API-Dokumentation',
            },
            apiKeys: {
                title: 'API-Schlüssel',
                intro:
                    'Jeder API-Schlüssel besitzt diesselben Rechte wie dein Benutzerkonto. Wenn du getrennte Rechte haben möchtest lege ein neues Benutzerkonto dafür an.',
                newKeyAction: 'Neuen Schlüssel erstellen',
                newKeyName: 'Name des Schlüssels',
                copyWarning:
                    'Bitte kopiere und speichere diesen Schlüssel da dieser danach nie mehr angezeigt werden wird.',
                copyKey: 'Schlüssel kopieren',
                copyKeyDone: 'Schlüssel wurde in die Zwischenablage kopiert!',
                deleteKeyTitle: 'Schlüssel löschen',
                deleteKeyPrompt:
                    'Bist du sicher dass du den Schlüssel löschen möchtest? Dies kann nicht rückgängig gemacht werden und alle Anwendungen die den Schlüssel verwenden, haben keinen Zugriff mehr.',
                deleteKeyAction: 'Ja, den Schlüssel löschen',
            },
        },
    },
}
