import React, { useCallback, useMemo } from 'react'
import { observer } from 'mobx-react-lite'

import { SortableHandle } from 'react-sortable-hoc'

import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import Link from '@newspaces/lucky-components/Link'
import Button from '@newspaces/lucky-components/Button'
import Avatar from '@newspaces/lucky-components/Avatar'
import baseUtils from '@newspaces/lucky-base/utils'
import Runtime from '@newspaces/lucky-base/Runtime'

import i18n from '../../i18n'
import AppContext from '../../AppContext'
import { FavoriteIcon, DragDropIcon, LogoutIcon } from '../../Icons'

import leaveOrganisation from './leaveOrganisation'

const useStyles = makeStyles(theme => ({
    listItem: {
        minWidth: 400,
        [theme.breakpoints.down('xs')]: {
            minWidth: 'auto',
        },
        '& .favorite, & .move, & .leave': {
            opacity: AppContext.isMobile ? 1 : 0,
        },
        '&:hover .favorite, & .favorite.isFavorite, &:hover .move, &:hover .leave': {
            opacity: 1,
            color: theme.palette.text.primary,
        },
        '& .favorite.isFavorite .MuiSvgIcon-root': {
            filter: 'none',
        },
        '& .actions': {
            display: 'flex',
            alignItems: 'center',
        },
    },
}))

const DragHandle = SortableHandle(() => (
    <Tooltip title={i18n().manageOrganisations.moveOrganisation} className="move">
        <Button isIcon>
            <DragDropIcon />
        </Button>
    </Tooltip>
))

export default observer(({ organisation, allowMoveOrganisations, changeFavorite, isFavorite }) => {
    const classes = useStyles()

    const handleToggleFavorite = useCallback(evt => {
        evt.preventDefault()
        evt.stopPropagation()
        const newFavorite = isFavorite ? undefined : organisation.id
        changeFavorite(newFavorite)
    }, [isFavorite, organisation, changeFavorite])

    const handleLeaveOrganisation = useCallback(evt => {
        evt.preventDefault()
        evt.stopPropagation()
        leaveOrganisation(organisation)
    }, [organisation])

    const organisationUrl = useMemo(() => {
        const { hostname } = window.location
        const isDevelopmentHostname = baseUtils.url.isDevelopmentHostname(hostname)
        if (isDevelopmentHostname) {
            return AppContext.getRootUrl(null, organisation.slugOrId)
        }

        return Runtime.getAppUrl(`/${organisation.slugOrId}`, { organisation })
    }, [organisation])

    return (
        <ListItem
            button
            classes={{ root: classes.listItem }}
            component={Link}
            href={organisationUrl}
            color="inherit"
            underline="none"
        >
            <ListItemAvatar>
                <Avatar
                    variant="none"
                    src={organisation.avatar.url}
                    name={organisation.name}
                />
            </ListItemAvatar>
            <ListItemText primary={organisation.name} />
            <div className="actions">
                {allowMoveOrganisations && <DragHandle />}
                <Tooltip title={i18n().manageOrganisations.leaveOrganisation}>
                    <Button
                        isIcon
                        className="leave"
                        onClick={handleLeaveOrganisation}
                    >
                        <LogoutIcon />
                    </Button>
                </Tooltip>
                <Tooltip
                    title={i18n().misc[isFavorite ? 'clearFavorite' : 'makeFavorite']}
                >
                    <Button
                        isIcon
                        className={classNames('favorite', { isFavorite })}
                        onClick={handleToggleFavorite}
                    >
                        <FavoriteIcon />
                    </Button>
                </Tooltip>
            </div>
        </ListItem>
    )
})