
import Runtime from '@newspaces/lucky-base/Runtime'

const customDomainStorageKey = 'CustomDomainInfo'

export default async hostname => {
    const normalizedHostname = hostname.toLowerCase()

    try {
        const storedCustomDomain = window.localStorage.getItem(customDomainStorageKey)
        if (storedCustomDomain) {
            return JSON.parse(storedCustomDomain)
        }
    } catch (e) {
        // in case it fails, does nothing, just let the function run and call api
    }

    try {
        const customDomainInfo = await Runtime.apiMethod(
            `get:/organisations/checkCustomDomain`,
            { domain: normalizedHostname }
        )

        window.localStorage.setItem(customDomainStorageKey, JSON.stringify(customDomainInfo))

        return customDomainInfo
    } catch (e) {
        // does nothing
        return null
    }
}