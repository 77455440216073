import formatNumber from './formatNumber'

const truncDecimals = (value, decimals) => {
    const pow = 10 ** decimals
    return Math.trunc(value * pow) / pow
}

const format = (locale, value, decimals) => {
    return formatNumber(
        locale,
        value,
        {
            currency: false,
            minimumFractionDigits: 0,
            maximumFractionDigits: decimals
        }
    )
}

export default (locale, number) => {
    const integerNumber = Math.trunc(number)

    if (integerNumber < 1000) {
        return integerNumber.toString()
    }

    let decimals = 1
    let base = 1000
    let char = 'k'

    if (integerNumber >= 1000000) {
        decimals = 1
        base = 1000000
        char = 'M'
    } else if (integerNumber >= 10000) {
        decimals = 0
    }

    const truncNumber = truncDecimals((integerNumber / base), decimals)
    return `${format(locale, truncNumber, decimals)}${char}`
}
