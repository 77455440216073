export default (value, isRequired, validationSettings, DateTimeClass) => {
    if (isRequired && !value) {
        return 'required'
    }

    if (value !== null && value !== undefined && !(value instanceof DateTimeClass)) {
        return 'invalidType'
    }

    return null
}
