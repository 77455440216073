import React, { useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'

import baseUtils from '@newspaces/lucky-base/utils'
import Runtime from '@newspaces/lucky-base/Runtime'

import Button from '@newspaces/lucky-components/Button'
import TextInput from '@newspaces/lucky-components/TextInput'
import Select from '@newspaces/lucky-components/Select'

import AppContext from '../AppContext'

import AppFrame from '../App/AppFrame'

import i18n from '../i18n'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4, 4),
        maxWidth: 500,
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(4, 2),
        },
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 18,
        fontWeight: 500,
        marginBottom: theme.spacing(2),
    },
    description: {
        fontSize: 16,
        color: theme.palette.text.secondary,
        textAlign: 'center',
        marginBottom: theme.spacing(3),
    },
}))

const instantiateOrganisation = (zone, userData) => {
    let defaultCountry = zone.defaultCountry
    if (Runtime.Organisation.DefaultCountry) {
        defaultCountry = Runtime.Organisation.DefaultCountry
    } else if (AppContext.deviceLanguage && zone.countries.includes(AppContext.deviceLanguage)) {
        defaultCountry = AppContext.deviceLanguage
    }

    return new Runtime.Organisation({
        name: '',
        zone: zone.key,
        country: defaultCountry,
        language: userData.language || i18n().language,
    })
}

export default observer(({ renderAppFrame }) => {
    const classes = useStyles()

    const { zone, userData } = AppContext

    const [organisation] = useState(instantiateOrganisation(zone, userData))

    const [isSaving, setSaving] = useState(false)

    const canCreateOrg = organisation.isValid

    const handleChangeCountry = country => {
        organisation.country = country
    }

    const handleChangeName = name => {
        organisation.name = name
    }

    const handleSubmit = async evt => {
        evt.preventDefault()

        setSaving(true)

        organisation.owner = userData.userId
        organisation.currency = baseUtils.countries[organisation.country]?.currency

        // just ignore the "_id" because it is null
        const organisationDocument = organisation.serialize({
            mode: 'save',
            addTestUUID: process.env.NODE_ENV === 'test',
        })

        // remove _id
        delete organisationDocument._id

        let organisationCreatedId
        try {
            organisationCreatedId = await Runtime.apiMethod(
                '/organisations',
                organisationDocument
            )
            await organisation.load(organisationCreatedId)
        } catch {
            organisationCreatedId = null
        }

        if (!organisationCreatedId) {
            setSaving(false)
            alert(i18n().misc.errors.unknown)
            return
        }

        try {
            await AppContext.waitForNewOrganisation(organisationCreatedId)

            if (organisation.uniqueSlug) {
                // Reload now with the new user organisation
                window.location = `/${organisation.uniqueSlug}`
            } else {
                // If for some reason, organisation wasnt loaded, we redirect to /organisations
                window.location = '/organisations'
            }
        } catch (e) {
            console.error(e)
            alert(i18n().misc.errors.unknown)
        }
    }

    const countryOptions = useMemo(() => {
        const countries = zone.countries?.length ? zone.countries : baseUtils.countries.codes

        return countries.map(countryCode => ({
            value: countryCode,
            label: baseUtils.countries.getCountryName(countryCode),
        }))
    }, [zone.countries])

    const Content = (
        <div className={classes.root}>
            <div className={classes.title}>{i18n().manageOrganisations.create.label}</div>
            <div className={classes.description}>{i18n().manageOrganisations.create.description}</div>
            <form className={classes.form} onSubmit={handleSubmit}>
                <TextInput
                    fullWidth
                    variant="contained"
                    margin="normal"
                    label={i18n().manageOrganisations.create.name}
                    value={organisation.name}
                    onChange={handleChangeName}
                    autoFocus
                    required
                />
                {Runtime.Organisation.Fields.country.required && (
                    <Select
                        margin="normal"
                        label={i18n().manageOrganisations.create.country}
                        value={organisation.country}
                        onChange={handleChangeCountry}
                        options={countryOptions}
                        required
                        fullWidth
                    />
                )}
                {AppContext.CreateOrganisationForm && (
                    <AppContext.CreateOrganisationForm organisation={organisation} />
                )}
                <FormControl margin="normal" fullWidth>
                    <Button
                        fullWidth
                        type="submit"
                        size="large"
                        color="primary"
                        variant="contained"
                        disabled={!canCreateOrg}
                        loading={isSaving}
                    >
                        {i18n().manageOrganisations.create.action}
                    </Button>
                </FormControl>
            </form>
        </div>
    )

    if (renderAppFrame) {
        return <AppFrame>{Content}</AppFrame>
    }

    return Content
})
