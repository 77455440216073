import i18n from '@newspaces/lucky-base/i18n'

import * as muiLocales from '@material-ui/core/locale'
import deLanguage from './languages/de'
import enLanguage from './languages/en'
import itLanguage from './languages/it'
import frLanguage from './languages/fr'

i18n.registerLanguage('de', deLanguage)
i18n.registerLanguage('en', enLanguage)
i18n.registerLanguage('it', itLanguage)
i18n.registerLanguage('fr', frLanguage)

// Enhance with util to return the proper mui locale for a given locale setup
i18n.getMuiLocale = locale => {
    let actualLocale = locale

    if (!locale) {
        actualLocale = i18n.getDefaultLocale()
    }

    const parts = actualLocale.split('-')
    const language = parts[0]
    const country = parts[1]

    const muiLong = `${language}${country}`
    const muiShort = language

    if (muiLocales[muiLong]) {
        return muiLocales[muiLong]
    }

    if (muiLocales[muiShort]) {
        return muiLocales[muiShort]
    }

    // Search for language only
    for (const muiLocale in muiLocales) {
        if (muiLocale.substr(0, 2) === muiShort) {
            return muiLocales[muiLocale]
        }
    }

    // Return english as default when coming here
    return muiLocales.enUS
}

export default i18n
