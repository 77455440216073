import baseUtils from '@newspaces/lucky-base/utils'
import Runtime from '@newspaces/lucky-base/Runtime'

// eslint-disable-next-line import/no-named-as-default
import AppContext from '../AppContext'

import getCustomDomainInfo from './getCustomDomainInfo'
import getSubdomainInfo from './getSubdomainInfo'

//
// This bootstraps the current zone and app. It'll also extract
// the organisation (or user) routes.
//
export default async ({
    Organisation,
    OrganisationUser,
    UserData,
    zones,
    apps,
    location = window.location,
    doBootstrapRuntime = true,
    CreateOrganisationForm,
    checkCustomDomain,
}) => {
    const { hostname, search, pathname, href } = location
    const normalizedHostname = hostname.toLowerCase()
    const parsedQuery = baseUtils.url.parseQueryString(search)

    let zone = null
    const defaultZone = zones.find(({ isDefault }) => isDefault)

    const isDevelopmentHostname = baseUtils.url.isDevelopmentHostname(hostname)
    const isCustomDomain = !zones.some(({ domain }) => {
        const normalizedDomain = domain.toLowerCase()
        return normalizedDomain === normalizedHostname || normalizedHostname.includes(`.${normalizedDomain}`)
    })
    const shouldCheckCustomDomain = isCustomDomain && checkCustomDomain && !isDevelopmentHostname && !AppContext.isMobile

    AppContext.invitationToken = parsedQuery.invitationToken || null
    AppContext.resetPasswordToken = parsedQuery.resetPasswordToken || null
    AppContext.returnTo = parsedQuery.returnTo || null

    //
    // First figure the appropriate zone based on the current domain.
    // If we're working on localhost the zone must come from the query
    // parameter initially or it is the default zone.
    //
    if (isDevelopmentHostname) {
        if (!parsedQuery.zone) {
            // On localhost we allow a default zone for easier development
            zone = defaultZone
        } else {
            zone = zones.find(({ key }) => key === parsedQuery.zone)
        }

        if (!zone) {
            throw new Error(`No valid zone found for zone "${parsedQuery.zone}".`)
        }
    } else {
        zone = zones.find(({ domain }) => normalizedHostname.endsWith(domain.toLowerCase()))

        if (!zone) {
            if (!shouldCheckCustomDomain) {
                throw new Error(`No valid zone found for domain "${normalizedHostname}".`)
            }
            zone = defaultZone
        }
    }

    //
    // As we have a zone now, we're ready to bootstrap our runtime
    //
    if (doBootstrapRuntime) {
        await Runtime.bootstrapRuntime({
            Organisation,
            OrganisationUser,
            UserData,
            zone,
            initUserData: false,
        })
    }

    let domainOrganisationId
    if (shouldCheckCustomDomain) {
        // To getCustomDomain info we need the Runtime.zone set
        Runtime.zone = defaultZone
        const customDomainInfo = await getCustomDomainInfo(hostname)
        if (customDomainInfo) {
            const { zone: customDomainZoneKey, organisationId } = customDomainInfo 
            domainOrganisationId = organisationId
            zone = zones.find(({ key }) => key === customDomainZoneKey)

            if (!zone) {
                throw new Error(`Zone "${customDomainZoneKey}" not found.`)
            }

            Runtime.customDomain = normalizedHostname
            Runtime.zone = zone
        }

        // If running on a custom domain and the organisation is not found, sets error on AppContext
        if (!domainOrganisationId) {
            AppContext.error = 'customDomainNotFound'
        }
    }

    // Assign zone and apps to AppContext
    AppContext.zone = zone
    AppContext.allApps = apps
    AppContext.CreateOrganisationForm = CreateOrganisationForm

    // Get info from subdomain
    let subdomainSlug
    let subdomainType
    if (!Runtime.customDomain) {
        const { type, slug } = getSubdomainInfo(href, apps)
        subdomainSlug = slug
        subdomainType = type
        Runtime.currentSubdomainType = type
    }

    //
    // Split up our domain which should be either
    //
    // /[globalAppKey]/*
    //
    // or
    //
    // /[organisationSlugOrId]/appKey/*
    //
    const pathnames = pathname.split('/')

    const findGlobalApp = () => {
        const findGlobalAvailableApp = appKey => AppContext.availableApps.find(
            ({ key, isGlobal }) => isGlobal && key.toLowerCase() === appKey.toLowerCase()
        )

        if (subdomainType === 'app') {
            return findGlobalAvailableApp(subdomainSlug) || findGlobalAvailableApp(pathnames[1])
        }

        return findGlobalAvailableApp(pathnames[1])
    }

    //
    // First check if we're trying to access a global app
    // /globalAppKey/*
    //
    const globalApp = findGlobalApp()
    if (globalApp) {
        AppContext.app = globalApp
    } else {
        let organisationSlugOrId
        let appKey

        //
        // Takes organisation from the base url and local app (optionally) from route
        //
        if (Runtime.customDomain) {
            // mycustomDomain.com/[appKey]/*
            appKey = pathnames[1] || null
            organisationSlugOrId = domainOrganisationId
        } else if (subdomainType === 'app') {
            // app-key.luckyshot.io/organisationSlugOrId/*
            organisationSlugOrId = pathnames[1] || null
            appKey = subdomainSlug
        } else {
            // luckyshot.io/organisationSlugOrId/[appKey]/*
            organisationSlugOrId = pathnames[1] || null
            appKey = pathnames[2] || null
        }

        const baseRoutes = ['auth', 'organisations', 'error', 'apps']
        const startRoute = pathnames[1] && baseRoutes.find(route => route.toLowerCase() === pathnames[1].toLowerCase())
        if (startRoute) {
            AppContext.startRoute = startRoute
            if (organisationSlugOrId?.toLowerCase() === startRoute.toLowerCase()) {
                organisationSlugOrId = null
            }
        }

        // If app key was defined on query uses it, otherwise use app key from path
        appKey = parsedQuery.app || appKey

        AppContext.organisationSlugOrId = organisationSlugOrId
        AppContext.app =
            (appKey && apps.find(({ key }) => key.toLowerCase() === appKey.toLowerCase())) || null
    }

    const hasAppThatCustomizesFavicon = apps.some(app => app.customizedFavicon)
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    // At this point we update the favicon, title etc in case there's no app that
    // customizes the favicon (so we avoid changing the icon 2 times - flickering)
    // or the navigator is Safari (where after this point the favicon can not be changed)
    if(!hasAppThatCustomizesFavicon || isSafari) {
        AppContext.updateDocumentTitle()
    }
}
