export default (userScopes, scope) => {
    if (!userScopes || Object.keys(userScopes).length === 0) {
        return false
    }

    if (userScopes.$owner === true) {
        return true
    }

    const scopeParts = scope.split('.')
    const scopeKey = scopeParts[0]
    const scopeType = scopeParts[1] || null

    const scopeData = userScopes[scopeKey]

    if (!scopeData) {
        return false
    }

    if (scopeType === 'insert' && !scopeData.insert) {
        return false
    }

    if (scopeType === 'edit' && !scopeData.edit) {
        return false
    }

    if (scopeType === 'remove' && !scopeData.remove) {
        return false
    }

    return true
}
