import SetupNotificationsMobile from './setupNotifications.mobile'
import SetupNotificationsBrowser from './setupNotifications.browser'

// eslint-disable-next-line
let setupNotifications

if (process.env.RUNTIME_TARGET === 'browser') {
    setupNotifications = SetupNotificationsBrowser
}

if (process.env.RUNTIME_TARGET === 'mobile') {
    setupNotifications = SetupNotificationsMobile
}

export default setupNotifications
