import cloneDeep from 'clone-deep'

import Runtime from '../../Runtime'

import DateTime from '../../DateTime'

import { aquireWatcher, releaseWatcher, canWatch } from '../watchers'

import convertUpdateDocument from '../convertUpdateDocument'

import cachedQueryExecution, { removeCachedData } from './cachedQueryExecution'
import normalizeQueryValues from './normalizeQueryValues'
import createFieldProjection from './createFieldProjection'
import createBreakdownPipeline from './breakdown/createBreakdownPipeline'
import convertBreakdownData from './breakdown/convertBreakdownData'

class Query {
    static canWatch = canWatch()

    modelClass

    sort

    documents = null

    count = null

    loading = false

    projection = []

    _watcher = null

    _throwSecurityError = false

    _originalQuery

    _query

    constructor(
        ModelClass,
        query,
        sort,
        { allowRemoved = false, ignoreTestUUID = false, projection = [], throwSecurityError = false } = {}
    ) {
        // we need to decorate default properties asap
        if (Runtime.mobx) {
            const { observable, computed, makeObservable } = Runtime.mobx
            makeObservable(this, {
                documents: observable,
                loading: observable,
                count: observable,
                hasMore: computed,
            })
        }

        if (!query || typeof query !== 'object') {
            throw new Error('Invalid query parameter.')
        }

        // First we make a clone of the query, so we dont change the original object
        const clonedQuery = cloneDeep(query)

        // Store non-normalized query to use externally and when cloning
        this._originalQuery = cloneDeep(query)

        // Normalize query first before it gets parsed into BSON
        normalizeQueryValues(clonedQuery, '', {})

        this.modelClass = ModelClass
        this._query = {
            ...this._parseQuery(clonedQuery),
            removedAt: null,
        }

        if (allowRemoved) {
            delete this._query.removedAt
        }

        this.projection = createFieldProjection(ModelClass, projection)

        this.sort = sort
        if (!this.sort) {
            this.sort = { _id: -1 }
        }

        // Since MongoDB 4.4, sorting fields that have duplicated values might be inconsistent
        // so we need to always apply a sort by _id on top of our existing sort to ensure that
        // different executions of the same query will return the same sorted result.
        // See: https://docs.mongodb.com/manual/reference/method/cursor.sort/#sort-consistency
        if (!this.sort._id) {
            this.sort._id = -1
        }

        this._throwSecurityError = throwSecurityError

        // Ugly hack - in test mode we add an "invisible" uuid to the query
        // so we hopefully don't clanch with other tests running in parallel
        if (process.env.NODE_ENV === 'test' && !ignoreTestUUID) {
            this._query.__testUUID = process.env.MODEL_TEST_UUID
        }
    }

    getDocumentById(documentId) {
        if (this.documents) {
            return this.documents.find(document => document.id === documentId.toString())
        }
        return null
    }

    // eslint-disable-next-line class-methods-use-this
    _parseQuery(query) {
        if (process.env.RUNTIME_TARGET === 'backend') {
            // do NOT normalize the query when running on backend. we already use BSON from
            // the environment itself so it would only break the existing values
            return query
        }

        return Runtime.BSON.deserialize(Runtime.BSON.serialize(query))
    }

    clone(query, sort, options) {
        const newSort = sort || this.sort

        const { removedAt, ...myQuery } = this._originalQuery

        return new Query(this.modelClass, { ...query, ...myQuery }, newSort, options)
    }

    get _collection() {
        return Runtime.db.collection(this.modelClass.CollectionName)
    }

    get hasMore() {
        if (typeof this.count === 'number' && this.count >= 0 && this.documents) {
            return this.count > this.documents.length
        }
        return false
    }

    get query() {
        return this._originalQuery
    }

    _call(method, ...args) {
        return Runtime.call(`db.${method}`, this.modelClass.CollectionName, ...args)
    }

    async getCount() {
        this.count = await this._collection.count(this._query)

        return this.count
    }

    async load(limit = 100, skip, ignoreLoading = false) {
        if (!ignoreLoading) {
            this.loading = true
        }

        const countAndQueryPromises = []

        // If we don't have a totalCount then get it with the first request
        if (typeof this.count !== 'number') {
            countAndQueryPromises.push(this.getCount())
        } else {
            // Add a dummy promise to resolve to our current count
            countAndQueryPromises.push(Promise.resolve(this.count))
        }

        // eslint-disable-next-line no-nested-ternary
        const useSkip = typeof skip === 'number' ? skip : this.documents ? this.documents.length : 0
        const useLimit = limit || 100

        countAndQueryPromises.push(this.execute(useLimit, useSkip))

        const [, loadedDocuments] = await Promise.all(countAndQueryPromises)

        if ((!this.documents && useSkip === 0) || (this.documents && useSkip === this.documents.length)) {
            // we can safely simply append the loaded documents
            this.documents = (this.documents || []).concat(loadedDocuments)
        } else {
            // We are utilizing sparse arrays to insert as skip might have left
            // out some rows. To do this we first clone the original array and
            // assign a copy back as otherwise mobx would go crazy (out of bounds shit)
            const newDocuments = (this.documents || []).slice()

            for (let index = useSkip; index < useSkip + loadedDocuments.length; index += 1) {
                newDocuments[index] = loadedDocuments[index - useSkip]
            }

            this.documents = newDocuments
        }

        if (!ignoreLoading) {
            this.loading = false
        }

        return loadedDocuments
    }

    async loadAll(bulkLimit = 1000) {
        this.loading = true

        const totalCount = await this.getCount()
        if (totalCount >= 5000) {
            throw new Error('More than 5k bulk load is not supported.')
        }

        if (!totalCount) {
            this.loading = false
            return 0
        }

        const pageCount = Math.ceil(totalCount / bulkLimit)
        for (let pageIndex = 0; pageIndex < pageCount; pageIndex += 1) {
            // eslint-disable-next-line no-await-in-loop
            await this.load(bulkLimit, undefined, true)
        }

        this.loading = false

        if (this.documents.length !== totalCount) {
            throw new Error(
                `Error while bulk loading, expected ${totalCount} documents but got only ${this.documents.length} documents.`
            )
        }

        return this.documents.length
    }

    async execute(limit = 100, skip, 
        {
            rawDocuments = false,
            cache = false,
            onCacheAlreadyUpdated
        } = {},
        onCacheUpdated
    ) {
        const useSkip = skip || 0
        const useLimit = limit || 100

        if (cache) {
            if (useSkip > 0) {
                throw new Error('Cache can not be used in combination with skipping.')
            }

            if (!onCacheUpdated) {
                throw new Error('Missing cache update callback when using cache.')
            }

            if (this._sort) {
                throw new Error('Sorting is not supported when using cache.')
            }
        }

        const returnDocuments = documents => {
            if (rawDocuments) {
                return documents || []
            }

            return (documents || []).map(document => {
                return this._createModelFromDocument(document)
            })
        }

        const doExecute = async (query, projection) =>
            this._call('find', query, {
                projection: projection || this._buildProjection() || {},
                sort: this.sort,
                limit: useLimit,
                skip: useSkip,
                multi: true,
            })

        const handleCacheUpdated = newDocuments => {
            onCacheUpdated(returnDocuments(newDocuments))
        }

        return returnDocuments(
            await cachedQueryExecution(
                this.modelClass,
                this._query,
                cache,
                doExecute,
                handleCacheUpdated,
                onCacheAlreadyUpdated,
            )
        )
    }

    // eslint-disable-next-line class-methods-use-this
    _resolveCursor(cursor) {
        if (process.env.RUNTIME_TARGET === 'backend') {
            // backend calls return a cursor here so we need to call the
            // toArray() function
            return cursor.toArray()
        }
        return cursor
    }

    aggregate(query = {}, pipeline = []) {
        // Only passed in pipeline as argument
        if (Array.isArray(query) && pipeline.length === 0) {
            return this._resolveCursor(this._call('aggregate', [{ $match: this._query }, ...query]))
        }

        return this._resolveCursor(
            this._call('aggregate', [{ $match: { ...this._query, ...query } }, ...pipeline])
        )
    }

    async breakdown(fieldMappings, options = {}) {
        const pipelines = createBreakdownPipeline(this.modelClass, fieldMappings, undefined, options)
        const groups = await this.aggregate(pipelines)

        if (!groups?.length) {
            return []
        }

        return convertBreakdownData(this.modelClass, fieldMappings, groups, options)
    }

    async group(groups, limit = 100, skip) {
        const useSkip = skip || 0
        const useLimit = limit || 100

        const $group = {
            _id: {},
            count: { $sum: 1 },
            sort_id: { $first: '$_id' },
        }
        const $sort = {}

        const propertiesKeyMap = {}

        for (const groupKey in groups) {
            if (Object.prototype.hasOwnProperty.call(groups, groupKey)) {
                const groupInfo = groups[groupKey]

                // Normalize nested group keys so we can use them and later destructure them again
                const normalizedGroupKey = groupKey.replace('.', '___')
                propertiesKeyMap[normalizedGroupKey] = groupKey

                if (groupInfo.mode) {
                    $group[normalizedGroupKey] = { [`$${groupInfo.mode}`]: `$${groupKey}` }
                } else {
                    $group._id[normalizedGroupKey] = `$${groupKey}`

                    if (groupInfo.sort) {
                        $sort[`_id.${normalizedGroupKey}`] = groupInfo.sort
                    }
                }
            }
        }

        const basePipelines = [{ $match: this._query }, { $group }]

        basePipelines.push({
            $sort: {
                ...$sort,
                // we always need to sort by a stable sort_id last as otherwise
                // sort order might be unpredictable
                sort_id: 1,
            },
        })

        const groupPipelines = basePipelines.concat([{ $skip: useSkip }, { $limit: useLimit }])
        const groupPromise = this._resolveCursor(this._call('aggregate', groupPipelines))
        let groupsCount
        let groupedResults

        // If we don't skip anything we'll also make a separate call to get the total count
        // of all available groups for proper pagination
        if (useSkip === 0) {
            const getGroupsGrount = async () => {
                const result = await this._resolveCursor(
                    this._call('aggregate', basePipelines.concat([{ $count: 'groupsCount' }]))
                )

                if (!result || result.length !== 1 || !result[0].groupsCount) {
                    return -1 // indicate an error
                }
                return result[0].groupsCount
            }

            ;[groupsCount, groupedResults] = await Promise.all([getGroupsGrount(), groupPromise])
        } else {
            groupedResults = await groupPromise
        }

        const assignResultProperty = (property, value, target) => {
            const originalProperty = propertiesKeyMap[property]
            target[originalProperty] = value
        }

        const result = {
            groups: groupedResults.map(groupedResult => {
                // ignore sort_id its just a helper and merge _id results into root
                const { _id: groupProperties, sort_id, count, ...aggProperties } = groupedResult

                const groupResult = { count }

                // eslint-disable-next-line guard-for-in
                for (const groupProperty in groupProperties) {
                    assignResultProperty(groupProperty, groupProperties[groupProperty], groupResult)
                }

                // eslint-disable-next-line guard-for-in
                for (const aggProperty in aggProperties) {
                    assignResultProperty(aggProperty, aggProperties[aggProperty], groupResult)
                }

                return groupResult
            }),
            totalCount: groupedResults.reduce(
                (totalCount, groupedResult) => totalCount + groupedResult.count,
                0
            ),
        }

        if (typeof groupsCount === 'number') {
            result.groupsCount = groupsCount
        }

        return result
    }

    async watch() {
        if (this._watcher) {
            throw new Error(`Already installed a watcher on ${this.modelClass.name} Query.`)
        }

        this._watcher = await aquireWatcher(
            this._collection,
            this._query,
            (operation, document, documentId) => {
                if (operation === 'insert' || operation === 'update' || operation === 'replace') {
                    const existingDocument = this.getDocumentById(documentId)
                    if (existingDocument) {
                        existingDocument.deserialize(document)
                    }

                    if (operation === 'insert' && !existingDocument) {
                        this.documents = this.documents.concat([this._createModelFromDocument(document)])
                    }
                } else if (operation === 'delete') {
                    const existingDocument = this.getDocumentById(documentId)
                    if (existingDocument) {
                        this.documents = this.documents.filter(doc => doc !== existingDocument)
                    }
                }
            }
        )

        return true
    }

    async unwatch() {
        if (this._watcher) {
            releaseWatcher(this._watcher)
            this._watcher = null
        }
    }

    async update(updateData, updateProps = {}) {
        const updateDocument = convertUpdateDocument(updateData)

        if (!Object.keys(updateDocument).length) {
            // nothing to update so return immediately
            return 0
        }

        const result = await this._call('update', this._query, updateDocument, {
            multi: true,
            ...updateProps,
        })

        return result ? result.modifiedCount : 0
    }

    async remove() {
        const result = await this._call(
            'update',
            this._query,
            { $set: { removedAt: new DateTime().toDate(), removedBy: Runtime.userId } },
            { multi: true }
        )

        return result ? result.modifiedCount : 0
    }

    cleanupCache() {
        return removeCachedData(this.modelClass, this._query)
    }

    _buildProjection() {
        if (!this.projection || !Array.isArray(this.projection) || !this.projection.length) {
            return null
        }

        const projectionObj = {}
        this.projection.forEach(unhandledField => {
            let field = unhandledField
            if (field.startsWith('!')) {
                field = field.substring(1, field.length)
            }
            projectionObj[field] = unhandledField.startsWith('!') ? 0 : 1
        })

        return projectionObj
    }

    async __dangerouslyDelete() {
        // TODO should we add delete to backend functions aswell?
        const result = await this._collection.deleteMany(this._query)

        return result ? result.deletedCount : 0
    }

    createModelInstance(...args) {
        const ModelClass = this.modelClass
        return new ModelClass(...args)
    }

    _createModelFromDocument(document) {
        const modelInstance = this.createModelInstance()
        modelInstance.deserialize(document)
        return modelInstance
    }
}

Query.normalizeQuery = normalizeQueryValues

export default Query
