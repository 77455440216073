export default (context, prefix) => {
    const { appTheme, zone } = context

    let title = appTheme?.title || zone.name
    if (prefix) {
        title = `${prefix} / ${title}`
    }

    document.title = title

    const favicon = appTheme?.favicon || zone.FavIcon

    const favIconElement = document.getElementById('favicon')
    if (favIconElement && favIconElement.getAttribute('href') !== favicon) {
        favIconElement.setAttribute('href', favicon)
    }
}
