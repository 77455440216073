import Runtime from '@newspaces/lucky-base/Runtime'

export default (context, { organisationId, allowGlobalApps = true } = {}) => {
    const availableDefaultApps = context.availableApps.filter(({ key, isGlobal }) => {
        if (!allowGlobalApps && isGlobal) {
            return false
        }

        return context.userHasAccessToApp(key, organisationId)
    })

    let app = null

    // Prefer to use the default app if its valid and accessible,
    // otherwise if there's only one accessible app use that one automatically,
    // otherwise let the user choose an app as there're too many available.
    if (!app) {
        app = availableDefaultApps.find(({ isDefault }) => isDefault)
    }

    // if there's a root app use this one
    if (!app) {
        app = availableDefaultApps.find(({ isRoot }) => isRoot)
    }

    // Only one app available anyway so use that one now
    if (!app && availableDefaultApps.length === 1) {
        app = availableDefaultApps[0]
    }

    // If under custom domain, uses app that is default when on custom domain
    if (!app && Runtime.customDomain) {
        app = availableDefaultApps.find(({ defaultForCustomDomain }) => defaultForCustomDomain)
    }

    return app
}
