import nanoid from 'nanoid'

import countries from '../../utils/countries'

import Model from '../../Model'

import OrganisationSubscriptions from './OrganisationSubscriptions'
import OrganisationCustomDomain from './OrganisationCustomDomain'

class Organisation extends Model {
    static ApiDescription = `
    An organisation represents a workspace for one or more users.
    <br/><br/>
    Most of the objects are bound to an organisation.
    `

    static CollectionName = 'organisations'

    static Permissions = {
        read: {
            who: 'owner',
            scope: 'organisation.read',
            fields: [
                'country',
                'currency',
                'language',
                'locale',
                'avatar',
                'name',
                'owner',
                'uniqueSlug',
                'zone',
                'credit',
                'serviceLimits',
                'subscriptions',
                'customDomain',
            ],
        },
        edit: [
            {
                who: 'owner',
                scope: 'organisation.edit',
                fields: ['avatar', 'name', 'uniqueSlug'],
            },
        ],
        remove: false,
    }

    static Fields = {
        country: {
            type: String,
            enum: countries.codes,
            serialize: 'insert',
            description: 'The country the organisation is located within',
        },
        currency: {
            type: String,
            enum: countries.currencies,
            serialize: 'insert',
            description: 'The currency the organisation is using as default',
        },
        language: {
            type: String,
            required: true,
            serialize: 'insert',
            description: 'The language the organisation is using as default',
        },
        locale: {
            type: String,
            get: true,
            serialize: 'insert',
            description: 'The locale the organisation is using as default',
        },
        avatar: {
            type: Model.Resource,
            default: () =>
                new Model.Resource({
                    image: {
                        maxWidth: 512,
                        maxHeight: 512,
                    },
                }),
            description: 'The avatar reflecting the organisation brand',
        },
        owner: {
            type: String,
            serialize: 'insert',
            description: 'The creator user id of the organisation',
        },
        name: {
            type: String,
            required: true,
            description: 'The full name of the organisation',
        },
        uniqueSlug: {
            type: String,
            required: true,
            default: nanoid,
            serialize: 'insert',
            description: 'Used to uniquely identify the url and mail for the organisation',
        },
        zone: {
            type: String,
            required: true,
            serialize: 'insert',
            description: 'The zone the organisation is located within',
        },
        credit: {
            type: Number,
            default: 0,
            serialize: false,
            description: 'The credits in the organisations currency it can spend',
        },
        // The customer's id at stripe if any
        subscriptions: {
            type: OrganisationSubscriptions,
            serialize: false,
            description: 'The subscriptions the organisation has',
        },
        // Custom domain
        customDomain: {
            type: OrganisationCustomDomain,
            serialize: false,
            description: 'The custom domain the organisation has',
        },
    }

    constructor(Fields, data) {
        super({ ...Organisation.Fields, ...Fields }, data)
    }

    get locale() {
        let result = ''

        if (this.language) {
            result = this.language
        }
        if (this.country) {
            if (result) {
                result += '-'
            }
            result += this.country
        }

        return result
    }

    get resourcePath() {
        if (!this.id) {
            return null
        }

        return `organisation/${this.id}/organisation`
    }

    get slugOrId() {
        return this.uniqueSlug || this.id
    }
}

Model.declareModel(Organisation)

export default Organisation
