import Fade from '@material-ui/core/Fade'
import { fade as alpha } from '@material-ui/core/styles'

import { fade } from '@newspaces/lucky-components/utils'

export default ({ primaryColor, primaryTextColor }) => {
    const selectedOpacity = 0.2
    const selected = alpha(primaryColor, selectedOpacity)
    const lightSelectedOpacity = 0.08
    const lightSelected = alpha(primaryColor, lightSelectedOpacity)

    const borderRadius = 7
    const smallBorderRadius = 3.5

    return {
        palette: {
            primary: {
                main: primaryColor,
                contrastText: primaryTextColor || undefined,
            },
            error: {
                main: '#E34444',
                contrastText: '#ffffff',
            },
            warning: {
                main: '#FFBA00',
                contrastText: '#ffffff',
            },
            info: {
                main: '#017EBD',
                contrastText: '#ffffff',
            },
            success: {
                main: '#1DBC77',
                contrastText: '#ffffff',
            },
            neutral: {
                light: '#CCCCCC',
                main: '#888888',
                dark: '#333333',
                contrastText: '#ffffff',
            },
            divider: 'rgba(0, 0, 0, 0.08)',
            background: {
                default: '#ffffff',
                dark: fade(primaryColor, 0.01, '#f4f4f4'),
                note: '#ffecb3',
                appBar: '#ffffff',
                appBarDark: fade(primaryColor, 0.12, '#333'),
            },
            action: {
                selected,
                selectedOpacity,
                lightSelected,
                lightSelectedOpacity,
            },
        },
        shape: {
            borderRadius,
            smallBorderRadius,
            panelShadow: '0 6px 12px rgba(0, 0, 0, 0.04), 0 0 0 1px rgba(0, 0, 0, 0.03)',
            appBarHeight: 56,
        },
        props: {
            MuiButton: {
                disableElevation: true,
            },
            MuiButtonGroup: {
                disableElevation: true,
            },
            MuiRadio: {
                disableRipple: true,
            },
            MuiCheckbox: {
                disableRipple: true,
            },
            MuiTooltip: {
                arrow: false,
                TransitionComponent: Fade,
            },
            MuiPopover: {
                TransitionComponent: Fade,
                TransitionProps: {
                    timeout: 0,
                },
            },
            MuiMenu: {
                transitionDuration: 0,
            },
        },
        overrides: {
            MuiSvgIcon: {
                root: {
                    width: '24px',
                    height: '24px',
                },
                fontSizeSmall: {
                    width: '20px',
                    height: '20px',
                },
                fontSizeLarge: {
                    width: '32px',
                    height: '32px',
                },
                fontSizeInherit: {
                    width: '1em',
                    height: '1em',
                },
            },
            MuiButton: {
                root: {
                    cursor: 'pointer',
                    transition: 'none !important',
                    textTransform: 'none',
                },
                contained: {
                    backgroundColor: 'rgba(0,0,0,0.10)',
                    padding: '7px 17px',
                    '&:hover': {
                        backgroundColor: selected,
                    },
                },
                outlined: {
                    padding: '6px 16px',
                    '&:hover': {
                        backgroundColor: 'white',
                        borderColor: primaryColor,
                        color: primaryColor,
                    },
                },
            },
            MuiMenuItem: {
                root: {
                    padding: '6px 8px',
                    margin: '0px 8px',
                    borderRadius,
                    overflow: 'hidden',
                    transition: 'none !important',
                    '&:hover': {
                        backgroundColor: lightSelected,
                    },
                },
            },
            MuiListItem: {
                button: {
                    transition: 'none !important',
                    '&:hover': {
                        backgroundColor: lightSelected,
                    },
                },
                gutters: {
                    borderRadius,
                    margin: '0 8px',
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    width: 'calc(100% - 16px)',
                },
            },
            MuiListItemIcon: {
                root: {
                    minWidth: 40,
                },
            },
            MuiRadio: {
                root: {
                    backgroundColor: 'transparent !important',
                    cursor: 'default',
                    padding: '5px 9px',
                    transition: 'none !important',
                    '& .MuiSvgIcon-root': {
                        transition: 'none !important',
                    },
                },
            },
            MuiCheckbox: {
                root: {
                    backgroundColor: 'transparent !important',
                    cursor: 'default',
                    padding: '5px 9px',
                },
            },
            MuiInputBase: {
                input: {
                    height: 16,
                },
            },
            MuiTableCell: {
                root: {
                    borderBottom: '1px solid #f0f0f0',
                },
                sizeSmall: {
                    paddingTop: 8,
                    paddingBottom: 8,
                },
                head: {
                    fontWeight: 700,
                    color: 'rgba(0,0,0,0.54)',
                },
            },
            MuiTooltip: {
                arrow: {
                    color: 'rgba(0, 0, 0, 0.04)',
                },
                tooltip: {
                    borderRadius,
                    backgroundColor: 'white !important',
                    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.04), 0 0 0 1px rgba(0, 0, 0, 0.04)',
                    border: '0px solid transparent !important',
                    fontSize: 13,
                    fontWeight: 400,
                    color: 'rgba(0, 0, 0, 0.54)',
                    margin: '0px 0px !important',
                },
            },
            MuiPopover: {
                paper: {
                    boxShadow: '0 0 12px rgba(0, 0, 0, 0.12)',
                    border: '1px solid rgba(0, 0, 0, 0.08)',
                },
            },
            MuiMenu: {
                paper: {
                    boxShadow: '0 0 12px rgba(0, 0, 0, 0.08)',
                    border: '1px solid rgba(0, 0, 0, 0.08)',
                },
            },
            MuiDialog: {
                root: {
                    paddingTop: 'env(safe-area-inset-top)',
                    paddingBottom: 'env(safe-area-inset-bottom)',
                },
            },
            MuiDialogActions: {
                root: {
                    padding: '8px 16px 12px 16px',
                },
            },
        },
        typography: {
            htmlFontSize: 14,
            fontSize: 14,
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            button: {
                fontSize: '1rem',
            },
            body1: {
                fontSize: '1rem',
            },
        },
    }
}
