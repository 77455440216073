import DateTime from './DateTime'

export default class Time extends DateTime {
    constructor(date, settings) {
        super(date, settings, 'time')
    }

    fromObject({ hours = 0, minutes = 0, seconds = 0, milliseconds = 0 } = {}) {
        if (
            typeof hours !== 'number' ||
            typeof minutes !== 'number' ||
            typeof seconds !== 'number' ||
            typeof milliseconds !== 'number'
        ) {
            return false
        }

        this._hours = hours
        this._minutes = minutes
        this._seconds = seconds
        this._milliseconds = milliseconds

        return true
    }
}
