/* eslint-disable max-len  */
/* eslint-disable no-template-curly-in-string */

export default {
    language: 'Langue',
    languageName: 'Allemand',
    months: [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'November',
        'Décembre',
    ],
    monthsShort: [
        'Janv.',
        'Févr.',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juill.',
        'Août',
        'Sept.',
        'Oct.',
        'Nov.',
        'Déc.',
    ],
    weekdays: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    weekdaysShort: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
    weekdaysMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
    relativeTime: {
        yesterday: 'Hier',
        today: "Aujourd'hui",
        tomorrow: 'Demain',
        daysAgo: 'Il y a ${this.days} jours',
        daysIn: 'Dans ${this.days} jours',
    },
    misc: {
        yesNo: {
            yes: 'Oui',
            no: 'Non',
        },
        errors: {
            unknown: "Une erreur inconnue s'est produite, veuillez contacter le support technique.",
        },
        noCloseAlert: {
            title: 'Merci de laisser la fenêtre ouverte !',
            message:
                "Merci de laisser la fenêtre ou l'onglet du navigateur ouverts tant que ce processus est encore en cours, autrement il sera annulé.",
        },
        close: 'Fermer',
        saveChanges: 'Enregistrer les modifications',
        collapseAll: 'Réduire tout',
        expandAll: 'Dépliez tout',
        create: 'Créer',
        edit: 'Modifier',
        remove: 'Supprimer',
        delete: 'Effacer',
        cancel: 'Annuler',
        save: 'Enregistrer',
        view: 'Voir',
        send: 'Envoyer',
        choose: 'Sélectionner',
        download: 'Télécharger',
        showMore: 'Afficher plus',
        showLess: 'Afficher moins',
        createdAtBy: 'Créé le ${this.date} par ${this.name}',
        updatedAtBy: '${this.date} modifié par ${this.name}',
        unknown: 'Inconnu',
        missingField: '${this.field} manquant !',
        today: "Aujourd'hui",
        for: 'pour',
        or: 'ou',
        back: 'Retour',
        next: 'Suivant',
        exportToExcel: 'Exporter en fichier Excel...',
        clearSelection: 'Supprimer la sélection',
        maxFileSizeMB: 'La taille du fichier ne peut pas dépasser ${this.megabyte} Mo.',
        search: 'Rechercher',
        makeFavorite: 'Ajouter aux favoris',
        clearFavorite: 'Supprimer des favoris',
        noColor: 'Sans couleur',
    },
    resetPassword: {
        subject: 'Réinitialiser le mot de passe',
        text: 'Veuillez cliquer sur ce lien pour définir un nouveau mot de passe: ${this.resetPasswordLink}',
    },
    sendInvitation: {
        subject: 'Vous avez été invité à participer à ${this.orgName}',
        text:
            "Bonjour ${this.name}, vous avez été invité à rejoindre ${this.orgName}. Cliquez sur le lien suivant pour accepter l'invitation: ${this.invitationLink}",
    },
    emailUnsubscription: {
        title: 'Ne plus recevoir nos e-mails',
        text: 'Vous vous êtes désinscrit avec succès et ne recevrez plus nos e-mails',
    },
    organisation: {
        modelName: {
            single: 'Organisation',
            multi: 'Organisations',
        },
        avatar: 'Photo de profil',
        primaryColor: 'Couleur principale',
        primaryTextColor: 'Couleur du texte',
        name: 'Nom',
        street: 'Rue + numéro ',
        zip: 'Code postal',
        city: 'Ville',
        country: 'Pays',
    },
    organisationUser: {
        modelName: {
            single: 'Utilisateur',
            multi: 'Utilisateurs',
        },
    },
}
