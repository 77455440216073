import GetPlatformBrowser from './getPlatform.browser'
import GetPlatformMobile from './getPlatform.mobile'

// eslint-disable-next-line
let getPlatform

if (process.env.RUNTIME_TARGET === 'browser') {
    getPlatform = GetPlatformBrowser
}

if (process.env.RUNTIME_TARGET === 'mobile') {
    getPlatform = GetPlatformMobile
}

export default getPlatform
