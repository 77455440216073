import React from 'react'
import { observer } from 'mobx-react-lite'
import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Button from '@newspaces/lucky-components/Button'

import i18n from '../i18n'

import AppContext from '../AppContext'

import AppFrame from './AppFrame'

const useStyles = makeStyles(theme => ({
    alert: {
        padding: theme.spacing(2, 4),
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(3, 0, 2, 0),
    },
}))

export default observer(() => {
    const classes = useStyles()

    return (
        <AppFrame>
            <div className={classes.root}>
                <Alert severity="error" className={classes.alert}>
                    <AlertTitle>{i18n().app.errors.title}</AlertTitle>
                    {i18n().app.errors[AppContext.error]}
                </Alert>
                <div className={classes.buttonContainer}>
                    <Button variant="contained" color="primary" href="/">
                        {i18n().app.errors.backToHome}
                    </Button>
                </div>
            </div>
        </AppFrame>
    )
})
