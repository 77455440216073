import createFields from './createFields'
import createValueGroupMapping from './createValueGroupMapping'
import createPreviousProjection from './createPreviousProjection'
import createInitialProjection from './createInitialProjection'
import createInitialUnwind from './createInitialUnwind'
import createAccumulators from './createAccumulators'
import createGroupId from './createGroupId'

export default (ModelClass, fieldMappings, sortOrder = 1, options) => {
    const pipeline = []

    const sort = {}

    const fields = createFields(ModelClass, fieldMappings, options)

    // Project initial values and map undefined to null as well
    pipeline.push({
        $project: createInitialProjection(fields),
    })

    createInitialUnwind(fields).forEach($unwind => {
        pipeline.push({ $unwind })
    })

    // Now add the breakdown step for each field
    fields.forEach((field, index) => {
        const { fieldIsArray } = field

        const { project, group } = createPreviousProjection(fields, index)

        // If we're not the first field we must unwind our values from the previous group
        if (index > 0) {
            pipeline.push({ $unwind: '$values' })
        }

        // If we have an array property we must unwind before and
        // add another projection projecting our unwinded documents
        // into the normal value name again
        if (fieldIsArray) {
            pipeline.push({
                $unwind: {
                    path: `$values.value_${index}`,
                    preserveNullAndEmptyArrays: true
                }
            })
        }

        // Now add projection and group stage for the current field
        pipeline.push({
            $project: {
                ...project,
                [`value_${index}`]: `$values.value_${index}`,
                [`value_map_${index}`]: createValueGroupMapping(`values.value_${index}`, field),
            },
        })

        // If we have an array property and a filter then add a match here
        if (fieldIsArray && field.filter) {
            const obj = field.filter(`value_${index}`)

            if (obj && Object.keys(obj).length > 0) {
                pipeline.push({
                    $match: obj,
                })
            }
        }

        // Add the grouping stage finally
        pipeline.push({
            $group: {
                ...group,
                ...createAccumulators(field.fieldType, index),
                ...createGroupId(index),
            },
        })

        // Add field to our sort
        sort[`value_${index}`] = sortOrder
    })

    // Add last projection to normalize everything
    pipeline.push({ $project: createPreviousProjection(fields, fields.length).project })

    // Add a final sort stage to ensure our values are sorted
    pipeline.push({ $sort: sort })

    return pipeline
}
