import createFields from './createFields'
import getAccumulators from './getAccumulators'

export default (ModelClass, fieldMappings, data, options) => {
    const fields = createFields(ModelClass, fieldMappings, options)

    const segments = []

    data.forEach(row => {
        let children = segments

        fields.forEach(({ fieldType }, index) => {
            const value = row[`value_${index}`]
            const accumulators = {}

            getAccumulators(fieldType).forEach(({ accumulator }) => {
                accumulators[accumulator] = row[`${accumulator}_${index}`] || 0
            })

            let segment = children.find(seg => seg.value === value)
            if (!segment) {
                segment = { value, ...accumulators }
                children.push(segment)

                if (index + 1 < fieldMappings.length) {
                    children = []
                    segment.children = children
                }
            } else {
                children = segment.children
            }
        })
    })

    return segments
}
