import isLeapYear from './isLeapYear'

// Returns the day of the year for a given date honoring leap-years.
// If normalized is set to true, every year is considered to be a leap-year so the
// returned day of the year is always between 1 (1st january) and 366 (31st december)
export default (year, month, date, normalized = false) => {
    const getDayOfYear = (y, m, d) => (Date.UTC(y, m, d) - Date.UTC(y, 0, 0)) / 24 / 60 / 60 / 1000

    const dayOfYear = getDayOfYear(year, month, date)

    if (!normalized || isLeapYear(year)) {
        return dayOfYear
    }

    const leapDayOfYear = getDayOfYear(year, 2, 1)

    // If the day in non-leap-year is after the leap year day, add 1 day
    if (dayOfYear > leapDayOfYear) {
        return dayOfYear + 1
    }

    // Return the default since it is same or before 28.02
    return dayOfYear
}
