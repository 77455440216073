import mediaBase from '../media.base'

import getImageSize from './getImageSize'
import resizeImage from './resizeImage'
import getVideoSize from './getVideoSize'
import getVideoPreview from './getVideoPreview'
import fileToBase64 from './fileToBase64'

export default {
    ...mediaBase,
    getImageSize,
    resizeImage,
    getVideoSize,
    getVideoPreview,
    fileToBase64,
}
