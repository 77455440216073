import Runtime from '@newspaces/lucky-base/Runtime'

import waitForNewOrganisation from './waitForNewOrganisation'

export default async (context, invitationToken) => {
    if (!invitationToken) {
        // No invitation token so return immediately no need to handle something
        return null
    }

    // If there's an invitationToken provided then enforce authentication
    // if we have no valid user available so we can actually handle the invitation
    if (!context.userData || !Runtime.hasSession) {
        return { mode: 'authentication' }
    }

    try {
        const { organisation, error } = await Runtime.call('organisation.acceptInvitation', invitationToken)
        // TODO: CHANGE WHEN MIGRATE TO USE LC2 EVERYWHERE
        // const { organisation, error } = await Runtime.apiMethod(
        //     '/user/acceptInvitation',
        //     { invitationToken }
        // )

        if (error) {
            return { mode: 'error', error }
        }

        await waitForNewOrganisation(context, organisation)

        return { organisationSlugOrId: organisation.toString() }
    } catch (e) {
        console.error(e)

        return { mode: 'error', error: 'invalidInvitationToken' }
    }
}
