import React, { useState, useCallback } from 'react'
// eslint-disable-next-line import/no-unresolved
import { Link as RouterLink } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { fade, makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

const useStyles = makeStyles(theme => {
    const result = {
        button: {
            position: 'relative',

            '&.MuiButton-text': {
                paddingTop: 7,
                paddingBottom: 7,

                '&.MuiButton-textPrimary:not(.Mui-disabled)': {
                    color: theme.palette.primary.dark,
                },
            },

            '&:not(&.Mui-disabled):hover .MuiSvgIcon-root': {
                color: theme.palette.primary.main,
            },

            '&.MuiButton-sizeSmall': {
                padding: '4px 7px',
                fontSize: 13,
                borderRadius: theme.shape.smallBorderRadius,

                '& .MuiSvgIcon-root': {
                    width: 20,
                    height: 20,
                },
            },

            '&.isIcon': {
                padding: '7px 5px',
                minWidth: 0,

                '&.MuiButton-sizeSmall': {
                    padding: '5px 3px',
                },
            },
        },
        link: {
            backgroundColor: 'transparent',
            color: theme.palette.primary.dark,
            textTransform: 'none',
            padding: 0,
            borderRadius: theme.shape.smallBorderRadius,

            '&:hover': {
                background: theme.palette.action.lightSelected,
                boxShadow: `0 0 0 3.5px ${theme.palette.action.lightSelected}`,

                '&.MuiButton-sizeSmall': {
                    boxShadow: `0 0 0 1.5px ${theme.palette.action.lightSelected}`,
                },
            },
            '&:active': {
                background: theme.palette.action.selected,
                boxShadow: `0 0 0 3.5px ${theme.palette.action.selected}`,

                '&.MuiButton-sizeSmall': {
                    boxShadow: `0 0 0 1.5px ${theme.palette.action.selected}`,
                },
            },

            '&.MuiButton-sizeSmall': {
                padding: 0,
            },
        },
        progressContainer: {
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-9px, -9px)',
            width: 18,
            height: 18,
        },
        // pre-defined special color mode (danger only visible on hover)
        danger: {
            '& .MuiSvgIcon-root': {
                color: 'currentColor !important',
            },

            '&.MuiButton-contained:not(.Mui-disabled)': {
                '&:hover, &:active': {
                    backgroundColor: fade(theme.palette.error.main, 0.24),
                    color: theme.palette.error.main,
                },
            },
            '&.MuiButton-outlined:not(.Mui-disabled)': {
                '&:hover, &:active': {
                    borderColor: theme.palette.error.main,
                    backgroundColor: fade(theme.palette.error.main, 0.12),
                    color: theme.palette.error.main,
                },
            },
            '&.MuiButton-text:not(.Mui-disabled)': {
                '&:hover, &:active': {
                    backgroundColor: fade(theme.palette.error.main, 0.12),
                    color: theme.palette.error.main,
                },
            },
        },
    }

    // add more colors
    ;['error', 'success', 'info', 'warning'].forEach(color => {
        result[color] = {
            '& .MuiSvgIcon-root': {
                color: 'currentColor !important',
            },

            '&.MuiButton-contained:not(.Mui-disabled)': {
                backgroundColor: theme.palette[color].main,
                color: theme.palette[color].contrastText,
                '&:hover, &:active': {
                    backgroundColor: theme.palette[color].dark,
                },
            },
            '&.MuiButton-outlined:not(.Mui-disabled)': {
                borderColor: fade(theme.palette[color].main, 0.5),
                color: theme.palette[color].main,
                '&:hover, &:active': {
                    borderColor: theme.palette[color].main,
                    backgroundColor: fade(theme.palette[color].main, 0.12),
                },
            },
            '&.MuiButton-text:not(.Mui-disabled)': {
                color: theme.palette[color].main,

                '&:hover, &:active': {
                    color: theme.palette[color].main,
                    backgroundColor: fade(theme.palette[color].main, 0.12),
                },
            },
        }
    })

    return result
})

const KNOWN_BUTTON_COLORS = ['default', 'inherit', 'primary', 'secondary']

export default React.forwardRef(
    (
        {
            loading,
            variant = 'text',
            color,
            isIcon,
            disabled,
            className,
            component,
            disableRipple,
            to,
            href,
            download,
            children,
            onClick,
            onFocus,
            renderPopup,
            openPopupOnFocus = false,
            ...otherProps
        },
        ref
    ) => {
        const classes = useStyles({ loading, variant, color })
        const [anchorEl, setAnchorEl] = useState(null)

        const handleClick = useCallback(
            event => {
                if (
                    renderPopup &&
                    (!openPopupOnFocus || window?.safari || document.activeElement === event.currentTarget)
                ) {
                    setAnchorEl(event.currentTarget)
                }

                if (onClick) {
                    onClick(event)
                }
            },
            [onClick, openPopupOnFocus, renderPopup]
        )

        const handleFocus = useCallback(
            event => {
                if (renderPopup && openPopupOnFocus) {
                    setAnchorEl(event.currentTarget)
                }

                if (onFocus) {
                    onFocus(event)
                }
            },
            [onFocus, openPopupOnFocus, renderPopup]
        )

        const closePopup = useCallback(() => {
            setAnchorEl(null)
        }, [])

        const isDisabled = disabled || loading

        const finalProps = {
            ...otherProps,
            color: KNOWN_BUTTON_COLORS.indexOf(color) >= 0 ? color : 'default',
        }

        let buttonComponent = component || 'button'
        if (to || href) {
            buttonComponent = to ? RouterLink : 'a'
            finalProps.to = to
            finalProps.href = href
        }

        return (
            <>
                <Button
                    {...finalProps}
                    ref={ref}
                    className={classNames(
                        className,
                        classes.button,
                        variant === 'link' && classes.link,
                        classes[color],
                        {
                            isIcon,
                            isLoading: loading,
                            coloredIcon: variant === 'contained' && color !== 'danger' && !!color,
                        }
                    )}
                    disabled={isDisabled}
                    component={buttonComponent}
                    variant={(variant !== 'link' && variant) || null}
                    onClick={(renderPopup && handleClick) || onClick}
                    onFocus={handleFocus}
                    disableRipple={
                        typeof finalProps.disableRipple === 'boolean'
                            ? finalProps.disableRipple
                            : variant === 'link'
                    }
                >
                    {isIcon && <span>&nbsp;</span>}
                    {React.Children.map(children, child =>
                        child?.type
                            ? React.cloneElement(child, { color: color === 'danger' ? 'error' : color })
                            : child
                    )}
                    {isIcon && <span>&nbsp;</span>}
                    {loading && (
                        <div className={classes.progressContainer}>
                            <CircularProgress size={16} />
                        </div>
                    )}
                </Button>
                {renderPopup &&
                    anchorEl &&
                    renderPopup({
                        anchorEl,
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        open: Boolean(anchorEl),
                        onClose: closePopup,
                    })}
            </>
        )
    }
)
