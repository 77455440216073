import getAccumulators from './getAccumulators'

export default (fieldType, index) => {
    return getAccumulators(fieldType).reduce(
        (res, { accumulator, create }) => ({
            ...res,
            [`${accumulator}_${index}`]: create(`value_${index}`),
        }),
        {}
    )
}
