import decorateModelBrowser from './decorateModel.browser'
import decorateModelBackend from './decorateModel.backend'

// eslint-disable-next-line import/no-mutable-exports
let decorateModelExport

if (process.env.RUNTIME_TARGET === 'browser') {
    decorateModelExport = decorateModelBrowser
}

if (process.env.RUNTIME_TARGET === 'backend') {
    decorateModelExport = decorateModelBackend
}

export default decorateModelExport
