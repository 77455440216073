import Runtime from '@newspaces/lucky-base/Runtime'

export default () => {
    if (!Runtime.user || !Runtime.user.customData) {
        return null
    }

    const settings = Runtime.user.customData.settings || {}
    const organisations = Runtime.user.customData.organisations || []
    const organisationIds = Runtime.user.customData.organisationIds || []

    // Prefer to use the user's favorite organisation if its valid otherwise
    // if there's only one organisation use that one and last but not least
    // if there're too many organisations or no organisation yet let the user choose
    const favoriteOrganisation = settings.favoriteOrganisation

    if (
        favoriteOrganisation &&
        organisationIds.find(({ $oid }) => $oid === favoriteOrganisation)
    ) {
        return favoriteOrganisation
    }

    if (organisations.length === 1) {
        const firstValidOrganisation = organisations.find(org => !!org && !!org.id)
        if (firstValidOrganisation) {
            return firstValidOrganisation.id?.$oid
        }
    }

    return null
}
