export default (value, isRequired, validationSettings) => {
    if (isRequired && (value === undefined || value === null)) {
        return 'required'
    }

    if (value !== null && value !== undefined && (typeof value !== 'number' || Number.isNaN(value))) {
        return 'invalidType'
    }

    // If we have not a number we're done here
    if (typeof value !== 'number') {
        return null
    }

    if (typeof validationSettings.min === 'number' && value < validationSettings.min) {
        return 'numberMin'
    }
    if (typeof validationSettings.max === 'number' && value > validationSettings.max) {
        return 'numberMax'
    }

    if (validationSettings.wholeNumber) {
        if (Math.floor(value) !== value) {
            return 'numberFraction'
        }
    }

    return null
}
