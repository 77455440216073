import nanoid from 'nanoid'

import getNextModelsBatch from './getNextModelsBatch'
import processModelsBatch from './processModelsBatch'
import createModelQuery from './createModelQuery'

export default async (
    Model,
    modelsQuery,
    onProgress,
    { limit = 100, groupId = nanoid() } = {}
) => {
    const modelsCount = await Model.createQuery(modelsQuery).getCount()

    const status = {
        // Unique group id for this run
        group: groupId,
        // Total number of models being removed
        total: modelsCount,
        // Number of models removed
        removed: 0,
        // Number of models that failed
        failed: 0,
        // Total number of models processed (removed and failed)
        processed: 0,
    }

    const handleProgressUpdate = async () => {
        if (onProgress) {
            return onProgress(status.processed, status.total, (status.processed / status.total) * 100)
        }
        return true
    }

    // update the progress the first time and then start
    await handleProgressUpdate()

    try {
        const finalQuery = createModelQuery(Model, modelsQuery, status.group)

        let modelsBatch = await getNextModelsBatch(
            finalQuery,
            0, // initial skip
            limit
        )

        while (modelsBatch) {
            // eslint-disable-next-line no-await-in-loop
            const [newModelsBatch, batchResult] = await Promise.all([
                getNextModelsBatch(
                    finalQuery,
                    modelsBatch.skip,
                    limit
                ),
                processModelsBatch(modelsBatch.models, status.group)
            ])
            status.removed += batchResult.removed
            status.failed += batchResult.failed
            status.processed += batchResult.processed
            modelsBatch = newModelsBatch
            handleProgressUpdate()
        }
    } catch (e) {
        // if any issues happen, we take the missing processed items and set as failed
        status.failed += status.total - status.processed
    }

    return status
}
