import Runtime from '@newspaces/lucky-base/Runtime'

import AppContext from '../../AppContext'

export default async () => {
    const { zone, userData } = AppContext

    if (!userData.organisationIds?.length) {
        return []
    }

    const organisations = await Runtime.Organisation.createQuery(
        {
            _id: { $in: userData.organisationIds },
            zone: zone.key,
        },
        undefined,
        {
            projection: ['uniqueSlug', 'avatar', 'name', 'customDomain'],
        }
    ).execute()

    const sortedOrganisations = userData.organisationIds
        .slice()
        .map(organisationId => {
            return organisations.find(document => document.id === organisationId.toString())
        })
        .filter(Boolean)

    return sortedOrganisations
}
