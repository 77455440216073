export default {
    group: {
        move: 'Move',
        expand: 'Expand',
        collapse: 'Collapse',
    },
    dataFilter: {
        searchField: 'Search field...',
        addFilter: 'Add filter',
        addFilterToGroup: 'Add filter to group',
        removeFilter: 'Remove filter',
        missingValue: 'missing value',
        done: 'Done',
        operator: {
            and: 'and',
            or: 'or',
        },
        compare: {
            list: {
                contains: 'contains',
                notContains: 'contains not',
                empty: 'is empty',
                notEmpty: 'is not empty',
            },
            string: {
                equals: 'equals',
                notEquals: 'equals not',
                contains: 'contains',
                notContains: 'contains not',
                startsWith: 'starts with',
                endsWith: 'ends with',
                exists: 'has any value',
                notExists: 'has no value',
            },
            number: {
                equals: 'equals',
                notEquals: 'equals not',
                greaterThan: 'greater than',
                lessThan: 'less than',
                exists: 'has any value',
                notExists: 'has no value',
            },
            date: {
                moreThanDaysAgo: 'more than',
                exactlyDaysAgo: 'exactly',
                lessThanDaysAgo: 'less than',
                moment: '',
                after: 'after',
                equals: 'on',
                before: 'before',
                exists: 'has any value',
                notExists: 'has no value',
                groupLabels: {
                    relative: 'Relative',
                    custom: 'Custom',
                    absolute: 'Absolute',
                },
                postfixLabels: {
                    days: 'Day(s)',
                },
                momentValues: {
                    beforeToday: 'Before today',
                    beforeOrAtToday: 'Today or before',
                    afterToday: 'After today',
                    afterOrAtToday: 'Today or after',
                },
            },
            bool: {
                isTrue: 'Is true',
                isFalse: 'Is false',
                exists: 'has any value',
                notExists: 'has no value',
            },
        },
    },
    dataGrid: {
        enabled: 'Enabled',
        columnMenu: 'Grouping, columns and more',
        pinColumn: 'Pin column',
        valueAggregation: 'Aggregate',
        autosizeThiscolumn: 'Adjust size to content',
        autosizeAllColumns: 'Adjust all columns to contents',
        groupBy: 'Group by',
        ungroupBy: 'Ungroup by',
        resetColumns: 'Reset all columns',
        expandAll: 'Expand all',
        collapseAll: 'Collapse all',
        pinLeft: 'Pin left',
        pinRight: 'Pin right',
        noPin: 'Remove pin',
        emptyGroup: 'not set',
        expandGroup: 'Open group',
        collapseGroup: 'Close group',
        sortAscending: 'Sort ascending',
        sortDescending: 'Sort descending',
    },
    fileInput: {
        upload: 'Upload file',
        remove: 'Remove file',
    },
    select: {
        initialLoading: 'Loading...',
    },
    loadMoreList: {
        loadMoreCount: '+${this.more} more',
        loadMoreInfinite: 'Load more',
    },
}
