import Runtime from '@newspaces/lucky-base/Runtime'

export default (userScopes, app) => {
    // If app requires no scopes then we may access it anyway
    if (!app.scopes) {
        return true
    }

    // If app has empty scopes we only require a valid user
    if (app.scopes.length === 0) {
        return Runtime.hasSession
    }

    // If app is not global and we have an owner then go through
    if (!app.isGlobal && userScopes.$owner) {
        return true
    }

    for (const scope of app.scopes) {
        // If array then when any scope within it matches the whole set matches
        if (Array.isArray(scope)) {
            const anyMatches = !!scope.find(s => Runtime.userHasScope(userScopes, s))
            if (!anyMatches) {
                return false
            }
        } else if (!Runtime.userHasScope(userScopes, scope)) {
            // If scope doesn't match exit
            return false
        }
    }

    // If we made it here then we matched everything
    return true
}
