import AppTheme from './AppTheme'
import AppThemeBrowser from './AppTheme.browser'
import AppThemeMobile from './AppTheme.mobile'

// eslint-disable-next-line
let platformTheme

if (process.env.RUNTIME_TARGET === 'browser') {
    platformTheme = AppThemeBrowser
}

if (process.env.RUNTIME_TARGET === 'mobile') {
    platformTheme = AppThemeMobile
}

export default args => [AppTheme(args), platformTheme(args)]
