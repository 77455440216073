import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { observer } from 'mobx-react-lite'

import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListSubheader from '@material-ui/core/ListSubheader'
import Divider from '@material-ui/core/Divider'
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles } from '@material-ui/core/styles'

import Runtime from '@newspaces/lucky-base/Runtime'

import Link from '@newspaces/lucky-components/Link'
import Avatar from '@newspaces/lucky-components/Avatar'
import TextSearchInput from '@newspaces/lucky-components/TextSearchInput'

import i18n from '../../i18n'
import AppContext from '../../AppContext'
import AppFrame from '../../App/AppFrame'
import { AddToListIcon } from '../../Icons'

import loadOrganisations from './loadOrganisations'
import moveOrganisation from './moveOrganisation'
import OrganisationItem from './OrganisationItem'

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    listHeaderItem: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: theme.spacing(1.5),
    },
    listItem: {
        minWidth: 400,
        [theme.breakpoints.down('xs')]: {
            minWidth: 'auto',
        },
    },
    divider: {
        margin: theme.spacing(1, 2),
        background: theme.palette.lightDivider,
    },
    search: {
        flex: 1,
    },
    emptySearch: {
        color: theme.palette.text.secondary,
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
}))

export default observer(({ renderAppFrame }) => {
    const classes = useStyles()
    const [organisations, setOrganisations] = useState(null)
    const [search, setSearch] = useState('')
    const [favoriteOrganisation, setFavoriteOrganisation] = useState(
        AppContext.userGetSetting('favoriteOrganisation', null)
    )

    useEffect(() => {
        const loadTheOrganisations = async () => {
            const loadedOrganisations = await loadOrganisations()
            setOrganisations(loadedOrganisations)
        }
        loadTheOrganisations()
    }, [])

    const filteredOrganisations = useMemo(() => {
        if (!organisations) {
            return []
        }
        return organisations.filter(organisation => {
            return !search || organisation.name.toLowerCase().includes(search.toLowerCase())
        })
    }, [search, organisations])

    const moveTheOrganisation = useCallback(
        evt => {
            const sortedOrganisations = moveOrganisation(evt, organisations)
            setOrganisations(sortedOrganisations)
        },
        [organisations]
    )

    const changeFavorite = useCallback(newFavorite => {
        AppContext.userSetSetting(
            'favoriteOrganisation',
            newFavorite,
            null,
        )
        setFavoriteOrganisation(newFavorite)
    }, [])

    let content
    if (!organisations) {
        content = (
            <List>
                <ListItem className={classes.listItem}>
                    <ListItemAvatar>
                        <Skeleton variant="circle" width={40} height={40} />
                    </ListItemAvatar>
                    <ListItemText primary={<Skeleton variant="text" />} />
                </ListItem>
                <ListItem className={classes.listItem}>
                    <ListItemAvatar>
                        <Skeleton variant="circle" width={40} height={40} />
                    </ListItemAvatar>
                    <ListItemText primary={<Skeleton variant="text" />} />
                </ListItem>
            </List>
        )
    } else {
        const Container = SortableContainer(() => {
            return (
                <div>
                    {filteredOrganisations.map((organisation, idx) => {
                        const Element = SortableElement(() => {
                            const isFavorite = favoriteOrganisation === organisation.id
                            const allowMoveOrganisations = filteredOrganisations.length > 1 &&
                                filteredOrganisations.length === organisations.length

                            return (
                                <OrganisationItem
                                    organisation={organisation}
                                    allowMoveOrganisations={allowMoveOrganisations}
                                    isFavorite={isFavorite}
                                    changeFavorite={changeFavorite}
                                />
                            )
                        })

                        return <Element index={idx} key={organisation.id} />
                    })}
                </div>
            )
        })

        const maxOrganisations = parseInt(Runtime.values.MAX_ORGANISATIONS_PER_USER || 0, 10)

        const canCreateOrganisation = !maxOrganisations || organisations.length < maxOrganisations

        content = (
            <List disablePadding>
                {organisations.length > 5 && (
                    <>
                        <ListSubheader className={classes.listHeaderItem} disableSticky>
                            {i18n().manageOrganisations.yourOrganisations}
                            <TextSearchInput
                                autoFocus
                                fullWidth
                                className={classes.search}
                                placeholder={i18n().misc.search}
                                value={search}
                                onChange={setSearch}
                            />
                        </ListSubheader>
                    </>
                )}

                <Container useDragHandle lockAxis="y" onSortEnd={moveTheOrganisation} />

                {!!organisations.length && !filteredOrganisations.length && (
                    <ListItem>
                        <ListItemText className={classes.emptySearch}>
                            {i18n().manageOrganisations.emptySearch}
                        </ListItemText>
                    </ListItem>
                )}

                {canCreateOrganisation && (
                    <>
                        {organisations.length > 0 && <Divider className={classes.divider} />}  
                        <ListItem button className={classes.listItem} component={Link} to="/organisations/create">
                            <ListItemAvatar>
                                <Avatar>
                                    <AddToListIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={i18n().manageOrganisations.newOrganisation} />
                        </ListItem>
                    </>
                )}
            </List>
        )
    }

    if (renderAppFrame) {
        return (
            <AppFrame>
                <div className={classes.root}>{content}</div>
            </AppFrame>
        )
    }

    return <div className={classes.root}>{content}</div>
})