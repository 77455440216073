/* eslint-disable max-len  */
/* eslint-disable no-template-curly-in-string */
export default {
    navigation: {
        userSettings: 'Le mie impostazioni',
        changeOrganisation: 'Cambia ${this.name}',
        changeApp: 'Cambia applicazione',
        login: 'Iscrizione',
        signup: 'Registrazione',
        logout: 'Disconnessione',
        menu: 'Menù',
    },
    app: {
        errors: {
            title: 'Siamo spiacenti, si è verificato un errore',
            backToHome: 'Torna alla home page',
            invalidInvitationToken: 'Il link di invito utilizzato è già stato utilizzato o non è valido.',
            invalidOrganisation: 'Non è stata trovata alcuna voce.',
            noAppAvailable: 'Non sono applicazioni disponibili.',
            appNotAccessible: "Nessun accesso all'applicazione richiesta.",
            unknown: 'Errore sconosciuto',
        },
        availableApps: 'Applicazioni disponibili',
        trialAlert: 'Il periodo di prova terminerà il ${this.date}.',
        trialAction: 'Vai alla fatturazione',
        subscription: {
            warning: {
                title: {
                    permanent: 'Il tuo accesso è bloccato.',
                    temporary: 'Il tuo accesso è stato temporaneamente sospeso.',
                },
                past_due: 'Non è stato possibile rinnovare automaticamente il tuo abbonamento.',
                unpaid: 'Non è stato possibile rinnovare automaticamente il tuo abbonamento.',
                canceled: 'Il tuo abbonamento è stato annullato.',
                incomplete: 'Il tuo abbonamento non è stato attivato correttamente.',
                incomplete_expired: 'Il tuo abbonamento non è stato attivato correttamente.',
                noSubscription: 'Il periodo di prova è terminato e non hai un abbonamento attivo.',
                contactSupport: {
                    permanent:
                        "Mettiti in contatto con noi per rinnovare il tuo abbonamento. Se l'abbonamento non viene rinnovato entro il ${this.date}, i tuoi dati verranno eliminati definitivamente.",
                    temporary: 'Mettiti in contatto con noi per riattivare il tuo accesso.',
                },
                actionSupport: 'Mettiti in contatto con noi',
                actionCreate: 'Sottoscrivi un nuovo abbonamento',
            },
        },
    },
    auth: {
        chooseAction: {
            title: 'Hai già un account ${this.name}?',
            acceptInvitation: "Accedi o crea un nuovo account per accettare l'invito:",
            loginAction: 'Ho già un account',
            signUpAction: 'Vorrei creare un account',
        },
        login: {
            title: 'Accedi a ${this.name}',
            action: 'Iscriviti ora',
            email: 'Indirizzo e-mail',
            password: 'Password',
            passwordForgotten: 'Hai dimenticato la password?',
            noAccountSignUp: 'Ancora nessun account? Crea subito un account.',
            errors: {
                invalidEmail: 'Nessun account trovato con questo indirizzo e-mail.',
                invalidPassword: "Password errata per l'indirizzo e-mail.",
                invalidCredentials: 'Indirizzo e-mail o password errati.',
                invalidZone: "L'utente non ha accesso a quest'area.",
            },
        },
        signUp: {
            title: 'Crea un nuovo account ${this.name}',
            action: 'Crea un account',
            email: 'Il tuo indirizzo email',
            password: 'Scegli una password',
            dataPrivacy:
                'Con la presente dichiaro di aver letto e accettato la <<Politica sulla privacy>>. Sono consapevole di poter revocare il mio consenso in qualsiasi momento.',
            termsOfUse:
                "Con la presente dichiaro di aver letto e accettato i <<Termini e condizioni d'uso>>.",
            dataContract: "<<Clicca qui>> per scaricare l'accordo sulla protezione dei dati dell'ordine.",
            alreadyRegisteredLogin: 'Hai già un account? Allora registrati qui.',
            errors: {
                invalidEmail: "L'indirizzo email che hai inserito non è valido.",
                invalidPassword: 'Password non valida, sono necessari almeno sei caratteri.',
                alreadySignedUp: 'Esiste già un account con questo indirizzo email, effettua il login.',
            },
        },
        resetPasswordQuery: {
            title: 'Reimposta la password ${this.name}',
            action: "Invia un'email per reimpostare",
            email: 'Indirizzo email registrato',
            success:
                'È stata inviata una e-mail a ${this.email} con le istruzioni su come reimpostare la password.',
            rememberPasswordLogin: 'Ti ricordi ancora la password? Allora registrati qui.',
            errors: {
                invalidEmail: "Non esiste un account per l'indirizzo e-mail specificato.",
                unknownError: 'Si è verificato un errore sconosciuto',
            },
        },
        resetPasswordAction: {
            title: 'Imposta la tua nuova password ${this.name}',
            action: 'Imposta la nuova password',
            email: 'Indirizzo email registrato',
            password: 'Nuova password',
            passwordConfirm: 'Ripeti la nuova password',
            success: 'La nuova password è stata impostata correttamente. Ora puoi accedere.',
            loginNow: 'Accedi ora con i tuoi nuovi dati di accesso.',
            errors: {
                passwordMissmatch: 'Le password non corrispondono.',
                expiredToken: 'Il link è scaduto. Reimposta nuovamente la password.',
                invalidToken: 'Il link non è valido. Reimposta nuovamente la password.',
                invalidEmail: "Non esiste un account per l'indirizzo e-mail specificato.",
                invalidPassword: 'Password non valida, sono necessari almeno sei caratteri.',
            },
        },
    },
    manageOrganisations: {
        newOrganisation: 'Crea una nuova organizzazione',
        yourOrganisations: 'Le tue organizzazioni',
        emptySearch: 'Nessuna organizzazione trovata',
        moveOrganisation: 'Cambia organizzazione',
        leaveOrganisation: "Lascia l'organizzazione",
        create: {
            label: 'Crea una nuova organizzazione',
            description: '',
            name: 'Nome della tua organizzazione',
            country: 'In quale paese si trova la tua organizzazione?',
            action: "Crea subito un'organizzazione",
        },
        leaveOrganisationPrompt: {
            title: 'Abbandona ${this.organisationName}',
            text: 'Sei sicuro di voler abbandonare "${this.organisationName}"?',
            action: "Sì, lascia l'organizzazione",
            errors: {
                onlyOwner:
                    "Non puoi lasciare questa organizzazione perché sei l'unico utente. Se vuoi, puoi eliminare questa organizzazione nelle impostazioni.",
            },
        },
    },
    organisationSettings: {
        profile: {
            title: 'Profilo',
            uploadAvatarButton: 'Carica una nuova immagine',
            information: {
                title: 'Impostazioni del profilo',
            },
            uniqueSlug: {
                title: 'Identificazione (ID)',
                description:
                    "L'ID viene utilizzato per fornire un URL leggibile e viene indicato anche come nome del mittente nelle e-mail. Attenzione: Se il tuo URL è già in uso e modifichi il tuo ID, tutti i vecchi URL non saranno più validi.",
                action: 'Imposta un nuovo ID',
                errors: {
                    invalidSlug: 'Questo ID non è valido. Scegli un altro ID e riprova.',
                    forbidden: 'Non sei autorizzato a modificare questo ID.',
                    alreadyClaimed: 'Questo ID è già utilizzato da qualcun altro.',
                    unknown: 'Si è verificato un errore sconosciuto.',
                },
                success: "L'ID è stato impostato correttamente e il tuo URL è stato modificato.",
            },
            dangerZone: {
                title: 'Zona pericolosa',
                deleteOrganisation: {
                    title: 'Elimina ${this.organisationName}',
                    description:
                        'Cancella irrevocabilmente tutti i dati da "${this.organisationName}". Attenzione, questo processo non può essere annullato!',
                    action: 'Spegnimento',
                },
                deleteOrganisationModal: {
                    title: 'Elimina ${this.organisationName}',
                    text:
                        'Sei assolutamente sicuro di voler cancellare "${this.organisationName}" e tutti i dati associati? Questa operazione non può essere annullata.',
                    input: {
                        text:
                            'Se vuoi continuare, inserisci il testo "${this.organisationName}" nel campo sottostante:',
                        action: 'Elimina ora',
                    },
                },
            },
        },
        billing: {
            title: 'Fatturazione e abbonamenti',
            products: {
                pricing: {
                    flat: {
                        year: '${this.amount} / anno',
                        month: '${this.amount} / mese',
                    },
                    metered: '${this.amount} per socio / mese',
                    tiered:
                        '${this.flatAmount} per i primi ${this.flatLimit} membri. ${this.meteredAmount} per ogni membro aggiuntivo.',
                    tieredLabel: "In base all'utilizzo",
                },
            },
            subscription: {
                title: 'Sottoscrizione',
                empty: 'Nessun abbonamento disponibile.',
                priceLink: 'Apri il piano tariffario',
                trial: {
                    title: 'Abbonamento di prova',
                    text:
                        "L'abbonamento \"${this.name}\" è in prova fino al ${this.date}. Per continuare l'accesso, è necessario sottoscrivere l'abbonamento entro la fine del periodo di prova.",
                    action: 'Iscriviti ora!',
                    price: 'Prezzo: ${this.price}',
                    priceLink: 'Apri il piano tariffario',
                },
                status: {
                    none: 'Nessun abbonamento',
                    incomplete: 'Incompleto',
                    incomplete_expired: 'Scaduto',
                    trialing: 'Periodo di prova fino al ${this.date}',
                    active: 'Attivo',
                    past_due: 'Scaduto',
                    canceled: 'Cancellato',
                    unpaid: 'Non pagato',
                    will_be_canceled: 'Cancellazione il ${this.date}',
                },
                switchPrice: 'Cambia piano tariffario',
                createSubscription: 'Iscrizione',
                newSubscription: 'Sottoscrivi un nuovo abbonamento',
                reactivateSubscription: "Riattiva l'abbonamento cancellato",
                cancel: {
                    action: "Cancella l'abbonamento",
                    prompt:
                        'Sei sicuro di voler cancellare questo abbonamento? Verrà cancellato alla fine del periodo in corso. Questo processo non può essere annullato.',
                },
                activate: {
                    action: "Attiva l'abbonamento",
                },
                editor: {
                    product: 'Prodotto',
                    price: 'Piano tariffario',
                    title: 'Seleziona il prodotto desiderato e il piano tariffario:',
                    serviceTitle:
                        'Il servizio "${this.service}" è in uso ma non è stato sottoscritto. Abbonati ora a uno dei servizi per continuare:',
                },
                unitAmount: '${this.amount} per membro',
                service: {
                    usage: 'Utilizzo: ${this.usage}',
                    costs: 'Costi: ${this.costs}',
                },
                applyDiscount: {
                    action: 'Utilizza il tuo codice-sconto',
                    tooltip: 'Puoi utilizzare il tuo codice-sconto ancora per ${this.hoursLeft} ore.',
                    dialog: {
                        title: 'Inserisci il codice del buono-spesa che vuoi utilizzare:',
                        action: 'Utilizza il codice-sconto',
                    },
                    errors: {
                        invalidPromotion: 'Questo codice-sconto non è più valido.',
                        codeNotFound: 'Questo codice-sconto è inesistente.',
                        unknownError: 'Si è verificato un errore sconosciuto. Riprova.',
                    },
                },
            },
            credits: {
                title: 'Credito',
                current: 'Credito attuale: ${this.credits}',
                charge: 'Ricarica il credito',
                amount: 'Importo da addebitare',
                minimun: 'Importo minimo richiesto: ${this.amount}',
            },
            services: {
                title: 'Servizi e utilizzo',
                emptyHistory: 'Non ci sono voci precedenti per questo servizio',
                noCosts:
                    'I costi ti verranno addebitati solo quando avrai un abbonamento attivo al servizio.',
                history: 'Cronologia di utilizzo del servizio ${this.service}',
                sections: {
                    allTime: 'Tempo totale di utilizzo',
                    monthly: 'Utilizzo per ${this.month}. ${this.year}',
                },
                table: {
                    service: 'Servizio',
                    usage: 'Utilizzo',
                    costs: 'Costi',
                    period: 'Periodo',
                    lastUpdated: 'Ultimo aggiornamento',
                    subscription: 'Abbonamento',
                    actions: {
                        seeHistory: 'vedi cronologia di utilizzo del servizio',
                    },
                },
            },
            paymentMethods: {
                title: 'Metodi di pagamento',
                typeValue: {
                    card:
                        'Carta di credito (${this.card.brand}) **** ${this.card.last4} - valida fino ${this.card.exp_month} /${this.card.exp_year}',
                    sepa_debit: 'Addebito diretto SEPA **** ${this.sepa_debit.last4}',
                },
                default: 'Standard',
                makeDefault: 'Imposta il metodo di pagamento come predefinito',
                delete: {
                    action: 'Elimina metodo di pagamento',
                    prompt:
                        'Sei sicuro di voler eliminare questo metodo di pagamento? Questa azione non può essere annullata.',
                },
            },
            details: {
                title: 'Dettagli della fatturazione',
                email: 'Email per la fatturazione',
                name: 'Destinatario della fattura',
                address: {
                    title: 'indirizzo della fatturazione',
                    line1: 'indirizzo 1',
                    line2: 'indirizzo 2',
                    postal_code: 'Codice postale',
                    city: 'Città',
                    country: 'Paese',
                },
            },
            invoices: {
                title: 'Cronologia della fatturazione',
                number: 'Fattura N.',
                amountPaid: 'Pagato',
                amountTotal: 'Totale',
                date: 'Data',
            },
        },
        users: {
            title: "Gestione dell'utente",
            table: {
                name: 'Nome',
                email: 'Indirizzo e-mail',
                status: 'Stato',
                statusValue: {
                    invited: 'invitato',
                    active: 'Attivo',
                    inactive: 'inattivo',
                    created: 'Previsto',
                    invite: 'Invito in attesa',
                },
                createdAt: 'Creato il',
                isOwner: 'Proprietario',
                loadMore: 'Caricamento di altri utenti...',
                editUser: "Modifica l'utente",
                deleteUser: "Elimina l'utente...",
                getLink: 'Copia il link di invito',
            },
            toolbar: {
                userCount: {
                    single: 'Utente',
                    multi: 'Utenti',
                },
                search: 'Cerca per nome o per e-mail',
                addUser: 'Invita utenti',
            },
            userSettings: {
                name: "Nome dell'utente",
                email: 'Indirizzo e-mail',
                inviteUser: 'Invita utenti',
                eMailAlreadyExists: 'Un utente con l\'indirizzo email "${this.email}" è già esistente.',
                permissions: {
                    owner: {
                        checkbox: 'Rendi questo utente il proprietario',
                        hint:
                            'I proprietari hanno tutte le autorizzazioni e possono visualizzare e modificare tutto senza limiti.',
                        deniedHint:
                            'Non disponi delle autorizzazioni necessarie per impostare il proprietario',
                    },
                    header: {
                        permission: 'Leggi da',
                        edit: 'Modifica consentita',
                    },
                    toggleCustomTooltip: 'Diritti di accesso estesi',
                    customOptions: {
                        read: 'Lettura',
                        insert: 'Crea',
                        edit: 'Modifica',
                        remove: 'Spegnimento',
                    },
                    test: 'Modalità live',
                    testTooltip:
                        'Verifica in tempo reale in che modo le modifiche influiscono sui diritti utente personalizzati',
                    debugContext: {
                        title: 'Live-test dei diritti degli utenti',
                        finish: 'Conferma',
                    },
                    apps: {
                        accessTo: 'Accesso consentito a',
                        noAccess: "Nessun accesso: fai clic per consentire l'accesso",
                        hasAccess: 'Accesso consentito',
                    },
                },
            },
            deleteUser: {
                title: "Elimina l'utente",
                text: 'Sei sicuro di voler cancellare ${this.userName} da ${this.orgName}?',
                action: "Elimina l'utente",
            },
            copyInvitationLink: {
                title: 'Link di invito',
                text:
                    "L'utente invitato può utilizzare questo link per accettare l'invito e unirsi a ${this.orgName}.",
                tooltipCopy: 'Copia link',
                tooltipCopied: 'Il link è stato copiato negli appunti!',
            },
            debug: {
                description:
                    'Tutte le modifiche ai diritti degli utenti sono indicate in tempo reale nella sessione corrente. Le modifiche vengono annullate con la chiusura della finestra.',
                transferUser: 'Assegnare i diritti utente',
                transferUserHint:
                    "Se il segno di spunta è impostato, le modifiche ai diritti dell'utente vengono trasferite all'utente creato/modificato dopo aver chiuso la finestra.",
            },
        },
    },
    userSettings: {
        profile: {
            title: 'Profilo',
            name: 'Il tuo nome (visibile agli altri)',
            email: 'Indirizzo e-mail',
            bio: 'Su di te',
            language: 'Lingua preferita',
            deleteAccount: {
                title: 'Zona pericolosa',
                description:
                    "Elimina il mio account con l'e-mail (${this.email}). Attenzione: Attenzione! Questa azione non può essere annullata.",
                action: 'Spegnimento',
            },
            deleteAccountModal: {
                title: 'Elimina ${this.email}',
                text:
                    "Sei sicuro di voler eliminare l'utente con l'email (${this.email})? Attenzione! Questa azione non può essere annullata.",
                input: {
                    verifyWord: 'Spegnimento',
                    text:
                        'Per verificare l\'operazione, inserisci la seguente parola nel campo: "${this.verifyWord}"',
                    action: 'Elimina ora',
                },
            },
        },
        developer: {
            title: 'Programmatore + API',
            apiDocumentation: {
                title: 'Documentazione API',
                intro:
                    'Il riferimento API contiene la documentazione su tutte le funzioni. Hai bisogno di una chiave API per provare gli esempi.',
                link: 'Vai alla documentazione API',
            },
            apiKeys: {
                title: 'Chiavi API',
                intro:
                    'Ogni chiave API possiede gli stessi diritti del tuo account utente. Se desideri diritti separati, crea un nuovo account.',
                newKeyAction: 'Crea una nuova chiave',
                newKeyName: 'Nome della chiave',
                copyWarning: 'Copia e salva questa chiave in quanto non verrà più visualizzata in seguito.',
                copyKey: 'Copia la chiave',
                copyKeyDone: 'La chiave è stata copiata negli appunti!',
                deleteKeyTitle: 'Elimina la chiave',
                deleteKeyPrompt:
                    "Sei sicuro di voler eliminare la chiave? Questa azione non può essere annullata e non sarà più possibile effettuare l'accesso alle applicazioni che utilizzano la chiave.",
                deleteKeyAction: 'Sì, cancella la chiave',
            },
        },
    },
}
