import React, { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import deepmerge from 'deepmerge'

import i18n from '../i18n'

import AppContext from '../AppContext'

import AppTheme from './AppTheme'
import AppRenderer from './AppRenderer'
import AppLoader from './AppLoader'
import SplashScreen from './SplashScreen'

export default observer(properties => {
    const [appContextInitialized, setAppContextInitialized] = useState(false)

    const appTheme = useMemo(() => {
        const theme = AppTheme({
            primaryColor: AppContext.appTheme.primaryColor || AppContext.zone.primaryColor,
            primaryTextColor: AppContext.appTheme.primaryTextColor || AppContext.zone.primaryTextColor,
            platform: AppContext.platform,
        })

        return createMuiTheme(
            deepmerge.all([...theme, AppContext.zone.theme || {}, AppContext.appTheme.theme || {}]),
            i18n.getMuiLocale(i18n().locale)
        )

        // may only update if i18n or appTheme have actually be changed
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n().locale, AppContext.appTheme, AppContext.platform])

    useEffect(() => {
        const initAppContext = async () => {
            await AppContext.init()
            setAppContextInitialized(true)
        }

        initAppContext()

        return () => AppContext.release()
    }, [])

    const { isMobile, zone, appLoader } = AppContext

    const showSplashScreen = !!(
        !appContextInitialized && 
        !appLoader && 
        zone.splashScreen && 
        isMobile
    )

    return (
        <MuiThemeProvider theme={appTheme}>
            <CssBaseline />
            {appContextInitialized && <AppRenderer theme={appTheme} {...properties} />}
            {appLoader === true && <AppLoader />}
            {showSplashScreen && <SplashScreen/>}
        </MuiThemeProvider>
    )
})
