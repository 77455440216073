import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import classNames from 'classnames'

import { fade } from '@newspaces/lucky-components/utils'

import AppContext from '../../AppContext'

import NoCloseAlert from './NoCloseAlert'
import ProgressBar from './ProgressBar'
import ResultActions from './ResultActions'

const useStyles = makeStyles(theme => ({
    backdrop: {
        background: 'rgba(0, 0, 0, 0.06)',
    },
    dialogPaper: {
        background: 'rgba(255, 255, 255, 0.9)',
        backdropFilter: 'blur(10px)',
        boxShadow: theme.shape.panelShadow,

        '&.success': {
            background: fade(fade(theme.palette.success.main, 0.08, 'white'), 0.9),
        },

        '&.error': {
            background: fade(fade(theme.palette.error.main, 0.08, 'white'), 0.9),
        },
    },
    dialogTitle: {
        '& > h2': {
            fontSize: 16,
            fontWeight: 500,
        },
    },
    dialogContent: ({ width }) => ({
        marginTop: -theme.spacing(2),
        width: width || (450 + theme.spacing(6)),
        overflow: 'hidden',
        '&.isFullScreen': {
            width: '100%',
            overflow: 'auto',
        },
    }),
    dialogText: {
        paddingTop: theme.spacing(1),
        color: theme.palette.text.primary,
    },
    dialogSecondaryText: {
        paddingTop: theme.spacing(1),
        fontSize: 12,
        color: theme.palette.text.secondary,
    }
}))

export default observer(({ modal }) => {
    const { store, onClose, noCloseAlert, width } = modal
    const classes = useStyles({ width })

    const theme = useTheme()
    const isFullScreen = useMediaQuery(theme.breakpoints.down('sm'))

    const handleClose = useCallback(
        arg => {
            if (['success', 'error', 'warning'].includes(store.status)) {
                let preventClose = false

                if (onClose) {
                    if (onClose(arg) === false) {
                        preventClose = true
                    }
                }

                if (!preventClose) {
                    AppContext.clearModal(modal)
                }
            }
        },
        [store, modal, onClose]
    )

    const { status, title, text, secondaryText, progress, actions } = store
    const isWorking = !['success', 'error', 'warning'].includes(status)

    return (
        <Dialog
            classes={{ paper: classNames(classes.dialogPaper, !isWorking && status) }}
            BackdropProps={{ className: classes.backdrop }}
            open
            fullScreen={isFullScreen}
            onClose={handleClose}
        >
            {title && <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>}
            <DialogContent className={classNames(classes.dialogContent, { isFullScreen })}>
                {text && <DialogContentText className={classes.dialogText}>{text}</DialogContentText>}
                {secondaryText && <DialogContentText className={classes.dialogSecondaryText}>{secondaryText}</DialogContentText>}
                {isWorking && <ProgressBar progress={progress} />}
                {isWorking && noCloseAlert !== false && <NoCloseAlert />}
                {!isWorking && <ResultActions status={status} actions={actions} onClose={handleClose} />}
            </DialogContent>
        </Dialog>
    )
})
