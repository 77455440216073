export default function waitFor(condition, intervalMS, maxRetries = -1) {
    return new Promise((resolve, reject) => {
        let tries = 0

        const checkCondition = async () => {
            if (await condition()) {
                return resolve()
            }

            tries += 1

            if (maxRetries > 0 && tries >= maxRetries) {
                return reject(new Error('max_tries_exceeded'))
            }

            return setTimeout(checkCondition, intervalMS)
        }

        checkCondition()
    })
}
