let activeChangeStreams = []

export function canWatch() {
    return false
}

export async function aquireWatcher(collection, query, onChange) {
    const options = {}

    if (Array.isArray(query)) {
        // Watching a set of ids so just take as-is
        options.ids = query
    } else {
        // We need to convert the query to work on deletions and for any other
        // operation to work on the $match of the actual fullDocument property
        // of the incoming changeEvent

        const getPropertyName = property => {
            if (property.charAt(0) === '$') {
                return property
            }

            return `fullDocument.${property}`
        }

        const convertQueryValue = queryValue => {
            if (Array.isArray(queryValue)) {
                return queryValue.map(item => convertQueryValue(item))
            }

            if (typeof queryValue === 'object' && queryValue && Object.keys(queryValue).length > 0) {
                const result = {}

                for (const property in queryValue) {
                    if (Object.prototype.hasOwnProperty.call(queryValue, property)) {
                        result[getPropertyName(property)] = convertQueryValue(queryValue[property])
                    }
                }

                return result
            }

            return queryValue
        }

        options.filter = {
            $or: [
                {
                    operationType: 'delete',
                },
                convertQueryValue(query),
            ],
        }
    }

    const createWatcher = () => {
        const collectionWatcher = collection.watch(options)

        // eslint-disable-next-line func-names
        const listenToChanges = async () => {
            // keep iterating over generator
            for await (const event of collectionWatcher) {
                const { operationType, fullDocument, documentKey } = event
                onChange(operationType, fullDocument, documentKey && documentKey._id)
            }
        }

        listenToChanges()

        return collectionWatcher
    }

    const watcher = createWatcher()
    
    if (process.env.NODE_ENV !== 'production') {
        activeChangeStreams.push(watcher)
    }

    return watcher
}

export function releaseWatcher(changeStream) {
    if (process.env.NODE_ENV !== 'production') {
        activeChangeStreams = activeChangeStreams.filter(item => item !== changeStream)
    }

    changeStream.return(null)
}
