import Runtime from '@newspaces/lucky-base/Runtime'

export default ({ newIndex, oldIndex }, organisations) => {
    const newOrganisations = organisations.slice()
    const [orgDataToMove] = newOrganisations.splice(oldIndex, 1)
    newOrganisations.splice(newIndex, 0, orgDataToMove)

    Runtime.apiMethod(
        `/organisations/${orgDataToMove.id}/move`,
        { newIndex }
    )

    return newOrganisations
}
