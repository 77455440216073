import Runtime from '@newspaces/lucky-base/Runtime'

export default (context, appKey, organisationSlugOrId) => {
    const { currentSubdomainType, organisationIdOnUrl } = Runtime

    const app =
        (appKey && context.availableApps.find(({ key }) => key.toLowerCase() === appKey.toLowerCase())) ||
        null

    if (app && app.isRoot && app.isGlobal) {
        return '/'
    }

    let parts = []
    if (organisationSlugOrId && !app?.isGlobal && organisationIdOnUrl) {
        parts.push(organisationSlugOrId)
    }

    if (app) {
        if (!app.isRoot && currentSubdomainType !== 'app') {
            parts.push(app.key)
        }

        if (app.State && app.State.basename) {
            parts = parts.concat(app.State.basename.split('/').filter(x => !!x))
        }
    }

    return `/${parts.join('/')}`
}
