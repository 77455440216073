import cloneDeep from 'clone-deep'
import equal from 'fast-deep-equal'
import nanoid from 'nanoid'
import flatley from 'flatley'

import Runtime from '../Runtime'

import isInstanceOfObjectId from '../utils/objectId/isInstanceOf'

import DateTime from '../DateTime'
import i18n from '../i18n'

import { aquireWatcher, releaseWatcher, canWatch } from './watchers'
import convertUpdateDocument from './convertUpdateDocument'
import flattenSerializedDocument from './flattenSerializedDocument'
import ValidationError from './ValidationError'
import validate from './validate'

const isSecurityRuleError = error => {
    return (
        error.error === 'update not permitted' ||
        error.error === 'insert not permitted' ||
        error.error === 'delete not permitted' ||
        error.errorCode === 'NoMatchingRuleFound'
    )
}

export default class Model {
    static canWatch = canWatch()

    static decorators = new Map()

    static id = idOrModelOrString => {
        if (!idOrModelOrString) {
            throw new Error('Invalid idOrModelOrString parameter.')
        }

        if (Model.isId(idOrModelOrString)) {
            return new Runtime.BSON.ObjectId(idOrModelOrString.toString())
        }

        if (idOrModelOrString._id) {
            return new Runtime.BSON.ObjectId(idOrModelOrString._id.toString())
        }

        return new Runtime.BSON.ObjectId(idOrModelOrString.toString())
    }

    static isRootModel(classOrInstance) {
        if (!classOrInstance) {
            return false
        }

        return (
            (classOrInstance instanceof Model || classOrInstance.prototype instanceof Model) &&
            !Model.isSubModel(classOrInstance)
        )
    }

    static isSubModel(classOrInstance) {
        if (!classOrInstance) {
            return false
        }

        if (classOrInstance instanceof Model) {
            return !classOrInstance.constructor.CollectionName
        }

        if (classOrInstance.prototype instanceof Model) {
            return !classOrInstance.CollectionName
        }

        return false
    }

    static isModel(classOrInstance) {
        return (
            classOrInstance &&
            (classOrInstance instanceof Model || classOrInstance.prototype instanceof Model)
        )
    }

    static isId(value) {
        if (!value) {
            return null
        }

        if (isInstanceOfObjectId(value)) {
            return true
        }

        if (typeof value === 'string') {
            return Runtime.BSON.ObjectId.isValid(value)
        }

        return false
    }

    static Fields = {
        _id: {
            type: Runtime.BSON.ObjectId,
            description: 'Unique identifier for the object (automatically set)',
        },
        createdAt: {
            type: DateTime,
            default: () => new DateTime(),
            description: 'When this object was initially created (automatically set)',
        },
        createdBy: {
            type: String,
            default: () => Runtime.userId,
            description: 'ID of the user that initially created this object (automatically set)',
        },
        updatedAt: {
            type: DateTime,
            description: 'When this object was updated the last time (automatically set)',
        },
        updatedBy: {
            type: String,
            description: 'ID of the user that updated this object the last time (automatically set)',
        },
        removedAt: {
            type: DateTime,
        },
        removedBy: {
            type: String,
        },
        // -- internal --
        data: {
            type: Object,
            // ignored -- no serialize, deserialize or anything else
            ignored: true,
        },
        mergeTags: {
            get: true,
            type: Object,
            serialize: false,
        },
    }

    fields

    data

    previousErrors = null

    previousValidationErrors = null

    _watcher = null

    _key = null

    listeners = []

    constructor(fields, data, settingsOrParent) {
        this.fields = { ...Model.Fields, ...fields }

        const { ignoreModelFields, assignProperties = {} } = settingsOrParent || {}

        Object.keys(assignProperties).forEach(property => {
            this[property] = assignProperties[property]
        })

        const decorator = Model.decorators.get(this.constructor)
        if (decorator) {
            // Ugly but make sure before applying decorator to declare all fields
            for (const fieldName in this.fields) {
                if (!this.fields[fieldName].get) {
                    this[fieldName] = undefined
                }
            }

            decorator.decorate(this)
        }

        this._instantiateModel()

        if (data) {
            if (data instanceof Model) {
                this.deserialize(data.serialize(), { ignoreModelFields })
            } else {
                this.deserialize(data, { ignoreModelFields })
            }
        }
    }

    get id() {
        return this._id ? this._id.toString() : null
    }

    get $key() {
        if (!this._key) {
            this._key = nanoid()
        }
        return this._key
    }

    get _collection() {
        return Runtime.db.collection(this.constructor.CollectionName)
    }

    get isValid() {
        return this.validationErrors === null
    }

    get validationErrors() {
        const newErrors = this.validate('', true)

        if (equal(newErrors, this.previousErrors)) {
            return this.previousValidationErrors
        }

        if (!newErrors) {
            this.previousErrors = null
            this.previousValidationErrors = null
            return this.previousValidationErrors
        }

        const newValidationErrors = {}

        newErrors.forEach(({ path, ...error }) => {
            newValidationErrors[path] = error
        })

        this.previousErrors = newErrors
        this.previousValidationErrors = newValidationErrors
        return this.previousValidationErrors
    }

    _call(method, ...args) {
        return Runtime.call(`db.${method}`, this.constructor.CollectionName, ...args)
    }

    hasValidationError(path, type = null) {
        if (!this.validationErrors) {
            return false
        }

        const error = this.validationErrors[path]
        if (!error) {
            return false
        }

        if (!type) {
            return true
        }

        if (type.charAt(0) === '!') {
            return error.type !== type.substr(1)
        }

        return error.type === type
    }

    get isModified() {
        return Object.keys(this.changeSet).length > 0
    }

    get changeSet() {
        const currentData = this.serialize({ mode: 'save' })
        const currentFlatData = flattenSerializedDocument(currentData)
        const previousFlatData = flattenSerializedDocument(this.data)

        const result = {}

        Object.keys(currentFlatData)
            .concat(Object.keys(previousFlatData))
            .filter((value, index, self) => self.indexOf(value) === index)
            .forEach(property => {
                if (!equal(currentFlatData[property], previousFlatData[property])) {
                    // NOTE: we tried to implement proper array patching but that turned into
                    // a nightmare. Do yourself a favor and think twice before trying to do it again
                    result[property] = currentFlatData[property]
                }
            })

        const resultKeys = Object.keys(result)
        Object.keys(result).forEach(key => {
            const matches = resultKeys.filter(matchKey => matchKey.indexOf(`${key}.`) === 0)
            const hasMatches = !!matches.length
            const hasDefinedMatch = !!matches.find(match => result[match] !== undefined)

            if (hasDefinedMatch) {
                // if there are nested properties being added, and the value if prop is undefined
                // and the previous value is falsy we need to recreate the object
                if (result[key] === undefined && !previousFlatData[key]) {
                    const newObject = {}
                    matches.forEach(match => {
                        const newKey = match.substring(key.length + 1)
                        newObject[newKey] = result[match]
                        delete result[match]
                    })
                    result[key] = flatley.unflatten(newObject)
                } else {
                    // if there are nested properties being added, and they have valid values, we
                    // remove the changeset for the object itself
                    delete result[key]
                }
                // if there are nested properties
            } else if (hasMatches) {
                const isValidValue = !!result[key]
                if (!isValidValue || !Object.keys(result[key]).length) {
                    // IF the value is falsy OR the object is empty
                    // THEN we remove the nested properties from the changeset
                    matches.forEach(match => {
                        delete result[match]
                    })
                }
            }
        })

        return result
    }

    markSaved(unhandledFields = [], { ignoreResources = false } = {}) {
        const serializedData = this.serialize({ mode: 'save' })

        if (Model.isRootModel(this)) {
            // only handle unhandled fields on root models.
            // submodels will never find the field itself anyway
            // since the field pathes are always provided using the
            // root as starting point
            unhandledFields.forEach(fieldPath => {
                let parent = serializedData
                const pathes = fieldPath.split('.')

                for (let i = 0; i < pathes.length - 1; i += 1) {
                    parent = parent[pathes[i]]

                    if (!parent || typeof parent !== 'object') {
                        return
                    }
                }

                delete parent[pathes[pathes.length - 1]]
            })
        }

        const markValueAsSaved = (fieldType, value) => {
            if (Model.isSubModel(value)) {
                if (!ignoreResources || !fieldType.IS_RESOURCE) {
                    value.markSaved(unhandledFields, { ignoreResources })
                }
            }
        }

        for (const fieldName in this.fields) {
            if (Object.prototype.hasOwnProperty.call(this.fields, fieldName)) {
                const fieldInfo = this.fields[fieldName]
                const fieldValue = this[fieldName]

                if (Array.isArray(fieldInfo.type)) {
                    ;(fieldValue || []).forEach(value => {
                        markValueAsSaved(fieldInfo.type[0], value)
                    })
                } else if (fieldValue !== undefined) {
                    markValueAsSaved(fieldInfo.type, fieldValue)
                }
            }
        }

        this.data = serializedData
        return true
    }

    revert(force = false) {
        if (!this.isModified && !force) {
            return true
        }

        if (this.data) {
            this.deserialize(this.data, { noReset: true })
            return true
        }
        return false
    }

    getField(fieldPath, throwOnWrongPath = true) {
        let fields = this.fields
        const pathes = fieldPath.split('.')

        for (let i = 0; i < pathes.length - 1; i += 1) {
            const field = fields[pathes[i]]
            let hasMoreFields = false

            if (field) {
                const fieldType = Array.isArray(field.type) ? field.type[0] : field.type
                if (Model.isSubModel(fieldType)) {
                    fields = fieldType.Fields
                    hasMoreFields = !!fields
                }
            }

            if (!hasMoreFields) {
                if (throwOnWrongPath) {
                    throw new Error(`Invalid field path "${fieldPath}" at "${pathes[i]}"`)
                }

                return null
            }
        }

        return fields[pathes[pathes.length - 1]] || null
    }

    getProperty(fieldPath, throwOnWrongPath = true) {
        let parent = this
        const pathes = fieldPath.split('.')

        for (let i = 0; i < pathes.length - 1; i += 1) {
            parent = parent[pathes[i]]

            if (!parent) {
                if (throwOnWrongPath) {
                    throw new Error(`Invalid path "${fieldPath}" at "${pathes[i]}"`)
                }
                return { parent, property: null }
            }
        }

        return { parent, property: pathes[pathes.length - 1] || null }
    }

    getPropertyValue(fieldPath) {
        const { parent, property } = this.getProperty(fieldPath)
        return parent[property]
    }

    setPropertyValue(fieldPath, value) {
        const { parent, property } = this.getProperty(fieldPath)
        parent[property] = value
    }

    clone({ ignoreModelFields = false } = {}) {
        const instance = new this.constructor()
        instance.deserialize(this.serialize(), { ignoreModelFields })
        return instance
    }

    validate(parentPath = '', returnErrors = false) {
        let errors = []

        const getPath = path => {
            if (parentPath) {
                return `${parentPath}.${path}`
            }
            return path
        }

        const isRequired = required => {
            if (typeof required === 'function') {
                return required(this)
            }
            return required || false
        }

        const validateValue = (type, settings, value, path) => {
            const required = isRequired(settings.required)

            if (Model.isSubModel(type)) {
                const valErrors = value.validate(getPath(path), true)
                if (valErrors) {
                    errors = errors.concat(valErrors)
                }
            } else if (Model.isRootModel(type)) {
                if (!value) {
                    if (required) {
                        errors.push({ path: getPath(path), type: 'required' })
                    }
                } else if (value instanceof Model) {
                    const ModelClass = type
                    if (!(value instanceof ModelClass)) {
                        errors.push({ path: getPath(path), type: 'wrongModel' })
                    } else if (required && !value._id) {
                        errors.push({ path: getPath(path), type: 'required' })
                    }
                } else {
                    const error = validate(Runtime.BSON.ObjectId, value, required, settings, Model)
                    if (error) {
                        errors.push({ path: getPath(path), type: error })
                    }
                }
            } else {
                const error = validate(type, value, required, settings, Model)
                if (error) {
                    errors.push({ path: getPath(path), type: error })
                }
            }
        }

        for (const fieldName in this.fields) {
            if (Object.prototype.hasOwnProperty.call(this.fields, fieldName)) {
                const fieldInfo = this.fields[fieldName]
                const fieldValue = this[fieldName]
                const isArray = Array.isArray(fieldInfo.type)

                if (isArray) {
                    if (!Array.isArray(fieldValue)) {
                        errors.push({ path: getPath(fieldName), type: 'invalidType' })
                    } else {
                        if (isRequired(fieldInfo.required) && (!fieldValue || !fieldValue.length)) {
                            errors.push({ path: getPath(fieldName), type: 'required' })
                        }

                        if (fieldValue) {
                            fieldValue.forEach((subValue, subIndex) => {
                                validateValue(
                                    fieldInfo.type[0],
                                    fieldInfo,
                                    subValue,
                                    `${fieldName}.${subIndex}`
                                )
                            })
                        }
                    }
                } else {
                    validateValue(fieldInfo.type, fieldInfo, this[fieldName], fieldName)
                }

                if (fieldInfo.validate && typeof fieldInfo.validate === 'function') {
                    const customError = fieldInfo.validate(this)
                    let customErrors = null

                    if (Array.isArray(customError)) {
                        customErrors = customError
                    } else if (customError) {
                        customErrors = [customError]
                    }

                    if (customErrors) {
                        // eslint-disable-next-line no-loop-func
                        customErrors.forEach(err => {
                            const path = getPath(fieldName)

                            if (typeof err === 'string') {
                                errors.push({ path, type: err })
                            } else if (typeof err === 'object') {
                                const subPath = err.path
                                const newPath = subPath ? `${path}.${subPath}` : path

                                errors.push({ path: newPath, type: err.type })
                            }
                        })
                    }
                }
            }
        }

        if (errors.length) {
            if (returnErrors) {
                return errors
            }

            throw new ValidationError(errors)
        }

        return null
    }

    async load(
        queryOrId,
        {
            allowRemoved = false,
            throwOnMissingQuery = true,
            cache = false,
            onCacheUpdated,
            onCacheAlreadyUpdated,
            deserializeOptions = {},
            throwSecurityError = false,
        } = {},
        ignoreTestUUID = false
    ) {
        let queryIgnoreTestUUID = ignoreTestUUID

        if (!this.constructor.CollectionName) {
            throw new Error(`Missing collection on ${this.constructor.name}`)
        }

        if (!queryOrId) {
            queryOrId = this._id
        }

        if (!queryOrId) {
            if (throwOnMissingQuery) {
                throw new Error('No ID for model to load from.')
            }

            return false
        }

        if (typeof queryOrId === 'string' || isInstanceOfObjectId(queryOrId)) {
            queryOrId = { _id: Model.id(queryOrId) }
            // enforce ignoring test uuid if loading directly from model id
            queryIgnoreTestUUID = true
        }

        const query = this.constructor.createQuery(queryOrId, null, {
            allowRemoved,
            ignoreTestUUID: queryIgnoreTestUUID,
            cache,
            throwSecurityError
        })

        // If we get a cache function wrap it as we handle a single document, only
        if (typeof cache === 'function') {
            const originalCache = cache
            cache = documents => {
                const thisDocument = documents[0]
                if (thisDocument) {
                    return originalCache(thisDocument)
                }
                return false
            }
        }

        const document =
            (
                await query.execute(
                    1,
                    0,
                    {
                        rawDocuments: true,
                        cache,
                        onCacheAlreadyUpdated,
                    },
                    updatedDocuments => {
                        if (updatedDocuments[0]) {
                            this.deserialize(updatedDocuments[0], { showWarnings: true })
                            if (onCacheUpdated) {
                                onCacheUpdated(this)
                            }
                        }
                    })
            )[0] || null

        if (document) {
            return this.deserialize(document, { showWarnings: true, ...deserializeOptions })
        }

        return false
    }

    async watch() {
        if (!Model.canWatch) {
            throw new Error('Watching not supported.')
        }

        if (!this._id) {
            throw new Error(`Invalid document to watch for on ${this.constructor.name}. Requires an _id.`)
        }

        if (this._watcher) {
            throw new Error(`Already installed a watcher on ${this.constructor.name}.`)
        }

        this._watcher = await aquireWatcher(this._collection, [this._id], (operation, document) => {
            if (operation === 'update') {
                this.deserialize(document, { showWarnings: true })
            }
        })

        return true
    }

    async unwatch() {
        if (this._watcher) {
            releaseWatcher(this._watcher)
            this._watcher = null
        }
    }

    async save({
        autoInsert = true,
        doValidate = true,
        returnChangeSet = false,
        returnSecurityFailInfo = false,
        logResult = false,
        ...updateProps
    } = {}) {
        if (Model.isSubModel(this)) {
            throw new Error('Saving is only supported on root models')
        }

        // Validate now which throws if invalid
        if (doValidate) {
            this.validate()
        }

        // Normalize all fields before saving
        this.normalize()

        const isNewDocument = !this._id
        if (!autoInsert) {
            // No auto-insertion so throw here as this is an error
            throw new Error('New document can not be saved as autoInsert is set to false.')
        }

        // If the document was sucefully inserted
        let wasInserted = false

        // Collect all resources first that are either required to be stored
        // or cleared out within this save
        const resources = this.resources.filter(
            resource => resource.isStorable || (!isNewDocument && resource.isDeleteable)
        )

        // If is a new document we generate a new ObjectId
        // and set it, the it will be available on resourcePath getter
        let newDocumentId = null
        if (isNewDocument) {
            newDocumentId = new Runtime.BSON.ObjectId()
            this._id = newDocumentId
        }

        try {
            const fireListeners = (event, args) => {
                this.listeners?.map(listener => listener(this, event, args))
            }

            if (resources.length) {
                const { resourcePath } = this

                if (!resourcePath) {
                    throw new Error('Found resources but missing resourcePath.')
                }

                const resourcesStored = await Promise.all(
                    resources.map(resource =>
                        resource.isStorable ?
                            resource.store(resourcePath) :
                            resource.delete()
                    )
                )

                for (const res of resourcesStored) {
                    if (!res) {
                        throw new Error('Unable to store or delete one or more resources.')
                    }
                }
            }

            // If there's no id yet, upsert document first to ensure we receive a valid id
            // before storing as otherwise we might not be able to correctly store resources
            if (isNewDocument) {
                // here we set the _id to undefined, because it need to be null when we call the
                // serialize function
                this._id = undefined

                // we need to store the whole document even when we update later on
                // as otherwise some security rules may fail
                const document = this.serialize({
                    mode: 'save',
                    addTestUUID: process.env.NODE_ENV === 'test',
                })

                // then we set the _id on the serialized document
                document._id = newDocumentId

                const insertResult = await this._call('insert', document)

                if (!insertResult || !insertResult.insertedId) {
                    if (logResult) {
                        // eslint-disable-next-line no-console
                        console.log('insert result', insertResult)
                    }
                    throw new Error('Something went wrong trying to insert a document')
                }

                // update the _id with the value returned on insertedId, just for safety
                this._id = insertResult.insertedId

                wasInserted = true

                // mark saved here to get a new changeSet later on
                this.markSaved([])

                // Assume we changed everything so use our whole document as the changeSet
                // to be returned later on when there're no modifications
                const changeSet = flattenSerializedDocument(this.data)

                // fire the event listeaners
                fireListeners('inserted', { changeSet })

                // Nothing to do so return true as everything went well
                return returnChangeSet ? changeSet : true
            }

            const changeSet = cloneDeep(this.changeSet || {})

            if (this.isModified) {
                const result = await this.update(this.changeSet, {
                    ignoreUpdateFields: isNewDocument,
                    preventReload: true,
                    throwSecurityFailInfo: returnSecurityFailInfo,
                    ...updateProps,
                })

                if (!result) {
                    throw new Error('Unable to patch document')
                }

                // mark saved here
                this.markSaved()

                // fire the event listeaners
                fireListeners('updated', { changeSet })
            }

            return returnChangeSet ? changeSet : true
        } catch (outerError) {
            if (isNewDocument && !wasInserted) {
                // remove the generated id
                this._id = undefined
                // if the document was not inserted we delete resources
                await Promise.all(
                    resources.map(resource => resource.delete())
                )
            }

            if (isSecurityRuleError(outerError)) {
                return returnSecurityFailInfo ? 'securityForbidden' : false
            }

            throw outerError
        }
    }

    async update(
        updateData,
        {
            ignoreUpdateFields = false,
            preventReload = true,
            filter = {},
            throwSecurityFailInfo = false,
            ...updateProps
        } = {},
        ignoreTestUUID = false
    ) {
        if (!this._id) {
            throw new Error('Update can only be executed on existing documents.')
        }

        const updateDocument = convertUpdateDocument(updateData)
        if (!Object.keys(updateDocument).length) {
            // nothing to update so return immediately
            return true
        }

        // Append our update information to the update set if desired
        if (!ignoreUpdateFields) {
            updateDocument.$set = {
                ...updateDocument.$set,
                ...this._getAndSetUpdateFields(),
            }
        }

        try {
            const result = await this._call(
                'update',
                { ...filter, _id: this._id },
                updateDocument,
                updateProps
            )

            if (result && result.modifiedCount === 1) {
                if (!preventReload) {
                    await this.load(null, {}, ignoreTestUUID)
                }
                return true
            }

            return false
        } catch (error) {
            if (isSecurityRuleError(error)) {
                if (!throwSecurityFailInfo) {
                    return false
                }
            }

            throw error
        }
    }

    async remove({ returnSecurityFailInfo = false } = {}) {
        if (!this._id) {
            throw new Error(`Invalid document to remove on ${this.constructor.name}. Requires an _id.`)
        }

        try {
            const removedAt = new DateTime()
            const removedBy = Runtime.userId

            // Remove ourself now
            const removeResult = await this._call(
                'update',
                { _id: this._id },
                { $set: { removedAt: removedAt.toDate(), removedBy } },
                { upsert: false }
            )

            if (removeResult && removeResult.modifiedCount === 1) {
                // Mark ourself being removed
                this.removedAt = removedAt
                this.removedBy = removedBy

                return true
            }

            return false
        } catch (error) {
            if (isSecurityRuleError(error)) {
                return returnSecurityFailInfo ? 'securityForbidden' : false
            }

            throw error
        }
    }

    async __dangerouslyDelete() {
        if (!this._id) {
            throw new Error(`Invalid document to delete on ${this.constructor.name}. Requires an _id.`)
        }

        // Collect and delete all resources first if any
        const { resources } = this
        if (resources.length) {
            await Promise.all(resources.map(resource => resource.delete()))
        }

        try {
            // Delete ourself now
            const deleteResult = await this._collection.deleteOne({ _id: this._id })

            return deleteResult && deleteResult.deletedCount === 1
        } catch (error) {
            if (isSecurityRuleError(error)) {
                // eslint-disable-next-line no-console
                console.error(error)
                return false
            }

            throw error
        }
    }

    async populate(...fieldNames) {
        // Get all target fields as they might be nested. Then we collect the same Models population
        // together to have less requests instead of loading every model individually
        const populations = []

        fieldNames.forEach(fieldName => {
            const { parent, property } = this.getProperty(fieldName)

            let ParentModelClass = parent.constructor

            if (Array.isArray(parent)) {
                if (!parent.length) {
                    return
                }

                ParentModelClass = parent[0].constructor
            }

            if (parent !== this && !Model.isSubModel(ParentModelClass)) {
                throw new Error(`Invalid path to field population "${fieldName}"`)
            }

            const fieldInfo = ParentModelClass.Fields[property]
            if (!fieldInfo) {
                throw new Error(`Trying to populate an undefined field "${fieldName}.`)
            }

            const fieldType = Array.isArray(fieldInfo.type) ? fieldInfo.type[0] : fieldInfo.type
            if (!Model.isRootModel(fieldType)) {
                throw new Error(
                    `Field "${fieldName} is not a valid root model and such can not be populated.`
                )
            }

            const ModelClass = fieldType

            const instancesToPopulate = Array.isArray(parent) ? parent : [parent]

            // Merge into existing population if any
            let population = populations.find(p => p.ModelClass === ModelClass)
            if (!population) {
                population = {
                    ModelClass,
                    instances: [],
                }
                populations.push(population)
            }

            // Add all of our instances and property to be populated
            instancesToPopulate.forEach(instance => {
                population.instances.push({
                    instance,
                    property,
                })
            })
        })

        // Iterate all required populations now, extract their values to be replaced
        // and populate each Model via combined queries here
        if (!populations.length) {
            // Nothing to do so done here
            return true
        }

        const modelQueries = []

        populations.forEach(({ ModelClass, instances }) => {
            const idsToPopulate = {}

            const maybeAddId = (_id, setter) => {
                if (isInstanceOfObjectId(_id)) {
                    const id = _id.toString()

                    if (!idsToPopulate[id]) {
                        idsToPopulate[id] = {
                            _id,
                            setters: [setter],
                        }
                    } else {
                        idsToPopulate[id].setters.push(setter)
                    }
                }
            }

            instances.forEach(({ instance, property }) => {
                const value = instance[property]

                if (!value) {
                    return
                }

                if (Array.isArray(value)) {
                    value.forEach((val, index) =>
                        maybeAddId(val, model => {
                            value[index] = model
                        })
                    )
                } else {
                    maybeAddId(value, model => {
                        instance[property] = model
                    })
                }
            })

            if (Object.keys(idsToPopulate).length > 0) {
                modelQueries.push({ ModelClass, idsToPopulate })
            }
        })

        if (!modelQueries.length) {
            // Nothing to do so done here
            return true
        }

        const populateModel = async ({ ModelClass, idsToPopulate }) => {
            const _ids = Object.values(idsToPopulate).map(({ _id }) => _id)

            const idsQuery = { _id: { $in: _ids } }

            if (this.organisation) {
                const modelToPopulate = new ModelClass()
                if (modelToPopulate.fields.organisation) {
                    // if model has organisation, append it so we can correctly
                    // check for owner/scope permissions on backend
                    idsQuery.organisation = Model.id(this.organisation)
                }
            }

            const query = ModelClass.createQuery(idsQuery)
            const models = await query.execute()

            if (models.length !== _ids.length) {
                // something went wrong so better fail here
                return false
            }

            for (const id in idsToPopulate) {
                if (Object.prototype.hasOwnProperty.call(idsToPopulate, id)) {
                    const { setters } = idsToPopulate[id]
                    const model = models.find(m => m.id === id)

                    if (!model) {
                        // Something horribly happened so fail here
                        return false
                    }

                    setters.forEach(setter => setter(model))
                }
            }

            return true
        }

        const result = await Promise.all(modelQueries.map(populateModel))

        for (const res of result) {
            if (res !== true) {
                return false
            }
        }

        return true
    }

    serialize({
        ignoreModelFields = false,
        mode = 'all',
        serializeRootModels = false,
        parent = undefined,
        addTestUUID = false,
        keepDateTime = false,
    } = {}) {
        const document = {}

        // This function change the value param, if you refactor this function
        // have in mind that you need to take care and ensure that the property
        // __dt can be setted
        const convertObjectDeeply = (value, property = null, parentObject = null) => {
            if (value === undefined || value === null) {
                return
            }

            // run converstion for array
            if (Array.isArray(value)) {
                value.forEach((arrayValue, index) => convertObjectDeeply(arrayValue, index, value))
                return
            }

            // convert DateTime, DateTime.Data, DateTime.Time
            if (value instanceof DateTime && !keepDateTime) {
                if (parentObject && property) {
                    parentObject[property] = value.toDate()
                    if (!Array.isArray(parentObject)) {
                        parentObject[`${property}__dt`] = value.type
                    }
                }

                return
            }

            // run converstion for object
            if (typeof value === 'object') {
                Object.keys(value).forEach(objectProperty => {
                    convertObjectDeeply(value[objectProperty], objectProperty, value)
                })
            }
        }

        const serializeValue = (type, value) => {
            if (Model.isSubModel(value)) {
                // We always ignore model fields on sub models
                const subModelValue = value.serialize({
                    ignoreModelFields: true,
                    mode,
                    parent: parent || this,
                    keepDateTime
                })

                return subModelValue
            }

            if (Model.isRootModel(value)) {
                if (serializeRootModels) {
                    return value.serialize({
                        ignoreModelFields,
                        mode,
                        serializeRootModels,
                        parent: parent || this,
                        keepDateTime
                    })
                }

                return value && value._id ? value._id : null
            }

            if (value instanceof DateTime && !keepDateTime) {
                // Convert our DateTime into a regular utc date for storing
                return value.toDate()
            }

            if (typeof value === 'object') {
                if (value && type === Object) {
                    const clonedValue = cloneDeep(value)
                    convertObjectDeeply(clonedValue)
                    value = clonedValue
                }

                const objectValue = Runtime.mobx ? Runtime.mobx && Runtime.mobx.toJS(value) : value

                return objectValue
            }

            return value
        }

        for (const fieldName in this.fields) {
            if (Object.prototype.hasOwnProperty.call(this.fields, fieldName)) {
                // Ignore model properties if desired
                if (ignoreModelFields) {
                    if (Object.prototype.hasOwnProperty.call(Model.Fields, fieldName)) {
                        continue
                    }
                }

                // Ignore ids on submodels
                if (fieldName === '_id' && Model.isSubModel(this)) {
                    continue
                }

                const fieldInfo = this.fields[fieldName]
                const fieldValue = this[fieldName]

                // Ignored fields are always ignored from serialize
                if (fieldInfo.ignored === true) {
                    continue
                }

                if (mode === 'save') {
                    // Ignore fields that are marked to be not serialized
                    if (fieldInfo.serialize === false) {
                        continue
                    }

                    // Ignore fields that are marked to be serialized for insert only
                    // when our document is already saved
                    if (fieldInfo.serialize === 'insert' && !!this._id) {
                        continue
                    }

                    // Ignore fields which serialize function returned false
                    if (typeof fieldInfo.serialize === 'function' && !fieldInfo.serialize(this, parent)) {
                        continue
                    }
                }

                if (Array.isArray(fieldInfo.type)) {
                    document[fieldName] = (fieldValue || []).map(value => {
                        return serializeValue(fieldInfo.type[0], value, fieldInfo, true)
                    })
                } else if (fieldValue !== undefined) {
                    document[fieldName] = serializeValue(fieldInfo.type, fieldValue, fieldInfo)
                }
            }
        }

        // Ugly hack - in test mode we add an "invisible" uuid to the documents
        // so we hopefully don't clanch with other tests running in parallel
        if (addTestUUID && !this._id) {
            document.__testUUID = process.env.MODEL_TEST_UUID
        }

        return document
    }

    deserialize(
        document,
        {
            showWarnings = false,
            noReset = false,
            ignoreModelFields = false,
            markSaved = true,
            unhandledFields = [],
            parent = '',
            ignore = []
        } = {}
    ) {
        // This function change the value param, if you refactor this function
        // have in mind that you need to take care and ensure that the property
        // __dt can be readed
        const convertObjectDeeply = (value, path, property = null, parentObject = null) => {
            if (value === undefined || value === null) {
                return
            }

            const newPath = property ? `${path}${property}.` : path

            // run converstion for array
            if (Array.isArray(value)) {
                value.forEach((arrayValue, index) => convertObjectDeeply(arrayValue, newPath, index, value))
                return
            }

            // convert Date to type especified in "__dt" helper property
            if (value instanceof Date) {
                if (parentObject && property) {
                    // default is "date"/DateTime.Date
                    const dateTypePropertyName = `${property}__dt`
                    const dateType = parentObject[dateTypePropertyName]
                    let DateClass = DateTime.Date
                    if (dateType === 'time') {
                        DateClass = DateTime.Time
                    } else if (dateType === 'datetime') {
                        DateClass = DateTime
                    } else if (!dateType && path) {
                        // add to unhandledFields
                        unhandledFields.push(`${path}${dateTypePropertyName}`)
                    }

                    try {
                        parentObject[property] = new DateClass(value, { forceUTC: true })
                    } catch (e) {
                        // fall-through
                    }
                    if (parentObject[dateTypePropertyName]) {
                        delete parentObject[dateTypePropertyName]
                    }
                }

                return
            }

            // run converstion for object
            if (typeof value === 'object') {
                Object.keys(value).forEach(objectProperty => {
                    convertObjectDeeply(value[objectProperty], newPath, objectProperty, value)
                })
            }
        }

        const deserializeValue = (type, path, settings, value) => {
            if (value === null || value === undefined || type === String) {
                return value
            }

            // Try to do some "smart" conversions here
            if (type === Runtime.BSON.ObjectId || Model.isRootModel(type)) {
                if (typeof value === 'string' && Model.isId(value)) {
                    value = new Runtime.BSON.ObjectId(value)
                }
            }

            else if (type === DateTime || type === DateTime.Date || type === DateTime.Time) {
                try {
                    const DateClass = type
                    value = new DateClass(typeof value === 'string' ? new Date(value) : value, {
                        forceUTC: true,
                    })
                } catch (e) {
                    // fall-through
                }
            }

            else if (type === Number) {
                if (typeof value === 'string') {
                    // we always parse as float as later on we'll validate against wholeNumber if given
                    const number = Number.parseFloat(value)
                    if (!Number.isNaN(number)) {
                        value = number
                    }
                }
            }

            else if (type === Boolean) {
                if (typeof value === 'string') {
                    if (['true', 'on', 'yes'].includes(value)) {
                        value = true
                    }
                    if (['false', 'off', 'no'].includes(value)) {
                        value = false
                    }
                } else if (typeof value === 'number') {
                    if (value === 1) {
                        value = true
                    }
                    if (value === 0) {
                        value = false
                    }
                }
            }

            else if (type === Object) {
                const cloneValue = cloneDeep(value)
                convertObjectDeeply(cloneValue, path)
                return cloneValue
            }

            return value
        }

        const deserializeSubModel = (instance, ModelClass, fieldName, modelParent, value) => {
            let isFreshInstance = false

            if (!instance) {
                instance = new ModelClass(null, this, fieldName)
                // set frash instance to true and avoid to run desnecessary code
                isFreshInstance = true
            }

            if (ModelClass.IS_RESOURCE) {
                if (!value) {
                    if (!isFreshInstance) {
                        instance.clear()
                    }
                } else if (typeof value === 'string') {
                    let type = null
                    let base64 = value

                    const semicolonIndex = value.indexOf(';')
                    if (semicolonIndex > 0) {
                        type = value.substring(0, semicolonIndex)
                        base64 = value.substr(semicolonIndex + 1)
                    }

                    if (base64) {
                        instance.fromBase64(base64, type, nanoid())
                    }
                } else {
                    instance.deserialize(value, {
                        unhandledFields,
                        parent: modelParent,
                        noReset: isFreshInstance
                    })
                }
            } else {
                instance.deserialize(value, {
                    unhandledFields,
                    parent: modelParent,
                    noReset: isFreshInstance
                })
            }

            return instance
        }

        if (!noReset) {
            this.reset()
        }

        if (!document) {
            this.markSaved(unhandledFields)

            return true
        }

        for (const fieldName in this.fields) {
            // TODO should we support ignoring nested fields here?
            if (ignore.includes(fieldName)) {
                continue
            }

            // Ignore computed properties
            if (this.isComputedProp(fieldName)) {
                continue
            }

            // Ignore model properties if desired
            if (ignoreModelFields) {
                if (Object.prototype.hasOwnProperty.call(Model.Fields, fieldName)) {
                    continue
                }
            }

            const fieldInfo = this.fields[fieldName]

            // Ignore fields that are marked to be not deserialized or are ignored
            if (fieldInfo.deserialize === false || fieldInfo.ignored === true) {
                continue
            }

            if (!Object.prototype.hasOwnProperty.call(document, fieldName)) {
                if (fieldInfo.default !== undefined) {
                    unhandledFields.push(`${parent}${fieldName}`)
                }
                continue
            }

            const propertyValue = document[fieldName]

            if (Array.isArray(fieldInfo.type)) {
                this[fieldName] = (propertyValue || []).map((value, idx) => {
                    const path = `${parent}${fieldName}.${idx}.`
                    if (Model.isSubModel(fieldInfo.type[0])) {
                        return deserializeSubModel(null, fieldInfo.type[0], fieldName, path, value)
                    }

                    return deserializeValue(fieldInfo.type[0], path, fieldInfo, value)
                })
            } else if (Model.isSubModel(fieldInfo.type)) {
                deserializeSubModel(
                    this[fieldName],
                    fieldInfo.type,
                    fieldName,
                    `${parent}${fieldName}.`,
                    propertyValue
                )
            } else if (propertyValue !== undefined && !fieldInfo.get) {
                this[fieldName] = deserializeValue(
                    fieldInfo.type,
                    `${parent}${fieldName}.`,
                    fieldInfo,
                    propertyValue
                )
            } else if (fieldInfo.default !== undefined) {
                // if default is not undefined, we include on default fields that were not deserialized
                unhandledFields.push(`${parent}${fieldName}`)
            }
        }

        if (process.env.NODE_ENV === 'development' && showWarnings) {
            const missingFieldsOnTarget = []
            const missingFieldsOnSource = []

            for (const prop in document) {
                if (!Object.prototype.hasOwnProperty.call(this.fields, prop)) {
                    missingFieldsOnTarget.push(prop)
                }
            }

            for (const fieldName in this.fields) {
                // Ignore ids on submodels
                if (fieldName === '_id' && Model.isSubModel(this)) {
                    continue
                }

                if (
                    !Object.prototype.hasOwnProperty.call(document, fieldName) &&
                    !['removedAt', 'removedBy'].includes(fieldName)
                ) {
                    missingFieldsOnSource.push(fieldName)
                }
            }

            if (missingFieldsOnTarget.length) {
                // eslint-disable-next-line no-console
                console.warn(
                    `Missing fields ${missingFieldsOnTarget
                        .map(prop => `${prop}=${document[prop]}`)
                        .join(', ')} in ${this.constructor.name}. Is this by intention?`
                )
            }

            if (missingFieldsOnSource.length) {
                // eslint-disable-next-line no-console
                console.warn(
                    `No properties for fields ${missingFieldsOnSource.join(', ')} in ${
                        this.constructor.name
                    } found in source document. Will all be set to their defaults. Is this by intention?`
                )
            }
        }

        if (markSaved) {
            this.markSaved(unhandledFields)
        }

        return true
    }

    // eslint-disable-next-line class-methods-use-this
    get resourcePath() {
        return null
    }

    get resources() {
        let resources = []

        for (const fieldName in this.fields) {
            if (Object.prototype.hasOwnProperty.call(this.fields, fieldName)) {
                const fieldInfo = this.fields[fieldName]
                const fieldType = Array.isArray(fieldInfo.type) ? fieldInfo.type[0] : fieldInfo.type

                if (!this[fieldName]) {
                    continue
                }

                if (Model.isSubModel(fieldType) && !fieldType.IS_RESOURCE) {
                    if (Array.isArray(fieldInfo.type)) {
                        resources = resources.concat(
                            this[fieldName].map(subModel => subModel.resources).flat()
                        )
                    } else {
                        resources = resources.concat(this[fieldName].resources)
                    }
                } else if (fieldType.IS_RESOURCE) {
                    if (Array.isArray(fieldInfo.type)) {
                        resources = resources.concat(this[fieldName])
                    } else {
                        resources.push(this[fieldName])
                    }
                }
            }
        }

        return resources
    }

    normalize() {
        for (const fieldName in this.fields) {
            // Ignore computed properties
            if (this.isComputedProp(fieldName)) {
                continue
            }

            const fieldInfo = this.fields[fieldName]
            const fieldType = Array.isArray(fieldInfo.type) ? fieldInfo.type[0] : fieldInfo.type

            if (Model.isRootModel(fieldType)) {
                continue
            }

            // Handle sub models first as they're special
            if (Model.isSubModel(fieldType)) {
                if (Array.isArray(fieldInfo.type)) {
                    const value = this[fieldName]
                    if (value) {
                        value.forEach(model => model && model.normalize())
                    }
                } else {
                    this[fieldName].normalize()
                }

                continue // done here
            }

            if (!fieldInfo.normalize) {
                continue
            }

            if (Array.isArray(fieldInfo.type)) {
                const value = this[fieldName]
                if (value) {
                    this[fieldName] = value.map(arrValue => {
                        if (arrValue === null || arrValue === undefined) {
                            return value
                        }
                        return fieldInfo.normalize(arrValue)
                    })
                }
            } else {
                const value = this[fieldName]
                if (value !== null && value !== undefined) {
                    this[fieldName] = fieldInfo.normalize(value)
                }
            }
        }
    }

    reset({ ignoreSubModelFields = false } = {}) {
        for (const fieldName in this.fields) {
            // Ignore computed properties
            if (this.isComputedProp(fieldName)) {
                continue
            }

            const fieldInfo = this.fields[fieldName]

            if (Model.isSubModel(fieldInfo.type)) {
                if (!ignoreSubModelFields) {
                    this[fieldName].reset()
                }
            } else if (fieldInfo.default !== undefined) {
                this[fieldName] =
                    typeof fieldInfo.default === 'function' ? fieldInfo.default() : fieldInfo.default
            } else if (Array.isArray(fieldInfo.type)) {
                this[fieldName] = []
            } else if (!['removedAt', 'removedBy'].includes(fieldName) && !fieldInfo.get) {
                this[fieldName] = null
            }
        }

        this.markSaved()
    }

    _instantiateModel() {
        for (const fieldName in this.fields) {
            // Ignore computed properties
            if (this.isComputedProp(fieldName)) {
                continue
            }

            // Bail if trying to access non-computed properties
            if (Runtime.mobx && !Runtime.mobx.isObservableProp(this, fieldName)) {
                throw new Error(
                    `None observable field ${fieldName} on ${this.constructor.name}. Did you forget to call decorateModel?`
                )
            }

            const fieldInfo = this.fields[fieldName]

            if (!fieldInfo.type) {
                throw new Error(`Missing type on field ${fieldName} on ${this.constructor.name}.`)
            }

            if (Array.isArray(fieldInfo.type)) {
                this[fieldName] = []
            } else if (Model.isSubModel(fieldInfo.type)) {
                const ModelClass = fieldInfo.type
                let classInstance = null

                if (fieldInfo.default) {
                    if (typeof fieldInfo.default !== 'function') {
                        throw new Error(`Expected constructor function for submodel on field "${fieldName}".`)
                    }

                    classInstance = fieldInfo.default(this, fieldName)

                    if (!(classInstance instanceof ModelClass)) {
                        throw new Error(
                            `Constructor function for submodel on field "${fieldName}" returned none or wrong class instance.`
                        )
                    }
                } else {
                    classInstance = new ModelClass(null, this, fieldName)
                }

                this[`_${fieldName}`] = classInstance
                Object.defineProperty(this, fieldName, {
                    get() {
                        return this[`_${fieldName}`]
                    },
                    set() {
                        throw new Error(`Sub model ${fieldName} on ${this.constructor.name} is read-only`)
                    },
                })
            }
        }

        // Reset everything to their default values for initial data
        // Ignore fields derived from Model because they were already reset in the instantiation
        this.reset({ ignoreSubModelFields: true })
    }

    _getAndSetUpdateFields() {
        this.updatedAt = new DateTime()
        this.updatedBy = Runtime.userId

        return {
            updatedAt: this.updatedAt.toDate(),
            updatedBy: this.updatedBy,
        }
    }

    isComputedProp(fieldName) {
        if (Runtime.mobx) {
            return Runtime.mobx.isComputedProp(this, fieldName)
        }

        const fieldInfo = this.fields[fieldName]

        if (!fieldInfo) {
            throw new Error(`Field ${fieldName} not found on ${this.constructor.name}.`)
        }

        return !!fieldInfo.get
    }

    // eslint-disable-next-line class-methods-use-this
    getMergeTagProperties() {
        return {}
    }

    get mergeTags() {
        const result = {}

        const properties = this.getMergeTagProperties()

        const isValidValue = value => value !== null && value !== undefined && value !== ''

        const i18nLocal = i18n()

        // eslint-disable-next-line guard-for-in
        for (const property in properties) {
            const definition = properties[property]
            const value = this.getPropertyValue(definition.key)

            if (isValidValue(value)) {
                if (definition.asString) {
                    const asStringResult = definition.asString(value, i18nLocal)
                    if (isValidValue(asStringResult)) {
                        result[property] = asStringResult
                    }
                } else if (Array.isArray(value)) {
                    if (value.length) {
                        result[property] = value.join(', ')
                    }
                } else if (typeof value === 'number') {
                    result[property] = i18nLocal.formatNumber(value, definition.formatOptions)
                } else if (value instanceof DateTime) {
                    result[property] = i18nLocal.formatDate(value)
                } else if (definition.formatOptions) {
                    result[property] = i18nLocal.formatValue(
                        'string',
                        value.toString(),
                        '', // empty value
                        definition.formatOptions
                    )
                } else {
                    result[property] = value.toString()
                }
            }
        }

        return result
    }
}
